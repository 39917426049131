import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { homeworkApi } from '../../../api'
import { useCourse, useCourses } from '../../../hooks/courses'
import { useOutsideClick } from '../../../hooks/ui'
import arrowDownIcon from './arrowDownIcon.svg'
import arrowUpIcon from './arrowUpIcon.svg'
import calendarIcon from './calendarIcon.svg'
import cancellAllIcon from './cancelAllIcon.svg'
import clearIcon from './clearIcon.svg'
import emptyStarsIcon from './emptyStarsIcon.svg'
import expandedIcon from './expandedIcon.svg'
import greenStarsIcon from './greenStarsIcon.svg'
import listIcon from './listIcon.svg'
import notExpandedIcon from './notExpandedIcon.svg'
import orangeStarsIcon from './orangeStarsIcon.svg'
import redStarsIcon from './redStarsIcon.svg'
import selectArrow from './select_arrow.svg'

const DropdownMenu = ({
  selectedValue,
  options,
  optionLabelKey,
  customOptionLabelGet,
  label,
  additionalClassName = '',
  wrapperClassName = '',
  setValue = () => {},
  disabled = false
}) => {
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, () => setOpen(false))

  const toggleOpen = () => {
    setOpen(!open)
  }

  const onSelect = option => () => {
    console.log('onSelect')
    setValue(option)
  }

  return (
    <div className={`relative ${wrapperClassName}`}>
      <div ref={wrapperRef}>
        <div
          className={`cursor-pointer py-[15px] px-4 rounded-lg bg-[#00C5A11F]/[12%] text-[#1A1A1A] text-[15px] font-medium pr-13 ${additionalClassName}`}
          {...(!disabled ? { onClick: toggleOpen } : {})}
        >
          <span>
            {selectedValue
              ? customOptionLabelGet
                ? customOptionLabelGet(selectedValue).length > 20
                  ? `${customOptionLabelGet(selectedValue).slice(0, 20)}...`
                  : customOptionLabelGet(selectedValue)
                : selectedValue?.[optionLabelKey]?.length > 20
                  ? `${selectedValue?.[optionLabelKey].slice(0, 20)}...`
                  : selectedValue?.[optionLabelKey]
              : 'Все'}
          </span>
        </div>
        <p
          className="cursor-pointer absolute left-4 top-[9px] text-[#AFB7CA] text-xs font-medium"
          onClick={toggleOpen}
        >
          {label}
        </p>
        {!disabled && (
          <img
            src={selectArrow}
            className="cursor-pointer absolute right-3 top-[11px]"
            onClick={toggleOpen}
          />
        )}
      </div>
      {open && (
        <div className="absolute z-[50] top-[54px] w-full bg-white flex flex-col text-unix-black max-h-[50vh] overflow-y-auto">
          {options.map((o, i) => (
            <div
              key={i}
              className="hover:bg-[#F0F3FA] px-4 py-2 cursor-pointer"
              onClick={onSelect(o)}
            >
              <span>
                {customOptionLabelGet
                  ? customOptionLabelGet(o)
                  : o?.[optionLabelKey]}
              </span>
              {i !== options.length - 1 && (
                <hr className="relative top-[7px]" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

const NestedDropdownMenu = ({
  selectedValue,
  options,
  optionLabelKey,
  optionValueKey,
  setValue,
  deleteCourses
}) => {
  const [open, setOpen] = useState(false)
  const [expandedTopics, setExpandedTopics] = useState(new Set())
  const [selectedLessons, setSelectedLessons] = useState(
    new Set(Array.isArray(selectedValue) ? selectedValue : [])
  )

  const dropdownRef = React.useRef(null)

  React.useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const toggleDropdown = () => setOpen(!open)

  const toggleTopicExpansion = topicId => {
    const newExpandedTopics = new Set(expandedTopics)
    if (newExpandedTopics.has(topicId)) {
      newExpandedTopics.delete(topicId)
    } else {
      newExpandedTopics.add(topicId)
    }
    setExpandedTopics(newExpandedTopics)
  }

  const handleTopicSelect = topic => () => {
    const newSelectedLessons = new Set(selectedLessons)
    const lessons = topic.lessons || []

    if (lessons.every(lesson => newSelectedLessons.has(lesson.id))) {
      lessons.forEach(lesson => newSelectedLessons.delete(lesson.id))
    } else {
      lessons.forEach(lesson => newSelectedLessons.add(lesson.id))
    }

    setSelectedLessons(newSelectedLessons)

    setValue(Array.from(newSelectedLessons))
  }

  const handleLessonSelect = lesson => () => {
    const lessonId = lesson.id
    const newSelectedLessons = new Set(selectedLessons)

    if (newSelectedLessons.has(lessonId)) {
      newSelectedLessons.delete(lessonId)
    } else {
      newSelectedLessons.add(lessonId)
    }

    setSelectedLessons(newSelectedLessons)

    setValue(Array.from(newSelectedLessons))
  }

  const isLessonSelected = lessonId => selectedLessons.has(lessonId)

  const areAllLessonsInTopicSelected = topic => {
    const lessons = topic.lessons || []
    return (
      lessons.length > 0 &&
      lessons.every(lesson => selectedLessons.has(lesson.id))
    )
  }

  useEffect(() => {
    if (deleteCourses) {
      setSelectedLessons(new Set())
      setExpandedTopics(new Set())
    }
  }, [deleteCourses])

  return (
    <div ref={dropdownRef} className="relative w-[150px] mr-[50px]">
      <div
        style={{
          border: selectedLessons.size > 0 ? '1px solid transparent' : 'none',
          borderImage:
            selectedLessons.size > 0
              ? 'linear-gradient(to right, #8F30BC 0%, #0068FF 100%) 1'
              : 'none',
          background:
            selectedLessons.size > 0
              ? 'linear-gradient(to right, rgba(143, 48, 188, 0.12) 0%, rgba(0, 104, 255, 0.12) 100%)'
              : '#F0F3FA',
          padding: '8px'
        }}
        className={` ${selectedLessons.size > 0 ? 'gap-[25px]' : ''} cursor-pointer relative py-2 px-4 border rounded-[8px] h-[52px] text-gray-700 flex justify-between items-center`}
        onClick={toggleDropdown}
      >
        <div className={`flex items-center gap-[6px]`}>
          <div className="flex items-center p-[8px] gap-[6px] rounded-[4px] text-[#1a1a1a] whitespace-nowrap">
            <img src={listIcon} alt="List Icon" />
            <span>{selectedLessons.size > 0 ? 'Выбрано' : 'Не выбрано'}</span>
          </div>
        </div>
        <div
          className={`flex bg-[#F0F3FA] rounded-[8px] items-center relative left-[30px] justify-center min-w-[48px] h-[52px]`}
        >
          <img
            src={open ? arrowUpIcon : arrowDownIcon}
            alt="Dropdown arrow"
            className=""
          />
        </div>
      </div>
      {open && (
        <div
          className="absolute top-full z-[50] left-0 mt-2 border rounded bg-white shadow-lg max-h-60 overflow-auto"
          style={{ width: '480px' }}
        >
          <p className="text-[#5F6B88] text-[16px] font-medium py-[16.5px] pl-[16px]">
            Аттестация №1
          </p>
          {options.length > 0 ? (
            options.map(option => (
              <div key={option[optionValueKey]}>
                <div
                  className="cursor-pointer py-2 px-4 flex justify-between items-center hover:bg-gray-200"
                  onClick={() => toggleTopicExpansion(option[optionValueKey])}
                >
                  <div className="flex items-center gap-2 flex-grow">
                    <img
                      src={
                        expandedTopics.has(option[optionValueKey])
                          ? expandedIcon
                          : notExpandedIcon
                      }
                      alt="Expand/Collapse Icon"
                    />
                    <div
                      className="flex items-center pl-[12px] flex-grow rounded-[8px]"
                      style={{ backgroundColor: 'rgba(143, 48, 188, 0.12)' }}
                    >
                      <input
                        type="checkbox"
                        checked={areAllLessonsInTopicSelected(option)}
                        onChange={handleTopicSelect(option)}
                        onClick={e => e.stopPropagation()}
                        style={{ display: 'block' }}
                      />
                      <span className="text-[15px] ml-[12px] py-[8.5px] text-[#1A1A1A] font-medium">
                        {option[optionLabelKey]}
                      </span>
                    </div>
                  </div>
                </div>
                {expandedTopics.has(option[optionValueKey]) && (
                  <div className="pl-8">
                    {option.lessons.map((lesson, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-2 flex-grow"
                      >
                        <div
                          className="flex items-center pl-[13px] mb-[4px] mr-[15px] py-[8.5px] ml-[27px] flex-grow rounded-[8px]"
                          style={{ backgroundColor: 'rgba(0, 104, 255, 0.12)' }}
                        >
                          <input
                            type="checkbox"
                            checked={isLessonSelected(lesson.id)}
                            onChange={handleLessonSelect(lesson)}
                            onClick={e => e.stopPropagation()}
                            style={{ display: 'block' }}
                          />
                          <span className="ml-[12px]">{lesson.title}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="py-2 px-4 text-gray-500">No options available</div>
          )}
        </div>
      )}
    </div>
  )
}

const Feedback = () => {
  const [feedbackData, setFeedbackData] = useState([])
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [topicsWithLessons, setTopicsWithLessons] = useState([])
  const [uniqueUsers, setUniqueUsers] = useState([])
  const [selectedTopicsAndLessons, setSelectedTopicsAndLessons] = useState({
    topics: [],
    lessons: []
  })
  const [users, setUsers] = useState(null)
  const [sortColumn, setSortColumn] = useState(null)
  const [sortDirection, setSortDirection] = useState('asc')
  const [selectedRatings, setSelectedRatings] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const [startPeriod, setStartPeriod] = useState('')
  const [endPeriod, setEndPeriod] = useState('')
  const [deleteCourses, setDeleteCourses] = useState(false)

  const convertToISOString = dateString => {
    const date = new Date(dateString)
    return date.toISOString()
  }

  console.log(startPeriod, ' ', endPeriod)

  const formatDateToHumanReadable = dateString => {
    const date = new Date(dateString)
    return date.toISOString().split('T')[0]
  }

  const handleStartPeriod = date => {
    const isoDate = convertToISOString(date)
    if (isoDate) {
      setStartPeriod(isoDate)
    }
  }

  const handleEndPeriod = date => {
    const isoDate = convertToISOString(date)
    if (isoDate) {
      setEndPeriod(isoDate)
    }
  }

  const courses = useCourses()

  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const courseIds = selectedCourse?.id ? [selectedCourse.id] : []
        const lessonIds = selectedTopicsAndLessons || []
        const userIds = users?.id ? [users.id] : []
        const ratingsRange = selectedRatings.length > 0 ? selectedRatings : []
        const search = searchInput || []
        const periodStart = startPeriod || []
        const periodEnd = endPeriod || []

        console.log('period start ', periodStart)

        const response = await homeworkApi.getFeedback(
          courseIds,
          lessonIds,
          userIds,
          ratingsRange,
          search,
          periodStart,
          periodEnd
        )

        if (response.data) {
          setFeedbackData(response.data)
        } else {
          console.error('Error fetching feedback:', response.error)
        }
      } catch (error) {
        console.error('An error occurred:', error)
      }
    }

    fetchFeedback()
  }, [
    selectedCourse,
    selectedTopicsAndLessons,
    users,
    selectedRatings,
    searchInput,
    startPeriod,
    endPeriod
  ])

  useEffect(() => {
    const uniqueUsers = []

    if (!users || users.length === 0) {
      feedbackData.forEach(feedback => {
        const existingUser = uniqueUsers.find(
          user => user.userId === feedback.userId
        )
        if (!existingUser) {
          uniqueUsers.push({
            userId: feedback.userId,
            userFirstName: feedback.userFirstName,
            userLastName: feedback.userLastName
          })
        }
      })

      setUniqueUsers(uniqueUsers)
    }
  }, [feedbackData])

  const courseData = useCourse(selectedCourse?.id)

  useEffect(() => {
    if (courseData.topics) {
      setTopicsWithLessons(
        courseData.topics.map(topic => ({
          ...topic,
          isTopic: true,
          lessons: topic.lessons.map(lesson => ({
            ...lesson,
            isTopic: false
          }))
        }))
      )
    }
  }, [courseData])

  const handleRatingChange = rating => {
    setSelectedRatings(prevRatings => {
      if (prevRatings.includes(rating)) {
        return prevRatings.filter(r => r !== rating)
      } else {
        return [...prevRatings, rating]
      }
    })
  }

  const formatDate = dateString => {
    const date = new Date(dateString)

    const day = String(date.getUTCDate()).padStart(2, '0')
    const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-based
    const year = date.getUTCFullYear()
    const hours = String(date.getUTCHours()).padStart(2, '0')
    const minutes = String(date.getUTCMinutes()).padStart(2, '0')

    return `${day}.${month}.${year} ${hours}:${minutes}`
  }

  const handleClear = () => {
    setSelectedCourse(null)
    setSelectedTopicsAndLessons(null)
    setUsers(null)
    setSearchInput('')
    setStartPeriod(null)
    setEndPeriod(null)
    setDeleteCourses(true)
    setTimeout(() => setDeleteCourses(false), 0)
  }

  const nestedClear = () => {
    setSelectedTopicsAndLessons(null)
    setDeleteCourses(true)
    setTimeout(() => setDeleteCourses(false), 0)
  }

  const toggleSortDirection = () => {
    setSortDirection(prevDirection =>
      prevDirection === 'asc' ? 'desc' : 'asc'
    )
  }

  const handleSort = columnName => {
    if (sortColumn === columnName) {
      toggleSortDirection()
    } else {
      setSortColumn(columnName)
      setSortDirection('asc')
    }
  }

  const renderSortIndicator = columnName => {
    if (sortColumn === columnName) {
      return (
        <div className="flex flex-col">
          <img
            onClick={() => handleSort(columnName)}
            src={sortDirection === 'asc' ? arrowUpIcon : arrowUpIcon}
            alt="Sort"
            className="mb-[5px] min-w-[12px] min-h-[7px]"
            width={12}
            height={7}
            style={{ opacity: sortDirection === 'asc' ? 0.5 : 1 }}
          />
          <img
            onClick={() => handleSort(columnName)}
            src={sortDirection === 'desc' ? arrowDownIcon : arrowDownIcon}
            className="min-w-[12px] min-h-[7px]"
            alt="Sort"
            width={12}
            height={7}
            style={{ opacity: sortDirection === 'asc' ? 1 : 0.5 }}
          />
        </div>
      )
    }
    return (
      <div className="flex flex-col">
        <img
          src={sortDirection === 'asc' ? arrowUpIcon : arrowUpIcon}
          alt="Sort"
          className="mb-[5px] min-w-[12px] min-h-[7px]"
          width={12}
          height={7}
          style={{ opacity: 0.5 }}
        />
        <img
          src={sortDirection === 'desc' ? arrowDownIcon : arrowDownIcon}
          alt="Sort"
          className="min-w-[12px] min-h-[7px]"
          width={12}
          height={7}
          style={{ opacity: 0.5 }}
        />
      </div>
    )
  }

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="flex items-center bg-[#F0F3FA] rounded-[8px] w-[140px] relative">
      <input
        value={value}
        onClick={onClick}
        ref={ref}
        style={{
          backgroundColor: '#F0F3FA',
          fontSize: '15px',
          padding: '8px 12px',
          borderRadius: '8px',
          outline: 'none',
          border: 'none',
          width: '115px'
        }}
      />
      <div className="flex items-center gap-[4px] absolute right-[2px] z-[10]">
        <p className="text-[#1A1A1A]">-</p>
        <img
          onClick={onClick}
          src={calendarIcon}
          alt="Calendar Icon"
          style={{ width: '24px', height: '24px' }}
        />
      </div>
    </div>
  ))

  const sortUsers = users => {
    return users.sort((a, b) => {
      console.log('a ', a)

      switch (sortColumn) {
        case 'firstname':
          return sortDirection === 'asc'
            ? (a.userFirstName ?? '').localeCompare(
                b.userFirstName ?? '',
                'en',
                {
                  sensitivity: 'base'
                }
              )
            : (b.userFirstName ?? '').localeCompare(
                a.userFirstName ?? '',
                'en',
                {
                  sensitivity: 'base'
                }
              )
        case 'courseTitle':
          return sortDirection === 'asc'
            ? (a.courseTitle ?? '').localeCompare(b.courseTitle ?? '', 'en', {
                sensitivity: 'base'
              })
            : (b.courseTitle ?? '').localeCompare(a.courseTitle ?? '', 'en', {
                sensitivity: 'base'
              })
        case 'date':
          return sortDirection === 'asc'
            ? new Date(a.createdAt) - new Date(b.createdAt)
            : new Date(b.createdAt) - new Date(a.createdAt)
        case 'grade':
          return sortDirection === 'asc'
            ? (a.rating ?? 0) - (b.rating ?? 0)
            : (b.rating ?? 0) - (a.rating ?? 0)
        case 'comment':
          return sortDirection === 'asc'
            ? (a.description ?? '').length - (b.description ?? '').length
            : (b.description ?? '').length - (a.description ?? '').length
        case 'lessonTitle':
          return sortDirection === 'asc'
            ? (a.lessonTitle ?? '').localeCompare(b.lessonTitle ?? '', 'en', {
                sensitivity: 'base'
              })
            : (b.lessonTitle ?? '').localeCompare(a.lessonTitle ?? '', 'en', {
                sensitivity: 'base'
              })
        case 'topicTitle':
          return sortDirection === 'asc'
            ? (a.topicTitle ?? '').localeCompare(b.topicTitle ?? '', 'en', {
                sensitivity: 'base'
              })
            : (b.topicTitle ?? '').localeCompare(a.topicTitle ?? '', 'en', {
                sensitivity: 'base'
              })
        default:
          return 0
      }
    })
  }

  CustomInput.displayName = 'CustomInput'

  const renderRating = rating => {
    switch (rating) {
      case 5:
        return (
          <span className="text-[#3BBC30] flex items-center">
            {Array(5)
              .fill(null)
              .map((_, index) => (
                <img
                  className="w-[20px] h-[20px]"
                  key={index}
                  src={greenStarsIcon}
                  alt="Green star"
                />
              ))}
          </span>
        )
      case 4:
        return (
          <span className="text-[#3BBC30] flex items-center">
            {Array(4)
              .fill(null)
              .map((_, index) => (
                <img
                  className="w-[20px] h-[20px]"
                  key={index}
                  src={greenStarsIcon}
                  alt="Green star"
                />
              ))}
            <img
              className="w-[20px] h-[20px]"
              src={emptyStarsIcon}
              alt="Empty star"
            />
          </span>
        )
      case 3:
        return (
          <span className="text-[#FF9900] flex items-center">
            {Array(3)
              .fill(null)
              .map((_, index) => (
                <img
                  className="w-[20px] h-[20px]"
                  key={index}
                  src={orangeStarsIcon}
                  alt="Orange star"
                />
              ))}
            {Array(2)
              .fill(null)
              .map((_, index) => (
                <img
                  className="w-[20px] h-[20px]"
                  key={index}
                  src={emptyStarsIcon}
                  alt="Empty star"
                />
              ))}
          </span>
        )
      case 2:
        return (
          <span className="text-[#FF9900] flex items-center">
            {Array(2)
              .fill(null)
              .map((_, index) => (
                <img
                  className="w-[20px] h-[20px]"
                  key={index}
                  src={orangeStarsIcon}
                  alt="Orange star"
                />
              ))}
            {Array(3)
              .fill(null)
              .map((_, index) => (
                <img
                  className="w-[20px] h-[20px]"
                  key={index}
                  src={emptyStarsIcon}
                  alt="Empty star"
                />
              ))}
          </span>
        )
      case 1:
        return (
          <span className="text-[#FF9900] flex items-center">
            <img
              className="w-[20px] h-[20px]"
              src={redStarsIcon}
              alt="Red star"
            />
            {Array(4)
              .fill(null)
              .map((_, index) => (
                <img
                  className="w-[20px] h-[20px]"
                  key={index}
                  src={emptyStarsIcon}
                  alt="Empty star"
                />
              ))}
          </span>
        )
      default:
        return null
    }
  }

  return (
    <div className="w-[1280px] mx-auto max-2xl:w-[1100px]">
      <div
        className={
          'flex items-center bg-white p-[16px] mt-[32px] rounded-[12px]'
        }
      >
        <div>
          <div className="flex items-center">
            <div>
              <p className={'text-[14px] text-[#5F6B88] font-500'}>Курс</p>
              <DropdownMenu
                selectedValue={selectedCourse}
                options={courses}
                optionLabelKey={'title'}
                optionValueKey={'id'}
                selectedCondition={(o, s) => o.id === s?.id}
                additionalClassName="py-[16.5px] max-2xl:w-[190px] whitespace-nowrap w-[277px] h-[52px] bg-[#00C5A11F]/[14%]"
                wrapperClassName="mr-[6px] min-w-48 max-2xl:w-[190px] w-[277px]"
                setValue={setSelectedCourse}
              />
            </div>
            <button
              className="text-black mr-[24px] relative top-[11px]"
              onClick={() => setSelectedCourse(null)}
            >
              <img className={'min-w-[32px] min-h-[32px]'} src={clearIcon} />
            </button>
            <div>
              <p className={'text-[14px] text-[#5F6B88] font-500'}>
                Лекция - Урок
              </p>
              <NestedDropdownMenu
                selectedValue={selectedTopicsAndLessons}
                options={topicsWithLessons}
                optionLabelKey="title"
                optionValueKey="id"
                additionalClassName="pt-[22px] pb-[9px] w-[202px]"
                wrapperClassName="min-w-48 w-[270px]"
                setValue={setSelectedTopicsAndLessons}
                deleteCourses={deleteCourses}
              />
            </div>
            <button
              className="text-black ml-[6px] mr-[24px] relative top-[11px]"
              onClick={nestedClear}
            >
              <img className={'min-w-[32px] min-h-[32px]'} src={clearIcon} />
            </button>
            <div>
              <p className={'text-[14px] text-[#5F6B88] font-500'}>Студент</p>
              <DropdownMenu
                selectedValue={users}
                options={uniqueUsers?.map(user => ({
                  id: user.userId,
                  title: `${user.userFirstName} ${user.userLastName}`
                }))}
                optionLabelKey={'title'}
                optionValueKey={'id'}
                selectedCondition={(o, s) => o.id === s?.id}
                additionalClassName="py-[16.5px] w-[277px] max-2xl:w-[190px] bg-[#00C5A11F]/[14%]"
                wrapperClassName="w-[277px] max-2xl:w-[190px]"
                setValue={setUsers}
              />
            </div>
            <button
              className="text-black ml-[6px] relative top-[11px]"
              onClick={() => setUsers(null)}
            >
              <img className={'min-w-[32px] min-h-[32px]'} src={clearIcon} />
            </button>
          </div>
          <div className="flex items-center gap-[16px] mt-[24px]">
            <input
              placeholder="Поиск"
              className={
                'w-[465px] max-2xl:w-[285px] h-[42px] pl-3 text-black bg-[#F0F3FA] rounded-[8px] border-0'
              }
              value={searchInput}
              onChange={event => {
                setSearchInput(event.target.value)
              }}
            />
            <div className={'flex items-center'}>
              <p className="text-[#5F6B88] font-medium mr-[8px] whitespace-nowrap">
                Период: <span className="text-[#AFB7CA]">С</span>
              </p>
              <DatePicker
                customInput={<CustomInput />}
                selected={startPeriod ? new Date(startPeriod) : null}
                onChange={handleStartPeriod}
                dateFormat="yyyy-MM-dd"
                value={
                  startPeriod ? formatDateToHumanReadable(startPeriod) : ''
                }
              />
              <p className="text-[#AFB7CA] font-medium mx-[6px]">По</p>
              <DatePicker
                customInput={<CustomInput />}
                selected={endPeriod ? new Date(endPeriod) : null}
                onChange={handleEndPeriod}
                dateFormat="yyyy-MM-dd"
                value={endPeriod ? formatDateToHumanReadable(endPeriod) : ''}
              />
              <button className="text-black ml-[6px]" onClick={() => {}}>
                <img className={'min-w-[32px] min-h-[32px]'} src={clearIcon} />
              </button>
            </div>
          </div>
        </div>
        <div className="flex items-center ml-[24px] gap-[24px]">
          <div className="text-black">
            <p className={'mb-[8px]'}>Оценка</p>
            <div className={'flex items-center mb-[6px]'}>
              <input
                id="rating-5"
                className="w-[20px] h-[20px] rounded-[3px] mr-[6px]"
                type="checkbox"
                onChange={() => handleRatingChange(5)}
              />
              <span className="text-[#3BBC30] flex items-center">
                {Array(5)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      className={'w-[20px] h-[20px]'}
                      key={index}
                      src={greenStarsIcon}
                      alt="Green star"
                    />
                  ))}
              </span>
            </div>
            <div className={'flex items-center mb-[6px]'}>
              <input
                id="rating-4"
                className="w-[20px] h-[20px] rounded-[3px] mr-[6px]"
                type="checkbox"
                onChange={() => handleRatingChange(4)}
              />
              <span className="text-[#3BBC30] flex items-center">
                {Array(4)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      className={'w-[20px] h-[20px]'}
                      key={index}
                      src={greenStarsIcon}
                      alt="Green star"
                    />
                  ))}
                <img
                  className={'w-[20px] h-[20px]'}
                  src={emptyStarsIcon}
                  alt="Empty star"
                />
              </span>
            </div>
            <div className="flex items-center mb-[6px]">
              <input
                id="rating-3"
                className="w-[20px] h-[20px] rounded-[3px] mr-[6px]"
                type="checkbox"
                onChange={() => handleRatingChange(3)}
              />
              <span className="text-[#FF9900] flex items-center">
                {Array(3)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      className={'w-[20px] h-[20px]'}
                      key={index}
                      src={orangeStarsIcon}
                      alt="Orange star"
                    />
                  ))}
                {Array(2)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      className={'w-[20px] h-[20px]'}
                      key={index}
                      src={emptyStarsIcon}
                      alt="Empty star"
                    />
                  ))}
              </span>
            </div>
            <div className="flex items-center mb-[6px]">
              <input
                id="rating-2"
                className="w-[20px] h-[20px] rounded-[3px] mr-[6px]"
                type="checkbox"
                onChange={() => handleRatingChange(2)}
              />
              <span className="text-[#FF9900] flex items-center">
                {Array(2)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      className={'w-[20px] h-[20px]'}
                      key={index}
                      src={orangeStarsIcon}
                      alt="Orange star"
                    />
                  ))}
                {Array(3)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      className={'w-[20px] h-[20px]'}
                      key={index}
                      src={emptyStarsIcon}
                      alt="Empty star"
                    />
                  ))}
              </span>
            </div>
            <div className={'flex items-center mr-[6px]'}>
              <input
                className="w-[20px] h-[20px] rounded-[3px] mr-[6px]"
                id="rating-1"
                type="checkbox"
                onChange={() => handleRatingChange(1)}
              />
              <span className="text-[#FF9900] flex items-center">
                {Array(1)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      className={'w-[20px] h-[20px]'}
                      key={index}
                      src={redStarsIcon}
                      alt="Orange star"
                    />
                  ))}
                {Array(4)
                  .fill(null)
                  .map((_, index) => (
                    <img
                      className={'w-[20px] h-[20px]'}
                      key={index}
                      src={emptyStarsIcon}
                      alt="Empty star"
                    />
                  ))}
              </span>
            </div>
          </div>
          <button
            className={`bg-[#F12D2D33]/[20%]
					flex items-center gap-[8px] py-[14px] px-[12px] w-[174px] font-bold rounded-[8px] whitespace-nowrap
					${selectedCourse == null && selectedTopicsAndLessons?.lessons.length == 0 && users == null && searchInput == '' && startPeriod == '' && endPeriod == '' ? 'opacity-[50%]  text-[#F12D2D]/[20%]' : 'opacity-[100%]  text-[#F12D2D]/[100%]'}
					`}
            onClick={handleClear}
          >
            <img src={cancellAllIcon} />
            <p className="text-[15px]">Сбросить все</p>
          </button>
        </div>
      </div>
      <table className="bg-white text-black w-full table-fixed p-[16px] mt-[16px] rounded-[12px]">
        <thead className="">
          <tr className={''}>
            <th
              onClick={() => handleSort('courseTitle')}
              className="pl-[16px] pt-[30px] pb-[22px] w-[146px] text-left"
            >
              <div className={'flex items-center gap-2'}>
                Курс
                {renderSortIndicator('courseTitle')}
              </div>
            </th>
            <th
              onClick={() => handleSort('lessonTitle')}
              className="pl-[16px] pt-[30px] pb-[22px] w-[146px] text-left"
            >
              <div className={'flex items-center gap-2'}>
                Лекция
                {renderSortIndicator('lessonTitle')}
              </div>
            </th>
            <th
              onClick={() => handleSort('topicTitle')}
              className="pl-[16px] pt-[30px] pb-[22px] w-[146px] text-left"
            >
              <div className={'flex items-center gap-2'}>
                Урок
                {renderSortIndicator('topicTitle')}
              </div>
            </th>
            <th
              onClick={() => handleSort('firstname')}
              className="pl-[16px] pt-[30px] pb-[22px] w-[182px] text-left"
            >
              <div className={'flex items-center gap-2'}>
                Студент
                {renderSortIndicator('firstname')}
              </div>
            </th>
            <th
              onClick={() => handleSort('date')}
              className="pl-[16px] pt-[30px] pb-[22px] w-[100px] text-left"
            >
              <div className={'flex items-center gap-2'}>
                Дата
                {renderSortIndicator('date')}
              </div>
            </th>
            <th
              onClick={() => handleSort('grade')}
              className="pl-[16px] pt-[30px] pb-[22px] w-[120px] text-left"
            >
              <div className={'flex items-center gap-2'}>
                Оценка
                {renderSortIndicator('grade')}
              </div>
            </th>
            <th
              onClick={() => handleSort('comment')}
              className="pl-[16px] pt-[30px] pb-[22px] w-[380px] text-left max-2xl:w-[260px]"
            >
              <div className={'flex items-center gap-2'}>
                Комментарий
                {renderSortIndicator('comment')}
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="">
          {sortUsers(feedbackData).map((feedback, index) => {
            const colors = ['#FFDF6E', '#78E389', '#72ABFF']
            const avatarBgColor = colors[index % colors.length]

            return (
              <tr
                className="h-[100px] border-t border-t-[1px] my-[12px]"
                key={feedback.id}
              >
                <td className="pl-[16px] py-[16px] my-[12px] h-full relative">
                  <div
                    className={`bg-[#00C5A11F]/[12%] my-[12px] absolute h-[75%] w-[90%] flex items-center justify-start top-0 text-[13px] font-[400] px-[12px] rounded-[8px]`}
                  >
                    {feedback.courseTitle.slice(0, 50)}
                  </div>
                </td>
                <td className="pl-[16px] py-[16px] my-[12px] h-full relative">
                  <div className="bg-[#8F30BC1F]/[12%] my-[12px] absolute h-[75%] w-[90%] flex items-center justify-start top-0 py-auto text-[13px] font-[400] px-[12px] rounded-[8px]">
                    {feedback.lessonTitle}
                  </div>
                </td>
                <td className="pl-[16px] py-[16px] my-[12px]  h-full relative">
                  <div className="bg-[#0068FF1F]/[12%] my-[12px] absolute h-[75%] w-[90%] flex items-center justify-start top-0 py-auto text-[13px] font-[400] px-[12px] rounded-[8px]">
                    {feedback.topicTitle.slice(0, 47)}...
                  </div>
                </td>
                <tr className="my-[12px]" key={feedback.id}>
                  <td className="pl-[16px] mt-[16px] flex items-center">
                    <div
                      className="flex items-center my-[12px] justify-center text-white font-bold text-[16px] mr-[12px]"
                      style={{
                        background: avatarBgColor,
                        width: '38px',
                        height: '38px',
                        borderRadius: '50%'
                      }}
                    >
                      {`${feedback.userFirstName.charAt(0)}${feedback.userLastName.charAt(0)}`}
                    </div>
                    <span
                      className={
                        'font-500 my-[12px] text-[15px] text-[#1A1A1A]'
                      }
                    >{`${feedback.userFirstName} ${feedback.userLastName}`}</span>
                  </td>
                </tr>
                <td className="pl-[16px] my-[12px] text-[#5F6B88]">
                  <div className="font-medium mt-[12px]">
                    {formatDate(feedback.createdAt).slice(0, 10)}
                  </div>
                  <div className="text-right text-[#AFB7CA] mb-[12px]">
                    {formatDate(feedback.createdAt).slice(11, 16)}
                  </div>
                </td>
                <td className="pl-[16px] my-[12px]">
                  <span className="my-[12px]">
                    {renderRating(feedback.rating)}
                  </span>
                </td>
                <td className="w-[380px] max-2xl:w-[270px] break-words pl-[16px] my-[12px] text-[13px] pr-[22px]">
                  <div className="my-[12px]">{feedback.description || ''}</div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Feedback

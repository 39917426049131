import { hookstate, none, useHookstate } from '@hookstate/core';

const store = hookstate({
    quizAdditionalQuestions: [],
    currentIndex: undefined
})

const wrapState = (s) => ({
    get: (prop) => s[prop].value,
    getOrEmpty: (prop) => s[prop].value || {},
    set: (prop, newVal) => s[prop].set(newVal),
    updateByIndex: (parentProp, index, prop, value) => s[parentProp][index][prop].set(value),
    deleteByIndex: (parentProp, index) => s[parentProp][index].set(none),
    mergeByIndex: (parentProp, index, prop, value) => s[parentProp][index][prop].merge(value),
    updateIndexDepth2: (parentProp, index, index2, prop, prop2, value) => s[parentProp][index][prop][index2][prop2].set(value),
    merge: (prop, newVal) => s[prop].merge(newVal),
    reset: (prop, child) => s[prop].merge({ [child]: none })
})

export const accessGlobalQuizState = () => wrapState(store)
export const useGlobalQuizState = () => wrapState(useHookstate(store))
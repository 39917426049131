import React, { useState, useEffect } from 'react';
import { getEngagementData } from '../../../actions/user';



export const EngagementRoot = () => {
    
    const [invitedCount, setInvitedCount] = useState('')
    const [joinedCount, setJoinedCount] = useState('')
    const [registeredCount, setRegisteredCount] = useState('')
    const [joinedStartedCount, setJoinedStartedCount] = useState('');

    useEffect(() => {
        async function fetchEngagementData() {
            try {
                const response = await getEngagementData();
                if (response.success && response.engagementData) {
                    
                    const { students, joinedStudents, startedUsers, joinedStartedUsers } = response.engagementData;

                    setInvitedCount(students);
                    setJoinedCount(joinedStudents);
                    setRegisteredCount(startedUsers);
                    setJoinedStartedCount(joinedStartedUsers);

                } else {
                    console.error('Failed to fetch engagement data');
                }
            } catch (error) {
                console.error('Error fetching engagement data:', error);
            }
        }

        fetchEngagementData();

    }, []);

    const joinedCoefficient = joinedCount / invitedCount;
    const registeredCoefficient = registeredCount / invitedCount;
    const joinedStartedCoefficient = joinedStartedCount / invitedCount;

    let joinedColorClass = '';
    let registeredColorClass = '';
    let joinedStartedColorClass = '';

    if (joinedCoefficient > 0.9) {
        joinedColorClass = 'bg-blue-800';
    } else if (joinedCoefficient > 0.8) {
        joinedColorClass = 'bg-blue-700';
    } else if (joinedCoefficient > 0.6) {
        joinedColorClass = 'bg-blue-600';
    } else if (joinedCoefficient > 0.4) {
        joinedColorClass = 'bg-blue-500';
    } else {
        joinedColorClass = 'bg-blue-400';
    }

    if (registeredCoefficient > 0.9) {
        registeredColorClass = 'bg-blue-600';
    } else if (registeredCoefficient > 0.8) {
        registeredColorClass = 'bg-blue-500';
    } else if (registeredCoefficient > 0.6) {
        registeredColorClass = 'bg-blue-400';
    } else if (registeredCoefficient > 0.4) {
        registeredColorClass = 'bg-blue-300';
    } else {
        registeredColorClass = 'bg-blue-200';
    }

    if (joinedStartedCoefficient > 0.9) {
        joinedStartedColorClass = 'bg-blue-600';
    } else if (joinedStartedCoefficient > 0.8) {
        joinedStartedColorClass = 'bg-blue-500';
    } else if (joinedStartedCoefficient > 0.6) {
        joinedStartedColorClass = 'bg-blue-400';
    } else if (joinedStartedCoefficient > 0.4) {
        joinedStartedColorClass = 'bg-blue-300';
    } else {
        joinedStartedColorClass = 'bg-blue-200';
    }

    return (
        <div className="p-8">
            <p className="text-xl font-bold text-black">Вовлеченность учащегося на платформе</p>
            <p className="text-sm text-black">Обзор использования показывает, как используется ваш контракт</p>
            <div className="flex justify-between mt-4">
                <div className="w-1/3 rounded-lg p-4 bg-blue-700">
                    <h1 className="text-white text-lg font-bold mb-2">Приглашенные учащиеся</h1>
                    <h2 className="text-white text-2xl">{invitedCount}</h2>
                </div>
                <div className={`w-1/3 rounded-lg p-4 ${registeredColorClass}`}>
                    <h1 className="text-white text-lg font-bold mb-2">Зарегистрировавшиеся учащиеся</h1>
                    <h2 className="text-white text-2xl">{registeredCount}</h2>
                </div>
                <div className={`w-1/3 rounded-lg p-4 ${joinedColorClass}`}>
                    <h1 className="text-white text-lg font-bold mb-2">Присоединившиеся учащиеся</h1>
                    <h2 className="text-white text-2xl">{joinedCount}</h2>
                </div>
                <div className={`w-1/3 rounded-lg p-4 ${joinedStartedColorClass}`}>
                    <h1 className="text-white text-md font-bold mb-2">Присоединившиеся и зарегистрировавшиеся учащиеся</h1>
                    <h2 className="text-white text-2xl">{joinedStartedCount}</h2>
                </div>
            </div>
        </div>
    );
};

import React, { useRef, useState } from "react"
import { useTags } from "../../../../hooks/courses"
import { useOutsideClick } from "../../../../hooks/ui"
import trash from '../../../../assets/trash.svg'
import { findStringMatchIndexes } from "../../../../utils"
import { simpleGetter } from "../../../../actions/common"
import { tagsApi } from "../../../../api"
import { showSnackbar } from "../../../../actions/snackbar"

export const CourseTags = ({ formTags, updateForm, courseId }) => {
    const [triggerFetch, setTriggerFetch] = useState(0)
    const tags = useTags(triggerFetch)
    const [expanded, setExpanded] = useState(false)
    const [tagSearch, setTagSearch] = useState('')
    const wrapperRef = useRef(null)
    useOutsideClick(wrapperRef, () => setExpanded(false), ['admin-form-input', 'tag-delete-icon'])

    const showExpanded = () => {
        setExpanded(true)
    }

    const updateTriggerFetch = () => {
        setTriggerFetch(triggerFetch + 1)
    }

    const onTagClick = (t) => () => {
        updateForm('tags', [...formTags, t])()
        setExpanded(false)
    }

    const onCreateTagClick = async () => {
        if (formTags?.length === 3) {
            showSnackbar("Можно привязать не более 3 тегов", "error")
            return
        }
        const response = await simpleGetter(tagsApi.createTag, {
            title: tagSearch
        })
        if (response.id) {
            if (courseId) {
                const attachResponse = await simpleGetter(tagsApi.mapTagsToCourse, {
                    courseId,
                    body: {
                        tagIds: [response.id]
                    }
                })
                if (attachResponse.length) {
                    updateForm('tags', [...formTags, {
                        ...response,
                        existing: true
                    }])()
                    showSnackbar("Успешно привязано")
                    updateTriggerFetch()
                    setExpanded(false)
                    setTagSearch("")
                }
            } else {
                updateForm('tags', [...formTags, response])()
                showSnackbar()
                updateTriggerFetch()
                setExpanded(false)
                setTagSearch("")
            }
        } else {
            showSnackbar("Произошла ошибка. Пожалуйста, попробуйте позднее", "error")
        }
    }

    const onDeleteTag = (t) => async () => {
        if (t.existing) {
            await simpleGetter(tagsApi.detachTag({
                courseId,
                body: {
                    tagIds: [t.id]
                }
            }))
            showSnackbar('Успешно отвязано')
        }
        updateForm('tags', formTags.filter(tag => tag.id !== t.id))()
    }

    const onTagSearchChange = (e) => {
        setTagSearch(e.target.value)
    }

    console.log(formTags)

    return (
        <>
            <p className="admin-form-label mb-2 flex flex-row items-baseline">
                <span className="leading-4">
                    Теги
                </span>
                {formTags.length > 3 && (
                    <span className="text-unix-error ml-2 font-medium text-xs leading-[14px]">
                        не более 3 тэгов
                    </span>
                )}
            </p>
            {Boolean(formTags.length) && (
                <div className="flex flex-row mb-4 flex-wrap">
                    {formTags.map(t => (
                        <div key={t.id} className="course-tag cursor-pointer flex flex-row items-center mr-2">
                            <span className="mr-2">
                                {t.title}
                            </span>
                            <img
                                src={trash}
                                onClick={onDeleteTag(t)}
                                className="tag-delete-icon"
                            />
                        </div>
                    ))}
                </div>
            )}
            <div
                className="tags-wrapper relative"
                ref={wrapperRef}
            >
                <div className={`flex flex-col border ${expanded && 'border-unix-blue'} rounded-lg bg-[#F0F3FA]`}>
                    <input
                        placeholder={`Введите тэг, например "Безопасность"`}
                        className="admin-form-input border-0 p-0 focus:border-0 focus:ring-0"
                        onClick={showExpanded}
                        value={tagSearch}
                        onChange={onTagSearchChange}
                    />
                    {expanded && Boolean(tagSearch.length) && (
                        <div className="top-[50px] absolute flex flex-col w-full bg-white tags-search-shadow py-2 rounded-xl">
                            <span className="mx-3 mt-1 mb-2 text-[#AFB7CA] text-sm">
                                Предложения
                            </span>
                            {tags.filter(t => !formTags.find(ft => ft.id === t.id) && t.title?.toLowerCase().includes(tagSearch.toLowerCase())).map(t => {
                                const matchIndexes = findStringMatchIndexes(tagSearch.toLowerCase(), t.title.toLowerCase())
                                let renderString = t.title, isIndexesFound = false
                                if ('start' in matchIndexes) {
                                    isIndexesFound = true
                                    renderString = []
                                    if (matchIndexes.start !== 0) {
                                        renderString.push({ text: t.title.slice(0, matchIndexes.start), found: false })
                                    }
                                    renderString.push({ text: t.title.slice(matchIndexes.start, matchIndexes.end), found: true })
                                    renderString.push({ text: t.title.slice(matchIndexes.end), found: false })
                                }
                                return (
                                    <div
                                        key={t.id}
                                        className="bg-white p-3 cursor-pointer flex flex-row hover:bg-unix-background-blue"
                                        onClick={onTagClick(t)}
                                    >
                                        {isIndexesFound ? renderString.map((str) => (
                                            <span key={str.text} className={`font-medium ${str.found ? 'text-unix-text-black' : 'text-unix-gray'}`}>
                                                {str.text}
                                            </span>
                                        )) : (
                                            <span className={`font-medium text-unix-gray`}>
                                                {t.title}
                                            </span>
                                        )}
                                    </div>
                                )
                            })}
                            {!tags.find((t) => t.title?.toLowerCase() === tagSearch.toLowerCase()) && (
                                <React.Fragment>
                                    <hr className="my-1.5 border-unix-background-blue" />
                                    <div
                                        className="bg-white p-3 cursor-pointer flex flex-row hover:bg-unix-background-blue"
                                        onClick={onCreateTagClick}
                                    >
                                        <span className="font-medium text-black">
                                            {tagSearch}
                                        </span>
                                        <span className="font-medium text-unix-gray">
                                            (новый тэг)
                                        </span>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
import { useEffect } from "react"
import { showSnackbar } from "../../../actions/snackbar"

export const CharacterCounter = ({text, length = 300, showWarning = true, additionalClassName = ''}) => {
    useEffect(() => {
        if(text.length > length && showWarning) {
            showSnackbar(`Описание не может быть длиннее ${length} символов`, "error")
        }
    }, [text])
    const isLengthOver = text.length > length
    return (
        <div className="flex flex-row items-center">
            <span className={`${text.length > length ? "text-red-600 opacity-95" : "text-black opacity-60"} ${additionalClassName.length ? additionalClassName : 'mt-3 text-xs'}`}>{text.length} / {length}</span>
            {!showWarning && isLengthOver && (
                <span className="ml-3 mt-0.5 text-xs text-[#F12D2D] font-medium">
                    Описание не может быть длиннее 300 символов
                </span>
            )}
        </div>
    )
}
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { simpleGetter } from "../../../../actions/common"
import { createCourse, updateCourse } from "../../../../actions/course"
import { showSnackbar } from "../../../../actions/snackbar"
import { tagsApi } from "../../../../api"
import crossDarkGray from '../../../../assets/cross-dark-gray.svg'
import tickWhite from '../../../../assets/tick-white.svg'
import { useCourse } from "../../../../hooks/courses"
import { formExclude } from "../../../../use-cases/constants"
import { CharacterCounter } from "../../courses/CharacterCounter"
import { CourseTags } from "./CourseTags"
import { useGlobalMiscState } from "../../../../stores/misc"
import { LanguageSelector } from "./LanguageSelector"
import { getCourseLocalizedKey } from "../../../../utils"

export const CreateCourseComponent = ({
    module,
    mode = "add",
    courseId,
    onClose
}) => {
    const navigate = useNavigate()
    const miscState = useGlobalMiscState()
    const courseSelectedLang = miscState.get('courseSelectedLang')
    const [imageSrc, setImageSrc] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [form, setForm] = useState({
        title: '',
        description: '',
        order: 1,
        type: "Major/Minor",
        isPublic: true,
        file: null,
        moduleId: module?.id,
        tags: [],
        titleRu: '',
        titleKz: '',
        descriptionRu: '',
        descriptionKz: ''
    })
    const course = useCourse(courseId)
    const isEdit = mode === "edit"

    useEffect(() => {
        if (course?.id) {
            setForm({
                ...form,
                ...course,
                tags: course?.coursesTagsMap?.map(tm => ({ ...tm.tag, existing: true }))
            })
        }
    }, [course?.id])

    const updateForm = (key, value) => () => {
        setForm(f => ({
            ...f,
            [key]: value
        }))
    }

    const onCancel = () => {
        if (isEdit) {
            onClose()
        } else {
            navigate(`/admin/modules/${module?.id}`)
        }
    }

    const updateFormNative = (key) => (e) => {
        const localizedKey = getCourseLocalizedKey(key, courseSelectedLang)
        setForm(f => ({
            ...f,
            [localizedKey]: e.target.value
        }))
    }

    const onLogoChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setImageSrc(e.target.result)
            }
            reader.readAsDataURL(event.target.files[0]);
            updateForm('file', event.target.files[0])()
        }
    }

    const renderPoster = () => {
        if (imageSrc.length || form.imageLink?.length) {
            return (
                <img
                    src={imageSrc || form.imageLink}
                    className="w-full h-full object-contain"
                />
            )
        } else {
            return (
                <div className="flex flex-row items-center">
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 3.5V7.5C14 7.76522 14.1054 8.01957 14.2929 8.20711C14.4804 8.39464 14.7348 8.5 15 8.5H19" stroke="#0068FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M17 21.5H7C6.46957 21.5 5.96086 21.2893 5.58579 20.9142C5.21071 20.5391 5 20.0304 5 19.5V5.5C5 4.96957 5.21071 4.46086 5.58579 4.08579C5.96086 3.71071 6.46957 3.5 7 3.5H14L19 8.5V19.5C19 20.0304 18.7893 20.5391 18.4142 20.9142C18.0391 21.2893 17.5304 21.5 17 21.5Z" stroke="#0068FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12 11.5V17.5" stroke="#0068FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 14.5L12 11.5L15 14.5" stroke="#0068FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span className="ml-2 hover:text-white text-[15px] font-semibold leading-[18px] text-[#0068FF]">
                        Загрузить обложку
                    </span>
                </div>
            )
        }
    }

    const validateForm = () => {
        if (!(imageSrc.length || form.imageLink?.length)) {
            showSnackbar("Загрузите обложку", 'error')
            return false
        }
        if (!form.title.trim()) {
            showSnackbar("Заголовок не может быть пустым.", 'error')
            return false
        }

        if (!form.description.trim()) {
            showSnackbar("Описание не может быть пустым.", 'error')
            return false
        }

        if (form.title.replace(/[0-9]/g, "").length === 0) {
            showSnackbar("Название не может состоят только из цифр", "error")
            return false
        }
        if (form.tags?.length > 3) {
            showSnackbar("Можно привязать не более 3 тегов", "error")
            return false
        }
        return true
    }


    const submit = async () => {
        const validated = validateForm()
        if (!validated) {
            return
        }
        setIsLoading(true)
        const formData = new FormData();
        for (let [key, val] of Object.entries(form).filter(([key]) => !formExclude.createCourse.includes(key))) {
            if (val !== null) {
                formData.append(key, val);
            }
        }

        let response;

        if (course?.id) {
            response = await updateCourse(course?.id, formData);
        } else {
            response = await createCourse(formData);
        }

        if (response.success) {
            if (form.tags.length) {
                await simpleGetter(tagsApi.mapTagsToCourse, {
                    courseId: response.id,
                    body: {
                        tagIds: form.tags.filter(t => !t.existing).map(t => t.id)
                    }
                })
            }
            if (isEdit) {
                onClose()
            } else {
                navigate(`/admin/courses/${response.id}`);
            }
        }
        setIsLoading(false)
    };
    console.log(getCourseLocalizedKey('title', courseSelectedLang), form[getCourseLocalizedKey('title', courseSelectedLang)])

    return (
        <div className="grid grid-cols-12">
            <div className="col-span-12 mb-5">
                <LanguageSelector />
            </div>
            <div className="col-span-4 flex flex-col">
                <p className="admin-form-label mb-2">
                    Обложка курса
                </p>
                <div className="flex flex-col">
                    <label className={`flex flex-col rounded-lg ${!(imageSrc.length || form.imageLink?.length) ? 'hover-poster' : ''} bg-[rgba(0,104,255,0.12)] h-52 justify-center items-center cursor-pointer`}>
                        <input hidden type="file" name="org-logo" accept="image/jpeg,image/png" onChange={onLogoChange} />
                        {renderPoster()}
                    </label>
                    <span className="mt-0.5 font-medium text-xs text-unix-light-gray">
                        JPG, PNG | Не более 1200x1200px, 30MB
                    </span>
                </div>
            </div>
            <div className="col-span-8 flex flex-col pl-6">
                <div className="flex flex-col mb-6">
                    <p className="admin-form-label mb-2">
                        Название курса
                    </p>
                    <input
                        value={form[getCourseLocalizedKey('title', courseSelectedLang)] || ""}
                        onChange={updateFormNative('title')}
                        placeholder="Название курса"
                        className="admin-form-input"
                    />
                </div>
                <div className="flex flex-col mb-6">
                    <p className="admin-form-label mb-2">
                        Краткое описание
                    </p>
                    <textarea
                        value={form[getCourseLocalizedKey('description', courseSelectedLang)] || ""}
                        onChange={updateFormNative('description')}
                        placeholder="Текст описания"
                        rows={4}
                        className="admin-form-input max-h-[112px]"
                    />
                    <CharacterCounter
                        additionalClassName="ml-4 mt-0.5 text-[#F12D2D] text-xs font-medium opacity-100"
                        text={form[getCourseLocalizedKey('description', courseSelectedLang)] || ""}
                        showWarning={false}
                    />
                </div>
                <div className="flex flex-col">
                    <CourseTags
                        formTags={form.tags}
                        updateForm={updateForm}
                        courseId={courseId}
                    />
                </div>
            </div>
            <div className="col-span-12 flex flex-row justify-center items-center mt-10">
                <button
                    className="unix-button px-12 bg-white border border-unix-gray flex flex-row items-center mr-2.5"
                    onClick={onCancel}
                >
                    <img src={crossDarkGray} />
                    <span className="text-unix-gray font-semibold text-[15px] ml-2">Отмена</span>
                </button>
                <button

                    className="unix-button px-9 bg-[#3BBC30] flex flex-row items-center"
                    disabled={isLoading}
                    onClick={submit}
                >
                    <img src={tickWhite} />
                    <span className="text-white font-semibold text-[15px] ml-2">Сохранить</span>
                </button>
            </div>
        </div>
    )
}
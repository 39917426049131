import { accessGlobalSnackBarState } from "../stores/snackbar"

const showSnackbar = (text = 'Успешно!', severity = 'success', duration = 3000) => {
    accessGlobalSnackBarState().set({
        text,
        severity,
        open: true,
        duration
    })
}

export {
    showSnackbar
}
import axios from 'axios'
import { logOut } from './actions/user'
import { accessGlobalUserState } from './stores/user'

export const API_HOST = process.env.REACT_APP_API_BASE

const makeRequest = async (method, url, params, headers = {}) => {
  const apiUrl = `${API_HOST}`
  //console.log('apiUrl: ', apiUrl)
  const userState = accessGlobalUserState().get()
  if (userState.token) {
    headers.Authorization = `Bearer ${userState.token}`
  }
  const options = {
    url: `${apiUrl}${url}`,
    method,
    headers
  }

  if (method === 'get') {
    options.params = params
  } else {
    options.data = params
  }

  return axios(options)
}

const request =
  (method, url) =>
  (...params) => {
    //console.log(method, url)

    return makeRequest(method, url, ...params)
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        if (error.response?.status === 401) {
          logOut()
        }
        return Promise.reject(error)
      })
  }

const profileApi = {
  certificates: () => request('get', '/courses/user/completed')(),
  uploadImage: body => request('post', `/users/avatar`)(body),
  getImage: () => request('get', `/users/avatar`)(),
  deleteImage: () => request('delete', `/users/avatar`)()
}

const homeworkApi = {
  createHW: body => request('post', '/home-work')(body),
  getTeacherHW: lessonId => request('get', `/home-work/${lessonId}`)(),
  editTeacherHW: body => request('patch', `/home-work`)(body),
  getStudentHW: lessonId => request('get', `/home-work/student/${lessonId}`)(),
  insertStudentHW: body => request('post', `/home-work-response`)(body),
  insertedHW: lessonId => request('get', `/home-work-response/${lessonId}`)(),
  deleteTeacherHW: lessonId => request('delete', `/home-work/${lessonId}`)(),
  getSubmittedHW: (
    courseId,
    lessonIds,
    roomIds,
    studentIds,
    search,
    status
  ) => {
    const filter = {
      ...(courseId && { courseId }),
      ...(lessonIds?.length > 0 && { lessonIds }),
      ...(roomIds?.length > 0 && { roomIds }),
      ...(studentIds?.length > 0 && { studentIds }),
      ...(search && { search }),
      ...(typeof status === 'boolean' && { status })
    }

    const queryString = `filter=${encodeURIComponent(JSON.stringify(filter))}`

    return request('get', `/home-work/statistic?${queryString}`)()
  },
  getFeedback: (
    courseIds,
    lessonIds,
    studentIds,
    ratingsRange,
    search,
    periodStart,
    periodEnd
  ) => {
    console.log(
      courseIds,
      ' ',
      lessonIds,
      ' ',
      studentIds,
      ' ',
      ratingsRange,
      ' ',
      search,
      ' ',
      periodStart,
      ' ',
      periodEnd
    )

    const filter = {
      ...(courseIds.length > 0 && { courseIds }),
      ...(lessonIds.length > 0 && { lessonIds }),
      ...(studentIds.length > 0 && { studentIds }),
      ...(ratingsRange.length > 0 && { ratingsRange }),
      ...(search.length > 0 && { search }),
      ...(periodStart.length > 0 && { periodStart }),
      ...(periodEnd.length > 0 && { periodEnd })
    }

    const queryString =
      Object.keys(filter).length > 0
        ? `filter=${encodeURIComponent(JSON.stringify(filter))}`
        : ''

    return request(
      'get',
      `/lesson-rating/information${queryString ? `?${queryString}` : ''}`
    )()
  },
  teacherGrade: body => request('post', `/home-work-progress`)(body),
  getStudentGrade: (studentId, lessonId) =>
    request('get', `/home-work-progress/${studentId}/${lessonId}`)()
}

const userApi = {
  signIn: body => request('post', '/auth/login')(body),
  updatePassword: body => request('post', '/users/password')(body),
  search: query => request('get', `/users/search?name=${query}`)(),
  teacherSearch: (courseId, field, query) =>
    request(
      'get',
      `/users/teachers-search/${courseId}?searchKey=${field}&searchValue=${query}`
    )(),
  roomSearch: (roomId, query) =>
    request('get', `/progresses/room/${roomId}?search=${query}`)(),
  allRoomSearch: ({ courseId, query }) =>
    request(
      'get',
      `/users/teachers-search/${courseId}/rooms?search=${query}`
    )(),
  orgAdmin: body => request('post', '/users/roles')(body),
  forgetPassword: body => request('post', '/auth/forget')(body),
  updateProfile: body => request('put', '/users')(body),
  getEngagementData: body => request('get', '/users/by-role/STUDENT')(body),
  teacherNotifications: () =>
    request('get', '/teachers-notifications/by-users-all')(),
  notifications: () => request('get', '/notifications')(),
  deleteStudent: (courseId, body) =>
    request('delete', `/courses/${courseId}/rooms/user`)(body),
  addModuleAdmin: (moduleId, userId) =>
    request(
      'post',
      `/users/admin/moderator-role/module/${moduleId}/user/${userId}`
    )(),
  addCourseTeacher: (courseId, userId) =>
    request(
      'post',
      `/users/admin/teacher-role/courses/${courseId}/users/${userId}`
    )(),
  addRoomDeadline: body => request('post', `/rooms/topics`)(body),
  getCertificateByProductId: id => request('get', `/users/profile/certificate/${id}`)
}

const organizationsApi = {
  all: () => request('get', '/organizations')(),
  getOrganization: id => request('get', `/organizations/${id}`)(),
  create: body => request('post', '/organizations')(body),
  update: (id, body) => request('put', `/organizations/${id}`)(body),
  addModules: (id, body) => request('post', `/organizations/${id}`)(body),
  getOrgStats: id => request('get', `/organizations/admin-statistic/${id}`)()
}

const modulesApi = {
  create: body => request('post', '/modules')(body),
  getModule: id => request('get', `/modules/${id}`)(),
  moduleCourses: id => request('get', `/modules/${id}/courses`)(),
  update: (id, body) => request('put', `/modules/${id}`)(body),
  all: () => request('get', `/modules`)(),
  addCourses: (id, body) => request('post', `/modules/${id}`)(body),
  deleteModule: id => request('delete', `/modules/${id}`)(),
  stats: () => request('get', '/modules/stats/all')(),
  userProgress: id => request('get', `/modules/${id}/userprogress`)(),
  search: q => request('get', `/modules/search-entities/${q}`)(),
  moduleStats: id => request('get', `/modules/admin-statistic/${id}`)(),
  searchModuleAdmin: (moduleId, q) =>
    request('get', `/users/admins-search/module/${moduleId}?search=${q}`)(),
  getModuleAdmin: moduleId =>
    request('get', `/modules/${moduleId}/moderators`)(),
  searchModerators: (moduleId, q) =>
    request(
      'get',
      `/users/modules/${moduleId}/search-moderators?search=${q}`
    )(),
  deleteModer: (moduleId, userId) =>
    request(
      'delete',
      `/users/admin/moderator-role/module/${moduleId}/user/${userId}/delete`
    )()
}

const coursesApi = {
  create: body => request('post', '/courses')(body),
  getCourse: id => request('get', `/courses/${id}`)(),
  update: (id, body) => request('put', `/courses/${id}`)(body),
  all: () => request('get', `/courses`)(),
  teacherAll: () => request('get', `/modules/teacher`)(),
  courseTypes: () => request('get', '/courses/types')(),
  deleteCourse: id => request('delete', `/courses/${id}`)(),
  examTypes: () => request('get', '/exams/types')(),
  submitExam: body => request('post', '/exams/')(body),
  getExam: id => request('get', `/exams/${id}`)(),
  stats: (id, moduleId) =>
    request('get', `/courses/admin-statistic/${id}/${moduleId}`)(),
  notificationsCreate: ({ body }) => request('post', `/notifications`)(body),
  teacherNotificationsCreate: ({ body }) =>
    request('post', `/teachers-notifications`)(body),
  onlineLesson: body => request('post', '/online-lessons')(body),
  getOnlineLesson: courseId =>
    request('get', `/online-lessons?courseId=${courseId}`)(),
  getRoomLesson: roomId => request('get', `/online-lessons/room/${roomId}`)(),
  updateRoomLesson: (lessonId, body) =>
    request('put', `/online-lessons/${lessonId}`)(body),
  roomDetails: (courseId, curatorId) =>
    request('get', `/courses/admin/${courseId}/rooms/curator/${curatorId}`)(),
  searchTeacher: (courseId, q) =>
    request('get', `/users/courses/${courseId}/search-teachers?search=${q}`)()
}

const progressApi = {
  getUserProgress: id => request('get', `/progresses/user/${id}`)(),
  getUserCourseProgress: (id, courseId) =>
    request('get', `/progresses/user-course/${id}/${courseId}`)()
}

const topicsApi = {
  create: body => request('post', '/topics')(body),
  update: (id, body) => request('put', `/topics/${id}`)(body),
  getTopic: id => request('get', `/topics/${id}`)(),
  deleteTopic: id => request('delete', `/topics/${id}`)()
}

const lessonsApi = {
  create: body => request('post', '/lessons')(body),
  update: (id, body) => request('put', `/lessons/${id}`)(body),
  getCourse: id => request('get', `/topics/${id}`)(),
  getLesson: id => request('get', `/lessons/${id}`)(),
  deleteLesson: id => request('delete', `/lessons/${id}`)(),
  getQuiz: id => request('get', `/lessons/${id}/quiz`)(),
  submitQuiz: (id, body) => request('post', `/quizes/${id}/check`)(body),
  watched: (id, body) => request('post', `/lessons/${id}/watched`)(body),
  rateLesson: body => request('post', `/lesson-rating`)(body),
  additionalQuizzesSubmit: body => request('post', `/additional-quizes`)(body),
  additionalQuizzes: id => request('get', `/additional-quizes/${id}`)(),
  deleteVideoQuiz: id => request('delete', `/lessons/${id}/video-quiz`)(),
  deleteQuiz: id => request('delete', `/lessons/${id}/quiz`)(),
  deleteVideoMaterial: id =>
    request('delete', `/lessons/${id}/video-material`)(),
  deleteAdditionalMaterial: id =>
    request('delete', `/lessons/${id}/additional-material`)()
}

const roomsApi = {
  getRooms: id => request('get', `/courses/${id}/rooms`)(),
  addUser: (id, body) => request('post', `/courses/${id}/rooms/user`)(body),
  createRoom: id => request('post', `/courses/${id}/rooms/empty`)(),
  createRoomFile: (id, body) => request('post', `/courses/${id}/rooms`)(body),
  updateRoom: (id, roomId, body) =>
    request(`put`, `/courses/${id}/rooms/${roomId}`)(body),
  download: id => request('get', `/courses/${id}/download`)(),
  delete: id => request('delete', `/courses/rooms/${id}`)(),
  userStats: ({ courseId, userId }) =>
    request('get', `/courses/${courseId}/rooms/users/${userId}`)(),
  lessonComment: ({ lessonId, userId, body }) =>
    request('put', `/lessons/${lessonId}/users/${userId}/comments`)(body),
  removeLessonComment: ({ lessonId, userId }) =>
    request('put', `/lessons/${lessonId}/users/${userId}/remove-comment`)()
}

const tagsApi = {
  getAll: () => request('get', `/tags`)(),
  createTag: body => request('post', '/tags')(body),
  detachTag: ({ courseId, body }) =>
    request('delete', `/courses-tags-map/${courseId}`)(body),
  mapTagsToCourse: ({ courseId, body }) =>
    request('post', `/courses-tags-map/${courseId}`)(body)
}

export {
  profileApi,
  userApi,
  organizationsApi,
  modulesApi,
  coursesApi,
  topicsApi,
  lessonsApi,
  roomsApi,
  progressApi,
  homeworkApi,
  tagsApi
}

import loadingGif from '../../../../assets/loading.gif'

export const UnixButton = ({ onClick, additionalClassName, children, loading }) => {
  return (
    <button
      className={`flex-1 unix-button flex flex-row items-center justify-center ${additionalClassName}`}
      onClick={onClick}
      disabled={loading}
    >
      {loading ? (
        <img className="w-6 h-6" src={loadingGif} />
      ) : children}
    </button>
  )
}
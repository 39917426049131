import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import arrowRightGray from '../../../../assets/arrow_right_gray.svg'
import backWhite from '../../../../assets/back_white.svg'
import { useMemoModule } from "../../../../hooks/modules"
import { useOrganizationMemo, useOrganizations } from "../../../../hooks/organizations"
import UnixSelect from "../../../ui/Select/UnixSelect"
import { CreateCourseComponent } from "./CreateCourseComponent"

export const CreateCourse = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [selectedOrganization, setSelectedOrganization] = useState(undefined)
    const [selectedModule, setSelectedModule] = useState(undefined)
    const [moduleInitiallySelected, setModuleInitiallySelected] = useState(false)
    const moduleId = searchParams.get('moduleId')
    const module = useMemoModule(moduleId)
    const organizations = useOrganizations()
    const orgParent = useOrganizationMemo(selectedOrganization?.id)
    const modules = orgParent.organization?.modules || []

    const updateSelecteOrganization = (org) => {
        setSelectedOrganization(org)
        setSelectedModule(undefined)
    }

    const updateSelectedModule = (m) => {
        setSelectedModule(m)
    }

    useEffect(() => {
        if (!moduleInitiallySelected && !!modules.find(m => m.id === parseInt(moduleId))) {
            setSelectedModule(modules.find(m => m.id === parseInt(moduleId)))
            setModuleInitiallySelected(true)
        }
    }, [modules, moduleInitiallySelected])

    useEffect(() => {
        if (module.organizationId) {
            setSelectedOrganization(organizations.find(o => o.id === module.organizationId))
        }
    }, [module.organizationId])

    return (
        <div className={"grid grid-cols-12 p-8"}>
            <div className={"col-span-12 flex flex-row"}>
                <button
                    className=""
                    onClick={() => navigate(`/admin/modules/${moduleId}`)}
                >
                    <img className="w-[50px]" src={backWhite} />
                </button>
                <UnixSelect
                    selectedValue={selectedOrganization}
                    options={organizations}
                    optionLabelKey={"title"}
                    optionValueKey={"id"}
                    selectedCondition={(o, s) => o.id === s?.id}
                    additionalClassName="pt-[22px] pb-[9px] w-full"
                    wrapperClassName="ml-4 min-w-48 mr-[6px]"
                    label="Организация"
                    setValue={updateSelecteOrganization}
                />
                {modules.length && (
                    <div className="flex flex-row">
                        <img
                            className="min-w-[14px] max-[1190px]:hidden"
                            src={arrowRightGray}
                        />
                        <UnixSelect
                            selectedValue={selectedModule}
                            options={modules}
                            optionLabelKey={"title"}
                            optionValueKey={"id"}
                            selectedCondition={(o, s) => o.id === s?.id}
                            additionalClassName="pt-[22px] pb-[9px] w-full"
                            wrapperClassName="mx-[6px] min-w-48 max-[1190px]:mx-[2px]"
                            label="Модуль"
                            setValue={updateSelectedModule}
                        />
                    </div>
                )}
            </div>
            {!!selectedModule?.id && (
                <div className="col-span-9 p-6 mt-6 rounded-xl bg-white">
                    <CreateCourseComponent
                        module={selectedModule}
                    />
                </div>
            )}
        </div>
    )
}
import { Box, CircularProgress, Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { resetPasswordEmail, signIn } from '../../../actions/user'
import { ThemeContext } from '../../../contexts'
import { useGlobalSnackBarState } from '../../../stores/snackbar'
import { useGlobalUserState } from '../../../stores/user'
import { fromBinary, inputClass, localize } from '../../../use-cases/constants'
import darkModeIcon from './darkModeIcon.svg'
import passwordVisibleIcon from './eyeIcon.png'
import lightModeIcon from './lightModeIcon.svg'
import login1 from './login-1.svg'
import login2 from './login-2.svg'
import login3 from './login-3.svg'
import passwordNotVisibleIcon from './passwordNotVisible.svg'
import passwordNotVisible from './passwordVisible.svg'
import passwordVisible from './passwordVisibleIcon.svg'
import logo from './unix_logo.svg'
import './auth.css'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

export const Auth = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [forgetEmail, setForgetEmail] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()
  const userState = useGlobalUserState()
  const isMobile = useMediaQuery({ query: '(max-width: 999px)' })
  const snackbarState = useGlobalSnackBarState()
  const user = userState.get()
  const [forgot, setForgot] = useState(false)

  const { theme, setTheme } = React.useContext(ThemeContext)

  const toggleTheme = () => {
    setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'))
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const login = async e => {
    e.preventDefault()

    const response = await signIn(email, password)
    if (!response.success) {
      if (!email || !password) {
        snackbarState.setText('Please fill in all fields.')
        snackbarState.setSeverity('error')
        snackbarState.setOpen(true)
      } else {
        snackbarState.setText(response.error)
        snackbarState.setSeverity('error')
        snackbarState.setOpen(true)
      }
    }
  }

  const handleModalClose = () => setModalOpen(false)
  const resetPassword = async () => {
    setResetPasswordLoading(true)
    const response = await resetPasswordEmail(forgetEmail)
    setResetPasswordLoading(false)
    if (response.success) {
      setForgetEmail('')
      handleModalClose()
    }
  }
  useEffect(() => {
    if (user.token) {
      navigate('/platform')
    }
  }, [user])
  useEffect(() => {
    if (
      window.location.search.length &&
      window.location.search.includes('user=')
    ) {
      const splitted = window.location.search.slice(1).split('=')
      const decoded = JSON.parse(fromBinary(splitted[1]))
      if (decoded.token) {
        userState.set(decoded)
        navigate('/platform')
      }
    }
  }, [])

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <div className="bg-[#F0F3FA] overflow-hidden h-screen flex items-center justify-center">
      {isMobile ? (
        <div className="customContainer">
          <div
            className={
              'flex flex-col h-[940px] w-[600px] bg-white dark:bg-[#1A1A1A] rounded-[40px] z-[10] max-[640px]:max-h-[620px] max-[640px]:w-[340px]'
            }
          >
            <div className={'flex flex-col justify-center'}>
              <div
                className={
                  'flex flex-col items-center justify-center mb-[28px] mt-[40px]'
                }
              >
                <img src={logo} />
              </div>
            </div>
            <div
              className={
                'flex flex-col overflow-hidden items-center justify-start text-center'
              }
            >
              {forgot ? (
                <>
                  <p
                    className={
                      'text-[#AFB7CA] font-semibold text-[24px] mb-[32px]'
                    }
                  >
                    Сброс пароля{' '}
                  </p>
                  <p className="text-[#5F6B88] text-[13px] w-[416px] max-[640px]:w-[320px] mb-[32px]">
                    Введите E-mail связанный с вашим аккаунтом и мы вышлем
                    письмо с инструкцией по смене пароля
                  </p>
                  <form
                    onSubmit={login}
                    className={'flex flex-col justify-center'}
                  >
                    <div className={'flex flex-col'}>
                      <span
                        className={
                          'text-platform-black dark:text-[#AFB7CA] text-base self-start font-medium'
                        }
                      >
                        {localize('email')}
                      </span>
                      <input
                        value={forgetEmail}
                        onChange={e => setForgetEmail(e.target.value)}
                        type="email"
                        placeholder="Введите адрес электронной почты"
                        className={`max-[640px]:w-[320px] py-3 px-5 block text-gray-500 placeholder:text-gray-400 sm:text-sm sm:leading-6 mt-2.5 bg-[#F0F3FA] rounded-full border-0 w-[416px] dark:bg-[#f0f3fa]/[12%] dark:placeholder:text-[#5F6B88] dark:text-white ${theme ? 'dark' : ''
                          }`}
                      />
                      <span
                        className="font-medium text-base cursor-pointer mt-2.5 text-[#0068FF]"
                        onClick={() => setForgot(false)}
                      >
                        Обычная форма входа{' '}
                      </span>
                      <button
                        className={
                          'bg-platform-blue px-2.5 py-2.5 text-white cursor-pointer mt-12 rounded-full text-center platform-auth-button'
                        }
                        onClick={resetPassword}
                        disabled={resetPasswordLoading}
                      >
                        {resetPasswordLoading ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: '#FFFFFF'
                            }}
                          />
                        ) : (
                          <p className={'text-xl font-medium'}>
                            Сбросить пароль
                          </p>
                        )}
                      </button>
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <p
                    className={
                      'text-[#5F6B88] font-semibold text-[24px] mb-[32px]'
                    }
                  >
                    Вход на платформу
                  </p>
                  <form
                    onSubmit={login}
                    className={'flex flex-col justify-center'}
                  >
                    <div className={'flex flex-col'}>
                      <span
                        className={
                          'text-platform-black dark:text-[#AFB7CA] text-base self-start font-medium'
                        }
                      >
                        {localize('email')}
                      </span>
                      <input
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        placeholder="Введите адрес электронной почты"
                        className={`max-[640px]:w-[320px] py-3 px-5 block text-gray-500 placeholder:text-gray-400 sm:leading-6 mt-2.5 bg-[#F0F3FA] rounded-full border-0 w-[416px] dark:bg-[#f0f3fa]/[12%] dark:placeholder:text-[#5F6B88] dark:text-white ${theme ? 'dark' : ''
                          }`}
                      />
                      <span
                        className={
                          'text-platform-black dark:text-[#AFB7CA] text-base self-start font-medium mt-7'
                        }
                      >
                        {localize('password')}
                      </span>
                      <div className="relative">
                        <input
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Введите свой пароль"
                          className={`max-[640px]:w-[320px] py-3 px-5 block text-gray-500 placeholder:text-gray-400 sm:leading-6 mt-2.5 bg-[#F0F3FA] rounded-full border-0 w-[416px] dark:bg-[#f0f3fa]/[12%] dark:placeholder:text-[#5F6B88] dark:text-white ${theme ? 'dark' : ''
                            }`}
                        />
                        <button
                          className="absolute top-[47%] right-[5%]"
                          type="button"
                          onClick={togglePasswordVisibility}
                          style={{ zIndex: '1' }}
                        >
                          {theme == 'dark' ? (
                            <img
                              src={
                                showPassword
                                  ? passwordVisible
                                  : passwordNotVisible
                              }
                              width="16px"
                              height="16px"
                              alt="Toggling password visibility"
                            />
                          ) : (
                            <img
                              src={
                                showPassword
                                  ? passwordVisibleIcon
                                  : passwordNotVisibleIcon
                              }
                              width="16px"
                              height="16px"
                              alt="Toggling password visibility"
                            />
                          )}
                        </button>
                      </div>
                      <span
                        className="font-medium text-base cursor-pointer mt-2.5 text-[#0068FF]"
                        onClick={() => setForgot(true)}
                      >
                        Забыли пароль?
                      </span>
                      <button
                        className={
                          'bg-platform-blue px-2.5 py-2.5 text-white cursor-pointer mt-12 rounded-full text-center platform-auth-button'
                        }
                        type="submit"
                      >
                        <p className={'text-xl font-medium'}>
                          {localize('sign_in')}
                        </p>
                      </button>
                    </div>
                  </form>
                </>
              )}
              <div
                className="flex items-center justify-between p-1 mt-[28px] bg-[#F0F3FA] dark:bg-[#f0f3fa]/[12%] rounded-full cursor-pointer dark:bg-[#F0F3FA][0.13]"
                onClick={toggleTheme}
              >
                {theme == 'dark' && (
                  <img
                    src={darkModeIcon}
                    className={`transition-transform duration-300 transform ${theme === 'light' ? 'ml-2' : 'mr-2'
                      }`}
                  />
                )}
                <div
                  className={`w-6 h-6 bg-white dark:bg-[#1A1A1A] rounded-full transition-transform duration-300 transform ${theme === 'light' ? 'translate-x-0' : 'translate-x-[0px]'
                    }`}
                />
                {theme == 'light' && (
                  <img
                    src={lightModeIcon}
                    className={`transition-transform duration-300 transform ${theme === 'light' ? 'ml-2' : 'mr-2'
                      }`}
                  />
                )}
              </div>
              <div
                className={
                  'max-[640px]:hidden  login-main bg-[#0068FF] mb-[20px] m-[20px] rounded-[30px] flex flex-col items-center justify-center w-[560px] h-[331px]'
                }
              >
                <Slider
                  className="w-full flex mt-[20px] h-[800px] overflow-x-hidden overflow-y-hidden"
                  {...sliderSettings}
                >
                  <div className="flex flex-col justify-center items-center">
                    <div className="w-[560px]">
                      <img
                        className="w-[288px] mx-auto h-[208px]"
                        src={login1}
                        alt="slide 1"
                      />
                    </div>
                    <p className="text-white text-[24px] mt-[20px] text-center whitespace-nowrap">
                      Смотрите видеоуроки
                    </p>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <div className="w-[560px]">
                      <img
                        className="w-[288px] mx-auto h-[208px]"
                        src={login2}
                        alt="slide 1"
                      />
                    </div>
                    <p className="text-white text-[24px] mt-[20px] text-center whitespace-nowrap">
                      Проходите тесты{' '}
                    </p>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <div className="w-[560px]">
                      <img
                        className="w-[288px] mx-auto h-[208px]"
                        src={login3}
                        alt="slide 1"
                      />
                    </div>
                    <p className="text-white text-[24px] mt-[20px] text-center whitespace-nowrap">
                      Узнавайте новое{' '}
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="overflow-hidden">
            <div className="shape blue-shape"></div>
            <div className="shape orange-shape"></div>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="customContainer">
            <div
              className={
                'flex h-[840px] max-[1300px]:h-[750px] max-w-[1280px] mx-auto bg-white dark:bg-[#1a1a1a] rounded-[40px] relative z-[10]'
              }
            >
              <div
                className={
                  'auth-side flex flex-col items-center w-[640px] max-[1300px]:w-[500px] max-[1050px]:w-[470px]'
                }
              >
                <div
                  className={
                    'flex flex-col items-center pt-[96px] pb-[137px] max-[1300px]:pt-[40px] max-[1300px]:pb-[50px]'
                  }
                >
                  <img className="w-[204px]" src={logo} />
                </div>
                <div
                  className={
                    'flex flex-col items-center justify-start text-center'
                  }
                >
                  {forgot ? (
                    <>
                      <p
                        className={
                          'text-[#AFB7CA] font-semibold text-[24px] mb-[32px]'
                        }
                      >
                        Сброс пароля
                      </p>
                      <p className="text-[#5F6B88] text-[13px] w-[416px] mb-[32px]">
                        Введите E-mail связанный с вашим аккаунтом и мы вышлем
                        письмо с инструкцией по смене пароля
                      </p>
                      <form
                        onSubmit={login}
                        className={'flex flex-col justify-center'}
                      >
                        <div className={'flex flex-col'}>
                          <span
                            className={
                              'text-platform-black dark:text-[#AFB7CA] text-base self-start font-medium'
                            }
                          >
                            {localize('email')}
                          </span>
                          <input
                            value={forgetEmail}
                            onChange={e => setForgetEmail(e.target.value)}
                            type="email"
                            placeholder="Введите адрес электронной почты"
                            className="py-3 px-5 block text-gray-500 placeholder:text-gray-400 sm:text-sm sm:leading-6 mt-2.5 border-[#f0f3fa]/[12%] rounded-full border-0 w-[416px] bg-[#F0F3FA] dark:bg-[#f0f3fa]/[12%] dark:placeholder:text-[#5F6B88] dark:focus:bg-[#f0f3fa]/[12%] dark:text-white dark:active:bg-[#f0f3fa]/[12%] "
                          />
                          <span
                            className="font-medium text-base cursor-pointer mt-2.5 text-[#0068FF]"
                            onClick={() => setForgot(false)}
                          >
                            Обычная форма входа
                          </span>
                          <button
                            className={
                              'bg-platform-blue px-2.5 py-2.5 text-white cursor-pointer mt-12 rounded-full text-center platform-auth-button'
                            }
                            onClick={resetPassword}
                            disabled={resetPasswordLoading}
                          >
                            {resetPasswordLoading ? (
                              <CircularProgress
                                size={24}
                                sx={{
                                  color: '#FFFFFF'
                                }}
                              />
                            ) : (
                              <p className={'text-xl font-medium'}>
                                Сбросить пароль
                              </p>
                            )}
                          </button>
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      <p
                        className={
                          'text-[#5F6B88] font-semibold text-[24px] mb-[32px]'
                        }
                      >
                        Вход на платформу
                      </p>
                      <form
                        onSubmit={login}
                        className={'flex flex-col justify-center'}
                      >
                        <div className={'flex flex-col'}>
                          <span
                            className={
                              'text-platform-black dark:text-[#AFB7CA] text-base self-start font-medium'
                            }
                          >
                            {localize('email')}
                          </span>
                          <input
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            type="email"
                            placeholder="Введите адрес электронной почты"
                            className="py-3 px-5 block text-gray-500 placeholder:text-gray-400 sm:text-sm sm:leading-6 mt-2.5 border-[#f0f3fa]/[12%] rounded-full border-0 w-[416px] bg-[#F0F3FA] dark:bg-[#f0f3fa]/[12%] dark:placeholder:text-[#5F6B88] dark:focus:bg-[#f0f3fa]/[12%] dark:text-white dark:active:bg-[#f0f3fa]/[12%] "
                          />
                          <span
                            className={
                              'text-platform-black text-base dark:text-[#AFB7CA] self-start font-medium mt-7'
                            }
                          >
                            {localize('password')}
                          </span>
                          <div className="relative">
                            <input
                              value={password}
                              onChange={e => setPassword(e.target.value)}
                              type={showPassword ? 'text' : 'password'}
                              placeholder="Введите свой пароль"
                              className="py-3 px-5 block text-gray-500 placeholder:text-gray-400 sm:text-sm sm:leading-6 mt-2.5 bg-[#F0F3FA] rounded-full border-0 w-[416px] dark:bg-[#f0f3fa]/[12%] dark:placeholder:text-[#5F6B88] dark:text-white"
                              style={{ paddingRight: '60px' }}
                            />
                            <button
                              className="absolute top-[47%] right-[5%]"
                              type="button"
                              onClick={togglePasswordVisibility}
                              style={{ zIndex: '1' }}
                            >
                              {theme == 'dark' ? (
                                <img
                                  src={
                                    showPassword
                                      ? passwordVisible
                                      : passwordNotVisible
                                  }
                                  width="16px"
                                  height="16px"
                                  alt="Toggling password visibility"
                                />
                              ) : (
                                <img
                                  src={
                                    showPassword
                                      ? passwordVisibleIcon
                                      : passwordNotVisibleIcon
                                  }
                                  width="16px"
                                  height="16px"
                                  alt="Toggling password visibility"
                                />
                              )}
                            </button>
                          </div>
                          <span
                            className="font-medium text-base cursor-pointer mt-2.5 text-[#0068FF]"
                            onClick={() => setForgot(true)}
                          >
                            Забыли пароль?
                          </span>
                          <button
                            className={
                              'bg-platform-blue px-2.5 py-2.5 text-white cursor-pointer mt-12 rounded-full text-center platform-auth-button'
                            }
                            type="submit"
                          >
                            <p className={'text-xl font-medium'}>
                              {localize('sign_in')}
                            </p>
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                  <div
                    className="flex items-center justify-between p-1 mt-[25%] bg-[#F0F3FA] dark:bg-[#f0f3fa]/[12%] rounded-full cursor-pointer"
                    onClick={toggleTheme}
                  >
                    {theme == 'dark' && (
                      <img
                        src={darkModeIcon}
                        className={`transition-transform duration-300 transform ${theme === 'light' ? 'ml-2' : 'mr-2'
                          }`}
                      />
                    )}
                    <div
                      className={`w-6 h-6 bg-white dark:bg-[#1A1A1A] rounded-full transition-transform duration-300 transform ${theme === 'light'
                          ? 'translate-x-0'
                          : 'translate-x-[0px]'
                        }`}
                    />
                    {theme == 'light' && (
                      <img
                        src={lightModeIcon}
                        className={`transition-transform duration-300 transform ${theme === 'light' ? 'ml-2' : 'mr-2'
                          }`}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div
                className={
                  'login-main bg-[#0068FF] m-[20px] rounded-[30px] flex flex-col items-center justify-center max-w-full max-[1150px]:w-[475px] max-[1280px]:w-[550px] w-[600px]'
                }
              >
                <Slider
                  className="w-full flex h-[800px] overflow-x-hidden overflow-y-hidden"
                  {...sliderSettings}
                >
                  <div className="flex flex-col justify-center items-center">
                    <div className="justify-center align-center items-center w-[600px] max-[1150px]:w-[475px] max-[1280px]:w-[550px]">
                      <img
                        className="mx-auto max-[1160px]:w-[386px]"
                        src={login1}
                        alt="slide 1"
                      />
                    </div>
                    <p className="text-white text-[32px] mt-[83px] text-center">
                      Смотрите видеоуроки
                    </p>
                  </div>
                  <div className="flex flex-col justify-center items-center w-[600px] max-[1150px]:w-[475px] max-[1280px]:w-[550px]">
                    <div className="">
                      <img
                        className="mx-auto max-[1160px]:w-[386px] max-[1160px]:h-[278px]"
                        src={login2}
                        alt="slide 2"
                      />
                    </div>
                    <p className="text-white text-[32px] mt-[83px] text-center">
                      Проходите тесты
                    </p>
                  </div>
                  <div className="flex flex-col justify-center items-center w-[600px] max-[1150px]:w-[475px] max-[1280px]:w-[550px]">
                    <div className="">
                      <img
                        className="mx-auto max-[1160px]:w-[386px] max-[1160px]:h-[278px]"
                        src={login3}
                        alt="slide 3"
                      />
                    </div>
                    <p className="text-white text-[32px] mt-[83px] text-center">
                      Узнавайте новое{' '}
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="overflow-hidden">
              <div className="shape blue-shape"></div>
              <div className="shape orange-shape"></div>
            </div>
          </div>
        </div>
      )}
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className="flex flex-col">
            <span className={'text-platform-black text-base font-medium mb-3'}>
              Введите почту для сброса пароля
            </span>
            <input
              value={forgetEmail}
              onChange={e => setForgetEmail(e.target.value)}
              type="email"
              placeholder="Введите адрес электронной почты"
              className={inputClass}
            />
            <button
              className={
                'bg-platform-blue px-2.5 py-2.5 text-white cursor-pointer mt-4 rounded-lg text-center platform-auth-button'
              }
              onClick={resetPassword}
              disabled={resetPasswordLoading}
            >
              {resetPasswordLoading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#FFFFFF'
                  }}
                />
              ) : (
                <p className={'text-xl font-medium'}>Сбросить пароль</p>
              )}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

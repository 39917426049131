import { Box, Modal } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import arrowRightGray from '../../../../assets/arrow_right_gray.svg'
import backWhite from '../../../../assets/back_white.svg'
import editBoxGray from '../../../../assets/edit-box-gray.svg'
import editGray from '../../../../assets/edit-gray.svg'
import plusBackgroundBlue from '../../../../assets/plus-background-blue.svg'
import plusBlue from '../../../../assets/plus-blue.svg'
import plusViolet from '../../../../assets/plus-violet.svg'
import sortGray from '../../../../assets/sort-gray.svg'
import trashRed from '../../../../assets/trash-red.svg'
import { useCourse } from '../../../../hooks/courses'
import { useMemoModule } from '../../../../hooks/modules'
import {
  useOrganizationMemo,
  useOrganizations
} from '../../../../hooks/organizations'
import {
  deleteActions,
  lessonMaterialMeta,
  modalStyle,
  statusSessions
} from '../../../../use-cases/constants'
import { getTopicDeadline, lessonsListSort, renderCourseLocalized } from '../../../../utils'
import UnixSelect from '../../../ui/Select/UnixSelect'
import { CourseTopicList } from './CourseTopicList'
import { CreateCourseComponent } from './CreateCourseComponent'
import { CreateExam } from './CreateExam'
import { CreateLessonComponent } from './CreateLessonComponent'
import { CreateTopicComponent } from './CreateTopicComponent'
import { LessonMaterial } from './LessonMaterial'
import { TopicLessonsList } from './TopicLessonsList'
import { LanguageSelector } from './LanguageSelector'
import { useGlobalMiscState } from '../../../../stores/misc'

export const Course = () => {
  const miscState = useGlobalMiscState()
  const courseSelectedLang = miscState.get('courseSelectedLang')
  const navigate = useNavigate()
  const { courseId } = useParams()
  const [selectedOrganization, setSelectedOrganization] = useState(undefined)
  const [selectedModule, setSelectedModule] = useState(undefined)
  const [moduleInitiallySelected, setModuleInitiallySelected] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [modalCurrent, setModalCurrent] = useState('')
  const [courseFetchTrigger, setCourseFetchTrigger] = useState(0)
  const [currentLectureType, setCurrentLectureType] = useState('')
  const [selectedTopic, setSelectedTopic] = useState({})
  const [selectedLesson, setSelectedLesson] = useState({})
  const [topicModalMode, setTopicModalMode] = useState('add')
  const [lessonModalMode, setLessonModalMode] = useState('add')
  const course = useCourse(courseId, courseFetchTrigger, updateCourseInfo)
  const topics = course?.topics || []
  const module = useMemoModule(course.moduleId)
  const organizations = useOrganizations()
  const orgParent = useOrganizationMemo(selectedOrganization?.id)
  const modules = orgParent.organization?.modules || []

  const updateSelecteOrganization = org => {
    setSelectedOrganization(org)
    setSelectedModule(undefined)
  }

  const updateSelectedModule = m => {
    setSelectedModule(m)
  }

  const triggerCourseFetch = () => {
    setCourseFetchTrigger(t => t + 1)
  }

  const deleteEntity = (key, id) => async () => {
    const action = deleteActions[key]
    await action(id)
    if (key === 'course') {
      navigate(`/admin/modules/${course.moduleId}`)
    } else {
      triggerCourseFetch()
    }
  }

  const updateCourseInfoGeneric = (parent, listKey, selected, setFunction) => {
    if ('id' in selected) {
      const found = parent[listKey]?.find(item => item.id === selected.id)
      if (found) {
        setFunction(found)
        return found
      } else {
        setFunction({})
      }
    }
    return null
  }

  function updateCourseInfo(responseCourse) {
    const updatedTopic = updateCourseInfoGeneric(
      responseCourse,
      'topics',
      selectedTopic,
      setSelectedTopic
    )
    if (updatedTopic) {
      updateCourseInfoGeneric(
        updatedTopic,
        'lessons',
        selectedLesson,
        setSelectedLesson
      )
    }
  }

  const toggleModal = () => setEditModalOpen(o => !o)

  const openModal = type => () => {
    setModalCurrent(type)
    setEditModalOpen(true)
  }

  const openLectureModal = (lectureType, mode) => () => {
    setTopicModalMode(mode)
    setCurrentLectureType(lectureType)
    openModal('addTopic')()
  }

  const openLessonModal = mode => () => {
    setLessonModalMode(mode)
    openModal('addLesson')()
  }

  useEffect(() => {
    if (!editModalOpen) {
      triggerCourseFetch()
    }
  }, [editModalOpen])

  const renderModal = () => {
    switch (modalCurrent) {
      case 'courseEdit':
        return (
          <CreateCourseComponent
            module={selectedModule}
            mode="edit"
            courseId={courseId}
            onClose={toggleModal}
          />
        )
      case 'addTopic': {
        let latestOrder = 1
        if (course.topics?.filter(t => t.type === currentLectureType)?.length) {
          const sorted = course.topics
            .filter(t => t.type === currentLectureType)
            .sort((a, b) => b.order - a.order)
          latestOrder = sorted.at(0).order + 1
        }
        return (
          <CreateTopicComponent
            course={course}
            order={latestOrder}
            type={currentLectureType}
            onClose={toggleModal}
            triggerFetch={triggerCourseFetch}
            topic={selectedTopic}
            mode={topicModalMode}
          />
        )
      }
      case 'addLesson': {
        let latestLesson = 1
        if (selectedTopic.lessons?.length) {
          const sorted = selectedTopic.lessons.sort((a, b) => b.order - a.order)
          latestLesson = sorted.at(0).order + 1
        }
        return (
          <CreateLessonComponent
            order={latestLesson}
            selectedTopic={selectedTopic}
            onClose={toggleModal}
            triggerFetch={triggerCourseFetch}
            mode={lessonModalMode}
            lesson={selectedLesson}
          />
        )
      }
      case 'addExam':
        return <CreateExam courseId={courseId} onClose={toggleModal} />
    }
  }

  const updateSelectedTopic = t => () => {
    setSelectedTopic(t)
    setSelectedLesson({})
  }

  const updateSelectedLesson = l => () => {
    setSelectedLesson(l)
  }

  const renderLessons = () => {
    if (!('id' in selectedTopic) || selectedTopic?.lessons?.length === 0) {
      return (
        <p className="text-[15px] leading-5 text-unix-light-gray text-center">
          Нет уроков для отображения
        </p>
      )
    }
    return (
      <TopicLessonsList
        list={selectedTopic.lessons?.slice()?.sort(lessonsListSort)}
        selectedLesson={selectedLesson}
        updateSelectedLesson={updateSelectedLesson}
        triggerCourseFetch={triggerCourseFetch}
      />
    )
  }

  const renderTopics = () => {
    if (!topics.length) {
      return (
        <p className="text-[15px] leading-5 text-unix-light-gray text-center">
          Нет лекций или экзаменов для отображения
        </p>
      )
    }
    const grouped = {}
    for (let topic of topics) {
      if (topic.statusSession?.length) {
        if (topic.statusSession in grouped) {
          grouped[topic.statusSession].push(topic)
        } else {
          grouped[topic.statusSession] = [topic]
        }
      }
    }
    for (let [key, groupedTopics] of Object.entries(grouped)) {
      const sessionMeta = statusSessions.find(s => s.statusSession === key)
      if (sessionMeta) {
        return (
          <CourseTopicList
            label={sessionMeta.label}
            list={groupedTopics}
            selectedTopic={selectedTopic}
            updateSelectedTopic={updateSelectedTopic}
            triggerCourseFetch={triggerCourseFetch}
          />
        )
      }
    }
  }

  useEffect(() => {
    if (
      !moduleInitiallySelected &&
      !!modules.find(m => m.id === parseInt(course.moduleId))
    ) {
      setSelectedModule(modules.find(m => m.id === parseInt(course.moduleId)))
      setModuleInitiallySelected(true)
    }
  }, [modules, moduleInitiallySelected])

  useEffect(() => {
    if (module.organizationId) {
      setSelectedOrganization(
        organizations.find(o => o.id === module.organizationId)
      )
    }
  }, [module.organizationId])

  return (
    <div className={'flex flex-col w-full h-full p-8'}>
      <div className={'flex flex-row items-center'}>
        <button
          className=""
          onClick={() => navigate(`/admin/modules/${course.moduleId}`)}
        >
          <img className="w-[50px]" src={backWhite} />
        </button>
        <UnixSelect
          selectedValue={selectedOrganization}
          options={organizations}
          optionLabelKey={'title'}
          optionValueKey={'id'}
          selectedCondition={(o, s) => o.id === s?.id}
          additionalClassName="pt-[22px] pb-[9px] w-full"
          wrapperClassName="ml-4 min-w-48 mr-[6px]"
          label="Организация"
          setValue={updateSelecteOrganization}
          disabled={true}
        />
        {modules.length && (
          <div className="flex flex-row">
            <img
              className="min-w-[14px] max-[1190px]:hidden"
              src={arrowRightGray}
            />
            <UnixSelect
              selectedValue={selectedModule}
              options={modules}
              optionLabelKey={'title'}
              optionValueKey={'id'}
              selectedCondition={(o, s) => o.id === s?.id}
              additionalClassName="pt-[22px] pb-[9px] w-full"
              wrapperClassName="mx-[6px] min-w-48 max-[1190px]:mx-[2px]"
              label="Модуль"
              setValue={updateSelectedModule}
              disabled={true}
            />
          </div>
        )}
        <LanguageSelector />
      </div >
      {course?.id && (
        <>
          <div className="flex flex-col mt-6">
            <div className="rounded-xl p-4 bg-white grid grid-cols-12">
              <div className="col-span-8 flex flex-row">
                <div
                  className="min-w-[190px] h-24 rounded-lg bg-cover"
                  style={{
                    backgroundImage: `url(${course.imageLink})`
                  }}
                />
                <div className="flex flex-col flex-1 min-w-0 px-4">
                  <p className="flex flex-row font-medium text-[15px] leading-5">
                    <span className="text-[#AFB7CA]">Курс</span>
                    {renderCourseLocalized('title', course, courseSelectedLang, 'text-unix-text-black ml-1')}
                  </p>
                  <div className="flex flex-row flex-wrap mt-2">
                    {course.coursesTagsMap?.map(tm => (
                      <div
                        key={tm.id}
                        className="course-tag mr-2 px-2 py-1 text-xs font-medium"
                      >
                        {tm.tag?.title}
                      </div>
                    ))}
                  </div>
                  {renderCourseLocalized('description', course, courseSelectedLang, 'text-ellipsis overflow-hidden whitespace-nowrap text-[13px] text-unix-text-black leading-4', 'text-[13px] mr-2', 'mt-2')}
                </div >
              </div >
              <div className="col-span-4 flex flex-row items-center justify-end">
                <button
                  className="py-2 px-6 rounded-lg bg-white border border-unix-gray flex flex-row items-center"
                  onClick={openModal('courseEdit')}
                >
                  <img src={editGray} />
                  <span className="ml-2 font-semibold text-[15px] text-unix-gray">
                    Редактировать
                  </span>
                </button>
                <button
                  className="ml-3 py-2 px-6 rounded-lg bg-white border border-unix-error flex flex-row items-center"
                  onClick={deleteEntity('course', courseId)}
                >
                  <img src={trashRed} />
                  <span className="ml-2 font-semibold text-[15px] text-unix-error">
                    Удалить
                  </span>
                </button>
              </div>
            </div >
          </div >
          <div className="flex-1 grid grid-cols-12 mt-4">
            <div className="col-span-3 flex flex-col h-full">
              <div className="flex flex-col rounded-xl bg-white h-full p-4">
                <div className="flex flex-row border-b border-b-unix-background-blue pb-4 pt-1">
                  <p className="flex-[9] font-medium text-sm text-unix-light-gray">
                    Порядок лекций и экзаменов
                  </p>
                  <div className="flex-1 flex flex-row justify-end items-center">
                    <img src={sortGray} className="w-6 h-6" />
                  </div>
                </div>
                <div className="flex flex-col flex-1 overflow-y-auto pt-4">
                  {renderTopics()}
                </div>
                <div className="flex flex-col pt-4">
                  <button
                    className="unix-button flex flex-row justify-center items-center border border-[#8F30BC] bg-[rgba(143,48,188,0.12)]"
                    onClick={openLectureModal('Major', 'add')}
                  >
                    <img src={plusViolet} />
                    <span className="ml-2 text-[15px] font-semibold text-[#8F30BC]">
                      Добавить лекцию Major
                    </span>
                  </button>
                  <button
                    className="mt-3 unix-button flex flex-row justify-center items-center border border-unix-gray bg-unix-background-blue"
                    onClick={openLectureModal('Minor', 'add')}
                  >
                    <img src={plusBackgroundBlue} />
                    <span className="ml-2 text-[15px] font-semibold text-unix-gray">
                      Добавить лекцию Minor
                    </span>
                  </button>
                </div>
              </div>
            </div>
            {'id' in selectedTopic && (
              <div className="col-span-9 flex flex-col h-full pl-4">
                <div className="rounded-xl p-4 bg-white grid grid-cols-12">
                  <div className="col-span-7 flex flex-col">
                    <div className="flex flex-row">
                      <div className="w-1/4 flex flex-row items-center justify-end">
                        <span className="text-[15px] leading-5 text-unix-light-gray">
                          Лекция {selectedTopic.type}
                        </span>
                      </div>
                      <div className="w-3/4 pl-3 flex flex-row items-center">
                        {renderCourseLocalized('title', selectedTopic, courseSelectedLang, 'text-ellipsis overflow-hidden whitespace-nowrap font-bold text-[15px] leading-5 text-unix-text-black', 'mr-1')}
                      </div >
                    </div >
                    {Boolean(selectedTopic.deadline) && (
                      <div className="flex flex-row mt-2">
                        <div className="flex-1 flex flex-row items-center justify-end">
                          <span className="text-[15px] leading-5 text-unix-light-gray">
                            Дедлайн
                          </span>
                        </div>
                        <div className="flex-[3] pl-3 flex flex-row items-center">
                          <span className="font-medium text-[15px] leading-5 text-unix-gray">
                            {getTopicDeadline(selectedTopic)}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-span-5 flex flex-row items-center justify-end">
                    <button
                      className="py-2 px-6 rounded-lg bg-white border border-unix-gray flex flex-row items-center"
                      onClick={openLectureModal(selectedTopic.type, 'edit')}
                    >
                      <img src={editGray} />
                      <span className="ml-2 font-semibold text-[15px] text-unix-gray">
                        Редактировать
                      </span>
                    </button>
                    <button
                      className="ml-3 py-2 px-6 rounded-lg bg-white border border-unix-error flex flex-row items-center"
                      onClick={deleteEntity('topic', selectedTopic.id)}
                    >
                      <img src={trashRed} />
                      <span className="ml-2 font-semibold text-[15px] text-unix-error">
                        Удалить
                      </span>
                    </button>
                  </div>
                </div >
                <div className="rounded-xl p4- bg-white grid grid-cols-12 flex-1 mt-4 p-4">
                  <div className="col-span-4">
                    <div className="flex flex-col rounded-xl bg-[rgba(240,243,250,0.5)] h-full p-4">
                      <div className="flex flex-row border-b border-b-unix-background-blue pb-4 pt-1">
                        <p className="flex-[9] font-medium text-sm text-unix-light-gray">
                          Порядок уроков
                        </p>
                        <div className="flex-1 flex flex-row justify-end items-center">
                          <img src={sortGray} className="w-6 h-6" />
                        </div>
                      </div>
                      <div className="flex flex-col flex-1 overflow-y-auto pt-2">
                        {renderLessons()}
                      </div>
                      <div className="flex flex-col pt-4">
                        <button
                          className="unix-button flex flex-row justify-center items-center border border-unix-blue bg-[rgba(0,104,255,0.12)]"
                          onClick={openLessonModal('add')}
                        >
                          <img src={plusBlue} />
                          <span className="ml-2 text-[15px] font-semibold text-unix-blue">
                            Добавить урок
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  {'id' in selectedLesson && (
                    <div className="col-span-8 pl-4">
                      <div className="flex flex-col h-full">
                        <div className="pb-[18px] grid grid-cols-12 border-b border-b-unix-background-blue">
                          <div className="col-span-9 flex flex-row items-center">
                            <span className="text-[15px] leading-5 text-unix-light-gray">
                              Урок
                            </span>
                            {renderCourseLocalized('title', selectedLesson, courseSelectedLang, 'text-ellipsis overflow-hidden whitespace-nowrap ml-1.5 font-bold text-[15px] leading-5 text-unix-text-black', 'mr-1 ml-2')}
                            <img
                              className="ml-4 cursor-pointer"
                              src={editBoxGray}
                              onClick={openLessonModal('edit')}
                            />
                          </div >
                          <div className="col-span-3 flex flex-row items-center justify-end">
                            <button
                              className="ml-3 py-2 px-6 rounded-lg bg-white border border-unix-error flex flex-row items-center"
                              onClick={deleteEntity(
                                'lesson',
                                selectedLesson.id
                              )}
                            >
                              <img src={trashRed} />
                              <span className="ml-2 font-semibold text-[15px] text-unix-error">
                                Удалить
                              </span>
                            </button>
                          </div>
                        </div >
                        <div className="flex flex-col overflow-y-auto flex-1">
                          {lessonMaterialMeta
                            .map(m => m.type)
                            .map(type => (
                              <LessonMaterial
                                key={type}
                                type={type}
                                lesson={selectedLesson}
                                triggerFetch={triggerCourseFetch}
                              />
                            ))}
                        </div>
                      </div >
                    </div >
                  )}
                </div >
              </div >
            )}
          </div >
        </>
      )}
      <Modal open={editModalOpen} onClose={toggleModal}>
        <Box
          sx={{
            ...modalStyle,
            width: '62.5%',
            border: 0,
            borderRadius: 3,
            padding: 3
          }}
        >
          {renderModal()}
        </Box>
      </Modal>
    </div >
  )
}

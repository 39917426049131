import { returnOrError } from "../utils"

const simpleGetter = async (apiFunction, params = {}) => {
    try {
        const res = await apiFunction(params)
        return returnOrError(res.data)
    } catch (e) {
        console.log(e)
        return {}
    }
}

export {
    simpleGetter
}
import { showSnackbar } from '../actions/snackbar'
import { homeworkApi } from '../api'

const getTeacherHW = async id => {
  const data = {}
  try {
    const response = await homeworkApi.getTeacherHW(id)

    if (response.data) {
      data.success = true
      data.homework = response.data
    }
  } catch (e) {
    console.error(e)
    data.success = false
  }
  return data
}

const getSubmittedHW = async (
  courseId = [],
  lessonIds = [],
  roomIds = [],
  studentIds = [],
  searchTerm,
  status = []
) => {
  const data = {}

  try {
    const response = await homeworkApi.getSubmittedHW(
      Number(courseId),
      lessonIds,
      roomIds,
      studentIds,
      searchTerm,
      status
    )

    if (response && response.data) {
      data.success = true
      data.homework = response.data
    } else {
      data.success = false
      data.error = 'No data received'
    }
  } catch (e) {
    data.success = false
    data.error = e.message || 'An error occurred'
  }
  return data
}

const getFeedback = async (
  courseIds,
  topicIds = [],
  studentIds = [],
  ratingsRange = [],
  search = [],
  periodStart = [],
  periodEnd = []
) => {
  const data = {}
  try {
    const response = await homeworkApi.getFeedback(
      courseIds,
      topicIds,
      studentIds,
      ratingsRange,
      search,
      periodStart,
      periodEnd
    )

    if (response && response.data) {
      data.success = true
      data.feedback = response.data
    } else {
      data.success = false
      data.error = 'No data received'
    }
  } catch (e) {
    data.success = false
    data.error = e.message || 'An error occurred'
  }
  return data
}

const getStudentHW = async id => {
  const data = {}
  try {
    const response = await homeworkApi.getStudentHW(id)

    if (response.data) {
      data.success = true
      data.homework = response.data
    }
  } catch (e) {
    console.error(e)
    data.success = false
  }
  return data
}

const createHW = async body => {
  const data = {}
  try {
    const response = await homeworkApi.createHW(body)
    if (response.data.id) {
      data.success = true
      data.body = response.data
    }
  } catch (e) {
    console.error('Error creating homework:', e)

    const errorMessage = e.response?.data?.message || 'Error creating homework'

    showSnackbar(errorMessage, 'error')

    data.success = false
  }
  return data
}
const insertStudentHW = async body => {
  const data = {}
  try {
    const formData = new FormData()
    formData.append('lessonId', body.lessonId)
    formData.append('homeWorkFile', body.homeWorkFile)

    const response = await homeworkApi.insertStudentHW(formData)

    if (response.data && response.data.id) {
      data.success = true
      data.body = response.data
    } else {
      console.error('Unexpected response format:', response)
      data.success = false
    }
  } catch (e) {
    console.error('Error creating homework:', e)
    data.success = false
  }
  return data
}

const teacherGrade = async (studentId, lessonId, grade) => {
  const data = {}
  try {
    console.log(studentId, lessonId, grade)
    const response = await homeworkApi.teacherGrade({
      studentId,
      lessonId,
      grade
    }) // Wrap in an object

    if (response.data && response.data.id) {
      data.success = true
      data.body = response.data
    } else {
      console.error('Unexpected response format:', response)
      data.success = false
    }
  } catch (e) {
    console.error('Error grading homework:', e)
    data.success = false
  }
  return data
}

const insertedHW = async id => {
  const data = {}
  try {
    const response = await homeworkApi.insertedHW(id)

    if (response.data) {
      data.success = true
      data.body = response.data
    }
  } catch (e) {
    console.error(e)
    data.success = false
  }
  return data
}

const deleteTeacherHW = async lessonId => {
  const data = {}
  try {
    const response = await homeworkApi.deleteTeacherHW(lessonId)
    console.log('response ', response)
    if (response.data) {
      showSnackbar()
      data.success = true
    }
  } catch (e) {
    console.error(e)
    data.success = false
  }
  return data
}

export {
  getTeacherHW,
  getSubmittedHW,
  getFeedback,
  teacherGrade,
  createHW,
  getStudentHW,
  insertStudentHW,
  insertedHW,
  deleteTeacherHW
}

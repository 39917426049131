import { Autocomplete, Button, Divider, List, ListItem, TextField } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { addCoursesToModulePost } from "../../../actions/module";
import { useCourses } from "../../../hooks/courses";
import { useModule } from "../../../hooks/modules";
import { CreateModuleComponent } from "./CreteModuleComponent";


export const Module = () => {
    const { moduleId } = useParams()
    const module = useModule(moduleId)
    const courses = useCourses()
    const navigate = useNavigate()
    const [selectedCourses, setSelectedCourses] = useState([])
    console.log(module)
    const createCourse = () => {
        navigate(`/admin/courses/create?moduleId=${moduleId}`)
    }
    const addCoursesToModule = async (coursesArr) => {
        const reqBody = {
            courses: coursesArr.map(m => m.id)
        }
        const response = await addCoursesToModulePost(moduleId, reqBody)
        if (response.success) {
            console.log(response)
        }
    }
    const submitCourses = () => {
        if (selectedCourses.length) {
            addCoursesToModule(selectedCourses)
        }
    }
    return (
        <div className={"grid grid-cols-12"}>
            <div className={"col-span-12"}>
                <CreateModuleComponent module={module} />
            </div>
            <div className={"col-span-12 mt-3"}>
                <div className={"unix-card ml-5 mr-5 grid grid-cols-12 px-5 py-6"}>
                    <div className={"col-span-12 flex flex-row justify-between items-center"}>
                        <span className={"text-sm text-[#797979]"}>
                            Курсы
                        </span>
                        <Button onClick={createCourse}>
                            Создать курс
                        </Button>
                    </div>
                    <div className={"col-span-12 mt-5"}>
                        <Autocomplete
                            multiple
                            options={courses}
                            getOptionLabel={(option) => `${option.id}. ${option.title}`}
                            defaultValue={courses[0]}
                            filterSelectedOptions
                            value={selectedCourses}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(e, newVal) => setSelectedCourses(newVal)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Выберите курсы"
                                    placeholder="Курсы"
                                />
                            )}
                        />
                    </div>
                    <div className={"col-span-12 flex flex-row justify-end mt-3"}>
                        <button onClick={submitCourses} className={"px-4 py-2.5 rounded-lg bg-unix-green text-base text-black/[.85]"}>
                            Сохранить
                        </button>
                    </div>
                    {module.courses?.length && (
                        <div className={"col-span-12 flex flex-col"}>
                            <p className={"font-bold text-unix-black text-lg"}>Текущие курсы</p>
                            <List className={"text-unix-black"}>
                                {module.courses.map(c => (
                                    <>
                                        <ListItem>
                                            <Link className="text-[#1976d2]" to={`/admin/courses/${c.id}`}>{c.id}. {c.title}</Link>
                                        </ListItem>
                                        <Divider />
                                    </>
                                ))}
                            </List>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getStudentGrade } from '../../../actions/homework'
import { getRooms } from '../../../actions/room'
import { useCourse, useTeacherCourses } from '../../../hooks/courses'
import { getSubmittedHW, teacherGrade } from '../../../hooks/homework'
import arrowDownIcon from './arrowDown.svg'
import arrowDown from './arrowDownIcon.svg'
import arrowUpIcon from './arrowUp.svg'
import arrowUp from './arrowUpIcon.svg'
import cancelIcon from './cancelIcon.svg'
import checkedIcon from './checkedIcon.svg'
import confirmIcon from './confirmIcon.svg'
import editIcon from './editIcon.svg'
import expandedIcon from './expandedIcon.svg'
import listIcon from './listIcon.svg'
import notExpandedIcon from './notExpandedIcon.svg'
import notSentIcon from './notSentIcon.svg'
import pdfFile from './pdfFile.svg'
import sentIcon from './sentIcon.svg'
import visibleIcon from './visibleIcon.svg'

const DropdownMenu = ({
  selectedValue,
  options,
  optionLabelKey,
  optionValueKey,
  setValue,
  displayValue
}) => {
  const [open, setOpen] = useState(false)
  const dropdownRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const toggleDropdown = () => setOpen(!open)

  const handleSelect = option => () => {
    setValue(option)
    setOpen(false)
  }

  return (
    <div ref={dropdownRef} className="relative w-full">
      <div
        className="cursor-pointer py-2 px-4 h-[52px] border rounded outline-none border-none bg-[#00C5A1]/[12%] text-gray-700 flex justify-between items-center"
        onClick={toggleDropdown}
      >
        <span>
          {displayValue ||
            (selectedValue ? selectedValue[optionLabelKey] : 'Все')}
        </span>
        <img
          src={open ? arrowUpIcon : arrowDownIcon}
          alt="Arrow icon"
          className="ml-2"
        />
      </div>
      {open && (
        <div className="absolute z-[50] w-[150px] top-full left-0 mt-2 border rounded bg-white shadow-lg max-h-60 overflow-auto">
          {options.length > 0 ? (
            options.map(option => (
              <div
                key={option[optionValueKey]}
                className="cursor-pointer py-2 px-4 hover:bg-gray-200"
                onClick={handleSelect(option)}
              >
                {option[optionLabelKey]}
              </div>
            ))
          ) : (
            <div className="py-2 px-4 text-gray-500">No options available</div>
          )}
        </div>
      )}
    </div>
  )
}

const NestedDropdownMenu = ({
  selectedValue,
  options,
  optionLabelKey,
  optionValueKey,
  setValue,
  deleteCourses
}) => {
  const [open, setOpen] = useState(false)
  const [expandedTopics, setExpandedTopics] = useState(new Set())
  const [selectedLessons, setSelectedLessons] = useState(
    new Set(Array.isArray(selectedValue) ? selectedValue : [])
  )

  const dropdownRef = React.useRef(null)

  React.useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const toggleDropdown = () => setOpen(!open)

  const toggleTopicExpansion = topicId => {
    const newExpandedTopics = new Set(expandedTopics)
    if (newExpandedTopics.has(topicId)) {
      newExpandedTopics.delete(topicId)
    } else {
      newExpandedTopics.add(topicId)
    }
    setExpandedTopics(newExpandedTopics)
  }

  const handleTopicSelect = topic => () => {
    const newSelectedLessons = new Set(selectedLessons)
    const lessons = topic.lessons || []

    if (lessons.every(lesson => newSelectedLessons.has(lesson.id))) {
      lessons.forEach(lesson => newSelectedLessons.delete(lesson.id))
    } else {
      lessons.forEach(lesson => newSelectedLessons.add(lesson.id))
    }

    setSelectedLessons(newSelectedLessons)

    setValue(Array.from(newSelectedLessons))
  }

  const handleLessonSelect = lesson => () => {
    const lessonId = lesson.id
    const newSelectedLessons = new Set(selectedLessons)

    if (newSelectedLessons.has(lessonId)) {
      newSelectedLessons.delete(lessonId)
    } else {
      newSelectedLessons.add(lessonId)
    }

    setSelectedLessons(newSelectedLessons)

    setValue(Array.from(newSelectedLessons))
  }

  const isLessonSelected = lessonId => selectedLessons.has(lessonId)

  const areAllLessonsInTopicSelected = topic => {
    const lessons = topic.lessons || []
    return (
      lessons.length > 0 &&
      lessons.every(lesson => selectedLessons.has(lesson.id))
    )
  }

  useEffect(() => {
    if (deleteCourses) {
      setSelectedLessons(new Set())
      setExpandedTopics(new Set())
    }
  }, [deleteCourses])

  return (
    <div ref={dropdownRef} className="relative mr-[60px]">
      <div
        style={{
          border: selectedLessons.size > 0 ? '1px solid transparent' : 'none',
          borderImage:
            selectedLessons.size > 0
              ? 'linear-gradient(to right, #8F30BC 0%, #0068FF 100%) 1'
              : 'none',
          background:
            selectedLessons.size > 0
              ? 'linear-gradient(to right, rgba(143, 48, 188, 0.12) 0%, rgba(0, 104, 255, 0.12) 100%)'
              : '#F0F3FA',
          borderRadius: '8px',
          padding: '8px'
        }}
        className="cursor-pointer relative py-2 px-4 border rounded-[8px] h-[52px] text-gray-700 flex justify-between items-center"
        onClick={toggleDropdown}
      >
        <div className="flex items-center gap-[6px] rounded-[8px]">
          <div className="flex items-center p-[8px] gap-[6px] rounded-[8px] text-[#1a1a1a] whitespace-nowrap">
            <img src={listIcon} alt="List Icon" />
            <span>{selectedLessons.size > 0 ? 'Выбрано' : 'Не выбрано'}</span>
          </div>
        </div>
        <div
          className={`flex bg-[#F0F3FA] rounded-[8px] items-center relative left-[30px] justify-center min-w-[48px] h-[52px] ${selectedLessons.size > 0 ? 'left-[65px]' : 'left-[65px]'}`}
        >
          <img
            src={open ? arrowUpIcon : arrowDownIcon}
            alt="Dropdown arrow"
            className=""
          />
        </div>
      </div>
      {open && (
        <div
          className="absolute top-full z-[50] left-0 mt-2 border rounded bg-white shadow-lg max-h-60 overflow-auto"
          style={{ width: '480px' }}
        >
          <p className="text-[#5F6B88] text-[16px] font-medium py-[16.5px] pl-[16px]">
            Аттестация №1
          </p>
          {options.length > 0 ? (
            options.map(option => (
              <div key={option[optionValueKey]}>
                <div
                  className="cursor-pointer py-2 px-4 flex justify-between items-center hover:bg-gray-200"
                  onClick={() => toggleTopicExpansion(option[optionValueKey])}
                >
                  <div className="flex items-center gap-2 flex-grow">
                    <img
                      src={
                        expandedTopics.has(option[optionValueKey])
                          ? expandedIcon
                          : notExpandedIcon
                      }
                      alt="Expand/Collapse Icon"
                    />
                    <div
                      className="flex items-center pl-[12px] flex-grow rounded-[8px]"
                      style={{ backgroundColor: 'rgba(143, 48, 188, 0.12)' }}
                    >
                      <input
                        type="checkbox"
                        checked={areAllLessonsInTopicSelected(option)}
                        onChange={handleTopicSelect(option)}
                        onClick={e => e.stopPropagation()}
                        style={{ display: 'block' }}
                      />
                      <span className="text-[15px] ml-[12px] py-[8.5px] text-[#1A1A1A] font-medium">
                        {option[optionLabelKey]}
                      </span>
                    </div>
                  </div>
                </div>
                {expandedTopics.has(option[optionValueKey]) && (
                  <div className="pl-8">
                    {option.lessons.map((lesson, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-2 flex-grow"
                      >
                        <div
                          className="flex items-center pl-[13px] mb-[4px] mr-[15px] py-[8.5px] ml-[27px] flex-grow rounded-[8px]"
                          style={{ backgroundColor: 'rgba(0, 104, 255, 0.12)' }}
                        >
                          <input
                            type="checkbox"
                            checked={isLessonSelected(lesson.id)}
                            onChange={handleLessonSelect(lesson)}
                            onClick={e => e.stopPropagation()}
                            style={{ display: 'block' }}
                          />
                          <span className="ml-[12px]">{lesson.title}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="py-2 px-4 text-gray-500">No options available</div>
          )}
        </div>
      )}
    </div>
  )
}

export const Homework = () => {
  const { courseId } = useParams()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [selectedRoom] = useState({})
  const [selectOption] = useState('firstname')
  const [sortDirection, setSortDirection] = useState('asc')
  const [sortColumn, setSortColumn] = useState(null)
  const [status, setStatus] = useState()
  const modules = useTeacherCourses()
  const [selectedCourse, setSelectedCourse] = useState(
    modules?.flatMap(m => m.courses)[0]
  )
  const [classes, setClasses] = useState([])
  const [students, setStudents] = useState([])
  const [selectedClass, setSelectedClass] = useState(null)
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [mode] = useState('search')
  const [selectedTopicsAndLessons, setSelectedTopicsAndLessons] = useState({
    topics: [],
    lessons: []
  })
  const [showTable, setShowTable] = useState(false)
  const [topicsWithLessons, setTopicsWithLessons] = useState([])
  const [grade, setGrade] = useState('')
  const [isSuccess] = useState(false)
  const [editingStudentId] = useState(null)
  const [editingLessonId] = useState(null)
  const [studentGrades, setStudentGrades] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const [editing, setEditing] = useState({ studentId: null, lessonId: null })
  const [deleteCourses, setDeleteCourses] = useState(false)

  useEffect(() => {
    if (students && students.length > 0) {
      students.forEach(student => {
        if (student.responses && student.responses.length > 0) {
          fetchAndSetStudentGrade(student.userId, student.responses[0].lessonId)
        }
      })
    }
  }, [students])

  useEffect(() => {
    if (editingStudentId && editingLessonId) {
      fetchAndSetStudentGrade(editingStudentId, editingLessonId)
    }
  }, [editingStudentId, editingLessonId])

  const handleGradeChange = e => {
    setGrade(e.target.value)
  }

  const handleSubmit = async (studentId, lessonId, grade) => {
    try {
      const result = await teacherGrade(
        Number(studentId),
        Number(lessonId),
        Number(grade)
      )
      if (result.success) {
        await fetchAndSetStudentGrade(studentId, lessonId)
        setEditing({ studentId: null, lessonId: null })
        setGrade('')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchRooms = async () => {
    if (!selectedCourse?.id) {
      return
    }

    const response = await getRooms(selectedCourse?.id)
    if (response.success) {
      setClasses(response.rooms.sort((a, b) => a.id - b.id))
      return response.rooms
    }
  }

  useEffect(() => {
    fetchRooms()
  }, [selectedCourse?.id])

  const fetchAndSetStudentGrade = async (studentId, lessonId) => {
    try {
      const response = await getStudentGrade(studentId, lessonId)
      if (response.success) {
        setStudentGrades(prevGrades => ({
          ...prevGrades,
          [`${studentId}-${lessonId}`]: response.grade
        }))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const courseData = useCourse(selectedCourse?.id)

  useEffect(() => {
    if (courseData.topics) {
      setTopicsWithLessons(
        courseData.topics.map(topic => ({
          ...topic,
          isTopic: true,
          lessons: topic.lessons.map(lesson => ({
            ...lesson,
            isTopic: false
          }))
        }))
      )
    }
  }, [courseData])

  const searchByName = async () => {}

  const roomSearchHandler = async () => {}

  useEffect(() => {
    if (name.length) {
      searchByName(name, selectOption)
    }
  }, [name, selectOption])

  useEffect(() => {
    if (name.length) {
      roomSearchHandler(name, selectOption)
    }
  }, [name, selectOption])

  useEffect(() => {
    setName('')
  }, [selectedRoom])

  // useEffect(() => {
  //     if(selectedUser?.id && selectedRoom?.id) {
  //         addUserRoom(selectedUser, selectedRoom)
  //     }
  // }, [selectedUser])

  const goToUser = id => () => {
    navigate(`/admin/teacher/courses/${courseId}/users/${id}`)
  }

  const toggleSortDirection = () => {
    setSortDirection(prevDirection =>
      prevDirection === 'asc' ? 'desc' : 'asc'
    )
  }

  const handleSort = columnName => {
    if (sortColumn === columnName) {
      toggleSortDirection()
    } else {
      setSortColumn(columnName)
      setSortDirection('asc')
    }
  }

  const renderSortIndicator = columnName => {
    if (sortColumn === columnName) {
      return (
        <div className="flex flex-col">
          <img
            onClick={() => handleSort(columnName)}
            src={sortDirection === 'asc' ? arrowUp : arrowUp}
            alt="Sort"
            className="mb-[5px] min-w-[12px] min-h-[7px]"
            width={12}
            height={7}
            style={{ opacity: sortDirection === 'asc' ? 0.5 : 1 }}
          />
          <img
            onClick={() => handleSort(columnName)}
            src={sortDirection === 'desc' ? arrowDown : arrowDown}
            className="min-w-[12px] min-h-[7px]"
            alt="Sort"
            width={12}
            height={7}
            style={{ opacity: sortDirection === 'asc' ? 1 : 0.5 }}
          />
        </div>
      )
    }
    return (
      <div className="flex flex-col">
        <img
          src={sortDirection === 'asc' ? arrowUp : arrowUp}
          alt="Sort"
          className="mb-[5px] min-w-[12px] min-h-[7px]"
          width={12}
          height={7}
          style={{ opacity: 0.5 }}
        />
        <img
          src={sortDirection === 'desc' ? arrowDown : arrowDown}
          alt="Sort"
          className="min-w-[12px] min-h-[7px]"
          width={12}
          height={7}
          style={{ opacity: 0.5 }}
        />
      </div>
    )
  }

  const sortUsers = users => {
    return users.sort((a, b) => {
      switch (sortColumn) {
        case 'firstname':
          return sortDirection === 'asc'
            ? (a.firstname ?? '').localeCompare(b.firstname ?? '', 'kz', {
                sensitivity: 'base'
              })
            : (b.firstname ?? '').localeCompare(a.firstname ?? '', 'kz', {
                sensitivity: 'base'
              })
        case 'lastname':
          return sortDirection === 'asc'
            ? (a.lastname ?? '').localeCompare(b.lastname ?? '', 'kz', {
                sensitivity: 'base'
              })
            : (b.lastname ?? '').localeCompare(a.lastname ?? '', 'kz', {
                sensitivity: 'base'
              })
        case 'status':
          return sortDirection === 'asc'
            ? (a.responses?.[0] ? 1 : 0) - (b.responses?.[0] ? 1 : 0)
            : (b.responses?.[0] ? 1 : 0) - (a.responses?.[0] ? 1 : 0)
        case 'grade': {
          const aGrade =
            a.progresses?.find(p => p.lessonId === a.responses?.[0]?.lessonId)
              ?.grade ?? -Infinity
          const bGrade =
            b.progresses?.find(p => p.lessonId === b.responses?.[0]?.lessonId)
              ?.grade ?? -Infinity

          // Additional logic using aGrade and bGrade can be placed here
          return aGrade - bGrade // Example of comparison logic
        }
        case 'material':
          return sortDirection === 'asc'
            ? (a.responses?.[0] ? 1 : 0) - (b.responses?.[0] ? 1 : 0)
            : (b.responses?.[0] ? 1 : 0) - (a.responses?.[0] ? 1 : 0)
        case 'id':
          return sortDirection === 'asc' ? a.id - b.id : b.id - a.id
        case 'cheatingProbability':
          return sortDirection === 'asc'
            ? a.cheatingProbability - b.cheatingProbability
            : b.cheatingProbability - a.cheatingProbability
        case 'lecturesCompleted':
          return sortDirection === 'asc'
            ? a.topicFinished - b.topicFinished
            : b.topicFinished - a.topicFinished
        case 'lessonsCompleted':
          return sortDirection === 'asc'
            ? a.passedLessonCount - b.passedLessonCount
            : b.passedLessonCount - a.passedLessonCount
        case 'quizzesPassed':
          return sortDirection === 'asc'
            ? a.quizzesPassedPercentages - b.quizzesPassedPercentages
            : b.quizzesPassedPercentages - a.quizzesPassedPercentages
        case 'email':
          return sortDirection === 'asc'
            ? (a.email ?? '').localeCompare(b.email ?? '', 'en', {
                sensitivity: 'base'
              })
            : (b.email ?? '').localeCompare(a.email ?? '', 'en', {
                sensitivity: 'base'
              })
        case 'lessonTitle':
          return sortDirection === 'asc'
            ? (a.lessonTitle ?? '').localeCompare(b.lessonTitle ?? '', 'en', {
                sensitivity: 'base'
              })
            : (b.lessonTitle ?? '').localeCompare(a.lessonTitle ?? '', 'en', {
                sensitivity: 'base'
              })
        case 'topicTitle':
          return sortDirection === 'asc'
            ? (a.topicTitle ?? '').localeCompare(b.topicTitle ?? '', 'en', {
                sensitivity: 'base'
              })
            : (b.topicTitle ?? '').localeCompare(a.topicTitle ?? '', 'en', {
                sensitivity: 'base'
              })
        default:
          return 0
      }
    })
  }

  const colors = ['bg-[#FFDF6E]', 'bg-[#78E389]', 'bg-[#72ABFF]']

  const renderSortedUsers = () => {
    const sortedUsers = sortUsers(students)

    console.log('sorted users ', sortedUsers)

    return (
      <>
        {showTable ? (
          <>
            {sortedUsers?.map((student, index) => (
              <tr key={index} className={'py-2'}>
                <td>
                  <div className="bg-[#8F30BC1F] py-[8px] rounded-[8px] text-[#1A1A1A] font-medium pl-4">
                    {student?.topicTitle}
                  </div>
                </td>
                <td>
                  <div className="flex flex-row cursor-pointer items-center">
                    <div className="bg-[#0068FF1F] py-[8px] rounded-[8px] text-[#1A1A1A] font-medium pl-4 w-[100%]">
                      {student?.lessonTitle}
                    </div>
                  </div>
                </td>
                <td>
                  <div
                    className="flex flex-row cursor-pointer items-center"
                    onClick={() => goToUser(student?.userId)}
                  >
                    <div
                      className={`${
                        colors[1 % colors.length]
                      } rounded-full flex justify-center items-center w-[38px] min-w-[38px] h-[38px] text-white`}
                    >
                      <div className="flex text-[15px] font-bold items-center gap-[4px]">
                        {student?.firstname?.substring(0, 1)}
                        {student?.lastname?.substring(0, 1)}
                      </div>
                    </div>
                    <div>
                      <div className="ml-2.5">
                        <span
                          className={
                            'text-[16px] text-[#1A1a1a] ffeont-medium opacity-90'
                          }
                        >
                          {student?.firstname} {student?.lastname}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  {student?.responses?.[0] ? (
                    studentGrades[
                      `${student?.userId}-${student?.responses?.[0]?.lessonId}`
                    ]?.homeWorkProgress?.[0]?.grade !== undefined ? (
                      <p className="flex items-center">
                        <img src={checkedIcon} alt="Checked" />
                        <p className="text-[#3BBC30] font-medium">Проверено</p>
                      </p>
                    ) : (
                      <div className="flex items-center gap-1">
                        <img src={sentIcon} alt="Sent" />
                        <p className="text-[#F58500] font-medium">Сдано</p>
                      </div>
                    )
                  ) : (
                    <div className="flex items-center gap-1">
                      <img src={notSentIcon} alt="Not Sent" />
                      <p className="text-[#F12D2D] font-medium">Не сдано</p>
                    </div>
                  )}
                </td>
                <td>
                  {student?.responses?.[0] && (
                    <div className="flex gap-4">
                      <img src={pdfFile} alt="PDF File" />
                      <div className="flex gap-2">
                        <a
                          className="cursor-pointer"
                          href={`https://safety-fiirst.fra1.cdn.digitaloceanspaces.com/homeworksresponse/students/${student?.userId}${student?.lessonId}${student?.responses[0]?.extension}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={visibleIcon} alt="View" />
                        </a>
                      </div>
                    </div>
                  )}
                </td>
                <td className="flex">
                  {student?.responses?.[0] &&
                    student?.responses[0]?.lessonId &&
                    (editing.studentId === student.userId &&
                    editing.lessonId === student?.responses[0]?.lessonId ? (
                      <div className="flex items-center relative">
                        <div className="flex items-center relative">
                          <input
                            type="number"
                            value={grade}
                            onChange={handleGradeChange}
                            className="border border-[#0068FF] w-[92px] h-[36px] rounded-[8px] px-4 pr-2" // Added padding-right for the image space
                            onBlur={() =>
                              handleSubmit(
                                student.userId,
                                student?.responses[0]?.lessonId,
                                grade
                              )
                            }
                          />
                          <img
                            src={confirmIcon}
                            onClick={() =>
                              handleSubmit(
                                student.userId,
                                student?.responses[0]?.lessonId,
                                grade
                              )
                            }
                            alt="Confirm icon"
                            className="absolute right-2"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        {studentGrades[
                          `${student.userId}-${student.responses[0]?.lessonId}`
                        ] !== undefined ? (
                          <div className={''}>
                            <span
                              onClick={() =>
                                setEditing({
                                  studentId: student.userId,
                                  lessonId: student.responses[0]?.lessonId
                                })
                              }
                              className="cursor-pointer flex justify-between max-w-[100px]"
                            >
                              {studentGrades[
                                `${student.userId}-${student.responses[0]?.lessonId}`
                              ]?.homeWorkProgress?.[0]?.grade === undefined ? (
                                <button
                                  className={`border rounded-[8px] py-2 px-4 font-bold text-[#0068FF] ${
                                    isSuccess ? 'bg-[#D4EDDA]' : ''
                                  }`}
                                  onClick={() =>
                                    setEditing({
                                      studentId: student.userId,
                                      lessonId: student.responses[0]?.lessonId
                                    })
                                  }
                                >
                                  Оценить
                                </button>
                              ) : (
                                <div className="text-[#1A1A1A] text-[20px] flex items-center">
                                  <div className="flex-0">
                                    {
                                      studentGrades[
                                        `${student.userId}-${student.responses[0]?.lessonId}`
                                      ]?.homeWorkProgress?.[0]?.grade
                                    }
                                  </div>
                                  <img src={editIcon} alt="Edit" />
                                </div>
                              )}
                            </span>
                          </div>
                        ) : (
                          <button
                            className={`border rounded-[8px] py-2 px-4 font-bold text-[#0068FF] ${
                              isSuccess ? 'bg-[#D4EDDA]' : ''
                            }`}
                            onClick={() =>
                              setEditing({
                                studentId: student.userId,
                                lessonId: student.responses[0]?.lessonId
                              })
                            }
                          >
                            Оценить
                          </button>
                        )}
                      </>
                    ))}
                </td>
              </tr>
            ))}
          </>
        ) : (
          <p className="py-10 text-left ml-3">
            Пожалуйста, выберите курс и лекцию, чтобы начать проверку домашней
            работы.
          </p>
        )}
      </>
    )
  }

  const resetAllDropdowns = () => {
    setSelectedClass(null)
    setSelectedTopicsAndLessons(null)

    setSelectedStudent(null)
    setSearchTerm('')
    setStatus(undefined)
    setDeleteCourses(true)
    setTimeout(() => setDeleteCourses(false), 0)
  }

  const nestedClear = () => {
    setSelectedTopicsAndLessons(null)
    setDeleteCourses(true)
    setTimeout(() => setDeleteCourses(false), 0)
  }

  const courseOptions = modules.flatMap(m => m.courses) || []

  useEffect(() => {
    const handleFilter = async () => {
      const courseId = selectedCourse?.id || ''

      if (courseId == '') {
        return
      }

      const lessonIds = selectedTopicsAndLessons
      const roomIds = selectedClass?.id ? [selectedClass.id] : []
      const studentIds = selectedStudent ? [selectedStudent.userId] : []
      const searchInput = searchTerm || ''
      const statusInput = status ? status.id : ''

      const result = await getSubmittedHW(
        courseId,
        lessonIds,
        roomIds,
        studentIds,
        searchInput,
        statusInput
      )

      if (result.success) {
        setStudents(result.homework || [])
        setShowTable(true)
      } else {
        console.error('Failed to fetch students')
      }
    }

    handleFilter()
  }, [
    selectedCourse,
    selectedTopicsAndLessons,
    selectedClass,
    selectedStudent,
    searchTerm,
    status
  ])

  const statusOptions = [
    { id: true, title: 'Сдано' },
    { id: false, title: 'Не сдано' }
  ]

  useEffect(() => {
    if (courseOptions.length > 0 && !selectedCourse) {
      setSelectedCourse(courseOptions[0])
    }
  }, [courseOptions, selectedCourse])

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text
  }
  const truncatedCourseTitle = selectedCourse
    ? truncateText(selectedCourse.title, 20)
    : ''

  return (
    <div>
      <div className="p-5">
        <div className="bg-white h-[175px] text-black flex flex-1 max-[1290px]:gap-2 items-center rounded-[12px]">
          <div className="w-[80%]">
            <div className="flex ml-4">
              <div className="mr-8 max-[1450px]:mr-4 min-w-[150px] w-[200px] ml-[3px]">
                <p className="ml-[0px]">Курс</p>
                <span className="flex">
                  <DropdownMenu
                    selectedValue={selectedCourse}
                    options={courseOptions}
                    optionLabelKey="title"
                    optionValueKey="id"
                    setValue={value => {
                      setSelectedCourse(value)
                      setSelectedTopicsAndLessons(null)
                      setDeleteCourses(true)
                    }}
                    displayValue={truncatedCourseTitle}
                  />
                </span>
              </div>
              <div className="mr-20 w-[180px] max-w-[200px]">
                Лекция - Урок
                <div className="flex items-center gap-[6px]">
                  <NestedDropdownMenu
                    selectedValue={selectedTopicsAndLessons}
                    options={topicsWithLessons || []}
                    optionLabelKey="title"
                    optionValueKey="id"
                    setValue={setSelectedTopicsAndLessons}
                    deleteCourses={deleteCourses}
                  />
                  <img
                    className="min-w-[32px] min-h-[32px]"
                    src={cancelIcon}
                    onClick={nestedClear}
                  />
                </div>
              </div>
              <div className="mx-8 max-[1450px]:mr-4 ml-[48px] min-w-[150px] w-[275px]">
                <p className="ml-[0px]">Класс</p>
                <span className="flex gap-[6px]">
                  <DropdownMenu
                    selectedValue={selectedClass}
                    options={classes || []}
                    optionLabelKey="title"
                    optionValueKey="id"
                    setValue={setSelectedClass}
                  />
                  <img
                    className="min-w-[32px] min-h-[32px]"
                    src={cancelIcon}
                    onClick={() => setSelectedClass(null)}
                  />
                </span>
              </div>
              <div className="mr-8 max-[1450px]:mr-4 min-w-[150px] w-[275px]">
                <p className="ml-[0px]">Студент</p>
                <span className="flex gap-[6px]">
                  <DropdownMenu
                    selectedValue={selectedStudent}
                    options={students || []}
                    optionLabelKey="firstname"
                    optionValueKey="userId"
                    setValue={selected => setSelectedStudent(selected)}
                  />
                  <img
                    className="min-w-[32px] min-h-[32px]"
                    src={cancelIcon}
                    onClick={() => setSelectedStudent(null)}
                  />
                </span>
              </div>
            </div>
            <div className="flex items-end ml-[22px] py-2">
              <input
                type="text"
                placeholder="Поиск"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="bg-[#F0F3FA] w-[53vw] h-[50px] stroke-none border-none rounded-[8px]"
              />
              <div className="min-w-[150px] w-[275px] ml-5">
                <p>Статус</p>
                <div className="flex gap-[6px]">
                  <DropdownMenu
                    selectedValue={status}
                    options={statusOptions}
                    optionLabelKey="title"
                    optionValueKey="id"
                    selectedCondition={(option, selectedValue) =>
                      option.id === selectedValue
                    }
                    additionalClassName="py-[12px] w-full bg-[#01C5A11D]"
                    wrapperClassName="mx-[6px] w-[9vw]"
                    setValue={selectedOption => {
                      setStatus(selectedOption)
                    }}
                  />
                  <img
                    src={cancelIcon}
                    onClick={() => {
                      setStatus(undefined)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-[20%] ml-[10px] items-center gap-4 relative top-[10px]">
            <button className="bg-[#F12D2D33]/[.16] w-[170px] max-[1275px]:h-[40px] max-[1500px]:w-[150px] max-[1275px]:w-[120px] py-1 flex items-center gap-0 justify-center rounded-[8px]">
              <img className="block max-[1275px]:hidden" src={cancelIcon} />
              <p
                className="text-[#F12D2D] text-[15px] whitespace-nowrap"
                onClick={resetAllDropdowns}
              >
                Сбросить все
              </p>
            </button>
          </div>
        </div>
      </div>
      {/* <button onClick={() => navigate(`/admin/teacher/courses`)}>
                            <img src={backWhite} />
                        </button>
                        <UnixSelect2
                            selectedValue={selectedModule}
                            options={modules}
                            optionLabelKey={'title'}
                            optionValueKey={'id'}
                            selectedCondition={(o, s) => o.id === s?.id}
                            additionalClassName="pt-[22px] pb-[9px] w-full"
                            wrapperClassName="ml-4 min-w-48 mr-[6px]"
                            label="Модуль"
                            setValue={updateSelectedModule}
                        />
                        <div className="text-black">{module.title}</div>
                        {courseOptions?.length && (
                            <div className="flex flex-row">
                                <img src={arrowRightGray} />
                                <UnixSelect2
                                    selectedValue={selectedCourse}
                                    options={courseOptions}
                                    optionLabelKey={'title'}
                                    optionValueKey={'id'}
                                    selectedCondition={(o, s) => o.id === s?.id}
                                    additionalClassName="pt-[22px] pb-[9px] w-full"
                                    wrapperClassName="mx-[6px] min-w-48"
                                    label="Курс"
                                    setValue={setSelectedCourse}
                                />
                            </div>
                        )}
                        {!!selectedCourse && (
                            <div className="flex flex-row">
                                <img src={arrowRightGray} />
                                <UnixSelect2
                                    selectedValue={selectedTeacher}
                                    options={[user]}
                                    customOptionLabelGet={(u) => `${u.lastname} ${u.firstname}`}
                                    optionValueKey={'id'}
                                    selectedCondition={(o, s) => o.id === s?.id}
                                    additionalClassName="pt-[22px] pb-[9px] w-full"
                                    wrapperClassName="mx-[6px] min-w-48"
                                    label="Преподаватель"
                                    setValue={setSelectedTeacher}
                                    emptyOption={false}
                                />
                            </div>
                        )} */}
      {/* {!!selectedCourse && !!students.length && (
                            <div className="flex flex-row">
                                <img src={arrowRightGray} />
                                <UnixSelect2
                                    selectedValue={selectedStudent}
                                    options={students}
                                    customOptionLabelGet={(u) => `${u.lastname} ${u.firstname}`}
                                    optionValueKey={'id'}
                                    selectedCondition={(o, s) => o.id === s?.id}
                                    additionalClassName="pt-[22px] pb-[9px] w-full"
                                    wrapperClassName="ml-[6px] min-w-48"
                                    label="Студент"
                                    setValue={setSelectedStudent}
                                />
                            </div>
                        )} */}
      <div className="grid grid-cols-1 grid-rows-10 w-full h-full pt-3 pb-10 font-sf-pro-display">
        <div className="row-span-8 flex ml-4 mr-5">
          <div className="w-[100%]">
            <div
              className={`unix-card ml-1 mt-5 ${mode == 'add'}`}
              style={{ overflowX: 'auto' }}
            >
              <table className="orgs-table text-[#5F6B88] w-[100%]">
                <thead>
                  <tr className="text-left font-500 text-[14px]">
                    <th onClick={() => handleSort('topicTitle')}>
                      <div className="flex items-center gap-2">
                        Лекция
                        {renderSortIndicator('topicTitle')}
                      </div>
                    </th>
                    <th onClick={() => handleSort('lessonTitle')}>
                      <div className="flex text-right items-center gap-2 w-[100%]">
                        Урок {renderSortIndicator('lessonTitle')}
                      </div>
                    </th>
                    <th onClick={() => handleSort('firstname')}>
                      <div className="flex items-center gap-2">
                        Студент {renderSortIndicator('firstname')}
                      </div>
                    </th>
                    <th onClick={() => handleSort('status')}>
                      <div className="flex text-right items-center gap-2 w-[150px]">
                        Статус
                        {renderSortIndicator('status')}
                      </div>
                    </th>
                    <th onClick={() => handleSort('material')}>
                      <div className="flex text-right items-center gap-2">
                        Сданный материал
                        {renderSortIndicator('material')}
                      </div>
                    </th>
                    <th onClick={() => handleSort('grade')}>
                      <div className="flex items-center gap-2">
                        Оценка {renderSortIndicator('grade')}
                      </div>
                    </th>
                    {/* <th onClick={() => handleSort("email")}>
                                    <div className="flex items-center gap-2">
                                        Почта
                                        {renderSortIndicator("email")}
                                    </div>
                                </th> */}
                  </tr>
                </thead>
                <tbody style={{ overflowY: 'auto' }}>
                  {renderSortedUsers()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// const DaysOfWeekSelect = ({ value, onChange }) => {
//   return (
//     <FormControl
//       variant="standard"
//       fullWidth
//       sx={{
//         backgroundColor: "#F0F3FA",
//         marginBottom: "1rem",
//         borderRadius: "8px",
//         position: "relative",
//         top: "1px",
//         color: "#1A1A1A",
//       }}
//     >
//       <Select
//         value={value}
//         onChange={onChange}
//         fullWidth
//         disableUnderline
//         IconComponent={ExpandMoreIcon}
//         sx={{
//           "& .MuiSelect-icon": { right: "10px" },
//           "& .MuiListItem-root.Mui-selected, & .MuiListItem-root.Mui-selected:hover & .MuiListItem-root.Mui-selected:focus":
//           {
//             backgroundColor: "transparent",
//           },
//           paddingLeft: "16px",
//           paddingTop: "11.5px",
//           paddingBottom: "11.5px",
//         }}
//         MenuProps={{
//           PaperProps: {
//             sx: {
//               backgroundColor: "#F0F3FA",
//               paddingLeft: "16px",
//               paddingTop: "11.5px",
//               paddingBottom: "11.5px",
//             },
//           },
//         }}
//       >
//         <MenuItem value="Monday">Понедельник</MenuItem>
//         <MenuItem value="Tuesday">Вторник</MenuItem>
//         <MenuItem value="Wednesday">Среда</MenuItem>
//         <MenuItem value="Thursday">Четверг</MenuItem>
//         <MenuItem value="Friday">Пятница</MenuItem>
//         <MenuItem value="Saturday">Суббота</MenuItem>
//         <MenuItem value="Sunday">Воскресенье</MenuItem>
//       </Select>
//     </FormControl>
//   );
// };

// const createOnlineLesson = async (lessonDetails) => {
//     try {
//         const response = await fetch('https://uni-x-api-dev.kz/online-lessons', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(lessonDetails),
//         });
//         const data = await response.json();
//         if (response.ok) {
//             console.log('Online lesson created successfully:', data);
//         } else {
//             console.error('Error creating online lesson:', data);
//         }
//     } catch (error) {
//         console.error('Error creating online lesson:', error);
//     }
// };

// const LessonModal = ({ isOpen, onClose, roomId, updateRoomLessonState }) => {
//   const { courseId } = useParams();
//   const [googleMeetLink, setGoogleMeetLink] = useState("");
//   const [startTime, setStartTime] = useState("");
//   const [dayOfWeek, setDayOfWeek] = useState("");
//   const [endTime, setEndTime] = useState("");

//   const handleClose = () => {
//     setGoogleMeetLink("");
//     setDayOfWeek("");
//     setStartTime("");
//     setEndTime("");
//     onClose();
//   };

//   const handleDayOfWeekChange = (e) => {
//     setDayOfWeek(e.target.value);
//   };

//   const handleSave = async () => {
//     const lessonDetails = {
//       roomId: roomId,
//       courseId: parseInt(courseId),
//       link: googleMeetLink,
//       day: dayOfWeek,
//       startDate:
//         new Date().toISOString().split("T")[0] + `T${startTime}:00.000Z`,
//       endDate: new Date().toISOString().split("T")[0] + `T${endTime}:00.000Z`,
//     };

//     const response = await createOnlineLesson(lessonDetails);
//     console.log(response);

//     if (response.success) {
//       updateRoomLessonState(response.data);
//       showSnackbar("Онлайн урок успешно добавлен.", "success");
//       handleClose();
//     } else {
//       console.error("Failed to create online lesson");
//     }
//   };
//   const generateTimeSlots = () => {
//     const timeSlots = [];
//     let hour = 8;
//     let minute = 0;

//     while (hour < 20 || (hour === 20 && minute === 0)) {
//       const time = `${hour.toString().padStart(2, "0")}:${minute
//         .toString()
//         .padStart(2, "0")}`;
//       timeSlots.push(time);
//       minute += 30;
//       if (minute >= 60) {
//         minute = 0;
//         hour += 1;
//       }
//     }

//     return timeSlots;
//   };

//   const timeSlots = generateTimeSlots();

//   return (
//     <Modal open={isOpen} onClose={handleClose}>
//       <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
//         <div className="modal-content p-4 bg-white rounded-lg">
//           <div className="flex justify-end" onClick={handleClose}>
//             <img src={closeIcon} alt="Close" />
//           </div>
//           <h2 className="text-xl mb-4">Онлайн урок</h2>
//           <p className="mb-[8px]">Ссылка на Google Meet</p>
//           <TextField
//             variant="standard"
//             InputProps={{
//               disableUnderline: true,
//             }}
//             sx={{
//               paddingLeft: "16px",
//               paddingTop: "12px",
//               paddingBottom: "12px",
//               borderRadius: "8px",
//               width: "450px",
//             }}
//             className="bg-[#F0F3FA] text-[#AFB7CA] pl-4 rounded-[8px]"
//             placeholder="Введите ссылку"
//             fullWidth
//             value={googleMeetLink}
//             onChange={(e) => setGoogleMeetLink(e.target.value)}
//           />
//           <div className="p-0 flex">
//             <div className="w-[190px]">
//               <p className="mt-5">День недели</p>
//               <DaysOfWeekSelect
//                 value={dayOfWeek}
//                 onChange={handleDayOfWeekChange}
//               />
//             </div>
//             <div className="w-[120px] outline-none relative left-[10px]">
//               <p className="mt-5 mr-5 ">Начало</p>
//               <select
//                 className="custom-select bg-[#F0F3FA] text-black mb-3 rounded-[8px] w-full p-2 pl-[16px] h-14 outline-none border-none"
//                 value={startTime}
//                 onChange={(e) => setStartTime(e.target.value)}
//               >
//                 <option value="" disabled>
//                   Время
//                 </option>
//                 {timeSlots.map((time, index) => (
//                   <option key={index} value={time}>
//                     {time}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className="w-[120px] relative left-[20px]">
//               <p className="mt-5">Окончание</p>
//               <select
//                 className="custom-select bg-[#F0F3FA] text-black rounded-[8px] mb-3  w-full p-2 pl-[16px] h-14  outline-none border-none"
//                 value={endTime}
//                 onChange={(e) => setEndTime(e.target.value)}
//               >
//                 <option value="" disabled>
//                   Время
//                 </option>
//                 {timeSlots.map((time, index) => (
//                   <option key={index} value={time}>
//                     {time}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </div>
//           <div className="mt-6 flex justify-end">
//             <button
//               onClick={handleClose}
//               className="bg-[#5F6B88] text-white h-[40px] rounded-[8px] w-full flex items-center justify-center gap-2"
//             >
//               <img src={leftIcon} alt="Back" />
//               Вернуться
//             </button>
//             <button
//               onClick={handleSave}
//               className={`bg-[#3BBC30] text-white h-[40px] ml-3 rounded-[8px] w-full flex items-center justify-center gap-2
//                                 ${dayOfWeek === "" ||
//                   startTime === "" ||
//                   endTime === ""
//                   ? "bg-opacity-30"
//                   : ""
//                 }`}
//             >
//               <img src={tick} alt="Save" />
//               Сохранить
//             </button>
//           </div>
//         </div>
//       </div>
//     </Modal>
//   );
// };

// const DeleteConfirmationModal = ({
//   isOpen,
//   onClose,
//   onConfirm,
//   student,
//   selectedRoom,
// }) => {
//   return (
//     <Modal open={isOpen} onClose={onClose}>
//       <div className="w-[50%] absolute top-[35%] left-[30%] modal-content bg-white rounded-lg overflow-hidden p-4">
//         <div className="flex justify-end mb-2 ml-[0px]">
//           <img onClick={onClose} className="text-end" src={closeIcon} />
//         </div>
//         <div className="flex items-center">
//           <div>
//             <img src={warningIcon} alt="" />
//           </div>
//           <div className="w-[90%] text-[20px] text-[#5F6B88]">
//             <p className="ml-4 mb-">
//               Вы уверены что хотите удалить студента{" "}
//               <span className="text-[#1A1A1A]">
//                 {student?.firstname} {student?.lastname}
//               </span>{" "}
//               из класса{" "}
//               <span className="text-[#1a1a1a]">{selectedRoom?.title}</span>
//             </p>
//           </div>
//         </div>
//         <div className="flex justify-end space-x-2 mt-8">
//           <button
//             onClick={onClose}
//             className="bg-[#5F6B88] text-white h-[48px] rounded-[8px] w-full flex items-center justify-center gap-2"
//           >
//             <img src={leftIcon} />
//             Вернуться
//           </button>
//           <button
//             onClick={onConfirm}
//             className="bg-[#F12D2D] text-white h-[48px] ml-3 rounded-[8px] w-full flex items-center justify-center gap-2"
//           >
//             <img src={bucket} />
//             Удалить
//           </button>
//         </div>
//       </div>
//     </Modal>
//   );
// };

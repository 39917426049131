import { useEffect, useState } from "react"
import { getOrganization, getOrganizations } from "../actions/organization"

const useOrganizations = () => {
    const [data, setData] = useState([])

    const getOrgs = async () => {
        const response = await getOrganizations()
        if(response.success) {
            setData(response.list)
        } 
    }

    useEffect(() => {
        getOrgs()
    }, [])
    return data
}

const useOrganization = (orgId) => {
    const [data, setData] = useState([])

    const getOrg = async () => {
        const response = await getOrganization(orgId)
        if(response.success) {
            setData(response)
        } 
    }

    useEffect(() => {
        if(orgId) {
            getOrg()
        }
    }, [orgId])
    return data
}

const useOrganizationMemo = (id) => {
    const [data, setData] = useState({})

    const getOrg = async () => {
        const response = await getOrganization(id)
        if(response.success) {
            setData({
                ...data,
                [id]: response
            })
        }
    }

    useEffect(() => {
        if(!!id && !(id in data)) {
            getOrg()
        }
    }, [id])

    return data[id] || {}
}

export {
    useOrganizations,
    useOrganization,
    useOrganizationMemo
}
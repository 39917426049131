import { useEffect, useRef, useState } from "react"
import { useGlobalQuizState } from "../../../../stores/quiz"
import { getTiming } from "../../../../utils"
import { showSnackbar } from "../../../../actions/snackbar"
import { simpleGetter } from "../../../../actions/common"
import { lessonsApi } from "../../../../api"
import deleteRoundBordered from '../../../../assets/delete-round-bordered.svg'
import plus from '../../../../assets/plus.svg'
import arrowDown from '../../../../assets/arrow-down.svg'
import tickbox from '../../../../assets/tickbox.svg'
import tickboxFilled from '../../../../assets/tickbox-filled.svg'
import Plyr from "plyr"

export const LessonAdditionalQuiz = ({ lesson, toggleModal, triggerFetch }) => {
    const [videoDuration, setVideoDuration] = useState(-1)
    const quizState = useGlobalQuizState()
    const scrollerRef = useRef(null)
    const quizAdditionalQuestions = quizState.get('quizAdditionalQuestions') || []
    const selectedVideoQuestionIdx = quizState.get('currentIndex') || 0

    const setSelectedVideoQuestionIdx = (i) => {
        quizState.set('currentIndex', i)
    }

    const addVideoQuizQuestion = () => {
        const lastTimingRaw = quizAdditionalQuestions.length ? quizAdditionalQuestions[quizAdditionalQuestions.length - 1].timingRaw : 0
        const addValue = lastTimingRaw > (0.9 * scrollerRef.current.offsetWidth) ? 20 : Math.round(scrollerRef.current.offsetWidth / 10)
        quizState.merge('quizAdditionalQuestions', [{
            questionText: "",
            timing: "00:00",
            timingRaw: lastTimingRaw + addValue,
            isHold: false,
            initialX: 0,
            additionalAnswers: [
                {
                    answerText: "",
                    isCorrect: false
                },
                {
                    answerText: "",
                    isCorrect: false
                },
                {
                    answerText: "",
                    isCorrect: false
                },
                {
                    answerText: "",
                    isCorrect: false
                }
            ]
        }])
    }

    const arrangeEqually = () => {
        const step = (scrollerRef.current.offsetWidth * 0.8) / (quizAdditionalQuestions.length)
        const startingPoint = 0.15 * scrollerRef.current.offsetWidth
        for (let iRaw in quizAdditionalQuestions) {
            const i = parseInt(iRaw)
            const value = startingPoint + step * i
            quizState.updateByIndex('quizAdditionalQuestions', i, 'timingRaw', value)
            quizState.updateByIndex('quizAdditionalQuestions', i, 'timingRaw', value)
        }
    }

    const deleteVideoQuestion = (index) => (e) => {
        e.stopPropagation()
        quizState.deleteByIndex('quizAdditionalQuestions', index)
    }

    const updateVideoQuestion = (prop) => (e) => {
        quizState.updateByIndex('quizAdditionalQuestions', selectedVideoQuestionIdx, prop, e.target.value)
    }

    const updateVideoQuestionAnswer = (prop, index) => (e) => {
        if (prop === "isCorrect") {
            for (let i in quizAdditionalQuestions[selectedVideoQuestionIdx].additionalAnswers) {
                quizState.updateIndexDepth2('quizAdditionalQuestions', selectedVideoQuestionIdx, i, 'additionalAnswers', prop, false)
            }
        }
        quizState.updateIndexDepth2('quizAdditionalQuestions', selectedVideoQuestionIdx, index, 'additionalAnswers', prop, e.target.value)
    }

    const onScrollerMouseUp = (e) => {
        if (quizState.get('currentIndex') > -1) {
            e.preventDefault()
            e.stopPropagation()
            quizState.updateByIndex('quizAdditionalQuestions', quizState.get('currentIndex'), 'isHold', false)
        }
    }

    const onScrollerMouseDown = (e) => {
        if (e.target.getAttribute('data-index')) {
            e.preventDefault()
            e.stopPropagation()
            const i = parseInt(e.target.getAttribute('data-index'))
            console.log(i)
            quizState.updateByIndex('quizAdditionalQuestions', i, 'isHold', true)
            quizState.updateByIndex('quizAdditionalQuestions', i, 'initialX', e.clientX)
            quizState.set('currentIndex', i)
        }
    }

    const onScrollerMouseMove = (e) => {
        if (quizState.get('currentIndex') > -1) {
            e.preventDefault()
            e.stopPropagation()
            const i = quizState.get('currentIndex')
            const scrollValue = quizAdditionalQuestions[i].timingRaw + (e.clientX - quizAdditionalQuestions[i].initialX)
            const isFirst = i === 0
            const isLast = i === quizAdditionalQuestions.length - 1
            const previous = isFirst ? null : quizAdditionalQuestions[i - 1]
            const next = isLast ? null : quizAdditionalQuestions[i + 1]
            if (quizAdditionalQuestions[i].isHold && scrollValue > (isFirst ? 0 : previous.timingRaw + 30) && scrollValue < (isLast ? scrollerRef.current?.offsetWidth - 20 : next.timingRaw - 30)) {
                quizState.updateByIndex('quizAdditionalQuestions', i, 'timingRaw', scrollValue)
                quizState.updateByIndex('quizAdditionalQuestions', i, 'initialX', e.clientX)
            }
        }
    }

    const submitVideoQuiz = async () => {
        const questions = []
        for (let question of quizAdditionalQuestions) {
            if (!question.additionalAnswers?.filter(a => a.isCorrect).length) {
                showSnackbar('Пожалуйста, выберите правильный ответ для всех вопросов', 'error')
                return
            }
            if (question.additionalAnswers?.filter(a => !a.answerText?.length).length) {
                showSnackbar('Пожалуйста, введите текст для всех вариантов ответа во всех вопросах', 'error')
                return
            }
            if (!question.questionText.length) {
                showSnackbar('Пожалуйста, введите текст вопроса для всех вопросов ', 'error')
                return
            }
            const obj = {}
            obj.questionText = question.questionText
            obj.timing = getTiming(question.timingRaw, scrollerRef.current?.offsetWidth, videoDuration)
            obj.additionalAnswers = question.additionalAnswers
            questions.push(obj)
        }
        const response = await simpleGetter(lessonsApi.additionalQuizzesSubmit, {
            lessonId: lesson?.id,
            additionalQuestions: questions
        })
        if (response?.additionalQuestions?.length) {
            toggleModal()
            showSnackbar('Успешно!')
            triggerFetch()
        } else {
            showSnackbar('Произошла ошибка. Пожалуйста, попробуйте еще раз.', 'error')
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', onScrollerMouseDown)
        document.addEventListener('mouseup', onScrollerMouseUp)
        document.addEventListener('mousemove', onScrollerMouseMove)
    }, [])

    useEffect(() => {
        if (quizAdditionalQuestions.length) {
            setSelectedVideoQuestionIdx(quizAdditionalQuestions.length - 1)
        }
    }, [quizAdditionalQuestions.length])

    useEffect(() => {
        console.log(lesson)
        if (lesson?.videoLink) {
            setTimeout(() => {
                const video = document.querySelector('video')
                if (video) {
                    video.onloadedmetadata = () => {
                        setVideoDuration(Math.floor(video.duration))
                    }
                    new Plyr(video)
                }
            }, 10)
        }
    }, [])

    const selectedVideoQuestion = quizAdditionalQuestions[selectedVideoQuestionIdx]

    return (
        <div className="flex flex-col h-full w-full px-6 py-2">
            {!!lesson?.videoLink && (
                <>
                    <div className="videoquiz-video-wrapper h-1/2">
                        <video controlsList="nodownload" onContextMenu={(e) => e.preventDefault()} controls id="video" src={lesson.videoLink?.includes('https://') ? lesson.videoLink : 'https://' + lesson.videoLink}>
                            <source src={lesson.videoLink?.includes('https://') ? lesson.videoLink : 'https://' + lesson.videoLink} />
                        </video>
                    </div>
                    <div className="relative">
                        <div className="ml-[45px] mr-[283px] mt-3 h-5 rounded-lg bg-[#F0F3FA]" ref={scrollerRef}>
                            {quizAdditionalQuestions.map((q, i) => (
                                <>
                                    <div
                                        style={{
                                            transform: `translateX(${q.timingRaw}px)`
                                        }}
                                        className={`absolute z-50 top-[-32px] left-[56.5px] w-[2px] h-[40px] ${i === selectedVideoQuestionIdx ? 'bg-[#0068FF]' : 'bg-[#3BBC30]'}`}
                                    />
                                    <div
                                        style={{
                                            transform: `translateX(${q.timingRaw}px)`
                                        }}
                                        className="absolute top-0 left-[55.5px] w-1 h-[10px] bg-[#bbbbbb]"
                                    />
                                    <div
                                        style={{
                                            transform: `translateX(${q.timingRaw}px)`
                                        }}
                                        className={`${i === selectedVideoQuestionIdx ? 'bg-[#0068FF]' : 'bg-unix-gray'} cursor-pointer absolute px-[8.5px] py-1 left-[45px] top-2 text-white rounded-md`}
                                        data-index={i}
                                    >
                                        {i + 1}
                                    </div>
                                    <div
                                        style={{
                                            transform: `translateX(${q.timingRaw}px)`
                                        }}
                                        className={`absolute left-[45px] top-10 text-[11px] font-medium text-[#5F6B88]`}
                                    >
                                        {getTiming(q.timingRaw, scrollerRef.current?.offsetWidth, videoDuration)}
                                    </div>
                                </>
                            ))}
                            <p
                                className="absolute top-3 right-[100px] text-[13px] leading-[15.6px] tracking-[-2%] text-[#0068FF] cursor-pointer"
                                onClick={arrangeEqually}
                            >
                                Распределить равномерно
                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 flex-1 mt-11 overflow-y-auto">
                        <div className="col-span-4 h-full bg-[#F0F3FA] px-2 py-4 rounded-lg flex flex-col overflow-y-auto">
                            <div className="flex flex-col flex-1 overflow-y-auto mb-8">
                                {quizAdditionalQuestions.map((q, i) => {
                                    const isCurrent = i === selectedVideoQuestionIdx
                                    return (
                                        <div key={i} className={`p-4 flex flex-row cursor-pointer justify-between ${isCurrent ? 'bg-[#0068FF] rounded-md text-white' : ''}`} onClick={() => setSelectedVideoQuestionIdx(i)}>
                                            <div className="flex flex-row mr-2">
                                                <span className={`${!isCurrent ? 'text-[#5F6B88]' : ''} font-medium mr-2`}>
                                                    {i + 1}{"."}
                                                </span>
                                                <span className={`${!isCurrent ? 'text-unix-black' : ''} font-medium break-all`}>
                                                    {q.questionText}
                                                </span>
                                            </div>
                                            <img
                                                className="opacity-30"
                                                src={deleteRoundBordered}
                                                onClick={deleteVideoQuestion(i)}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={`p-4 flex flex-row border border-[#AFB7CA] rounded-md py-3 cursor-pointer justify-center`} onClick={addVideoQuizQuestion}>
                                <img src={plus} />
                                <span className="font-medium text-[15px] text-[#5F6B88]">
                                    Добавить вопрос
                                </span>
                            </div>
                        </div>
                        <div className="col-span-8 pl-6 flex flex-col overflow-y-auto">
                            {!!selectedVideoQuestion && (
                                <>
                                    <p className="text-unix-black text-2xl">
                                        {selectedVideoQuestionIdx + 1}{"."} Вопрос
                                    </p>
                                    <input
                                        value={selectedVideoQuestion.questionText}
                                        onChange={updateVideoQuestion('questionText')}
                                        placeholder="Текст вопроса"
                                        className='px-4 py-3 my-6 text-[15px] text-[#1A1A1A] bg-[#F0F3FA] rounded-lg border-0 w-full'
                                    />
                                    <div className="flex flex-row justify-between">
                                        <p className="text-sm text-unix-gray">
                                            Варианты ответов
                                        </p>
                                        <div className="flex flex-row items-center">
                                            <p className="text-[13px] text-[#AFB7CA] overflow-hidden whitespace-nowrap">
                                                Выберите верный вариант
                                            </p>
                                            <img src={arrowDown} className="w-4 h-4 mx-0.5" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col mt-2 overflow-y-auto">
                                        {selectedVideoQuestion.additionalAnswers.map((ans, i) => (
                                            <div key={i} className="flex flex-row mb-2 mt-1">
                                                <div className="bg-[#AFB7CA] py-[12.5px] px-[17.5px] rounded-lg text-[15px] text-white">
                                                    {i + 1}
                                                </div>
                                                <input
                                                    value={ans.answerText}
                                                    onChange={updateVideoQuestionAnswer('answerText', i)}
                                                    placeholder="Текст вопроса"
                                                    className={`ml-2 px-4 text-[15px] text-[#1A1A1A] bg-[#F0F3FA] rounded-lg ${ans.isCorrect ? 'border-2 border-[#3BBC30] py-2' : 'border-0 py-3'} w-full`}
                                                />
                                                <img
                                                    className="ml-2 cursor-pointer"
                                                    onClick={() => updateVideoQuestionAnswer('isCorrect', i)({ target: { value: !ans.isCorrect } })}
                                                    src={ans.isCorrect ? tickboxFilled : tickbox}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-row justify-center text-[15px] font-semibold mt-4">
                        <button className="py-3 px-8 bg-[#0068FF] rounded-lg text-white" onClick={submitVideoQuiz}>
                            Сохранить
                        </button>
                    </div>
                </>
            )}
        </div>
    )
}
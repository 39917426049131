import { Box, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { simpleGetter } from "../../../../actions/common";
import {
  updateLessonPost
} from "../../../../actions/lesson";
import { showSnackbar } from "../../../../actions/snackbar";
import crossDarkGray from "../../../../assets/cross-dark-gray.svg";
import dashGrayBackground from "../../../../assets/dash-gray-background.svg";
import editBoxGrayOutline from "../../../../assets/edit-box-gray-outline.svg";
import tickWhite from "../../../../assets/tick-white.svg";
import trashRed from "../../../../assets/trash-red.svg";
import {
  languageMapping,
  lessonMaterialMeta,
  modalStyle,
} from "../../../../use-cases/constants";
import { LessonAdditionalQuiz } from "./LessonAdditionalQuiz.js";
import { LessonMaterialUpload } from "./LessonMaterialUpload";
import { LessonMaterialVideoUpload } from "./LessonMaterialVideoUpload";
import { UnixButton } from "../../../common/UI/Buttons/UnixButton.js";
import { useGlobalMiscState } from "../../../../stores/misc.js";
import { LanguageSelector } from "./LanguageSelector.js";

export const LessonMaterial = ({
  lesson,
  type,
  triggerFetch,
}) => {
  const miscState = useGlobalMiscState()
  const courseSelectedLang = miscState.get('courseSelectedLang')
  const [videoQuizModalOpen, setVideoQuizModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [files, setFiles] = useState({});
  const [loading, setLoading] = useState(false);


  const toggleModal = () => setModalOpen((o) => !o);

  const meta = lessonMaterialMeta.find((m) => m.type === type);

  const resetFiles = () => {
    setFiles(
      meta.fileUploads
        .map((m) => ({ [m.field]: {} }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {})
    );
  };

  useEffect(() => {
    const object = {}
    for (let fileUploadMeta of (meta.fileUploads || [])) {
      if (fileUploadMeta.localized) {
        for (let k of Object.keys(languageMapping)) {
          if (k === "EN") {
            object[fileUploadMeta.field] = {}
          } else {
            object[`${fileUploadMeta.field}${courseSelectedLang}`] = {}
          }
        }
      } else {
        object[fileUploadMeta.field] = files[fileUploadMeta.field]
      }
    }
    setFiles((f) => ({
      ...f,
      ...object
    }))
  }, [courseSelectedLang])

  useEffect(() => {
    if (meta?.fileUploads?.length) {
      resetFiles();
    }
  }, [meta?.fileUploads?.length]);

  const updateFile = (key) => (e) => {
    if (e.target.files?.length) {
      let localizedKey
      const foundFileUploadMeta = meta.fileUploads.find((fm) => fm.field === key)
      if (foundFileUploadMeta) {
        localizedKey = foundFileUploadMeta.localized ? courseSelectedLang === "EN" ? key : `${key}${courseSelectedLang}` : key
        const file = e.target.files[0];
        setFiles((f) => ({
          ...f,
          [localizedKey]: file,
        }));
      }
    }
  };


  const handleDelete = async () => {
    const response = await simpleGetter(meta.deleteApi, lesson.id)
    if (response.id) {
      showSnackbar("Успешно удалено")
      triggerFetch()
    }
  };

  const submit = async () => {
    const formData = new FormData();
    // eslint-disable-next-line no-unused-vars
    for (let [key, file] of Object.entries(files).filter(([_, v]) =>
      Boolean(v)
    )) {
      if (file?.name) {
        let finalKey = key
        const foundLanguage = Object.keys(languageMapping).find((lang) => key.includes(lang))
        if (foundLanguage) {
          finalKey = key.slice(0, key.length - 1) + key.slice(-1).toLowerCase()
        }
        formData.append(finalKey, file);
      }
    }
    formData.append("topicId", lesson.topicId);
    setLoading(true)
    const response = await updateLessonPost(lesson.id, formData);
    setLoading(false)
    if (response.success) {
      triggerFetch();
      showSnackbar();
      toggleModal();
      resetFiles();
    } else {
      showSnackbar("Произошла ошибка. Пожалуйста, повторите позднее", "error");
    }
  };

  const renderFieldCheck = () => {
    switch (meta.type) {
      case "video":
        return (
          <div
            className="w-[116px] h-[70px] rounded-lg bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${lesson[meta.fieldCheck]})`,
            }}
          >
            {console.log("meta ", lesson)}
          </div>
        );
      case 'videoquiz':
        return (
          <div
            className="w-[116px] h-[70px] rounded-lg bg-center bg-contain flex flex-row justify-center items-center"
            style={{
              backgroundImage: `url(${dashGrayBackground})`,
            }}
          >
            <div className="flex flex-col px-3 items-center">
              <span className="text-unix-blue text-[13px]">Создан</span>
              <span className="text-unix-gray text-[13px]">вопросов: {lesson.additionalQuizzes[0]._count?.additionalQuestions}</span>
            </div>
          </div>
        );
      case 'test':
        return (
          <div
            className="w-[116px] h-[70px] rounded-lg bg-center bg-contain flex flex-row justify-center items-center"
            style={{
              backgroundImage: `url(${dashGrayBackground})`,
            }}
          >
            <div className="flex flex-col px-3 items-center">
              <span className="text-unix-blue text-[13px]">Создано</span>
              <span className="text-unix-gray text-[13px]">вопросов: {lesson.quizes[0]._count?.questions}</span>
            </div>
          </div>
        );
      case "additional":
        return (
          <div
            className="w-[116px] h-[70px] rounded-lg bg-center bg-contain flex flex-row justify-center items-center"
            style={{
              backgroundImage: `url(${dashGrayBackground})`,
            }}
          >
            <div className="flex flex-col px-3 items-center">
              <span className="text-unix-blue text-[13px]">Загружен</span>
              <span className="text-unix-gray text-[13px]">файлов: 1</span>
            </div>
          </div>
        );
    }
  };

  const renderUploadButton = () => {
    if (lesson[meta.fieldCheck]?.length) {
      return (
        <div className="flex flex-row">
          {renderFieldCheck()}
          <img
            src={editBoxGrayOutline}
            className="ml-4 cursor-pointer"
            onClick={toggleModal}
          />
          <img className="ml-2 cursor-pointer" onClick={handleDelete} src={trashRed} />
        </div>
      );
    }
    return (
      <button
        className="flex flex-row items-center justify-center rounded-lg border border-unix-blue px-4 py-1.5 min-w-36"
        onClick={type === "videoquiz" ? toggleVideoQuizModal : toggleModal}
      >
        <img src={meta.buttonIcon} />
        <span className="ml-2 font-semibold text-[15px] leading-[18px] text-unix-blue">
          {meta.buttonText}
        </span>
      </button>
    );
  };

  const toggleVideoQuizModal = () => {
    if (lesson.videoLink?.length) {
      setVideoQuizModalOpen((o) => !o);
    } else {
      showSnackbar("Сначала загрузите видео", "error");
    }
  };

  console.log(files)

  if (meta) {
    return (
      <>
        <div
          className={`flex flex-row rounded-lg py-5 px-6 lesson-material-${type} mt-4`}
        >
          <div className="flex flex-col flex-1 justify-center">
            <div className="flex flex-row items-center">
              <img src={meta.icon} />
              <span className="ml-1 font-medium text-[15px] leading-5 text-unix-text-black">
                {meta.label}
              </span>
            </div>
            <div className="flex flex-col mt-1">
              {meta.bottomTexts.map((bt) => (
                <span key={bt} className="font-medium text-xs text-unix-text-black opacity-30">
                  {bt}
                </span>
              ))}
            </div>
          </div>
          <div className="flex flex-col flex-1 items-end justify-center">
            {renderUploadButton()}
          </div>
        </div>
        <Modal open={modalOpen} onClose={toggleModal}>
          <Box
            sx={{
              ...modalStyle,
              width: "32.5%",
              border: 0,
              borderRadius: 3,
              padding: 3,
            }}
          >
            <div className="flex flex-col">
              {meta.type === "video" && (
                <div className="flex flex-col mb-3">
                  <LanguageSelector />
                </div>
              )}
              <p className="text-[15px] text-unix-gray">{meta.label} урока</p>
              <p className="mb-8 text-2xl text-unix-text-black">
                {lesson.title}
              </p>
              {meta.fileUploads?.map((uploadMeta) =>
                meta.type === "video" ? (
                  <LessonMaterialVideoUpload
                    key={uploadMeta?.field}
                    lesson={lesson}
                    file={files[uploadMeta?.[uploadMeta.localized ? courseSelectedLang === "EN" ? "field" : `field${courseSelectedLang}` : 'field']]}
                    updateFile={updateFile}
                    meta={uploadMeta}
                  />
                ) : (
                  <LessonMaterialUpload
                    key={uploadMeta?.field}
                    lesson={lesson}
                    file={files[uploadMeta?.field]}
                    updateFile={updateFile}
                    meta={uploadMeta}
                  />
                )
              )}
              <div className="flex flex-row justify-center items-center mt-6">
                <button
                  className="flex-1 unix-button bg-white border border-unix-gray flex flex-row items-center justify-center mr-2.5"
                  onClick={toggleModal}
                >
                  <img src={crossDarkGray} />
                  <span className="text-unix-gray font-semibold text-[15px] ml-2">
                    Отмена
                  </span>
                </button>
                <UnixButton
                  additionalClassName="bg-[#3BBC30]"
                  onClick={submit}
                  loading={loading}
                >
                  <img src={tickWhite} />
                  <span className="text-white font-semibold text-[15px] ml-2">
                    Сохранить
                  </span>
                </UnixButton>
              </div>
            </div>
          </Box>
        </Modal>
        <Modal open={videoQuizModalOpen} onClose={toggleVideoQuizModal}>
          <Box
            sx={{
              ...modalStyle,
              height: "95%",
              width: "95%",
              paddingX: 4,
              paddingY: 0,
            }}
          >
            <LessonAdditionalQuiz
              lesson={lesson}
              toggleModal={toggleVideoQuizModal}
              triggerFetch={triggerFetch}
            />
          </Box>
        </Modal>
      </>
    );
  }
};

import { CircularProgress } from "@mui/material"
import "react-multi-carousel/lib/styles.css"
import { useNavigate } from "react-router-dom"
import { useProgress } from "../../../hooks/progress"
import { useGlobalUserState } from "../../../stores/user"
import { courseReducer, localize } from "../../../use-cases/constants"
import arrowBlack from '../../pages/orgs/arrow-black.svg'

const stats = (val, label, bg) => (
    <div className="flex flex-row items-center mb-6">
        <div className={`w-2.5 h-2.5 rounded-[10px] border border-white ${bg}`}>
        </div>
        <div className="flex flex-col ml-6">
            <p className="font-medium text-[22px]">
                {val}
            </p>
            <p className="font-medium text-[14px]">
                {label}
            </p>
        </div>
    </div>
)

const ProgressCard = ({ progress }) => {
    const navigate = useNavigate()
    const courseStats = `${progress.module.courses.filter(c => !!c.coursesProgressesMap?.isPassed).length} / ${progress.module.courses.length}`
    const quizzesTotal = courseReducer(progress.module, 'quizesTotalCount')
    const quizzesPassed = courseReducer(progress.module, 'quizesPassedCount')
    const quizzesProgressMap = courseReducer(progress.module, 'quizesProgressesMap')
    const quizAverage = Math.round(quizzesProgressMap / quizzesPassed)
    const hasCertificates = `${progress.module.courses.filter(c => !!c.coursesProgressesMap?.hasCertificate).length} / ${progress.module.courses.length}`
    console.log(quizzesTotal, quizzesPassed)
    const goToCourseProgress = (id) => () => {
        navigate(`/platform/progress/${id}`)
    }
    return (
        <div className="grid grid-cols-12 mb-14">
            <div className="col-span-12 mb-8">
                <p className="text-4xl font-light">
                    Статистика по модулю {progress.module.title}
                </p>
            </div>
            <div className={"col-span-12 md:col-span-6 bg-[#ECF2F7] rounded-lg py-3 px-4 cursor-pointer"} onClick={goToCourseProgress(progress.module.id)}>
                <div className="grid grid-cols-12">
                    <div className="col-span-12">
                        <p className="text-base mb-5">
                            Прогресс курсов
                        </p>
                    </div>
                    <div className="col-span-6 flex flex-col">
                        {stats(courseStats, 'Пройдено курсов', "bg-progress-green")}
                        {stats(hasCertificates, 'Получено сертификатов', "bg-progress-violet")}
                        {stats(`${quizzesPassed} / ${quizzesTotal}`, 'Тестовых задания', "bg-progress-green")}
                        {stats(quizAverage, 'Средний балл', "bg-progress-violet")}
                    </div>
                    <div className="col-span-12 flex flex-row justify-end pb-3 pr-3">
                        <img style={{ width: 40 }} src={arrowBlack} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export const PlatformProgress = () => {
    const userState = useGlobalUserState()
    const user = userState.get()?.user || {}
    const progress = useProgress(user.id)

    return (
        <div className="grid grid-cols-12 mt-2">
            <div className={"col-span-12"}>
                <p className={"platform-black font-bold text-4xl mb-14"}>
                    {localize('progress')}
                </p>
            </div>
            {!progress.length ? (
                <div className="col-span-12 flex flex-col items-center">
                    <CircularProgress size="large" className="w-24 h-24" sx={{ color: "#A5896D" }} />
                </div>
            ) : (
                <div className={"col-span-12 flex flex-col"}>
                    {!!progress.length && progress.filter(p => !!p.module?.courses?.length).map(p => (
                        <ProgressCard key={p} progress={p} />
                    ))}
                </div>
            )
            }
        </div>
    )
}
import { languageMapping, lectureTypesOrdered, videoQualities } from "./use-cases/constants"
import topicMetaMajor from './assets/topic-meta-major.svg'
import topicMetaHomework from './assets/topic-meta-homework.svg'
import topicMetaTest from './assets/topic-meta-test.svg'
import topicMetaDeadline from './assets/topic-meta-deadline.svg'
import React from "react"

const returnOrError = (resData, arr = false) => {
    if (resData.error) {
        return arr ? [] : {}
    } else {
        return resData
    }
}

const isVideoPlaying = video => !!(video?.currentTime > 0 && !video?.paused && !video?.ended && video?.readyState > 2)

const isVideoPaused = video => !!(video?.currentTime > 0 && video?.paused && !video?.ended && video?.readyState > 2)

const getFileSize = (url) => {
    fetch(url)
        .then(res => {
            if (!res.ok) {
                return res.text().then(() => { throw new Error('assda') })
            }
            else {
                return res.json();
            }
        })
        .catch(err => {
            console.error('caught it!', err);
        });
}

const hasTopicFailedDeadline = (topic) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    for (let lesson of topic?.lessons) {
        if ('lessonsProgress' in lesson) {
            if (!lesson.lessonsProgress.isPassedOnTime) {
                return true
            }
        }
    }
    return false
}

const topicsListSort = (a, b) => {
    if (lectureTypesOrdered.indexOf(a.type) !== lectureTypesOrdered.indexOf(b.type)) {
        return lectureTypesOrdered.indexOf(a.type) - lectureTypesOrdered.indexOf(b.type)
    } else {
        return a.order - b.order
    }
}

const lessonsListSort = (a, b) => a.order - b.order

const findStringMatchIndexes = (search, string) => {
    for (let i = 0; i < string.length; i++) {
        if (string.slice(i, i + search.length) === search) {
            return { start: i, end: i + search.length }
        }
    }
    return {}
}

const getTopicDeadline = (topic) => {
    try {
        return new Date(topic.deadline).toJSON().slice(0, 10).split('-').reverse().join('.')
    } catch (e) {
        console.error(e)
        return topic.deadline
    }
}

const getTopicMetaData = (key, topic) => {
    switch (key) {
        case 'type':
            return topic.type === "Major" ? (
                <img
                    src={topicMetaMajor}
                />
            ) : null
        case 'deadline':
            return topic.deadline ? (
                <React.Fragment>
                    <img
                        src={topicMetaDeadline}
                    />
                    <span className="ml-0.5">{getTopicDeadline(topic)}</span>
                </React.Fragment>
            ) : null
        case 'quiz':
            return topic.quizesTotalCount ? (
                <img
                    src={topicMetaTest}
                />
            ) : null
        case 'homework':
            return topic.lessons?.some((l) => Boolean(l.homeWork?.length)) ? (
                <img
                    src={topicMetaHomework}
                />
            ) : null
    }
}

const quizQuestionClass = (question, meta) => {
    let isCorrect = isQuestionFullyCorrect(question, meta)
    return {
        className: isCorrect ? 'bg-question-correct' : 'bg-question-incorrect',
        isCorrect

    }
}

const renderCourseLocalized = (englishKey, object, lang, mainClassName, enClassName = 'ml-1', wrapperClassName = '') => {
    const langValue = object[languageMapping[lang][englishKey]]
    if (langValue) {
        return (
            <span className={`${mainClassName} ${wrapperClassName}`}>
                {langValue}
            </span>
        )
    }
    return (
        <div className={`flex flex-row items-center ${wrapperClassName}`}>
            <span className={`text-unix-black font-extrabold ${enClassName}`}>
                (EN){" "}
            </span>
            <span className={mainClassName}>
                {object[englishKey]}
            </span>
        </div>
    )
}

const getCourseLocalizedValue = (englishKey, object, lang, raw = false) => {
    const langKey = languageMapping[lang][englishKey]
    let returnValue = object[langKey]
    if (raw) {
        returnValue = object[englishKey + lang[0] + lang[1]?.toLowerCase()]
    }
    return returnValue || object[englishKey]
}

const getCourseLocalizedKey = (englishKey, lang) => {
    return languageMapping[lang][englishKey] || englishKey
}

const applyToForm = (lang, form, existingObject) => {
    const keys = Object.values(languageMapping[lang])
    for (let k of keys) {
        if (existingObject[k]) {
            form[k] = existingObject[k]
        }
    }
}

const topicLessonReducer = (acc, curr) => [...acc, ...curr.lessons]

const courseTopicsReducer = (acc, curr) => [...acc, ...curr.topics.reduce(topicLessonReducer, [])]

const getFirstInProgressLessonId = (module, courseId, lessonId, next = false) => {
    const allLessons = module.courses.filter((c) => c.id === courseId).reduce(courseTopicsReducer, [])
    const currentLesson = allLessons.find((l) => l.id === lessonId) || {}
    const currentLessonIndex = allLessons.findIndex((l) => l.id === lessonId)
    const firstWithProgressId = allLessons.filter((l) => l.lessonsProgressesMap?.length).at(-1)?.id
    const firstWithProgress = allLessons.findIndex((l) => l.id === firstWithProgressId)
    if (allLessons[currentLessonIndex + 1]?.lessonsProgressesMap?.length || firstWithProgress > currentLessonIndex) {
        return false
    }
    if (!currentLesson.lessonsProgressesMap?.length || next) {
        if (firstWithProgress >= 0) {
            return allLessons[Math.min(firstWithProgress + 1, allLessons.length - 1)].id
        } else {
            return allLessons[0].id
        }
    }
}

const getSources = (videoLink) => {
    if (videoLink?.includes("/1080p/")) {
        const splitted = videoLink.split("/")
        return videoQualities.map((q) => ({
            label: q,
            videoLink: [...splitted.slice(0, splitted.length - 2), q, [...splitted.slice(-1)]].join("/")
        }))
    } else {
        return [{
            label: null,
            videoLink
        }]
    }
}

const shuffleArray = array => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array
}

const getClosestDeadline = (deadlines) => {
    const dateDifferences = deadlines.map((t) => ({ ...t, diff: new Date(t.deadline) - new Date() })).filter((t) => t.diff > 0).sort((a, b) => a.diff - b.diff)
    return dateDifferences.at(0)?.id
}

const isAnswerCorrect = (ansId, question, meta) => {
    let isCorrect = false
    if ('correctAnswers' in question) {
        isCorrect = question.correctAnswers.find(a => a.id === ansId)
    } else {
        const correctAnswersKey = 'questionsWithCorrectAnswers' in meta ? 'questionsWithCorrectAnswers' : 'history'
        const foundQuestion = meta[correctAnswersKey].find(q => q.id === question.id)
        if (foundQuestion) {
            const foundAnswer = foundQuestion.answers.find(a => a.id === ansId)
            if (foundAnswer) {
                isCorrect = correctAnswersKey === "questionsWithCorrectAnswers" ? true : foundAnswer.isCorrect
            }
        }
    }
    return isCorrect
}

const isQuestionFullyCorrect = (question, meta) => {
    let foundQuestion
    if ('questionsWithCorrectAnswers' in meta) {
        foundQuestion = meta.questionsWithCorrectAnswers.find(q => q.id === question.id)
    } else if ('amountRightAnswers' in meta) {
        foundQuestion = meta.history.find(q => q.id === question.id)
    } else {
        foundQuestion = question
    }
    const shouldFilter = 'amountRightAnswers' in meta
    if (foundQuestion) {
        let answersKey
        if ('correctAnswers' in foundQuestion) {
            answersKey = 'correctAnswers'
        } else {
            answersKey = 'answers'
        }
        return foundQuestion[answersKey].filter(a => shouldFilter ? a.isCorrect : true).every(correctAnswer => !!question.userAnswerIds.find(a => a === correctAnswer.id))
            && foundQuestion[answersKey].filter(a => shouldFilter ? a.isCorrect : true).length === question.userAnswerIds.length
    }
    return false
}

const historyToQuestions = (lessonQuestions, history) => {
    const questions = []
    for (let question of lessonQuestions) {
        const found = questions.find(q => question.id === q.id)
        if (!found) {
            const obj = { ...question }
            const userAnswerIds = history.filter(h => h.questionId === question.id).map(h => h.answerId)
            obj.userAnswerIds = [...new Set(userAnswerIds)]
            obj.correctAnswers = question.answers.filter(ans => ans.isCorrect)
            questions.push(obj)
        }
    }
    return questions.sort((a, b) => a.id - b.id)
}

const totalQuizStats = (questionsRaw, meta) => {
    let questions = []
    if ('pastHistory' in questionsRaw) {
        questions = historyToQuestions(meta.history, questionsRaw.pastHistory)
    } else {
        questions = questionsRaw
    }
    const totalCorrectAnswers = questions.length
    const totalUserCorrectAnswers = questions.filter(question => isQuestionFullyCorrect(question, meta)).length
    return {
        totalCorrectAnswers,
        totalUserCorrectAnswers
    }
}

const classesAverageWatchedTime = (lessons) => {
    if (lessons.length) {
        return lessons.reduce((acc, curr) => acc + (curr.lessonsProgress?.videoWatched || 0), 0) / lessons.length
    }
    return 0
}

const getTiming = (timingRaw, width, duration) => {
    const secondsRaw = Math.floor((timingRaw / width) * duration)
    let minutes = Math.floor(secondsRaw / 60)
    if (minutes < 10) {
        minutes = `0${minutes}`
    }
    let seconds = secondsRaw % 60
    if (seconds < 10) {
        seconds = `0${seconds}`
    }
    return `${minutes}:${seconds}`
}

const stringTimeToSeconds = (timing) => {
    const splitted = timing.split(':')
    if (splitted.length > 1) {
        return (60 * parseInt(splitted[0])) + parseInt(splitted[1])
    }
    return 0
}

const formatDuration = totalSeconds => {
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const formattedMinutes = String(minutes).padStart(2, '0')

    if (hours > 0) {
        return `${hours} ч ${formattedMinutes} мин`
    } else {
        return `${formattedMinutes} мин`
    }
}

export {
    returnOrError,
    isVideoPlaying,
    isVideoPaused,
    getTiming,
    stringTimeToSeconds,
    hasTopicFailedDeadline,
    classesAverageWatchedTime,
    quizQuestionClass,
    totalQuizStats,
    isAnswerCorrect,
    getFileSize,
    historyToQuestions,
    getTopicDeadline,
    topicsListSort,
    findStringMatchIndexes,
    lessonsListSort,
    getClosestDeadline,
    getTopicMetaData,
    getSources,
    getFirstInProgressLessonId,
    renderCourseLocalized,
    getCourseLocalizedValue,
    getCourseLocalizedKey,
    applyToForm,
    shuffleArray,
    formatDuration
}
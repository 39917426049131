import { Alert, Snackbar } from '@mui/material'
import React, { useCallback } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Login, NotFound } from './components/pages'
import CourseInfo from './components/pages/courseInfo/CourseInfo'
import CourseInfoRoot from './components/pages/courseInfo/CourseInfoRoot'
import { EngagementRoot } from './components/pages/engagement/EngagementRoot'
import Feedback from './components/pages/feedback/Feedback'
import { Homework } from './components/pages/homework/Homework'
import Landing from './components/pages/landing/Landing'
import { CreateModule } from './components/pages/modules/CreateModule'
import { Module } from './components/pages/modules/Module'
import { Modules } from './components/pages/modules/Modules'
import { Course } from './components/pages/new-entity-redesign/courses/Course'
import { CreateCourse } from './components/pages/new-entity-redesign/courses/CreateCourse'
import { Lesson } from './components/pages/new-entity-redesign/lessons/Lesson'
import Notifications from './components/pages/notifications/Notifications'
import { AllOrganizations } from './components/pages/orgs/AllOrganizations'
import { CreateOrganization } from './components/pages/orgs/CreateOrganization'
import { Organization } from './components/pages/orgs/Organization'
import PlatformRoot from './components/pages/root/PlatformRoot'
import Root from './components/pages/root/Root'
import { StatisticsClass } from './components/pages/statistics/StatisticsClass'
import { StatisticsCourse } from './components/pages/statistics/StatisticsCourse'
import { StatisticsModule } from './components/pages/statistics/StatisticsModule'
import StatisticsOrg from './components/pages/statistics/StatisticsOrg'
import { StatisticsRoot } from './components/pages/statistics/StatisticsRoot'
import UserInfo from './components/pages/statistics/UserInfo'
import { TeacherCourse } from './components/pages/teacher/TeacherCourse'
import { TeacherCourses } from './components/pages/teacher/TeacherCourses'
import UserStats from './components/pages/teacher/UserStats'
import { Auth } from './components/platform/auth/Auth'
import { PlatformCourse } from './components/platform/courses/PlatformCourse'
import { PlatformHome } from './components/platform/home/PlatformHome'
import { PlatformLesson } from './components/platform/lessons/PlatformLesson'
import { PlatformProfile } from './components/platform/profile/PlatformProfile'
import { PlatformModuleProgress } from './components/platform/progress/PlatformModuleProgress'
import { PlatformProgress } from './components/platform/progress/PlatformProgress'
import { PlatformCertificate } from './components/platform/profile/PlatformCertificate';
import { SiteSettingsProvider, ThemeProvider } from './contexts'
import {
  accessGlobalSnackBarState,
  useGlobalSnackBarState
} from './stores/snackbar'
import { useGlobalUserState } from './stores/user'
import { PrivateRoute } from './use-cases/'
import { VerifyCertificate } from './components/platform/profile/VerifyCertificate'

function App() {
  const snackbarState = useGlobalSnackBarState()
  console.log('Erorsss', snackbarState.get())
  const snackbar = snackbarState.get()
  const userState = useGlobalUserState()
  const user = userState.get()
  const handleSnackbarClose = useCallback(() => {
    accessGlobalSnackBarState().setOpen(false)
  }, [])

  return (
    <ThemeProvider>
      <SiteSettingsProvider>
        <BrowserRouter basename={'/'}>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            open={snackbar.open}
            autoHideDuration={snackbar.duration}
            onClose={handleSnackbarClose}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={handleSnackbarClose}
              severity={snackbar.severity}
              sx={{ width: '100%' }}
            >
              {snackbar.text}
            </Alert>
          </Snackbar>
          <Routes>
            <Route path="/partners" element={<Landing />} />
            <Route path="/" element={<Navigate replace to="/platform" />} />
            <Route path="/admin" element={<Root />}>
              <Route
                path={'/admin/'}
                element={
                  user.roles?.includes('TEACHER') ? (
                    <Navigate to="/admin/teacher/courses" replace />
                  ) : (
                    <Navigate to="/admin/orgs" replace />
                  )
                }
              />
              <Route
                path={'/admin/orgs'}
                element={
                  <PrivateRoute prefix="admin">
                    <AllOrganizations />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/orgs/create'}
                element={
                  <PrivateRoute prefix="admin">
                    <CreateOrganization />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/orgs/:organizationId'}
                element={
                  <PrivateRoute prefix="admin">
                    <Organization />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/modules/create'}
                element={
                  <PrivateRoute prefix="admin">
                    <CreateModule />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/modules/statistics/:moduleId'}
                element={
                  <PrivateRoute>
                    <StatisticsModule />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/courses/create'}
                element={
                  <PrivateRoute prefix="admin">
                    <CreateCourse />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/courses/:courseId'}
                element={
                  <PrivateRoute prefix="admin">
                    <Course />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/course'}
                element={
                  <PrivateRoute prefix="admin">
                    <CourseInfoRoot />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/course/:courseId'}
                element={
                  <PrivateRoute prefix="admin">
                    <CourseInfo />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/modules/:moduleId'}
                element={
                  <PrivateRoute prefix="admin">
                    <Module />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/modules'}
                element={
                  <PrivateRoute prefix="admin">
                    <Modules />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/teacher/courses'}
                element={
                  <PrivateRoute prefix="admin">
                    <TeacherCourses />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/teacher/courses/:courseId'}
                element={
                  <PrivateRoute prefix="admin">
                    <TeacherCourse />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/teacher/courses/:courseId/users/:userId'}
                element={
                  <PrivateRoute prefix="admin">
                    <UserStats />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/courses/:courseId/users/:userId'}
                element={
                  <PrivateRoute prefix="admin">
                    <UserInfo />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/engagement/'}
                element={
                  <PrivateRoute prefix="admin">
                    <EngagementRoot />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/homework'}
                element={
                  <PrivateRoute prefix="admin">
                    <Homework />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/notifications/'}
                element={
                  <PrivateRoute prefix="admin">
                    <Notifications />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/statistics/'}
                element={
                  <PrivateRoute prefix="admin">
                    <StatisticsRoot />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/feedback/'}
                element={
                  <PrivateRoute prefix="admin">
                    <Feedback />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/statistics/:courseId'}
                element={
                  <PrivateRoute prefix="platform">
                    <StatisticsCourse />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/statistics/organization/:organizationId'}
                element={
                  <PrivateRoute prefix="admin">
                    <StatisticsOrg />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/admin/courses/:courseId/rooms/curator/:curatorId'}
                element={
                  <PrivateRoute prefix="admin">
                    <StatisticsClass />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path={'/admin/login'} element={<Login />} />
            <Route path="/platform" element={<PlatformRoot />}>
              <Route
                path={'/platform/'}
                element={
                  <PrivateRoute prefix="platform">
                    <PlatformHome />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/platform/courses/:courseId'}
                element={
                  <PrivateRoute prefix="platform">
                    <PlatformCourse />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/platform/old-lessons/:lessonId'}
                element={
                  <PrivateRoute prefix="platform">
                    <PlatformLesson />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/platform/lessons/:lessonId'}
                element={
                  <PrivateRoute prefix="platform">
                    <Lesson />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/platform/profile'}
                element={
                  <PrivateRoute prefix="platform">
                    <PlatformProfile />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/platform/profile/certificates/:id'}
                element={
                  <PrivateRoute prefix="platform">
                    <PlatformCertificate />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/platform/progress'}
                element={
                  <PrivateRoute prefix="platform">
                    <PlatformProgress />
                  </PrivateRoute>
                }
              />
              <Route
                path={'/platform/progress/:moduleId'}
                element={
                  <PrivateRoute prefix="platform">
                    <PlatformModuleProgress />
                  </PrivateRoute>
                }
              />
            </Route>
            <Route path={'/platform/login'} element={<Auth />} />
            <Route path={'/verify/:certificateId'} element={<VerifyCertificate />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        {/* <BrowserRouter basename={'/admin'}>
          </BrowserRouter> */}
        {/* <BrowserRouter basename='/platform'>
          </BrowserRouter> */}
      </SiteSettingsProvider>
    </ThemeProvider>
  )
}

export default App

import { useEffect, useState } from "react"
import { getCertificates } from "../actions/profile"
import { getCourseProgress, getProgress } from "../actions/progress"

const useProgress = (id) => {
    const [data, setData] = useState([])

    const fetch = async () => {
        const response = await getProgress(id)
        if (response.success) {
            setData(response.progress)
        }
    }

    useEffect(() => {
        fetch()
    }, [])
    return data
}

const useModuleProgress = (userId, ids) => {
    const [data, setData] = useState([])

    const fetch = async () => {
        const arr = []
        for (let id of ids) {
            const response = await getCourseProgress(userId, id)
            if (response.success) {
                arr.push(response.progress)
            }
        }
        setData(arr)
    }

    useEffect(() => {
        if (ids.length) {
            fetch()
        }
    }, [ids.length])
    return data
}

const useCourseProgress = (id, courseId) => {
    const [data, setData] = useState([])

    const fetch = async () => {
        const response = await getCourseProgress(id, courseId)
        if (response.success) {
            setData(response.course)
        }
    }

    useEffect(() => {
        fetch()
    }, [])
    return data
}

const useCertificates = () => {
    const [data, setData] = useState([])

    const fetch = async () => {
        const response = await getCertificates()
        if (response.success) {
            setData(response.certificates)
        }
    }

    useEffect(() => {
        fetch()
    }, [])
    return data
}

export {
    useCertificates, useCourseProgress, useModuleProgress, useProgress
}

import { CircularProgress, LinearProgress, linearProgressClasses, styled } from "@mui/material"
import "react-multi-carousel/lib/styles.css"
import { useParams } from "react-router-dom"
import { useModule } from "../../../hooks/modules"
import { useCertificates, useModuleProgress } from "../../../hooks/progress"
import { useGlobalUserState } from "../../../stores/user"
import { localize, topicsReducer } from "../../../use-cases/constants"
import certificateIcon from "../profile/certificate.svg"

const BorderLinearProgressGreen = styled(LinearProgress)(() => ({
    height: 8,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#F6F7F8',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#65C293',
    },
}));

export const PlatformModuleProgress = () => {
    const { moduleId } = useParams()
    const module = useModule(moduleId)
    const userState = useGlobalUserState()
    const user = userState.get()?.user || {}
    const moduleProgress = useModuleProgress(user.id, module?.courses?.map(c => c.id) || [])
    const certificates = useCertificates()
    return (
        <div className="grid grid-cols-12 mt-2">
            <div className={"col-span-12"}>
                <p className={"platform-black font-bold text-4xl mb-14"}>
                    {localize('progress')}
                </p>
            </div>
            <div className={"col-span-12 grid grid-cols-12"}>
                {!moduleProgress.length ? (
                    <div className="col-span-12 flex flex-col items-center">
                        <CircularProgress size="large" className="w-24 h-24" sx={{ color: "#A5896D" }} />
                    </div>
                ) : moduleProgress.map(course => (
                    <div key={course.id} className="col-span-12 md:col-span-4 px-2 py-2">
                        <div className={"bg-gray-blue rounded-[20px] px-6 py-6"}>
                            <p className={"text-[22px] font-bold mt-3"}>
                                {course.title}
                            </p>
                            <div className="flex flex-col md:flex-row mt-5">
                                <div className="text-base text-black flex-1 mb-3 md:mb-0">
                                    {localize('test')}
                                </div>
                                <div className="flex flex-row flex-1 items-center">
                                    <div className="text-base text-black mr-5">
                                        {topicsReducer(course, 'quizesPassedCount')} / {course.amountQuizesInCourse}
                                    </div>
                                    <BorderLinearProgressGreen className={"flex-1"} variant="determinate" value={course.amountQuizesInCourse === 0 ? 0 : (topicsReducer(course, 'quizesPassedCount') / course.amountQuizesInCourse) * 100} />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row mt-5">
                                <div className="text-base text-black flex-1 mb-3 md:mb-0">
                                    {localize('video')}
                                </div>
                                <div className="flex flex-row flex-1 items-center">
                                    <div className="text-base text-black mr-5">
                                        {course.watchedLessonCount} / {course.amountLessonInCourse}
                                    </div>
                                    <BorderLinearProgressGreen className={"flex-1"} variant="determinate" value={course.amountLessonInCourse === 0 ? 0 : (course.watchedLessonCount / course.amountLessonInCourse) * 100} />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row mt-5">
                                <div className="text-base text-black flex-1 mb-3 md:mb-0">
                                    Средний балл
                                </div>
                                <div className="flex flex-row flex-1 items-center">
                                    <div className="text-base text-black mr-5">
                                        {topicsReducer(course, 'quizesPassedCount') === 0 ? 0 : Math.round(topicsReducer(course, 'quizesProgressesMap') / topicsReducer(course, 'quizesPassedCount'))} / {5}
                                    </div>
                                    <BorderLinearProgressGreen className={"flex-1"} variant="determinate" value={topicsReducer(course, 'quizesPassedCount') === 0 ? 0 : (Math.round(topicsReducer(course, 'quizesProgressesMap') / topicsReducer(course, 'quizesPassedCount')) / 5) * 100} />
                                </div>
                            </div>
                            {certificates.find(cert => cert.courseId === course.id) ? (
                                <div className="mt-5 flex flex-row items-center">
                                    <img src={certificateIcon} />
                                    <a className="text-lg font-semibold ml-5" href={certificates.find(cert => cert.courseId === course.id).link} target="_blank" rel="noreferrer">
                                        {certificates.find(cert => cert.courseId === course.id).title}
                                    </a>
                                </div>
                            ) : null}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
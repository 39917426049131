import { useRef, useState } from "react"
import DraggableList from "react-draggable-list"
import { showSnackbar } from "../../../../actions/snackbar"
import { updateTopicPost } from "../../../../actions/topic"
import chevronDownGray from '../../../../assets/chevron-down-gray.svg'
import chevronUpGray from '../../../../assets/chevron-up-gray.svg'
import { renderCourseLocalized, topicsListSort } from "../../../../utils"
import { accessGlobalMiscState } from "../../../../stores/misc"

// export const CourseTopicList = ({label, list, selectedTopic, updateSelectedTopic}) => {
//     const [isExpanded, setIsExpanded] = useState(true)
//     const icon = isExpanded ? chevronUpGray : chevronDownGray

//     const toggleExpanded = () => setIsExpanded(e => !e)

//     const renderTopic = (topic) => {
//         return (
//             <div
//                 className="flex flex-row mt-2 cursor-pointer"
//                 onClick={updateSelectedTopic(topic)}
//             >
//                 <div className={`p-2 w-[35px] flex flex-row items-center justify-center rounded-lg topic-${topic.type}`}>
//                     <span className="text-[15px] leading-5 text-unix-gray">
//                         {topic.type === "Minor" ? "-" : topic.order}
//                     </span>
//                 </div>
//                 <div className={`ml-2 p-2 rounded-lg flex-1 topic-${topic.type} ${selectedTopic.id === topic.id && 'border-2 border-unix-blue'}`}>
//                     <span className="font-medium text-[15px] leading-5 text-unix-text-black">
//                         {topic.title}
//                     </span>
//                 </div>
//             </div>
//         )
//     }

//     return (
//         <div className="flex flex-col">
//             <div 
//                 className="flex flex-row cursor-pointer"
//                 onClick={toggleExpanded}
//             >
//                 <span className="font-medium leading-5 text-unix-gray">
//                     {label}
//                 </span>
//                 <img src={icon} />
//             </div>
//             <div className="flex flex-col">
//                 {isExpanded && list.sort(topicsListSort).map(renderTopic)}
//             </div>
//         </div>
//     )
// }

const TopicListItem = ({ dragHandleProps, item: topic, selectedTopic, updateSelectedTopic }) => {
    const courseSelectedLang = accessGlobalMiscState().get('courseSelectedLang')
    return (
        <div
            className="flex flex-row cursor-pointer"
            onClick={updateSelectedTopic(topic)}
        >
            <div
                {...dragHandleProps}
                className={`p-2 w-[35px] flex flex-row items-center justify-center rounded-lg topic-${topic.type}`}
            >
                <span className="text-[15px] leading-5 text-unix-gray">
                    {topic.type === "Minor" ? "-" : topic.order}
                </span>
            </div>
            <div className={`ml-2 p-2 rounded-lg flex-1 topic-${topic.type} ${selectedTopic.id === topic.id && 'border-2 border-unix-blue'}`}>
                {renderCourseLocalized('title', topic, courseSelectedLang, 'font-medium text-[15px] leading-5 text-unix-text-black', 'mr-1')}
            </div>
        </div>
    )
}

export const CourseTopicList = ({ label, list, selectedTopic, updateSelectedTopic, triggerCourseFetch }) => {
    const [isExpanded, setIsExpanded] = useState(true)
    const icon = isExpanded ? chevronUpGray : chevronDownGray

    const toggleExpanded = () => setIsExpanded(e => !e)
    const listRef = useRef(null);

    const onMoveEnd = async (newList) => {
        for (let idxRaw in newList) {
            const idx = parseInt(idxRaw)
            const topic = newList[idx]
            if (topic.order !== idx + 1) {
                const formData = new FormData()
                formData.append('order', idx + 1)
                formData.append('courseId', topic.courseId)
                const result = await updateTopicPost(topic.id, formData)
                if (!result.success) {
                    showSnackbar("Произошла ошибка. Пожалуйста, повторите позднее", "error")
                    return
                }
            }
        }
        triggerCourseFetch()
        showSnackbar("Успешно")
    }

    const renderTopic = (topic) => {
        return (
            <div
                className="flex flex-row mb-2 cursor-pointer"
                onClick={updateSelectedTopic(topic)}
            >
                <div className={`p-2 min-w-[35px] flex flex-row items-center justify-center rounded-lg topic-${topic.type}`}>
                    <span className="text-[15px] leading-5 text-unix-gray">
                        {topic.type === "Minor" ? "-" : topic.order}
                    </span>
                </div>
                <div className={`ml-2 p-2 rounded-lg flex-1 topic-${topic.type} ${selectedTopic.id === topic.id && 'border-2 border-unix-blue'}`}>
                    <span className="font-medium text-[15px] leading-5 text-unix-text-black">
                        {topic.title}
                    </span>
                </div>
            </div>
        )
    }

    return (
        <div ref={listRef} className="flex flex-col">
            <div
                className="flex flex-row cursor-pointer"
                onClick={toggleExpanded}
            >
                <span className="font-medium leading-5 text-unix-gray">
                    {label}
                </span>
                <img src={icon} />
            </div>
            <div className="flex flex-col mt-2">
                {isExpanded && (
                    <div className="flex flex-col">
                        <DraggableList
                            itemKey="id"
                            template={(props) => (
                                <TopicListItem
                                    {...props}
                                    selectedTopic={selectedTopic}
                                    updateSelectedTopic={updateSelectedTopic}
                                />
                            )}
                            padding={8}
                            list={list.filter((t) => t.type === "Major")}
                            onMoveEnd={onMoveEnd}
                            container={() => listRef.current}
                        />
                        {list.filter((t) => t.type !== "Major").sort(topicsListSort).map(renderTopic)}
                    </div>
                )}
            </div>
        </div>
    )
}
import { profileApi } from "../api"

const getCertificates = async () => {
    const data = {}
    try {
        const response = await profileApi.certificates()
        if (response.data) {
            data.success = true
            data.certificates = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await profileApi.uploadImage(formData);
        return {
            success: true,
            img: response.data
        };
    } catch (e) {
        console.error(e)
        return {
            success: false
        };
    }
};


const getImage = async () => {
    try {
        const response = await profileApi.getImage();
        return {
            success: true,
            data: response.data
        };
    } catch (e) {
        console.error(e)
        return {
            success: false
        };
    }
};

const deleteImage = async () => {
    try {
        const response = await profileApi.deleteImage();
        return {
            success: true,
            data: response.data
        };
    } catch (e) {
        console.error('Error deleting image:', e);
        return {
            success: false,
            error: e.message
        };
    }
};

export {
    getCertificates,
    uploadImage,
    getImage,
    deleteImage,
}
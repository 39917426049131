import { Box, Modal, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { simpleGetter } from '../../../actions/common'
import { getCourse, roomDetails } from '../../../actions/course'
import { moduleStats } from '../../../actions/module'
import { showSnackbar } from '../../../actions/snackbar'
import { roomsApi } from '../../../api'
import {
  useRooms,
  useRoomUserStats,
  useTeacherCourses
} from '../../../hooks/courses'
import { modalStyle } from '../../../use-cases/constants'
import { hasTopicFailedDeadline } from '../../../utils'
import UnixSelect from '../../ui/Select/UnixSelect'
import achieve from './achieve.svg'
import arrowLeftWhite from './arrow-left-white.svg'
import arrowRightGray from './arrow_right_gray.svg'
import avgCourse from './avg-course.svg'
import avgDay from './avg-day.svg'
import avgLecture from './avg-lecture.svg'
import avgTime from './avg-time.svg'
import backWhite from './back_white.svg'
import tickGreen from './green_tick.svg'
import lectures from './lectures.svg'
import lessons from './lessons.svg'
import modalClose from './modal-close.svg'
import redWarning from './red-warning.svg'
import redCross from './red_cross.svg'
import tests from './tests.svg'
import tickWhite from './tick.svg'
import topicFailedDeadlineIcon from './topic-failed-deadline.png'
import warning from './warning.svg'
import whiteCross from './white-cross.svg'
import yellowTick from './yellow_tick.png'

const statTopicReducer = (topics, prop) => {
  return topics.reduce((acc, curr) => acc + curr[prop], 0)
}

const UserStats = () => {
  const { courseId, userId } = useParams()
  const navigate = useNavigate()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const moduleId = queryParams.get('moduleId')
  const curatorId = queryParams.get('curator')
  const modules = useTeacherCourses()
  const [topicSelected, setTopicSelected] = useState(null)
  const [course, setCourse] = useState({ topics: [] })
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedCourse, setSelectedCourse] = useState(undefined)
  const [initialUserSelected, setInitialUserSelected] = useState(false)
  const [statsTrigger, setStatsTrigger] = useState(0)
  const [currentCommentLesson] = useState({})
  const [commentModalOpen, setCommentModalOpen] = useState(false)
  const [commentText, setCommentText] = useState(false)
  const [commentModalMode] = useState('approve')
  const [selectedCurator, setSelectedCurator] = useState(null)
  const [module, setModule] = useState({})
  const moduleOptions = Array.isArray(module) ? module : []
  const { students } = useRooms(courseId)

  console.log('selected ', selectedCourse?.id)
  const stats = useRoomUserStats(courseId, userId, statsTrigger)

  console.log('stats ', stats)

  const handleCuratorChange = newCurator => {
    navigate(
      `/admin/courses/${courseId}/user/${userId}?moduleId=${moduleId}&curator=${newCurator.userId}`,
      { replace: true }
    )
  }

  const handleUserChange = selected => {
    setSelectedUser(selected)

    const newUrl = `/admin/courses/${courseId}/users/${selected.id}?moduleId=${moduleId}&curator=${curatorId}`
    navigate(newUrl)
  }

  useEffect(() => {
    if (!initialUserSelected) {
      const found = students.find(s => s.id === parseInt(userId))
      if (found) {
        setInitialUserSelected(true)
      }
    }
  }, [userId, students, initialUserSelected])

  useEffect(() => {
    const fetchRoomDetails = async () => {
      const data = await roomDetails(courseId, curatorId)
      if (data) {
        setUsers(data.body.users)
      } else {
        console.error('Failed to fetch user details')
      }
    }

    fetchRoomDetails()
  }, [courseId, curatorId])

  const fetchCourse = async id => {
    const response = await getCourse(id)
    if (response.success) {
      setCourse(response.course)
      setSelectedCourse(response.course)
    }
  }

  const submitLessonComment = async () => {
    const topic =
      (stats.course?.topics || []).find(t => t.topicId === topicSelected) || {}
    const response = await simpleGetter(roomsApi.lessonComment, {
      lessonId: currentCommentLesson.id,
      userId: currentCommentLesson.lessonsProgress.userId || topic.userId,
      body: {
        comment: commentText
      }
    })
    if (response.id) {
      setStatsTrigger(t => t + 1)
      setCommentModalOpen(false)
      showSnackbar('Успешно')
    } else {
      showSnackbar('Произошла ошибка. Пожалуйста, повторите позднее', 'error')
    }
  }

  const cancelLessonComment = async () => {
    const topic =
      (stats.course?.topics || []).find(t => t.topicId === topicSelected) || {}
    const response = await simpleGetter(roomsApi.removeLessonComment, {
      lessonId: currentCommentLesson.id,
      userId: currentCommentLesson.lessonsProgress.userId || topic.userId
    })
    if (response.id) {
      setStatsTrigger(t => t + 1)
      setCommentModalOpen(false)
      showSnackbar('Успешно')
    } else {
      showSnackbar('Произошла ошибка. Пожалуйста, повторите позднее', 'error')
    }
  }

  const renderCommentModal = () => {
    if (commentModalMode === 'cancel') {
      return (
        <div className="flex flex-col pb-8 px-8">
          <div className="flex flex-row items-center py-2 px-3">
            <img src={redWarning} />
            <p className="text-xl leading-6 ml-3">
              <span className="text-unix-gray">
                Вы уверены что хотите отменить засчитанный урок{' '}
              </span>
              <span className="text-unix-text-black">
                {currentCommentLesson.title}
              </span>
            </p>
          </div>
          <div className="flex flex-row mt-8">
            <button
              className="flex flex-row flex-1 mr-1.5 rounded-lg bg-unix-gray py-3 items-center justify-center"
              onClick={() => setCommentModalOpen(false)}
            >
              <img src={arrowLeftWhite} className="mr-2" />
              <p className="text-[15px] font-semibold text-white">Вернуться</p>
            </button>
            <button
              className="flex flex-row flex-1 mr-1.5 rounded-lg bg-[#F12D2D] py-3 items-center justify-center"
              onClick={cancelLessonComment}
            >
              <img src={whiteCross} className="mr-2" />
              <p className="text-[15px] font-semibold text-white">Отменить</p>
            </button>
          </div>
        </div>
      )
    }

    return (
      <div className="flex flex-col pb-8 px-8">
        <div className="flex flex-col">
          <p className="text-[15px] text-unix-gray">Засчитать урок</p>
          <p className="text-2xl text-unix-text-black">
            {currentCommentLesson.title}
          </p>
        </div>
        <div className="flex flex-col mt-8">
          <p className="text-sm font-medium text-unix-gray">Комментарий</p>
          <textarea
            value={commentText}
            onChange={e => setCommentText(e.target.value)}
            className="mt-2 rounded-lg bg-[#F0F3FA] py-3 px-4 text-[15px]"
            placeholder="Текст комментария"
            rows={4}
          />
        </div>
        <div className="flex flex-row mt-8">
          <button
            className="flex flex-row flex-1 mr-1.5 rounded-lg bg-unix-gray py-3 items-center justify-center"
            onClick={() => setCommentModalOpen(false)}
          >
            <img src={arrowLeftWhite} className="mr-2" />
            <p className="text-[15px] font-semibold text-white">Вернуться</p>
          </button>
          <button
            className="flex flex-row flex-1 mr-1.5 rounded-lg bg-[#3BBC30] py-3 items-center justify-center"
            onClick={submitLessonComment}
          >
            <img src={tickWhite} className="mr-2" />
            <p className="text-[15px] font-semibold text-white">Засчитать</p>
          </button>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (modules.length) {
      fetchCourse(courseId)
    }
  }, [courseId, modules.length])

  const getInitials = (firstname, lastname) => {
    if (!firstname || !lastname) return ''
    return `${firstname[0]}${lastname[0]}`
  }

  const statsTopics = stats.course?.topics || []
  const courseOptions = module.courses || []
  const topicCount = statsTopics?.length || 0
  const topicFinishedCount = statsTopics?.filter(t => t.isFinished)?.length || 0
  const lessonCount = statTopicReducer(statsTopics || [], 'lessonCount')
  const lessonsProgressesMap = statTopicReducer(
    statsTopics || [],
    'lessonsProgressesMap'
  )
  const quizesTotalCount = statTopicReducer(
    statsTopics || [],
    'quizesTotalCount'
  )
  const quizesPassedCount = statTopicReducer(
    statsTopics || [],
    'quizesPassedCount'
  )
  const quizesProgressesMap = statTopicReducer(
    statsTopics || [],
    'quizesProgressesMap'
  )

  const calculatePercentage = (finished, total) =>
    total > 0 ? (finished / total) * 100 : 0

  function formatTime(seconds) {
    if (seconds >= 3600) {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.round((seconds % 3600) / 60)
      return (
        <p>
          {hours}
          <span className="text-[15px] ml-1 text-[#AFB7CA] mr-2">h</span>
          {minutes}
          <span className="text-[15px] ml-1 text-[#AFB7CA]">min</span>
        </p>
      )
    } else {
      const minutes = Math.round(seconds / 60)
      return (
        <p>
          {minutes}
          <span className="text-[15px] ml-1 text-[#AFB7CA]">min</span>
        </p>
      )
    }
  }

  const lecturePercentage = calculatePercentage(topicFinishedCount, topicCount)
  const lessonsPercentage = calculatePercentage(
    lessonsProgressesMap,
    lessonCount
  )
  const quizzesPercentage = calculatePercentage(
    quizesPassedCount,
    quizesTotalCount
  )

  let probabilityOfCheating = stats.course?.probabilityOfCheating ?? '-'
  if (probabilityOfCheating !== '-') {
    probabilityOfCheating = Math.max(0, probabilityOfCheating)
    probabilityOfCheating = Math.round(probabilityOfCheating * 10) / 10
  }
  const avgTimeLesson = stats.course?.averageTimeLesson ? (
    <span>{formatTime(stats.course.averageTimeLesson)}</span>
  ) : (
    '—'
  )
  const avgTimeTopic = stats.course?.averageTimeTopic
    ? formatTime(stats.course.averageTimeTopic)
    : '—'

  const rawAvgTimeComplete =
    stats.course?.averageTimeTopic * stats.course?.topics?.length
  const avgTimeComplete = isNaN(rawAvgTimeComplete)
    ? '—'
    : formatTime(rawAvgTimeComplete)

  let percentages = []

  statsTopics.forEach(topic => {
    topic.topic.lessons.forEach(lesson => {
      if (
        lesson.quiz &&
        lesson.quiz.quizProgress &&
        lesson.quiz.questions.length > 0
      ) {
        let percentage =
          (lesson.quiz.quizProgress.result / lesson.quiz.questions.length) * 100
        percentages.push(percentage)
      }
    })
  })

  const quizResCalc = result => {
    let ans = (result / 5) * 100

    if (ans > 100) {
      ans /= 2
    }

    return ans
  }

  let averagePercentage =
    percentages.reduce((acc, val) => acc + val, 0) / percentages.length

  let integerPart = Math.floor(averagePercentage)
  let decimalPart = Math.floor((averagePercentage - integerPart) * 10)

  function formaterTime(seconds, t) {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.round((seconds % 3600) / 60)
    let formattedTime = []
    if (hours > 0) {
      formattedTime.push(
        <span className="text-[#AFB7CA]">
          <span
            key="hours"
            className={`text-[24px] mr-1 ${
              topicSelected === t.topicId ? 'text-white' : 'text-[#0068FF]'
            }`}
          >
            {hours}
          </span>
          h
        </span>
      )
    }
    if (minutes >= 0) {
      formattedTime.push(
        <span className="text-[#AFB7CA]">
          <span
            key="minutes"
            className={`text-[24px] mr-1 ${
              topicSelected === t.topicId ? 'text-white' : 'text-[#0068FF]'
            }`}
          >
            {minutes}
          </span>
          min
        </span>
      )
    }
    return formattedTime
  }

  useEffect(() => {
    const fetchModule = async () => {
      const data = await moduleStats(moduleId)
      if (data) {
        setModule(data.module?.module)
      } else {
        console.error('Error failed to metch modules')
      }
    }
    fetchModule()
  }, [])

  const curators = module?.courses?.filter(c => c.id == courseId)[0]
    .coursesCuratorsMap

  useEffect(() => {
    if (curatorId) {
      const defaultCurator = curators?.find(
        c => c.userId === parseInt(curatorId)
      )
      setSelectedCurator(defaultCurator)
    }
    if (userId) {
      const defaultUser = users?.find(c => c.id === parseInt(userId))
      setSelectedUser(defaultUser)
    }
  }, [curatorId, curators])

  useEffect(() => {
    const fetchCourse = async () => {
      const data = await getCourse(courseId)
      if (data) {
        setCourse(data.course)
      } else {
        console.error('Error failed to metch modules')
      }
    }
    fetchCourse()
  }, [])

  return (
    <div className="flex flex-col h-full">
      <div className="grid grid-cols-12 p-[30px]">
        <div className="col-span-12 mb-5 flex flex-row relative z-50 max-[1190px]:left-[-17px]">
          <button
            className="max-[1190px]:hidden"
            onClick={() =>
              navigate(
                `/admin/courses/${courseId}/rooms/curator/${curatorId}?moduleId=${moduleId}`
              )
            }
          >
            <img className="min-w-[32px]" src={backWhite} />
          </button>
          <UnixSelect
            selectedValue={module}
            options={moduleOptions}
            disabled
            optionLabelKey={'title'}
            optionValueKey={'id'}
            selectedCondition={(o, s) => o.id === s?.id}
            additionalClassName="pt-[22px] pb-[9px] w-full"
            wrapperClassName="ml-4 min-w-48 mr-[6px]"
            label="Модуль"
            setValue={setModule}
          />
          {module && (
            <div className="flex flex-row">
              <img src={arrowRightGray} />
              <UnixSelect
                disabled
                selectedValue={course}
                options={courseOptions}
                optionLabelKey={'title'}
                optionValueKey={'id'}
                selectedCondition={(o, s) => o.id === s?.id}
                additionalClassName="pt-[22px] pb-[9px] w-full"
                wrapperClassName="mx-[6px] min-w-48"
                label="Курс"
                setValue={setCourse}
              />
            </div>
          )}
          {!!course && (
            <div className="flex flex-row">
              <img src={arrowRightGray} />
              <UnixSelect
                selectedValue={selectedCurator}
                options={curators}
                disabled
                customOptionLabelGet={u =>
                  `${u.user.lastname} ${u.user.firstname}`
                }
                optionValueKey={'id'}
                selectedCondition={(o, s) => o.id === s?.id}
                additionalClassName="pt-[22px] pb-[9px] w-full"
                wrapperClassName="mx-[6px] min-w-48"
                label="Преподаватель"
                setValue={handleCuratorChange}
                emptyOption={false}
              />
            </div>
          )}
          {!!course && !!students && (
            <div className="flex flex-row">
              <img
                className="min-w-[14px] max-[1190px]:hidden"
                src={arrowRightGray}
              />
              <UnixSelect
                selectedValue={selectedUser}
                options={users}
                customOptionLabelGet={u => `${u.lastname} ${u.firstname}`}
                optionValueKey={'id'}
                selectedCondition={(o, s) => o.id === s?.id}
                additionalClassName="pt-[22px] pb-[9px] w-full"
                wrapperClassName="ml-[6px] min-w-48"
                label="Студент"
                setValue={handleUserChange}
              />
            </div>
          )}
        </div>
        <>
          <div className="col-span-12">
            <div className="flex flex-col bg-white rounded-[12px] px-2">
              <div className="flex flex-row items-center">
                <div className="w-[48px] h-[48px] rounded-full bg-[#FFDF6E] flex items-center justify-center text-[16px]  text-[#fff] font-semibold min-w-[48px] min-h-[48px]">
                  {getInitials(
                    stats?.student?.firstname,
                    stats?.student?.lastname
                  )}
                </div>
                <div className="flex flex-col ml-4">
                  <p className="text-[24px] max-xl:text-[15px] text-[#1A1A1A]">
                    {stats?.student?.firstname} {stats?.student?.lastname}
                  </p>
                  <p className="text-[#5F6B88] font-medium text-[15px]">
                    {stats?.student?.email}
                  </p>
                </div>
                <div className="flex flex-col p-4 max-xl:px-2 rounded-2xl ml-10 max-xl:ml-4">
                  <div className="flex">
                    <img src={warning} alt="" />
                    <p className="font-medium ml-2 text-[14px] text-[#5F6B88]">
                      Вероятность читинга
                    </p>
                  </div>
                  <p className="">
                    <span className="text-4xl max-xl:text-[24px] font-bold text-[#3BBC30]">
                      {probabilityOfCheating}
                    </span>
                    <span className="text-2xl ml-2 text-[#AFB7CA]">%</span>
                  </p>
                </div>
                <div className="flex flex-col p-4 max-xl:px-2 rounded-2xl ml-10 max-xl:ml-4">
                  <div className="flex">
                    <img src={lectures} />
                    <p className="font-medium ml-2 text-[14px] text-[#5F6B88]">
                      Пройдено лекций
                    </p>
                  </div>
                  <div className="font-bold flex items-center text-[36px] max-xl:text-[24px]">
                    <div>
                      <span className="text-[#0068FF]">
                        {topicFinishedCount}
                      </span>
                      <span className="text-[24px] max-xl:text-[18px] max-xl:ml-[3px] text-[#AFB7CA] font-light">
                        / {topicCount}
                      </span>
                    </div>
                    <div
                      className="relative top-[5px] ml-2"
                      style={{ width: 24, height: 24 }}
                    >
                      <CircularProgressbar
                        value={lecturePercentage}
                        strokeWidth={15}
                        styles={buildStyles({
                          textSize: '24px',
                          pathColor: `#AFB7CA`,
                          textColor: '#0068FF',
                          trailColor: '#d6d6d6',
                          strokeWidth: 50
                        })}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col p-4 max-xl:px-2 rounded-2xl ml-10 max-xl:ml-4">
                  <div className="flex">
                    <img src={lessons} alt="" />
                    <p className="font-medium ml-2 text-[14px] text-[#5F6B88]">
                      Пройдено уроков
                    </p>
                  </div>
                  <p className="font-bold text-[36px] max-xl:text-[24px]">
                    <div className="flex items-center text-[36px] max-xl:text-[24px]">
                      <div>
                        <span className="text-[#0068FF]">
                          {lessonsProgressesMap}
                        </span>
                        <span className="text-[24px] text-[#AFB7CA] font-light max-xl:text-[18px] max-xl:ml-[3px]">
                          / {lessonCount}
                        </span>
                      </div>
                      <div
                        className="relative top-[5px] ml-2"
                        style={{ width: 24, height: 24 }}
                      >
                        <CircularProgressbar
                          value={lessonsPercentage}
                          strokeWidth={15}
                          styles={buildStyles({
                            textSize: '24px',
                            pathColor: `#AFB7CA`,
                            textColor: '#0068FF',
                            trailColor: '#d6d6d6',
                            strokeWidth: 10
                          })}
                        />
                      </div>
                    </div>
                  </p>
                </div>
                <div className="flex flex-col p-4 max-xl:px-2 rounded-2xl ml-10 max-xl:ml-4">
                  <div className="flex">
                    <img src={tests} alt="" />
                    <p className="font-medium text-[14px] text-[#5F6B88]">
                      Пройдено тестов
                    </p>
                  </div>
                  <div className="font-bold flex items-center text-[36px] max-xl:text-[24px]">
                    <div>
                      <span className="text-[#0068FF]">
                        {lessonsProgressesMap}
                      </span>
                      <span className="text-[24px] text-[#AFB7CA] font-light max-xl:text-[18px] max-xl:ml-[3px]">
                        / {lessonCount}
                      </span>
                    </div>
                    <div
                      className="relative top-[5px] ml-2"
                      style={{ width: 24, height: 24 }}
                    >
                      <CircularProgressbar
                        value={quizzesPercentage}
                        strokeWidth={15}
                        styles={buildStyles({
                          textSize: '24px',
                          pathColor: `#AFB7CA`,
                          textColor: '#0068FF',
                          trailColor: '#d6d6d6',
                          strokeWidth: 10
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 pt-4">
            <div className="flex flex-col rounded-[12px]">
              <div className="flex justify-between space-x-4">
                <div className="flex-1">
                  <div className="flex flex-col rounded-2xl bg-[#FFF] p-4 h-full justify-between">
                    <div className="flex justify-between items-center relative">
                      <p className="font-medium text-[14px]  text-[#5F6B88]">
                        Средняя оценка
                      </p>
                      <img
                        width={24}
                        height={24}
                        src={achieve}
                        alt="Achievement Icon"
                      />
                    </div>
                    <p className="mb-[10px] relative top-[10px] max-[1279px]:top-[5px]">
                      <div className="flex items-center text-[36px] max-xl:text-[24px]">
                        <div className="flex items-center">
                          <p>
                            <span className="text-4xl font-bold text-[#3BBC30]">
                              {integerPart}
                            </span>
                            <span className="text-3xl text-[#3BBC30] font-normal">
                              {decimalPart ? ',' + decimalPart : ''}
                            </span>
                          </p>
                          <span className="text-2xl ml-2 text-[#AFB7CA] relative top-[3px] left-[-3px]">
                            %
                          </span>
                        </div>
                        <div
                          className="relative top-[5px] ml-5"
                          style={{ width: 24, height: 24 }}
                        >
                          <CircularProgressbar
                            value={
                              quizesTotalCount > 0
                                ? (
                                    100 *
                                    (quizesProgressesMap /
                                      (quizesTotalCount * 5))
                                  ).toFixed(1)
                                : '—'
                            }
                            strokeWidth={15}
                            styles={buildStyles({
                              textSize: '24px',
                              pathColor: `#AFB7CA`,
                              textColor: '#0068FF',
                              trailColor: '#d6d6d6'
                            })}
                          />
                        </div>
                      </div>
                    </p>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex flex-col rounded-2xl bg-[#FFF] p-4 h-full justify-between">
                    <div className="flex justify-between items-center">
                      <p className="font-medium text-[14px] text-[#5F6B88]">
                        Среднее время просмотра видео/урока
                      </p>
                      <img src={avgTime} alt="Average Time Icon" />
                    </div>
                    <p className="font-bold text-[36px] text-[#0068FF] max-[1510px]:relative max-[1415px]:top-[0px]">
                      {avgTimeLesson ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex flex-col rounded-2xl bg-[#FFF] p-4 h-full justify-between">
                    <div className="flex justify-between items-center">
                      <p className="font-medium text-[14px] text-[#5F6B88]">
                        Среднее время прохождения лекции
                      </p>
                      <img src={avgLecture} alt="Average Lecture Time Icon" />
                    </div>
                    <p className="font-bold text-[36px] max-[1054px]:mt-5 text-[#0068FF] max-[1510px]:relative max-[1321px]:top-[0px]">
                      {avgTimeTopic ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex flex-col rounded-2xl bg-[#FFF] p-4 h-full justify-between">
                    <div className="flex justify-between items-center">
                      <p className="font-medium text-[14px] text-[#5F6B88]">
                        Среднее время прохождения курса
                      </p>
                      <img src={avgCourse} alt="Average Course Time Icon" />
                    </div>
                    <p className="font-bold text-[36px] max-[1054px]:mt-5 text-[#0068FF] max-[1510px]:relative max-[1271px]:top-0">
                      {avgTimeComplete ?? '-'}
                    </p>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="flex flex-col rounded-2xl bg-[#FFF] p-4 h-full justify-between">
                    <div className="flex justify-between items-center">
                      <p className="font-medium text-[14px] text-[#5F6B88]">
                        Среднее время прохождения курса в день
                      </p>
                      <img src={avgDay} alt="Average Daily Time Icon" />
                    </div>
                    <p className="font-bold text-[36px] text-[#0068FF] relative">
                      -
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      <div className="grid grid-cols-12 p-[30px] flex-grow">
        <div className="col-span-4 pr-4">
          <div className={`flex flex-col bg-white p-4 h-full rounded-[12px]`}>
            <div className="flex justify-between items-center text-[#5F6B88] text-sm font-medium border-b pb-4 mb-4">
              <h1>Лекция</h1>
              <p className="text-right">
                Среднее время <br /> прохождения лекции
              </p>
            </div>
            {statsTopics.map((t, index) => {
              const topicFailedDeadline = hasTopicFailedDeadline(t.topic)
              return (
                <div
                  key={t.topicId || t.id}
                  className={`relative rounded-2xl py-3 px-4 max-xl:px-2 font-medium text-xl flex flex-row items-center ${
                    'topicId' in t
                      ? 'text-[#1D1D1D] cursor-pointer'
                      : 'text-gray-400 cursor-not-allowed'
                  } ${
                    (t.topicId || t.id) === topicSelected
                      ? 'bg-[#0068FF] text-white'
                      : ''
                  }`}
                  onClick={() => 'topicId' in t && setTopicSelected(t.topicId)}
                >
                  <span className="w-[60%]">{t.topic?.title || t.title}</span>
                  {topicFailedDeadline && (
                    <Tooltip
                      placement="top"
                      title={
                        <span style={{ fontSize: '20px' }}>
                          Есть нарушение дедлайна
                        </span>
                      }
                    >
                      <img
                        className="w-6 h-6 ml-3"
                        src={topicFailedDeadlineIcon}
                      />
                    </Tooltip>
                  )}
                  <p className="ml-auto">
                    {formaterTime(
                      stats.course.spentTimePerTopic[index]?.totalTime,
                      t
                    )}
                  </p>
                </div>
              )
            })}
          </div>
        </div>
        <div className="col-span-8 px-4">
          {topicSelected && (
            <div className=" bg-white p-6 rounded-xl">
              <div className="flex flex-col space-y-4">
                <table className="min-w-full bg-white text-[14px]">
                  <thead>
                    <tr>
                      <th className="py-2 text-left text-[#5F6B88] font-medium text-sm">
                        Урок
                      </th>
                      <th className="py-2 text-left text-[#5F6B88] font-medium text-sm lg:text-end min-w-28">
                        Времени затрачено на видео/урок
                      </th>
                      <th className="py-2 text-left text-[#5F6B88] font-medium text-sm lg:text-center px-4">
                        Видео просмотрено
                      </th>
                      <th className="py-2 text-[#5F6B88] font-medium text-sm text-end px-4">
                        Процент сдачи теста
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {statsTopics
                      .find(t => t.topicId === topicSelected)
                      ?.topic.lessons.map(l => {
                        const parentTopic = statsTopics.find(
                          t => t.topicId === topicSelected
                        ).topic
                        return (
                          <tr key={l.id} className="border-t text-black">
                            <td className="py-2 font-medium text-[15px] text-unix-text-black">
                              {l.title}
                            </td>
                            <td className="py-2 flex justify-end items-center">
                              <p>
                                <span className="text-[#0068FF] text-[32px] pb-1 mr-1">
                                  {'videoWatched' in
                                  (l?.lessonsProgress || {}) ? (
                                    Math.floor(
                                      l.lessonsProgress.videoWatched / 60
                                    )
                                  ) : (
                                    <span className="text-[#AFB7CA]">-</span>
                                  )}
                                </span>
                                <span className="text-[#AFB7CA] text-[15px] font-light ml-1">
                                  min
                                </span>
                              </p>
                            </td>
                            <td className="py-2">
                              <div className="flex flex-row items-center justify-center">
                                {'lessonsProgress' in l &&
                                l.lessonsProgress.isWatched ? (
                                  parentTopic.deadline <
                                  l.lessonsProgress.createdAt ? (
                                    <Tooltip
                                      placement="top"
                                      title={
                                        <div className="flex flex-col items-end">
                                          <p className="text-[15px] leading-[19.5px] text-white">
                                            <span>Дедлайн </span>
                                            <span className="font-semibold">
                                              {new Date(parentTopic.deadline)
                                                .toLocaleDateString()
                                                .replaceAll('/', '.')}
                                            </span>
                                          </p>
                                          <p className="text-[15px] leading-[19.5px] text-white">
                                            <span>Просмотрено </span>
                                            <span className="font-semibold">
                                              {new Date(
                                                l.lessonsProgress.createdAt
                                              )
                                                .toLocaleDateString()
                                                .replaceAll('/', '.')}
                                            </span>
                                          </p>
                                        </div>
                                      }
                                    >
                                      <img
                                        className="w-6 h-6"
                                        src={yellowTick}
                                        alt="Completed"
                                      />
                                    </Tooltip>
                                  ) : (
                                    <img
                                      className="px-10"
                                      src={tickGreen}
                                      alt="Completed"
                                    />
                                  )
                                ) : (
                                  <img
                                    src={redCross}
                                    className=""
                                    alt="Not Completed"
                                  />
                                )}
                              </div>
                            </td>
                            <td className="py-2 flex items-center justify-end xl:px-6 xl:relative xl:left-[10px]">
                              <div className="flex items-center">
                                <p className="text-[#0068FF] text-[32px] font-[500]">
                                  {'quiz' in l ? (
                                    'quizProgress' in l.quiz ? (
                                      `${quizResCalc(
                                        l.quiz.quizProgress.result
                                      ).toFixed(1)}`
                                    ) : (
                                      '0.0'
                                    )
                                  ) : (
                                    <span className="text-[#AFB7CA]">-</span>
                                  )}
                                </p>
                                <p className="text-[#AFB7CA] text-2xl font-light ml-1">
                                  %
                                </p>
                              </div>
                              <div
                                className="relative top-[2px] ml-1 "
                                style={{ width: 24, height: 24 }}
                              >
                                {'quiz' in l && 'quizProgress' in l.quiz ? (
                                  <CircularProgressbar
                                    value={
                                      (('quizProgress' in l.quiz
                                        ? l.quiz?.quizProgress.result
                                        : 0) /
                                        5) *
                                      100
                                    }
                                    strokeWidth={15}
                                    styles={buildStyles({
                                      textSize: '24px',
                                      pathColor: `#AFB7CA`,
                                      textColor: '#0068FF',
                                      trailColor: '#d6d6d6',
                                      strokeWidth: 10
                                    })}
                                  />
                                ) : (
                                  ''
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={commentModalOpen}
        onClose={() => setCommentModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...modalStyle,
            p: 0,
            border: 0,
            borderRadius: 3,
            width: 520
          }}
        >
          <div className="flex flex-col">
            <div className="flex flex-row justify-end px-2 pt-2">
              <img
                src={modalClose}
                className="w-6 h-6 cursor-pointer"
                onClick={() => setCommentModalOpen(false)}
              />
            </div>
            {renderCommentModal()}
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default UserStats

import { useState } from 'react'
import arrowRightBlueGrayBox from '../../../../assets/arrow_right_blue_gray_box.svg'
import chevronDownGrayTopic from '../../../../assets/chevron_down_gray_topic.svg'
import chevronUpGrayTopic from '../../../../assets/chevron_up_gray_topic.svg'
import plusGray from '../../../../assets/plus_gray.svg'
import topicCircleAdditionalFailed from '../../../../assets/topic-circle-additional-failed.svg'
import topicCircleAdditionalTick from '../../../../assets/topic-circle-additional-tick.svg'
import topicCircleDeadlineFailed from '../../../../assets/topic-circle-deadline-failed.svg'
import topicCircleFinished from '../../../../assets/topic-circle-finished.svg'
import topicCircleNotStarted from '../../../../assets/topic-circle-not-started.svg'
import topicCircleStarted from '../../../../assets/topic-circle-started.svg'
import { topicMetaDataKeys } from '../../../../use-cases/constants'
import { getCourseLocalizedValue, getTopicMetaData } from '../../../../utils'
import { SingleLesson } from './SingleLesson'
import { useGlobalMiscState } from '../../../../stores/misc'

export const SingleTopic = ({
  topic,
  listItem = false,
  currentLessonId,
  hideMeta = false,
  textSize
}) => {
  const miscState = useGlobalMiscState()
  const platformLang = miscState.get('lang')
  const hasCurrentLesson = Boolean(
    topic.lessons.find(l => l.id === currentLessonId)
  )
  const [isExpanded, setIsExpanded] = useState(hasCurrentLesson)
  const renderTopicCircle = () => {
    let backgroundCircle, additionalIcon
    if (topic.isFinished) {
      if (
        !topic.lessons?.some(l => !l.lessonsProgressesMap[0]?.isPassedOnTime)
      ) {
        backgroundCircle = topicCircleFinished
        additionalIcon = topicCircleAdditionalTick
      } else {
        backgroundCircle = topicCircleDeadlineFailed
        additionalIcon = topicCircleAdditionalFailed
      }
    } else {
      if (topic.lessons?.some(l => l.lessonsProgressesMap[0]?.isWatched)) {
        backgroundCircle = topicCircleStarted
      } else {
        backgroundCircle = topicCircleNotStarted
      }
    }
    return {
      backgroundCircle,
      additionalIcon
    }
  }

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const { backgroundCircle, additionalIcon } = renderTopicCircle()
  return (
    <div className="flex flex-col">
      <div
        className={`flex flex-row ${hideMeta || topic.type === 'Minor' ? 'items-center' : 'items-start'} ${!topic.isPublic ? 'opacity-30' : 'cursor-pointer'}`}
        {...(topic.isPublic ? { onClick: toggleExpanded } : {})}
      >
        <div className="flex flex-col items-center">
          <div
            className="relative w-7 h-7 rounded-full flex flex-row items-center justify-center"
            style={{
              backgroundImage: `url(${backgroundCircle})`
            }}
          >
            {topic.type === 'Minor' ? (
              <img src={plusGray} />
            ) : (
              <span className="text-[13px] text-unix-gray">{topic.order}</span>
            )}
            {Boolean(additionalIcon) && (
              <img src={additionalIcon} className="absolute right-0 bottom-0" />
            )}
          </div>
          {listItem && !hideMeta && (
            <div className="w-[1px] h-[18px] relative left-[0.5px] bg-[rgba(175,183,202,0.5)]" />
          )}
        </div>
        <div className="flex flex-col mx-2 flex-1">
          <span
            className={`${textSize ? `text-[${textSize}]` : 'text-xs'} font-medium text-unix-text-black dark:text-white line-clamp-1`}
          >
            {getCourseLocalizedValue('title', topic, platformLang)}
          </span>
          {!hideMeta && (
            <div className="text-unix-gray text-[11px] flex flex-row">
              {topicMetaDataKeys
                .map(k => getTopicMetaData(k, topic))
                .filter(item => Boolean(item))
                .map((rendered, i) => (
                  <div
                    key={topicMetaDataKeys[i]}
                    className="flex flex-row mr-1.5 items-start"
                  >
                    {rendered}
                  </div>
                ))}
            </div>
          )}
        </div>
        {listItem ? (
          isExpanded ? (
            <img src={chevronUpGrayTopic} />
          ) : (
            <img src={chevronDownGrayTopic} />
          )
        ) : (
          <img src={arrowRightBlueGrayBox} />
        )}
      </div>
      <div className="flex flex-col pl-3.5">
        {isExpanded ? (
          <div
            className={`w-[1px] ${textSize ? 'h-[16px]' : 'h-[8px]'} bg-[rgba(175,183,202,0.5)]`}
          />
        ) : (
          <div className="w-[1px] h-[16px] bg-[rgba(175,183,202,0.5)]" />
        )}
      </div>
      {isExpanded && (
        <div className="flex flex-col">
          {topic.lessons
            ?.slice()
            ?.sort((a, b) => a.order - b.order)
            ?.map(l => (
              <SingleLesson
                key={l.id}
                lesson={l}
                currentLessonId={currentLessonId}
              />
            ))}
        </div>
      )}
      {isExpanded && (
        <div className="flex flex-col pl-3.5">
          <div className="w-[1px] h-[8px] bg-[rgba(175,183,202,0.5)]" />
        </div>
      )}
    </div>
  )
}

import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useExamTypes } from "../../../../hooks/courses"
import Exam from "../../../shared/Exam/Exam"
import { submitExamPost } from "../../../../actions/course"
import { showSnackbar } from "../../../../actions/snackbar"

export const CreateExam = ({ courseId, onClose }) => {
    const examTypes = useExamTypes()
    const [examForm, setExamForm] = useState({})
    const [examUpdate, setExamUpdate] = useState(0)
    const [examLoading, setExamLoading] = useState(false)
    const [examErrors, setExamErrors] = useState({})

    const updateExam = (key, val) => {
        setExamForm(f => ({
            ...f,
            [key]: val
        }))
    }

    useEffect(() => {
        setExamForm({
            title: '',
            examQuestions: [],
            course_id: parseInt(courseId),
            type: examTypes[0],
            order: 1
        })
    }, [courseId])

    const submitExam = () => {
        setExamUpdate(e => e + 1)
    }

    const validateExam = () => {
        const errors = {}
        for (let index in examForm.examQuestions) {
            const idx = parseInt(index)
            const question = examForm.examQuestions[idx]
            errors[idx] = []
            if (!question.questionText.length) {
                errors[idx].push("Пожалуйста, заполните название вопроса")
            }
            if (!question.examAnswers?.length) {
                errors[idx].push("Пожалуйста, добавьте варианты ответа")
            }
            if (question.examAnswers.filter(ans => !ans.answerText.length).length) {
                errors[idx].push("Пожалуйста, заполните все варианты ответов")
            }
            if (!question.examAnswers.filter(ans => ans.isCorrect).length) {
                errors[idx].push("Пожалуйста, выберите правильный вариант ответа")
            }

        }
        setExamErrors(errors)
        return !Object.values(errors).filter(err => err.length).length
    }

    const submitExamForm = async () => {
        const isValid = validateExam()
        if (isValid) {
            setExamLoading(true)
            const response = await submitExamPost(examForm)
            setExamLoading(false)
            if (response.success) {
                showSnackbar()
                onClose()
            }
        }
    }

    const clearExamErrors = () => setExamErrors({})

    useEffect(() => {
        if (Object.keys(examErrors).length) {
            setTimeout(clearExamErrors, 10000)
        }
    }, [examErrors])

    useEffect(() => {
        if (examForm.examQuestions?.length && examUpdate != 0) {
            submitExamForm()
        }
    }, [examUpdate, examForm])

    if ('examQuestions' in examForm) {
        return (
            <div className="flex flex-col h-full max-h-[80vh]">
                <div className="flex flex-col">
                    <FormControl fullWidth>
                        <InputLabel variant="outlined" id="demo-simple-select-label">Тип экзамена</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={examForm.type}
                            label="Тип экзамена"
                            onChange={e => updateExam('type', e.target.value)}
                        >
                            {examTypes.map(t => (
                                <MenuItem key={t} value={t}>{t}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ marginTop: '2rem' }}>
                        <TextField
                            label="Название"
                            variant="filled"
                            value={examForm.title}
                            onChange={e => updateExam('title', e.target.value)}
                        />
                    </FormControl>
                </div>
                {!!Object.keys(examErrors).length && !!Object.values(examErrors).filter(err => err.length).length && Object.entries(examErrors).map(([key, value]) => (
                    <div key={key} className="flex flex-col bg-red-600 p-3 mx-3 my-4 rounded-md">
                        <p className="text-white font-semibold mb-4">Вопрос {parseInt(key) + 1}</p>
                        {value.map(err => (
                            <p key={err} className="text-white">{err}</p>
                        ))}
                    </div>
                ))}
                <div className="mt-4 flex-1 overflow-y-auto border border-gray-300 rounded-md">
                    <Exam type="edit" triggerUpdate={examUpdate} examQuestions={examForm.examQuestions} update={arr => updateExam('examQuestions', arr)} />
                </div>
                <Button variant="contained" color="success" onClick={submitExam} disabled={examLoading} sx={{ marginTop: '1rem', alignSelf: 'flex-start' }}>
                    Сохранить
                </Button>
            </div>
        )
    }
}
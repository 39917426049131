import { useGlobalMiscState } from "../../../../stores/misc"
import { languageMapping } from "../../../../use-cases/constants"

export const LanguageSelector = () => {
  const miscState = useGlobalMiscState()
  const courseSelectedLang = miscState.get('courseSelectedLang')

  const updateLang = (newLang) => () => {
    miscState.set('courseSelectedLang', newLang)
  }

  return (
    <div className="flex flex-row w-[200px]">
      {Object.keys(languageMapping).map((lang, idx) => {
        return (
          <div
            key={lang}
            className={`${courseSelectedLang === lang ? 'border bg-unix-green' : 'border-0 bg-unix-light-gray'} ${idx === 1 && 'mx-[5px]'} border-black rounded-[10px] cursor-pointer py-[3px] px-4 text-[13px] font-bold flex-1 text-black flex flex-row items-center justify-center leading-[18px]`}
            onClick={updateLang(lang)}
          >
            {lang}
          </div>
        )
      })}
    </div>
  )
}
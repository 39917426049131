import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import feature2 from './feature2.jpg'
import feature3 from './feature3.jpg'
import feature4 from './feature4.jpg'
import feature5 from './feature5.jpg'
import feature6 from './feature6.jpg'
import feature7 from './feature7.jpg'
import feature1 from './featureFull1.jpg'
import './index.css'

const features = [
  {
    title: 'Разделение на модули:',
    description:
      'Гибкая система организации <br/> пользователей и материалов позволяет создать персонализированные образовательные планы.',
    image: feature2
  },
  {
    title: 'Умная отчетность:',
    description:
      'Получайте детальные отчеты о прогрессе каждого участника, чтобы оценить их успех и поддерживать развитие.',
    image: feature3
  },
  {
    title: 'Умная отчетность:',
    description:
      'Получайте детальные отчеты о прогрессе каждого участника, чтобы оценить их успех и поддерживать развитие.',
    image: feature4
  },
  {
    title: 'Умная отчетность:',
    description:
      'Получайте детальные отчеты о прогрессе каждого участника, чтобы оценить их успех и поддерживать развитие.',
    image: feature5
  },
  {
    title: 'Умная отчетность:',
    description:
      'Получайте детальные отчеты о прогрессе каждого участника, чтобы оценить их успех и поддерживать развитие.',
    image: feature6
  },
  {
    title: 'Умная отчетность:',
    description:
      'Получайте детальные отчеты о прогрессе каждого участника, чтобы оценить их успех и поддерживать развитие.',
    image: feature7
  }
]

const FeatureSection = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  }

  return (
    <section className="bg-[#2B2B2B] text-white py-12">
      <div>
        <div className="mx-auto px-6" style={{ maxWidth: '1435px' }}>
          <h2 className="text-[54px] max-sm:text-[44px] font-medium">
            Возможности платформы
          </h2>
          <p className="mt-2 text-[34px] max-sm:text-[27.5px] font-medium text-[#77FF85]">
            Узнайте, что делает <br />{' '}
            <span className="text-[#fff]">Uni-X.kz</span> особенным:
          </p>
        </div>
        <div className="mt-8 overflow-x-hidden overflow-y-auto">
          <Slider
            className="relative lg:left-[50px] xl:left-[300px] max-[600px]:left-0"
            {...settings}
          >
            <img className="feature-section" src={feature1} />
            {features.map(feature => (
              <img
                key={feature.title}
                className="feature-section"
                src={feature.image}
              />
            ))}
          </Slider>
        </div>
      </div>
    </section>
  )
}

const SampleNextArrow = props => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'gray' }}
      onClick={onClick}
    />
  )
}

const SamplePrevArrow = props => {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'gray' }}
      onClick={onClick}
    />
  )
}

export default FeatureSection

import React from "react";
import {Link, useLocation} from "react-router-dom";

export function NotFound() {

    const location = useLocation();
    const isAdminRoute = location.pathname.startsWith('/admin');

    return (
        <main className={'flex flex-col justify-center items-center h-screen'} style={{ padding: "1rem" }}>
            <h1 className={'text-7xl font-bold text-black'}>404</h1>
            <p className={'md:text-lg text-center mt-6 text-black'}>К сожалению, мы не можем найти страницу, которую вы ищете..</p>
            <Link className={'mt-6'} style={{fontFeatureSettings: '"calt" 1'}} to={isAdminRoute ? '/admin' : '/'}><i className={'la text-black la-arrow-left'} /> <span className={'hover:underline text-black'}>Домой</span></Link>
        </main>
    )
}
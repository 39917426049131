import { accessGlobalMiscState } from "../stores/misc"
import localizationData from './localization.json'
import PDF from '../assets/PDF.svg'
import DOC from '../assets/DOC.svg'
import DOCX from '../assets/DOCX.svg'
import JPG from '../assets/JPG.svg'
import PNG from '../assets/PNG.svg'
import ZIP from '../assets/ZIP.svg'
import videoBlack from '../assets/video-black.svg'
import videoBlue from '../assets/video-blue.svg'
import subtitlesBlue from '../assets/subtitle-blue.svg'
import videoquizBlack from '../assets/videoquiz-black.svg'
import testBlack from '../assets/test-black.svg'
import additionalBlack from '../assets/additional-black.svg'
import plusBlue from '../assets/plus-blue.svg'
import uploadBlue from '../assets/upload-blue.svg'
import { courseDelete } from "../actions/course"
import { topicDelete } from "../actions/topic"
import { lessonDelete } from "../actions/lesson"
import { lessonsApi } from "../api"

const inputClass = 'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
const courseReducer = (module, prop) => module.courses.reduce((acc, curr) => acc + curr.topics.reduce((topicAcc, topicCurr) => topicAcc + (topicCurr?.topicsProgressesMap?.length ? (topicCurr?.topicsProgressesMap[0][prop] || 0) : 0), 0), 0)
// eslint-disable-next-line no-unsafe-optional-chaining
const topicsReducer = (course, prop) => course.topics.reduce((topicAcc, topicCurr) => topicAcc + ('topicsProgressesMap' in topicCurr && prop in topicCurr?.topicsProgressesMap ? (topicCurr?.topicsProgressesMap[prop] || 0) : 0), 0)
const localize = (prop) => {
  const lang = accessGlobalMiscState().get('lang') || "RU"
  return localizationData?.[prop]?.[lang] || prop
}
const languageMapping = {
  'EN': {
    title: 'title',
    description: 'description'
  },
  'RU': {
    title: 'titleRu',
    description: 'descriptionRu'
  },
  'KZ': {
    title: 'titleKz',
    description: 'descriptionKz'
  },
}

const lessonLangMapping = {
  'EN': {
    title: 'title',
    description: 'content'
  },
  'RU': {
    title: 'titleRu',
    description: 'contentRu'
  },
  'KZ': {
    title: 'titleKz',
    description: 'contentKz'
  },
}
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const statusSessions = [
  {
    statusSession: 'current',
    label: 'Аттестация №1'
  }
]
const languageSelector = (selectedLang, setSelectedLang, object, mapping) => (
  <div className="flex flex-row w-[200px] mt-5">
    {Object.keys(mapping).map((lang, idx) => {
      return (
        <div
          key={lang}
          className={`${selectedLang === lang ? 'border' : 'border-0'} ${languageFilled(lang, object, mapping) ? 'bg-[#AFDFB7]' : 'bg-[#ECECEC]'} ${idx === 1 && 'mx-[5px]'} border-black rounded-[10px] cursor-pointer py-[3px] px-4 text-[13px] font-bold flex-1 text-black flex flex-row items-center justify-center leading-[18px]`}
          onClick={() => setSelectedLang(lang)}
        >
          {lang}
        </div>
      )
    })}
  </div>
)
const languageFilled = (lang, object, mapping) => {
  return object[mapping[lang].title]?.length && object[mapping[lang].description]?.length
}
function fromBinary(encoded) {
  const binary = window.atob(encoded);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < bytes.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  return String.fromCharCode(...new Uint16Array(bytes.buffer));
}

const videoQualities = ["360p", "480p", "720p", "1080p"]

const additionalMaterialIcons = [
  {
    extensions: ['pdf'],
    icon: PDF
  },
  {
    extensions: ['doc'],
    icon: DOC
  },
  {
    extensions: ['docx'],
    icon: DOCX
  },
  {
    extensions: ['jpg', 'jpeg'],
    icon: JPG
  },
  {
    extensions: ['png'],
    icon: PNG
  },
  {
    extensions: ['zip'],
    icon: ZIP
  },
]

const formExclude = {
  createCourse: ['tags']
}

const additionalQuizClasses = {
  video: 'w-full absolute flex-row items-center justify-center z-50',
  modal: 'rate-modal additional-quiz-modal'
}

const topicMetaDataKeys = ['type', 'homework', 'quiz', 'deadline']

const lessonBreadCrumbs = [
  {
    type: "module",
  },
  {
    type: "course",
  },
  {
    type: "topic",
  },
]

const lessonMaterialMeta = [
  {
    type: "video",
    label: "Видеоматериал",
    icon: videoBlack,
    bottomTexts: ["MP4 | Не более", "1920x1080px, 30ГB"],
    buttonIcon: plusBlue,
    buttonText: 'Загрузить',
    fieldCheck: "backgroundImage",
    deleteApi: lessonsApi.deleteVideoMaterial,
    fileUploads: [
      {
        type: 'image',
        label: 'Обложка',
        existingField: 'backgroundImage',
        field: 'image',
        shouldReadBase64: true,
        bottomText: "Webp | Не более 1920x1080px, 30MB",
        accept: "image/webp"
      },
      {
        type: 'video',
        label: 'Видео',
        existingField: 'videoLink',
        field: 'video',
        buttonText: "Загрузить видео",
        icon: videoBlue,
        bottomText: "Mp4 | Не более 1920x1080px, 10GB",
        accept: "video/mp4,video/x-m4v,video/*",
        localized: true,
        fieldRU: 'videoRU',
        fieldEN: 'videoEN',
        existingFieldRU: 'videoLinkRu',
        existingFieldKZ: 'videoLinkKz'
      },
      {
        type: 'subtitles',
        label: 'Субтиты',
        existingField: 'subtitlesEn',
        field: 'subtitlesEn',
        buttonText: "Загрузить субтитры",
        icon: subtitlesBlue,
        bottomText: "Vtt",
        accept: ".vtt"
      },
    ]
  },
  {
    type: "videoquiz",
    label: "Видеоквиз",
    icon: videoquizBlack,
    bottomTexts: ["Доступно после загрузки видео"],
    buttonIcon: uploadBlue,
    buttonText: 'Создать',
    fieldCheck: "additionalQuizzes",
    deleteApi: lessonsApi.deleteVideoQuiz,
  },
  {
    type: "test",
    label: "Тестовое задание",
    icon: testBlack,
    bottomTexts: ["Прикрепи тестовое задание"],
    buttonIcon: uploadBlue,
    buttonText: 'Создать',
    fieldCheck: "quizes",
    deleteApi: lessonsApi.deleteQuiz,
    fileUploads: [
      {
        type: 'json',
        label: 'Тестовое задание',
        existingFieldType: 'custom',
        existingField: (item) => {
          if (item.quizes?.length) {
            return (
              <div className="mt-2 p-3 border border-unix-background-blue flex flex-row items-center rounded-lg">
                <p className="text-sm text-unix-text-black truncate flex-1">
                  Тест ({item.quizes[0].description})
                </p>
              </div>
            )
          }
        },
        field: 'quiz',
      }
    ]
  },
  {
    type: "additional",
    label: "Дополнительный материал",
    icon: additionalBlack,
    bottomTexts: ["Прикрепи дополнительный материал"],
    buttonIcon: plusBlue,
    buttonText: 'Загрузить',
    fieldCheck: "additionalMaterial",
    deleteApi: lessonsApi.deleteAdditionalMaterial,
    fileUploads: [
      {
        type: 'any',
        label: 'Дополнительный материал',
        existingField: 'additionalMaterial',
        field: 'additionalMaterial'
      }
    ]
  },
]

const lectureTypesOrdered = ["Major", "Minor"]

const deleteActions = {
  'course': courseDelete,
  'topic': topicDelete,
  'lesson': lessonDelete
}

const infoDisplayModes = [
  {
    label: "Подробно",
    key: "full"
  },
  {
    label: "Кратко",
    key: "short"
  },
  {
    label: "Свернуть",
    key: "hide"
  },
]

const skipDeadlineRenderModuleTitles = ['Safety-First', 'Safety First', 'Freshman Adaptation']

const weekdays = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"]

export {
  inputClass,
  fromBinary,
  courseReducer,
  topicsReducer,
  modalStyle,
  formExclude,
  localize,
  languageSelector,
  languageFilled,
  languageMapping,
  lessonLangMapping,
  additionalMaterialIcons,
  additionalQuizClasses,
  statusSessions,
  lessonMaterialMeta,
  lectureTypesOrdered,
  deleteActions,
  infoDisplayModes,
  topicMetaDataKeys,
  weekdays,
  lessonBreadCrumbs,
  videoQualities,
  skipDeadlineRenderModuleTitles
}
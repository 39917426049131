import ru from "date-fns/locale/ru";
import { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import { createLesson, updateLessonPost } from "../../../../actions/lesson";
import { showSnackbar } from "../../../../actions/snackbar";
import crossDarkGray from '../../../../assets/cross-dark-gray.svg';
import tickWhite from '../../../../assets/tick-white.svg';
import { useGlobalMiscState } from "../../../../stores/misc";
import { applyToForm, getCourseLocalizedKey } from "../../../../utils";
import { LanguageSelector } from "./LanguageSelector";
registerLocale("ru", ru)

export const CreateLessonComponent = ({ order, selectedTopic, onClose, triggerFetch, mode = "add", lesson }) => {
    const miscState = useGlobalMiscState()
    const courseSelectedLang = miscState.get('courseSelectedLang')
    const isEdit = mode === "edit"
    const [form, setForm] = useState({})

    useEffect(() => {
        const formObject = {
            title: "",
            topicId: selectedTopic.id,
        }
        if ('id' in lesson && isEdit) {
            formObject.id = lesson.id
            formObject.title = lesson.title
            applyToForm('RU', formObject, lesson)
            applyToForm('KZ', formObject, lesson)
        }
        if (!isEdit) {
            formObject.order = order
        }
        setForm(formObject)
    }, [selectedTopic.id, order, lesson?.id])

    const updateFormNative = (key) => (e) => {
        const localizedKey = getCourseLocalizedKey(key, courseSelectedLang)
        setForm(f => ({
            ...f,
            [localizedKey]: e.target.value
        }))
    }

    const validateForm = () => {
        if (form.title.replace(/[0-9]/g, "").length === 0) {
            showSnackbar("Название не может состоят только из цифр", "error")
            return false
        }
        return true
    }

    const submit = async () => {
        const validated = validateForm()
        if (!validated) {
            return
        }
        let response
        const formData = new FormData()
        for (let [key, val] of Object.entries(form)) {
            formData.append(key, val)
        }
        if (form.id) {
            response = await updateLessonPost(form.id, formData);
        } else {
            response = await createLesson(formData);
        }
        if (response.success) {
            onClose()
            triggerFetch()
        }
    }

    if ('topicId' in form) {
        return (
            <div className="flex flex-col">
                <div className="flex flex-row mb-4">
                    <LanguageSelector />
                </div>
                <div className="flex flex-col">
                    <p className="admin-form-label mb-2">
                        Название урока
                    </p>
                    <input
                        value={form[getCourseLocalizedKey('title', courseSelectedLang)] || ""}
                        onChange={updateFormNative('title')}
                        className="admin-form-input"
                    />
                </div>
                <div className="flex flex-row justify-center items-center mt-6">
                    <button
                        className="flex-1 unix-button bg-white border border-unix-gray flex flex-row items-center justify-center mr-2.5"
                        onClick={onClose}
                    >
                        <img src={crossDarkGray} />
                        <span className="text-unix-gray font-semibold text-[15px] ml-2">Отмена</span>
                    </button>
                    <button
                        className="flex-1 unix-button bg-[#3BBC30] flex flex-row items-center justify-center"
                        onClick={submit}
                    >
                        <img src={tickWhite} />
                        <span className="text-white font-semibold text-[15px] ml-2">Сохранить</span>
                    </button>
                </div>
            </div>
        )
    }
}
import { useState } from 'react'
import star from './star.svg'
import starFilled from './star_filled.svg'
import { CharacterCounter } from '../../pages/courses/CharacterCounter'
import { simpleGetter } from '../../../actions/common'
import { lessonsApi } from '../../../api'
import { showSnackbar } from '../../../actions/snackbar'
const RateLesson = ({ lessonId, onClose }) => {
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        rating: 0,
        description: ""
    })

    const updateForm = (key, value) => {
        setForm({
            ...form,
            [key]: value
        })
    }

    const submit = async () => {
        setLoading(true)
        const response = await simpleGetter(lessonsApi.rateLesson, {
            lessonId: parseInt(lessonId),
            ...form
        })
        setLoading(false)
        if (response.id) {
            onClose()
            showSnackbar('Ваш ответ записан. Спасибо за фидбек!')
        }
    }

    return (
        <div className="flex flex-col p-6 bg-white rounded-xl border border-[#5F6B88]">
            <p className="font-medium text-sm text-[#5F6B88] mb-2">
                Оценка*
            </p>
            <div className="flex flex-row">
                {Array.from({ length: 5 }, (_, i) => i + 1).map(i => (
                    <img
                        key={i}
                        src={i <= form.rating ? starFilled : star}
                        className="cursor-pointer mr-1"
                        onClick={() => updateForm('rating', i)}
                    />
                ))}
            </div>
            <p className="font-medium text-sm text-[#5F6B88] mt-6 mb-2">
                Комментарий*
            </p>
            <textarea
                value={form.description}
                onChange={e => updateForm('description', e.target.value)}
                placeholder="Текст комментария"
                className='px-4 py-3 text-[15px] text-[#1A1A1A] bg-[#F0F3FA] rounded-lg border-0'
                rows={4}
            />
            <CharacterCounter
                text={form.description}
                length={255}
            />
            <p className='text-[13px] text-[#5F6B88] mt-6 mb-6'>
                *Обязательно для заполнения
            </p>
            <div className='flex flex-row'>
                <div
                    className='flex-1 py-3 mr-1 border border-[#5F6B88] text-[#5F6B88] rounded-lg cursor-pointer text-[15px] font-bold text-center'
                    onClick={onClose}
                >
                    Не оценивать
                </div>
                <button
                    disabled={loading}
                    className={`flex-1 py-3 ml-1 bg-[#0068FF] text-white rounded-lg cursor-pointer text-[15px] font-bold text-center ${(!(form.rating > 0 && form.description.length > 0) || loading) ? 'opacity-30' : ''}`}
                    onClick={submit}
                >
                    Отправить
                </button>
            </div>
        </div>
    )
}

export default RateLesson
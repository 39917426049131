import React from "react";
import { Navigate } from "react-router-dom";
import { accessGlobalUserState } from "../stores/user";
import { isLoggedIn } from "./index";

export function PrivateRoute(props) {
    const user = accessGlobalUserState().get()
    const { prefix } = props
    if (prefix === "admin" && (user.roles?.length && !user.roles?.filter(role => ['SUPERADMIN', 'ADMIN', 'MODERATOR', 'TEACHER', 'CONTENTMAKER'].includes(role)).length)) {
        return <Navigate to={`/platform`} />
    }
    return isLoggedIn() ? props.children : <Navigate to={`/${prefix}/login`} />
}
import { coursesApi } from "../api"
import { showSnackbar } from "./snackbar"

const createCourse = async (body) => {
    const data = {}
    try {
        const response = await coursesApi.create(body)
        if (response.data.id) {
            showSnackbar()
            data.success = true
            data.id = response.data.id
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const teacherAll = async () => {
    const data = {}
    try {
        const response = await coursesApi.teacherAll()
        if (response.data.modules) {
            data.success = true
            data.modules = response.data.modules
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getExam = async (id) => {
    const data = {}
    try {
        const response = await coursesApi.getExam(id)
        if (response.data.title) {
            data.success = true
            data.exam = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const submitExamPost = async (body) => {
    const data = {}
    try {
        const response = await coursesApi.submitExam(body)
        if (response.data.title) {
            data.success = true
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getCourseStats = async (id, moduleId) => {
    let data = {}
    try {
        const response = await coursesApi.stats(id, moduleId)
        console.warn(response)
        if ('userTotalCount' in response.data) {
            data = response.data
            data.success = true
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const examTypes = async () => {
    const data = {}
    try {
        const response = await coursesApi.examTypes()
        if (response.data.types) {
            data.success = true
            data.types = response.data.types
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const updateCourse = async (id, body) => {
    const data = {}
    try {
        const response = await coursesApi.update(id, body)
        if (response.data.id) {
            showSnackbar()
            data.success = true
            data.id = response.data.id
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getCourseTypes = async () => {
    const data = {}
    try {
        const response = await coursesApi.courseTypes()
        if (response.data.types) {
            data.success = true
            data.types = response.data.types
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getCourse = async (id) => {
    const data = {}
    try {
        const response = await coursesApi.getCourse(id)
        if (response.data.id) {
            data.success = true
            data.course = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const courseDelete = async (id) => {
    const data = {}
    try {
        const response = await coursesApi.deleteCourse(id)
        if (response.data.id) {
            showSnackbar()
            data.success = true
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getCourses = async () => {
    const data = {}
    try {
        const response = await coursesApi.all()
        if (response.data.courses) {
            data.success = true
            data.courses = response.data.courses
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const createOnlineLesson = async (body) => {
    const data = {};
    try {
        console.log("Creating online lesson with payload:", body);
        const response = await coursesApi.onlineLesson(body);
        if (response && response.data && response.data.id) {
            data.success = true;
            data.id = response.data.id;
            data.data = response.data;
        } else {
            data.success = false;
        }
    } catch (e) {
        console.error('Error creating online lesson:', e.response ? e.response.data : e.message);
        data.success = false;
    }
    return data;
};

const updateRoomLesson = async (lessonId, body) => {
    const data = {};
    try {
        const response = await coursesApi.updateRoomLesson(lessonId, body);
        console.log('Response ', response)
        if (response) {
            showSnackbar();
            data.success = true;
            data.lessonId = response.id;
            data.data = response.data;
        } else {
            data.success = false;
        }
    } catch (e) {
        console.error('Error creating online lesson:', e.response ? e.response.data : e.message);
        data.success = false;
    }
    return data;
};

const getOnlineLesson = async (courseId) => {
    const data = {}
    try {
        const response = await coursesApi.getOnlineLesson(courseId)
        if (response.data && response.data.id) {
            data.success = true
            data.onlineLesson = response.data
        } else {
            data.success = false
        }
    } catch (e) {
        console.error('Error fetching online lesson:', e);
        data.success = false
    }
    return data
}

const getRoomLesson = async (roomId) => {
    const data = {}
    try {
        const response = await coursesApi.getRoomLesson(roomId)
        if (response.data && response.data.id) {
            data.success = true
            data.roomLesson = response.data
        } else {
            data.success = false
        }
    } catch (e) {
        console.error('Error fetching online lesson:', e);
        data.success = false
    }
    return data
}

const roomDetails = async (courseId, curatorId) => {
    const data = {}
    try {
        const response = await coursesApi.roomDetails(courseId, curatorId)
        if (response.data) {
            data.success = true
            data.body = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const searchTeacher = async (moduleId, q) => {
    const data = {}
    try {
        const response = await coursesApi.searchTeacher(moduleId, q)
        if (response.data?.length) {
            data.users = response.data
            data.success = true
        }
    } catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

export {
    createCourse,
    updateCourse,
    getCourse,
    getCourses,
    getCourseTypes,
    teacherAll,
    courseDelete,
    submitExamPost,
    getCourseStats,
    getExam,
    examTypes,
    createOnlineLesson,
    getOnlineLesson,
    getRoomLesson,
    updateRoomLesson,
    roomDetails,
    searchTeacher
}
import { useEffect, useState } from "react"
import { simpleGetter } from "../actions/common"
import { examTypes, getCourse, getCourseStats, getCourseTypes, getCourses, teacherAll } from "../actions/course"
import { getRooms } from "../actions/room"
import { coursesApi, lessonsApi, roomsApi, tagsApi } from "../api"
import { stringTimeToSeconds } from "../utils"

const useCourse = (id, trigger, callback = () => { }) => {
    const [data, setData] = useState({})

    const fetchModule = async () => {
        const response = await getCourse(id)
        if (response.success) {
            setData(response.course)
            callback(response.course)
        }
    }

    useEffect(() => {
        if (id) {
            fetchModule()
        }
    }, [id, trigger])
    return data
}

const useOnlineLesson = (id) => {
    const [data, setData] = useState({})

    const fetchData = async () => {
        const response = await simpleGetter(coursesApi.getOnlineLesson, id)
        if (response.link?.length) {
            setData(response)
        }
    }

    useEffect(() => {
        if (id) {
            fetchData()
        }
    }, [id])
    return data
}

const useCourses = () => {
    const [data, setData] = useState([])

    const fetchModule = async () => {
        const response = await getCourses()
        if (response.success) {
            setData(response.courses)
        }
    }

    useEffect(() => {
        fetchModule()
    }, [])
    return data
}

const useCourseTypes = () => {
    const [data, setData] = useState([])

    const fetchModule = async () => {
        const response = await getCourseTypes()
        if (response.success) {
            setData(response.types)
        }
    }

    useEffect(() => {
        fetchModule()
    }, [])
    return data
}

const useExamTypes = () => {
    const [data, setData] = useState([])

    const fetchExamTypes = async () => {
        const response = await examTypes()
        if (response.success) {
            setData(response.types)
        } else {
            setData([
                "Midterm",
                "Endterm"
            ])
        }
    }

    useEffect(() => {
        fetchExamTypes()
    }, [])
    return data
}

const useCourseStats = (id, moduleId) => {
    const [data, setData] = useState({})
    const fetchExamTypes = async () => {
        const response = await getCourseStats(id, moduleId)
        if (response.success) {
            setData(response)
        }
    }

    useEffect(() => {
        if (!!id && !!moduleId) {
            fetchExamTypes()
        }
    }, [id, moduleId])
    return data
}


const useRoomUserStats = (courseId, userId, trigger) => {
    const [data, setData] = useState({})
    const fetchStats = async () => {
        const response = await simpleGetter(roomsApi.userStats, { courseId, userId })
        if ('course' in response) {
            setData(response)
        }
    }

    useEffect(() => {
        if (!!courseId && !!userId) {
            fetchStats()
        } else {
            setData({})
        }
    }, [courseId, userId, trigger])
    return data
}

const useRooms = (courseId) => {
    const [rooms, setRooms] = useState([])
    const [students, setStudents] = useState([])
    const fetchRooms = async () => {
        const response = await getRooms(courseId)
        if (response.success) {
            setRooms(response.rooms.sort((a, b) => a.id - b.id));
            setStudents(response.users);
        }
    }

    useEffect(() => {
        if (courseId) {
            fetchRooms()
        } else {
            setRooms([])
            setStudents([])
        }
    }, [courseId])
    return { rooms, students }
}

const useAdditionalQuiz = (lessonId) => {
    const [data, setData] = useState([])
    const fetchData = async () => {
        const response = await simpleGetter(lessonsApi.additionalQuizzes, lessonId)
        if (response?.length) {
            const additionalQuestions = response[0].additionalQuestions.map(q => ({
                ...q,
                seconds: stringTimeToSeconds(q.timing)
            }))
            setData(additionalQuestions)
        }
    }

    useEffect(() => {
        if (lessonId) {
            fetchData()
        }
    }, [lessonId])

    return data
}

const useTeacherCourses = () => {
    const [data, setData] = useState([])

    const fetchCourses = async () => {
        const response = await teacherAll()
        if (response.success) {
            setData(response.modules)
        }
    }

    useEffect(() => {
        fetchCourses()
    }, [])
    return data
}

const useTags = (triggerFetch) => {
    const [data, setData] = useState([])

    const fetchData = async () => {
        const response = await simpleGetter(tagsApi.getAll)
        if (response?.length) {
            setData(response)
        }
    }

    useEffect(() => {
        fetchData()
    }, [triggerFetch])
    return data
}

export {
    useAdditionalQuiz, useCourse, useCourseStats, useCourseTypes, useCourses,
    useExamTypes, useOnlineLesson, useRoomUserStats, useRooms,
    useTags, useTeacherCourses
}

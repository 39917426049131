import { lessonsApi } from "../api";
import { showSnackbar } from "./snackbar";

const createLesson = async (body) => {
  const data = {};
  try {
    const response = await lessonsApi.create(body);
    if (response.data.id) {
      showSnackbar();
      data.success = true;
      data.lesson = response.data;
    }
  } catch (e) {
    console.error(e)
    showSnackbar("Произошла ошибка. Пожалуйста, попробуйте еще раз.", "error");
    data.success = false;
  }
  return data;
};

const updateLessonPost = async (id, body) => {
  const data = {};
  try {
    const response = await lessonsApi.update(id, body);
    if (response.data.id) {
      showSnackbar();
      data.success = true;
      data.lesson = response.data;
    }
  } catch (e) {
    console.error(e)
    showSnackbar("Произошла ошибка. Пожалуйста, попробуйте еще раз.", "error");
    data.success = false;
  }
  return data;
};

const getLesson = async (id) => {
  const data = {};
  try {
    const response = await lessonsApi.getLesson(id);
    if (response.data.id) {
      data.success = true;
      data.lesson = response.data;
    }
  } catch (e) {
    console.error(e)
    data.success = false;
  }
  return data;
};

const lessonDelete = async (id) => {
  const data = {};
  try {
    const response = await lessonsApi.deleteLesson(id);
    if (response.data.id) {
      showSnackbar();
      data.success = true;
    }
  } catch (e) {
    console.error(e)
    data.success = false;
  }
  return data;
};

const deleteVideoMaterial = async (id) => {
  const data = {};
  try {
    const response = await lessonsApi.deleteVideoMaterial(id);
    if (response.data.id) {
      showSnackbar();
      data.success = true;
    }
  } catch (e) {
    console.error(e)
    data.success = false;
  }
  return data;
};

const deleteAdditionalMaterial = async (id) => {
  const data = {};
  try {
    const response = await lessonsApi.deleteAdditionalMaterial(id);
    if (response.data.id) {
      showSnackbar();
      data.success = true;
    }
  } catch (e) {
    console.error(e)
    data.success = false;
  }
  return data;
};

const getQuiz = async (id) => {
  const data = {};
  try {
    const response = await lessonsApi.getQuiz(id);
    if (response.data.id) {
      data.success = true;
      data.quiz = response.data;
    }
  } catch (e) {
    console.error(e)
    data.success = false;
  }
  return data;
};

const quizSubmit = async (id, body) => {
  const data = {};
  try {
    const response = await lessonsApi.submitQuiz(id, body);
    if ("result" in response.data) {
      data.meta = response.data;
      data.success = true;
    }
  } catch (e) {
    if (e.response?.status === 417) {
      data.meta = e.response.data;
    }
    data.success = false;
  }
  return data;
};

const videoWatched = async (id, body) => {
  const data = {};
  try {
    const response = await lessonsApi.watched(id, body);
    if (response.data.isWatched) {
      data.success = true;
    }
  } catch (e) {
    console.error(e)
    data.success = false;
  }
  return data;
};

export {
  createLesson,
  updateLessonPost,
  getLesson,
  getQuiz,
  quizSubmit,
  lessonDelete,
  videoWatched,
  deleteVideoMaterial,
  deleteAdditionalMaterial,
};

import { ExpandMore } from "@mui/icons-material"
import { useEffect, useRef, useState } from "react"
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from "react-router-dom"
import { getTopic } from "../../../actions/topic"
import lectureFinished from './LectureFinished.svg'
import lectureStarted from './LectureInactive.svg'
import deadlinePassed from './deadline.svg'
import done from './done.svg'
import inProgress from './in-progress.svg'
import lessonDone from './lessonDone.svg'
import lessonUnbegun from './lessonInactive.svg'
import lessonStarted from './lessonStarted.svg'
import passedOnTime from './passed.svg'

const CourseNavigation = ({ course, organization, highlightedTopicId }) => {
    console.log('HIghtl ', highlightedTopicId)
    const isTablet = useMediaQuery({ query: '(max-width: 767.98px)' })
    const navigate = useNavigate()
    const [lessons, setLessons] = useState({})
    const [expanded, setExpanded] = useState({})
    const [lastWatchedLesson, setLastWatchedLesson] = useState(null)
    const lastWatchedRef = useRef(null)
    const highlightedSectionRef = useRef(null);
    const [hoveredTopicId, setHoveredTopicId] = useState(null);

    useEffect(() => {
        highlightedSectionRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

    }, [highlightedTopicId]);

    const goToLesson = (l, t) => {
        localStorage.setItem('lastWatchedLesson', l.id)
        setLastWatchedLesson(l.id)
        navigate(`/platform/lessons/${l.id}`, { state: { course, organization, topic: t, lesson: l } })
    }

    const isLastWatchedLesson = (lessonId) => {
        return lessonId == lastWatchedLesson
    }

    useEffect(() => {
        if (Object.keys(lessons).length !== 0) {
            const initialExpandedState = {}
            course.topics.forEach(topic => {
                const isAnyLessonWatched = lessons[topic.id]?.some(lesson => isLastWatchedLesson(lesson.id))
                if (isAnyLessonWatched && lessons[topic.id]?.length !== 1) {
                    initialExpandedState[topic.id] = true
                } else {
                    initialExpandedState[topic.id] = false
                }
            })
            setExpanded(initialExpandedState)
        }
    }, [lessons])

    useEffect(() => {
        const lastWatched = localStorage.getItem('lastWatchedLesson')
        if (lastWatched) {
            setLastWatchedLesson(lastWatched)
        }
    }, [])

    useEffect(() => {
        if (lastWatchedRef.current) {
            lastWatchedRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }, [expanded])

    const getLessons = async (topics) => {
        const obj = {}
        const promises = topics.map(topic => getTopic(topic.id))
        const res = await Promise.allSettled(promises)
        for (let response of res.filter(r => r.status === "fulfilled")) {
            if (response.value?.topic?.lessons) {
                obj[response.value?.topic.id] = response.value?.topic?.lessons
            }
        }
        setLessons(obj)
    }

    const renderLessonIcon = (lesson, isTablet) => {
        if (!lesson.isWatched) {
            return (
                <svg className={isTablet ? "w-3/4 go-button-circle" : "go-button-circle w-[32px] h-[32px]"} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <circle cx="24" cy="24" r="23.5" fill="#F1EFE7" stroke="#F1EFE7" />
                    <path d="M31.039 18.9522L16.2943 29.2766M25.4681 17.9699L31.039 18.9522L25.4681 17.9699ZM31.039 18.9522L30.0567 24.5231L31.039 18.9522Z" stroke="#9C7A2A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            )
        } else {
            if (!lesson.isHasQuiz || (lesson.isHasQuiz && lesson.isQuizPassed)) {
                return (
                    <img src={done} className="course-progress-icon" />
                )
            }
            return (
                <img src={inProgress} className="course-progress-icon" />
            )
        }
    }
    const renderLessonIcon2 = (lesson) => {
        if (!lesson.isWatched) {
            return (
                <img src={lessonUnbegun} />
            )
        } else {
            if (!lesson.isHasQuiz || (lesson.isHasQuiz && lesson.isQuizPassed)) {
                return (
                    <img src={lessonDone} />
                )
            }
            return (
                <img src={lessonStarted} />
            )
        }
    }

    const renderTopLine = (finished, parent = false, parentIdx = 0) => {
        return (
            <div style={{
                height: parent ? '2rem' : '1.5rem',
                width: 2,
                backgroundColor: !parent || (parent && parentIdx !== 0) ? finished ? "#10C900" : "#F1EFE7" : 'transparent'
            }} />
        )
    }
    const renderBottomLine = (finished, isLastLesson, isLastTopic) => {
        if (isLastTopic && isLastLesson) {
            return null;
        }
        return (
            <div style={{
                width: 2,
                backgroundColor: finished ? "#10C900" : "#F1EFE7",
                flexGrow: 1
            }} />
        )
    }


    const renderTopicBottomLine = (finished, isLastTopic) => {
        if (isLastTopic) {
            return null;
        }
        return (
            <div style={{
                width: 2,
                backgroundColor: finished ? "#10C900" : "#F1EFE7",
                flexGrow: 1
            }} />
        )
    }

    const isLessonFinished = (lesson) => {
        if (!lesson.isHasQuiz) {
            return lesson.isWatched
        } else {
            return lesson.isWatched && lesson.isQuizPassed
        }
    }

    const handleExpandedChange = (topicId) => {
        setExpanded(e => {
            return {
                ...e,
                [topicId]: !e[topicId]
            }
        })
    }

    useEffect(() => {
        if (course.topics?.length) {
            if (Object.keys(expanded).length === 0) {
                setExpanded(course.topics.map(t => ({ [t.id]: false })).reduce((acc, curr) => ({ ...acc, ...curr }), {}))
            }
            if (Object.keys(lessons).length === 0) {
                getLessons(course.topics)
            }
        }
    }, [course.topics?.length])

    console.log('Course ', course)

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    return (
        <div className="grid grid-cols-12">
            {course.topics && course.topics.map((topic, index) => {

                const isLastTopic = index === course.topics.length - 1;

                return (
                    <div key={topic.id} className={`col-span-12 grid grid-cols-12 ${expanded[topic.id] ? "bg-white rounded-[10px] my-3" : ""}`}>
                        <div className="col-span-1 flex flex-col items-center">
                            {renderTopLine(index === 0 ? false : lessons[course.topics[index - 1].id]?.every(isLessonFinished), true, index)}
                            <div className="relative">
                                <div
                                    className={`
                                    absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-lg font-bold
                                    ${isLessonFinished ? 'text-[#9C7A2A]' : 'text-[#10C900]'}
                                `}
                                >
                                    {topic.type == 'Major' ? <span>{index + 1}</span> : '+'}
                                    {hoveredTopicId === topic.id && topic.type == 'Major' && (
                                        <div>
                                            <div
                                                className="absolute z-50 top-[-23px] left-0 transform -translate-x-1/2 -translate-y-1/2 text-center bg-[#1A1A1ACC] bg-opacity-80 text-white shadow-lg p-2 rounded-lg font-normal
                                                max-md:left-20 max-lg:top-[-31px]  
                                            ">
                                                {topic.isPass ? (
                                                    <div className="w-[180px]">
                                                        <span className="text-[14px]">Дедлайн <span className="font-medium">{formatDate(topic.deadline)}</span></span><br />
                                                        <span className="text-[14px] relative top-[-7px]">Пройдено <span className="font-medium">{formatDate(topic.progressUpdatedAt)}</span></span>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="arrow_down_on_hover absolute z-50"></div>
                                        </div>
                                    )}
                                    {topic.type == 'Major' && topic.isPass ?
                                        <div className="absolute w-[16px] h-[16px] max-sm:w-[14px] max-sm:h-[14px] z-50" onMouseEnter={() => setHoveredTopicId(topic.id)}
                                            onMouseLeave={() => setHoveredTopicId(null)} >
                                            {new Date(topic.progressUpdatedAt) > new Date(topic.deadline) ? (
                                                <img src={deadlinePassed} alt="Yellow Warning" className="w-4 relative top-[-12px] max-lg:top-[-20px] left-[12px] h-4 mx-auto mt-2 max-sm:left-[7px]" />
                                            ) : (
                                                <img src={passedOnTime} alt="Green Tick" className="w-4 relative top-[-12px] max-lg:top-[-20px] left-[12px] h-4 mx-auto mt-2 max-sm:left-[7px]" />
                                            )}
                                        </div> : ''
                                    }
                                </div>
                                {lessons[topic.id]?.every(isLessonFinished) ? (
                                    <div>
                                        <img src={lectureFinished} />
                                    </div>
                                ) : (
                                    <img src={lectureStarted} />
                                )}
                            </div>
                            {isLastTopic && (
                                <div
                                    className="topic-bottom-line"
                                    style={{
                                        width: 2,
                                        backgroundColor: lessons[topic.id]?.every(isLessonFinished) ? "#10C900" : "#F1EFE7",
                                        flexGrow: 1,
                                        display: expanded[topic.id] ? 'block' : 'none'
                                    }}
                                />
                            )}
                            {renderTopicBottomLine(
                                lessons[topic.id]?.every(isLessonFinished),
                                isLastTopic
                            )}
                        </div>
                        <div className="col-span-11">
                            <div className={`flex flex-row items-center justify-between pt-8 px-4 cursor-pointer topic-title ${highlightedTopicId === topic.id ? 'bg-[#0068FF14] bg-border-8 rounded-[20px]' : ''}`} onClick={() => lessons[topic.id]?.length === 1 ? goToLesson(lessons[topic.id][0], topic) : handleExpandedChange(topic.id)}
                                ref={highlightedTopicId === topic.id ? highlightedSectionRef : null}
                            >
                                <p className={"max-sm:text-[20px] text-[28px] font-semibold"}>
                                    {topic.title}
                                    {topic.type == 'Major'
                                        ?
                                        <div className="mb-2 text-[15px] mt-0 flex gap-6">
                                            <span className="text-[#8F30BC] font-500">Обязательная лекция</span>
                                            <span className="text-[#5F6B88]">Дедлайн {new Date(topic.deadline).toLocaleDateString('ru-RU')}</span>
                                        </div>
                                        :
                                        <div className="text-[14px] text-[#696a6c]">
                                            <span>Необязательная лекция</span>
                                        </div>
                                    }
                                </p>
                                <ExpandMore />
                            </div>
                        </div>
                        {expanded[topic.id] && lessons[topic.id] && lessons[topic.id].map((lesson, idx) => (
                            <>
                                <div className="col-span-1 flex flex-col items-center">
                                    {renderTopLine(isLessonFinished(idx === 0 ? lesson : lessons[topic.id][idx - 1]))}
                                    {renderLessonIcon2(lesson)}
                                    {renderBottomLine(isLessonFinished(lesson), idx === lessons[topic.id].length - 1, index === course.topics.length - 1)}
                                </div>
                                <div ref={isLastWatchedLesson(lesson.id) ? lastWatchedRef : null} className="col-span-11">
                                    <div className={`topic-lesson-card cursor-pointer rounded-[20px] lesson-title ${isLastWatchedLesson(lesson.id) ? 'bg-[#ECF2F6]' : ''}`} onClick={() => goToLesson(lesson, topic)}>
                                        {isTablet ? (
                                            <>
                                                <div className={"flex flex-row p-2 justify-between items-center mt-3 mb-6"}>
                                                    <p className="text-[28px] max-sm:text-[16px] font-light basis-10/12">
                                                        {lesson.title}
                                                    </p>
                                                    <div className="basis-2/12 flex flex-row justify-end">
                                                        {renderLessonIcon(lesson, isTablet)}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="text-[28px] p-4  font-extralight flex items-center justify-between">
                                                    <p className="mr-1">{lesson.title}</p>
                                                    {renderLessonIcon(lesson, isTablet)}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                )
            })}
        </div>
    )
}

export default CourseNavigation

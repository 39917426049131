import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createOrganization, updateOrganization } from "../../../actions/organization";
import { useGlobalUserState } from "../../../stores/user";
import { CharacterCounter } from "../courses/CharacterCounter";

export const CreateOrganizationComponent = ({ organization, compact = false }) => {
    const navigate = useNavigate()
    const [editMode, setEditMode] = useState(false)
    const [imageSrc, setImageSrc] = useState('')
    const userState = useGlobalUserState()
    const user = userState.get()
    const [form, setForm] = useState({
        title: '',
        description: '',
        label: '',
        file: null,
    })
    const updateForm = (key, value) => {
        setForm(f => ({
            ...f,
            [key]: value
        }))
    }
    useEffect(() => {
        if (organization?.id) {
            setForm({
                ...organization
            })
        }
    }, [organization])
    const onOrgLogoChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                setImageSrc(e.target.result)
            }
            reader.readAsDataURL(event.target.files[0]);
            updateForm('file', event.target.files[0])
        }
    }
    const enableEditMode = () => {
        setEditMode(true)
    }
    const submit = async () => {
        const formData = new FormData()
        for (let [key, val] of Object.entries(form)) {
            if (key !== "logo" && !!val) {
                formData.append(key, val)
            }
        }
        let response
        if (organization?.id) {
            response = await updateOrganization(organization.id, formData)
        } else {
            response = await createOrganization(formData)
        }
        if (response.success) {
            setEditMode(false)
            navigate(`/admin/orgs/${response.id}`)
        }
    }
    return (
        <div className={`unix-card ml-5 mr-5 grid grid-cols-12 px-5 py-6 ${!compact && 'mb-5'}`}>
            <div className={`col-span-${compact ? 12 : 3} px-4`}>
                <label className={"file-mask"}>
                    <input hidden type="file" name="org-logo" accept="image/jpeg,image/png" onChange={onOrgLogoChange} />
                    <div className={"w-100 bg-gray-cool flex flex-col justify-center items-center org-logo-container"}>
                        {imageSrc.length || form.logo ? (
                            <img src={imageSrc || form.logo} />
                        ) : (
                            <>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.46004 28.8415C2.62119 28.9449 2.80864 28.9999 3.00012 28.9999H19.0001C19.5524 28.9999 20.0001 28.5522 20.0001 27.9999C20.0001 27.8024 19.9416 27.6092 19.832 27.4449L14.25 19.0782C14.0473 18.7637 13.719 18.5824 13.719 18.5824C13.3834 18.3972 13.0001 18.3972 13.0001 18.3972C12.6168 18.3972 12.2813 18.5824 12.2813 18.5824C11.9526 18.7639 11.7499 19.0788 11.7499 19.0788L9.56787 22.357L8.76648 21.1094C8.54355 20.7782 8.21047 20.601 8.21047 20.601C7.8774 20.4238 7.50012 20.4238 7.50012 20.4238C7.12285 20.4238 6.78977 20.601 6.78977 20.601C6.4567 20.7782 6.24586 21.0911 6.24586 21.0911L6.23351 21.1098L2.15851 27.4598C1.86023 27.9246 1.99523 28.5432 2.46004 28.8415ZM17.1308 26.9999H4.83006L7.50034 22.8389L8.29197 24.0711C8.29197 24.0711 8.49487 24.3926 8.82641 24.5787C8.82641 24.5787 9.15794 24.7647 9.53806 24.7704C9.53806 24.7704 9.91818 24.776 10.2551 24.6C10.2551 24.6 10.5921 24.4239 10.8076 24.104L13.0006 20.8092L17.1308 26.9999Z" fill="#2B2F38" />
                                    <path d="M19.0001 12H26.0001C26.5524 12 27.0001 11.5523 27.0001 11C27.0001 10.4477 26.5524 10 26.0001 10H20.0001V4C20.0001 3.44772 19.5524 3 19.0001 3C18.4478 3 18.0001 3.44772 18.0001 4V11C18.0001 11.5523 18.4478 12 19.0001 12Z" fill="#2B2F38" />
                                    <path d="M5.00012 5V17C5.00012 17.5523 5.44784 18 6.00012 18C6.55241 18 7.00012 17.5523 7.00012 17V5H18.5859L25.0001 11.4142V27H24.0001C23.4478 27 23.0001 27.4477 23.0001 28C23.0001 28.5523 23.4478 29 24.0001 29H25.0001C25.8286 29 26.4143 28.4142 26.4143 28.4142C27.0001 27.8284 27.0001 27 27.0001 27V11C27.0001 10.7348 26.8948 10.4804 26.7072 10.2929L19.7072 3.29289C19.5197 3.10536 19.2653 3 19.0001 3H7.00012C6.1717 3 5.58591 3.58579 5.58591 3.58579C5.00012 4.17157 5.00012 5 5.00012 5Z" fill="#2B2F38" />
                                </svg>
                                <p className="text-black/[.40] text-sm mt-1.5">Логотип</p>
                            </>
                        )}
                    </div>
                </label>
            </div>
            <div className={`col-span-${compact ? 12 : 7} px-4`}>
                <div>
                    <div className="flex flex-row">
                        <span className={"card-title"}>
                            Название
                        </span>
                        {editMode ? (
                            <Chip className="ml-3" label="Режим редактирования" color="success" />
                        ) : null}
                    </div>
                    <input value={form.title} onChange={e => updateForm('title', e.target.value)} type="text" name="org-name" id="org-name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2.5" />
                </div>
                <div className="mt-2.5">
                    <span className={"text-unix-black text-base font-medium"}>
                        Лейбл
                    </span>
                    <input value={form.label} onChange={e => updateForm('label', e.target.value)} type="text" name="org-name" id="org-name" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2.5" />
                </div>
                <div className="mt-2.5">
                    <span className={"text-unix-black text-base font-medium"}>
                        Краткое описание
                    </span>
                    <textarea value={form.description} onChange={e => updateForm('description', e.target.value)} id="about" name="about" rows="3" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                    <CharacterCounter text={form.description} />
                </div>
            </div>
            <div className={`col-span-${compact ? 12 : 2} px-4 flex flex-row items-end ${compact && 'mt-4'}`}>
                <button className={`rounded-lg edit-button mr-3 ${editMode ? 'hidden' : ''}`} disabled={!(user?.roles?.includes('SUPERADMIN'))} onClick={enableEditMode}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.238 3.64072C12.1405 2.78701 11.463 2.10948 10.6093 2.01204L9.80992 2.8114C10.5263 3.14476 11.1052 3.72373 11.4386 4.44008L12.238 3.64072ZM4.74047 7.88085L8.60928 4.01204C9.46299 4.10948 10.1405 4.78701 10.238 5.64072L6.36915 9.50953C6.34152 9.53716 6.31289 9.56363 6.28335 9.58889C6.07656 9.76573 5.82492 9.88349 5.55509 9.92846L4.07483 10.1752L4.32154 8.69491C4.37294 8.38653 4.5194 8.10192 4.74047 7.88085ZM9.71939 0.780605L7.71939 2.7806L3.67981 6.82019C3.23768 7.26232 2.94474 7.83155 2.84195 8.44831L2.48016 10.619C2.36736 11.2959 2.95413 11.8826 3.63095 11.7698L5.80169 11.4081C6.41845 11.3053 6.98768 11.0123 7.42981 10.5702L11.4694 6.53061L13.4694 4.53061C13.6491 4.35094 13.75 4.10725 13.75 3.85316C13.75 2.00126 12.2487 0.5 10.3968 0.5C10.1427 0.5 9.89906 0.600937 9.71939 0.780605ZM2.75 13C2.33579 13 2 13.3358 2 13.75C2 14.1642 2.33579 14.5 2.75 14.5H13.25C13.6642 14.5 14 14.1642 14 13.75C14 13.3358 13.6642 13 13.25 13H2.75Z" fill="#AFDFB4" />
                    </svg>
                </button>
                <button onClick={submit} className={"px-4 py-2.5 rounded-lg bg-unix-green text-base text-black/[.85]"}>
                    Сохранить
                </button>
            </div>
        </div>
    )
}
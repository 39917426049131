import React, { useEffect, useState } from "react";
import info from './info.svg'
import { Tooltip } from "@mui/material";
import { useModules } from "../../../hooks/modules";
import { simpleGetter } from "../../../actions/common";
import { coursesApi } from "../../../api";
import { showSnackbar } from "../../../actions/snackbar";
import { useRooms, useTeacherCourses } from "../../../hooks/courses";
import { useGlobalUserState } from "../../../stores/user";
const InfoIcon = React.forwardRef(function Info(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
        <div {...props} ref={ref}>
            <img src={info} />
        </div>
    );
});

const Notifications = () => {
    const userState = useGlobalUserState()
    const user = userState.get()
    const isTeacher = user.roles?.includes("TEACHER")
    const modules = useModules()
    const teacherModules = useTeacherCourses()
    const teacherCourses = teacherModules.map(m => m.courses).reduce((acc, curr) => [...curr, ...acc], [])
    const [selectedModule, setSelectedModule] = useState({})
    const [selectedCourses, setSelectedCourses] = useState([])
    const [teacherSelectedCourse, setTeacherSelectedCourse] = useState({})
    const [teacherSelectedRooms, setTeacherSelectedRooms] = useState([])
    const [notificationContent, setNotificationContent] = useState('')
    const [title, setTitle] = useState('')
    const { rooms } = useRooms(teacherSelectedCourse?.id)

    const updateTeacherSelectedCourse = e => {
        if (!isNaN(parseInt(e.target.value))) {
            setTeacherSelectedCourse(teacherCourses.find(c => c.id === parseInt(e.target.value)))
        }
    }

    const updateSelectedModule = e => {
        if (!isNaN(parseInt(e.target.value))) {
            setSelectedModule(modules.find(m => m.id === parseInt(e.target.value)))
        }
    }
    useEffect(() => {
        setSelectedCourses([])
    }, [selectedModule?.id])

    const updateTeacherSelectedRooms = (c) => () => {
        setTeacherSelectedRooms(teacherSelectedRooms.map(room => room.id).includes(c.id) ?
            teacherSelectedRooms.filter(room => room.id !== c.id)
            :
            [...teacherSelectedRooms, c]
        )
    }

    const updateSelectedCourse = (c) => () => {
        setSelectedCourses(selectedCourses.map(course => course.id).includes(c.id) ?
            selectedCourses.filter(course => course.id !== c.id)
            :
            [...selectedCourses, c]
        )
    }
    const submit = async () => {
        const date = new Date()
        date.setDate(date.getDate() + 1)
        const body = {
            title,
            notificationText: notificationContent,
            startDate: date.toISOString(),
            ...(isTeacher ? {
                roomIds: teacherSelectedRooms.map(r => r.id)
            } : {
                modules: selectedCourses.length ? [] : [selectedModule.id],
                courses: selectedCourses.map(c => c.id)
            }),
        }
        const response = await simpleGetter(coursesApi[isTeacher ? 'teacherNotificationsCreate' : 'notificationsCreate'], { body })
        console.log(response)
        if (response.length) {
            showSnackbar('Успешно!')
            setSelectedModule({})
            setSelectedCourses([])
            setTeacherSelectedCourse({})
            setTeacherSelectedRooms([])
            setTitle('')
            setNotificationContent('')
        } else {
            showSnackbar('Произошла ошибка, попробуйте позднее', 'error')
        }
    }

    const rolesMeta = {
        'teacher': {
            parentLabel: 'Курс',
            childrenLabel: 'Комнаты',
            parentList: teacherCourses,
            selectedParent: teacherSelectedCourse,
            updateParent: updateTeacherSelectedCourse,
            childList: rooms,
            selectedChildren: teacherSelectedRooms,
            updateChildren: updateTeacherSelectedRooms,
            displayChildrentImage: false
        },
        'superadmin': {
            parentLabel: 'Модуль',
            childrenLabel: 'Курсы',
            parentList: modules,
            selectedParent: selectedModule,
            updateParent: updateSelectedModule,
            childList: selectedModule?.courses,
            selectedChildren: selectedCourses,
            updateChildren: updateSelectedCourse,
            displayChildrentImage: true
        }
    }

    const metaObject = rolesMeta[isTeacher ? 'teacher' : 'superadmin']
    const validateForm = () => (isTeacher ? !!teacherSelectedRooms.length : !!selectedModule.id) && !!notificationContent.length && !!title.length
    return (
        <div className={"flex flex-col py-8 px-6 h-full"}>
            <div className="flex flex-row items-center">
                <p className="text-[32px] text-[#1A1A1A] font-medium mr-3">
                    Отправка уведомления
                </p>
                <Tooltip title={"Здесь вы можете отправить студентам уведомление с важной информацией. Оно отобразится в их личном кабинете в разделе \"Уведомления\"."}>
                    <InfoIcon />
                </Tooltip>
            </div>
            <div className="flex-1 flex flex-row mt-8 overflow-y-auto">
                <div className="w-2/5 mr-2">
                    <div className="w-full h-full bg-white rounded-xl p-6 flex flex-col">
                        <p className="font-medium text-[#5F6B88] mb-2">
                            {metaObject.parentLabel}
                        </p>
                        <select onChange={metaObject.updateParent} className="py-[15px] px-4 rounded-lg bg-[#F0F3FA] text-[#1A1A1A]">
                            <option selected={!!metaObject.selectedParent.id}>Выберите...</option>
                            {metaObject.parentList.map(m => (
                                <option key={m.id} selected={metaObject.selectedParent.id === m.id} value={m.id}>{m.title}</option>
                            ))}
                        </select>
                        <p className="font-medium text-[#5F6B88] mt-6 opacity-30">
                            {metaObject.childrenLabel}
                        </p>
                        <div className="mt-2 flex-1 bg-[#F0F3FA] rounded-lg overflow-y-auto">
                            {metaObject.childList?.map(c => (
                                <div key={c.id} className={`flex flex-row py-2 px-4 items-center hover:bg-[#0068FF] hover:text-white cursor-pointer min-h-[66px] text-unix-text-black`} onClick={metaObject.updateChildren(c)}>
                                    <input type="checkbox" checked={metaObject.selectedChildren.map(course => course.id).includes(c.id)} />
                                    {metaObject.displayChildrentImage && (
                                        <div className="ml-3 bg-center bg-contain w-[100px] h-[50px] rounded-md" style={{ background: c.imageLink?.length ? `url(${c.imageLink}) center / cover` : '#D8DAE1' }} />
                                    )}
                                    <p className={`ml-3 text-[15px] font-medium`}>
                                        {c.title}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w-3/5 ml-2">
                    <div className="w-full p-6 bg-white rounded-xl flex flex-col items-start">
                        <p className="font-medium text-[#5F6B88] mb-2">
                            Тема
                        </p>
                        <input
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            placeholder="Название темы"
                            className='px-4 py-3 mb-6 text-[15px] text-[#1A1A1A] bg-[#F0F3FA] rounded-lg border-0 w-full'
                        />
                        <p className="font-medium text-[#5F6B88] mb-2">
                            Уведомление
                        </p>
                        <textarea
                            value={notificationContent}
                            onChange={e => setNotificationContent(e.target.value)}
                            placeholder="Текст уведомления"
                            className='px-4 py-3 text-[15px] text-[#1A1A1A] bg-[#F0F3FA] rounded-lg border-0 min-h-[301px] w-full'
                        />
                        <button onClick={submit} className={`bg-[#0068FF] mt-6 text-white rounded-lg py-3 px-12 font-bold text-[15px] ${!validateForm() && 'opacity-30'}`} disabled={!validateForm()}>
                            Отправить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notifications
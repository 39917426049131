import { hookstate, none, useHookstate } from '@hookstate/core';
import { localstored } from '@hookstate/localstored';

const store = hookstate({
    lang: 'RU',
    lastWatchedTime: {},
    quizAdditionalQuestions: [],
    additionalQuestionsShow: false,
    additionalQuizzesAnswered: {},
    tabExits: {},
    courseSelectedLang: 'EN',
    moduleStatsLastSelectedCourse: null,
    readNotificationIds: []
}, localstored({
    key: 'unix-misc-store-7',
    engine: localStorage
}))

const wrapState = (s) => ({
    get: (prop) => s[prop].value,
    getOrEmpty: (prop) => s[prop].value || {},
    set: (prop, newVal) => s[prop].set(newVal),
    updateByIndex: (parentProp, index, prop, value) => s[parentProp][index][prop].set(value),
    mergeByIndex: (parentProp, index, prop, value) => s[parentProp][index][prop].merge(value),
    merge: (prop, newVal) => s[prop].merge(newVal),
    mergeChild: (prop, prop2, newVal) => s[prop][prop2].merge(newVal),
    reset: (prop, child) => s[prop].merge({ [child]: none })
})

export const accessGlobalMiscState = () => wrapState(store)
export const useGlobalMiscState = () => wrapState(useHookstate(store))
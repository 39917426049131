import { homeworkApi } from '../api'
import { showSnackbar } from './snackbar'

const createHW = async (body) => {
    const data = {};
    try {
        const response = await homeworkApi.createHW(body);
        if (response.data.id) {
            showSnackbar();
            data.success = true;
            data.body = response.data;
        }
    } catch (e) {
        console.error("Error creating homework:", e);
        data.success = false;
    }
    return data;
}

const editTeacherHW = async (body) => {
    const data = {};
    try {
        const response = await homeworkApi.editTeacherHW(body);
        if (response.data.id) {
            showSnackbar();
            data.success = true;
            data.body = response.data;
        }
    } catch (e) {
        console.error("Error creating homework:", e);
        data.success = false;
    }
    return data;
}

const getTeacherHW = async (id) => {
    const data = {}
    try {
        const response = await homeworkApi.getTeacherHW(id)
        if (response.data.id) {
            data.success = true
            data.homework = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getStudentHW = async (id) => {
    const data = {}
    try {
        const response = await homeworkApi.getStudentHW(id)
        if (response.data.id) {
            data.success = true
            data.homework = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getStudentGrade = async (studentId, lessonId) => {
    const data = {}
    try {
        const response = await homeworkApi.getStudentGrade(studentId, lessonId)
        if (response.data.id) {
            data.success = true
            data.grade = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

export {
    createHW,
    getTeacherHW,
    getStudentHW,
    editTeacherHW,
    getStudentGrade
}
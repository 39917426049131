import 'moment/locale/ru'
import moment from 'moment/moment'
import { useContext, useEffect, useState } from 'react'
import 'react-multi-carousel/lib/styles.css'
import { useNavigate } from 'react-router-dom'
import { ThemeContext } from '../../../contexts'
import { useModuleProgressMultiple, useModules } from '../../../hooks/modules'
import { localize } from '../../../use-cases/constants'
import cancelIcon from './cancelIcon.svg'
import tagsIcon from './tagsIcon.svg'
import tagsWhiteIcon from './tagsWhiteIcon.svg'
import applyIcon from './tickIcon.svg'
import { getCourseLocalizedValue } from '../../../utils'
import { useGlobalMiscState } from '../../../stores/misc'

moment.locale('ru')

const PlatformCourseCard = ({ course, passedPercentage, platformLang }) => {
  const navigate = useNavigate()
  const isFake = course.type === 'Coming soon'

  const truncateDescription = (description, maxWords = 50) => {
    const words = description.split(' ')
    if (words.length <= maxWords) return description
    return words.slice(0, maxWords).join(' ') + '...'
  }

  const truncatedDescription = truncateDescription(getCourseLocalizedValue('description', course, platformLang, true))

  return (
    <div
      className="relative bg-white dark:bg-[#1A1A1A] rounded-[28px] w-full flex flex-col px-5 py-6 cursor-pointer min-h-[250px] overflow-hidden group"
      onClick={() => !isFake && navigate(`/platform/courses/${course.id}`)}
    >
      <div className="relative rounded-[5px] w-full h-[150px] mt-0 overflow-hidden">
        <div
          className="absolute inset-0 bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${course.imageLink})`,
            filter: isFake ? 'brightness(0.2)' : 'none'
          }}
        />
        {isFake && (
          <div className="absolute inset-0 flex items-start justify-start p-2">
            <span className="text-white text-[16px] font-bold">
              Coming Soon
            </span>
          </div>
        )}
      </div>
      <div>
        <div className="relative h-1 bg-gray-200 mt-1">
          <div
            className="absolute top-0 left-0 h-full bg-green-500 rounded-full"
            style={{ width: `${passedPercentage}%` }}
          />
        </div>
      </div>
      <p className="text-lg mt-5 card-title-clip text-black dark:text-white font-extrabold">
        {getCourseLocalizedValue('title', course, platformLang, true)}
      </p>

      <div className="absolute inset-0 bg-[#0068FF] text-white flex flex-col items-start p-5 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <p className="text-lg font-extrabold">{getCourseLocalizedValue('title', course, platformLang, true)}</p>
        <p className="text-[13px] font-400 mt-2">{truncatedDescription}</p>
      </div>
    </div>
  )
}

const modulesSortByProgress = progress => (a, b) => {
  if (!Array.isArray(progress) || !progress.length) {
    return 0
  }

  const passedA = progress.find(p => p.courseid === a.id)?.passedPercentage || 0
  const passedB = progress.find(p => p.courseid === b.id)?.passedPercentage || 0

  return passedB - passedA
}

const CategoryList = ({
  categories,
  setModuleIndex,
  selectedModuleIndex,
  theme
}) => {
  return (
    <div className="flex flex-wrap gap-2">
      {categories.map((category, index) => {
        // const displayValue = (() => {
        //   // if ([32, 67, 72].includes(category.moduleIndex)) {
        //   //   const totalCoursesInSafetyFirst = modules
        //   //     .filter(module => [32, 67, 72].includes(module.id))
        //   //     .reduce((acc, module) => acc + module.courses.length, 0)
        //   //   return totalCoursesInSafetyFirst
        //   // } else if (category.moduleIndex === 0) {
        //   //   return totalCourses
        //   // } else if (category.moduleIndex === 10) {
        //   //   return myCourse
        //   // } else {
        //   const matchingModule = modules[category.moduleIndex - 1]
        //   return matchingModule?.courses.length || 0
        //   // }
        // })()

        const isDisabled = category.count === 0

        const handleClick = () => {
          if (!isDisabled) {
            setModuleIndex(category.moduleIndex)
          }
        }

        return (
          <div
            key={index}
            onClick={handleClick}
            className={`flex items-center px-4 py-[16px] max-[1000px]:px-2 rounded-full text-black max-[1000px]:py-[8px]
            max-[640px]:text-[14px]`}
            style={{
              backgroundColor:
                selectedModuleIndex === category.moduleIndex
                  ? '#0068FF'
                  : `rgba(${parseInt(category.bgColor.slice(1, 3), 16)}, ${parseInt(category.bgColor.slice(3, 5), 16)}, ${parseInt(category.bgColor.slice(5, 7), 16)}, 0.20)`,
              color:
                selectedModuleIndex === category.moduleIndex
                  ? 'white'
                  : theme === 'dark'
                    ? 'white'
                    : 'black',
              cursor: 'pointer',
              opacity: isDisabled ? 0.4 : 1
            }}
          >
            <span className="text-[18px] max-[1000px]:text-[15px] font-medium">
              {category.label}{' '}
              <span
                style={{
                  color:
                    selectedModuleIndex === category.moduleIndex
                      ? 'rgba(255, 255, 255, 0.5)'
                      : 'rgba(95, 107, 136, 0.5)'
                }}
              >
                <span>{category.count}</span>
              </span>
            </span>
          </div>
        )
      })}
    </div>
  )
}

const moduleColors = ['#8F30BC', '#00C5A1', '#A5896D', '#F58500', '#F12D2D', '#3BBC30', '#A5896D', '#A5896D']

export const PlatformHome = () => {
  const miscState = useGlobalMiscState()
  const platformLang = miscState.get('lang')
  const modules = useModules()
  const moduleProgress = useModuleProgressMultiple(modules.map(m => m.id))
  const [moduleIndex, setModuleIndex] = useState(0)
  const [myCourse, setMyCourse] = useState(0)

  let totalCourses = 0

  modules.forEach(module => {
    totalCourses += module.courses.length
  })

  const progressArray = (() => {
    if (moduleIndex === 0 || moduleIndex === 10) {
      return moduleProgress.reduced
    } else if ([32, 67, 72].includes(moduleIndex)) {
      // Logic specific to 'Safety-First' module
      return 'Safety-First Progress'
    } else {
      const matchingModule = modules.find(module => module.id === moduleIndex)
      return (
        moduleProgress.data.find(p => p.id === matchingModule?.id)?.result || []
      )
    }
  })()

  const getPassedPercentage = (courseId, progressArray) => {
    if (!Array.isArray(progressArray)) {
      return null
    }

    const progress = progressArray.find(item => item.courseid === courseId)
    return progress ? progress.passedPercentage : null
  }
  useEffect(() => {
    const count = moduleProgress.reduced.reduce((acc, item) => {
      return item.passedPercentage > 0 ? acc + 1 : acc
    }, 0)

    setMyCourse(count)
  }, [moduleProgress, modules])

  const moduleCourses = (() => {
    if (moduleIndex === 0) {
      return modules
        .map(m => m.courses)
        .reduce((acc, curr) => [...acc, ...curr], [])
      // } else if (moduleIndex === 10) {
      //   return moduleProgress.reduced
      //     .filter(progress => progress.passedPercentage > 0)
      //     .map(progress => {
      //       const course = modules
      //         .flatMap(m => m.courses)
      //         .find(course => course.id === progress.courseid)
      //       return course
      //     })
      //     .filter(course => course !== undefined)
      // } else if ([32, 67, 72].includes(moduleIndex)) {
      //   return modules
      //     .filter(module => [32, 67, 72].includes(module.id))
      //     .reduce((acc, module) => [...acc, ...module.courses], [])
      // } else {
    } else {
      const matchingModule = modules[moduleIndex - 1]
      return matchingModule?.courses || []
    }
  })()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModuleModalOpen, setIsModuleModalOpen] = useState(false)
  const [selectedTags, setSelectedTags] = useState([])
  const { theme } = useContext(ThemeContext)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
    setIsModuleModalOpen(!isModalOpen)
  }

  const handleTagClick = tag => {
    setSelectedTags(prev =>
      prev.includes(tag) ? prev.filter(t => t !== tag) : [...prev, tag]
    )
  }

  const applyFilters = () => {
    setIsModalOpen(false)
    setIsModuleModalOpen(false)
  }

  const clearTags = () => {
    setSelectedTags([])
  }

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isModalOpen])

  useEffect(() => {
    const elements1 = document.querySelectorAll('.css-1q0v17x')
    const elements2 = document.querySelectorAll('.css-11plsb8')
    elements1.forEach(element => (element.style.padding = '0'))
    elements2.forEach(element => (element.style.padding = '0'))

    return () => {
      elements1.forEach(element => (element.style.padding = ''))
      elements2.forEach(element => (element.style.padding = ''))
    }
  }, [])
  let topCategories = [{
    label: localize('all_sections'),
    count: modules.reduce((acc, curr) => [...acc, ...(curr.courses || [])], []).length,
    isActive: true,
    bgColor: '#8F30BC',
    moduleIndex: 0
  }]
  if (modules.length) {
    topCategories = [...topCategories,
    ...modules.map((m, i) => ({
      label: m.title,
      count: m.courses?.length,
      isActive: false,
      bgColor: moduleColors[i + 1] || moduleColors.at(-1),
      moduleIndex: i + 1
    }))
    ]
  }

  const tags = [
    'Анализ данных',
    'Дизайн',
    'Менеджмент',
    'Программирование',
    'Маркетинг',
    'Английский язык',
    'Высшее образование',
    'Безопасность',
    'Обязательные',
    'С нуля',
    'С опытом'
  ]

  return (
    <div className="${theme}">
      <div className="bg-[#fff] dark:bg-[#1A1A1A]">
        <div className="mx-auto grid grid-cols-12 max-w-[1280px] mt-6  max-[1400px]:px-3 px-[16px] pt-[24px] mb-[-1px] rounded-t-[12px]">
          <div className="col-span-12">
            <p className="platform-black font-bold text-[48px] px-2 mb-4 max-[1000px]:text-[32px] dark:text-[#fff]">
              {localize('list_courses')}
            </p>
          </div>
          {/* <Carousel responsive={responsive} className={"col-span-12"}>
                {[{} ,...modules].map((m, i) => (
                    <div key={i} className="w-[193px] mx-auto">
                        {renderModuleCard(m, i, navigate, moduleIndex, setModuleIndex)}
                    </div>
                ))}
            </Carousel> */}

          {/* <div className={"col-span-12 mt-12"}>
                <p className={"platform-black font-bold text-[22px] px-5 flex flex-row items-center"}>
                    <span>
                        Всего курсов
                    </span>
                    <span className="font-medium text-[22px] text-[#7C7C7C] ml-1">
                        {moduleCourses.length}
                    </span>
                </p>
            </div> */}
          <div className="col-span-12 flex relative justify-between items-end">
            <section className="[768px]:flex-col gap-3 mb-8 w-[70%] max-[768px]:hidden max-[1000px]:gap-1">
              <div className="mb-1 max-md:hidden md:block">
                <CategoryList
                  categories={topCategories}
                  theme={theme}
                  totalCourses={totalCourses}
                  setModuleIndex={setModuleIndex}
                  selectedModuleIndex={moduleIndex}
                  modules={modules}
                  moduleCourses={moduleCourses}
                  myCourse={myCourse}
                />
              </div>
            </section>
            <div
              className={`flex items-center py-[9px] gap-[6px] px-[12px] rounded-full mb-10 md:hidden max-[md]:block ${selectedTags.length > 0
                ? 'text-white bg-[#0068FF]'
                : 'text-[#1A1A1A] bg-[#F0F3FA]'
                }`}
            >
              <img
                className={`max-[1000px]:w-[17px] ${!selectedTags.length ? '' : 'hidden'
                  }`}
                src={tagsIcon}
                alt="Tags Icon"
                onClick={toggleModal}
              />
              <img
                className={`max-[1000px]:w-[17px] ${selectedTags.length ? '' : 'hidden'
                  }`}
                src={tagsWhiteIcon}
                alt="Tags Icon"
                onClick={toggleModal}
              />
              <p className={`text-[13px]`} onClick={toggleModal}>
                {' '}
                Модули и тэги{' '}
                {selectedTags.length > 0 && `(${selectedTags.length})`}
              </p>
              {selectedTags.length > 0 && (
                <span
                  className="flex items-center whitespace-nowrap bg-[#FFFFFF]/[0.3] py-[6.5px] max-[1000px]:py-[4.5px] max-[1000px]:pr-[14px] px-[8px] rounded-full ml-3 max-[1000px]:ml-1"
                  onClick={clearTags}
                >
                  <button
                    onClick={clearTags}
                    className="ml-2 text-white font-bold text-lg cursor-pointer"
                  >
                    <img className="min-w-[18px] h-[18px]" src={cancelIcon} />
                  </button>
                  <p className="text-white max-[1000px]:text-[13px]">
                    Сбросить
                  </p>
                </span>
              )}
            </div>

            <div
              className={`flex items-center absolute bottom-[45px] max-[1000px]:bottom-[30px] max-[1000px]:right-[0] right-[15px] py-[14px] max-[1000px]:py-[5px] px-[16px] max-[1000px]:px-[4px] max-[1000px]:pl-[10px] rounded-full max-md:hidden ${selectedTags.length > 0
                ? 'bg-[#0068FF]'
                : 'bg-[#F0F3FA] dark:bg-[#f0f3fa]/[12%]'
                }`}
            >
              {theme == 'dark' ? (
                <img
                  className={`max-[1000px]:w-[17px] ${!selectedTags.length ? '' : 'hidden'
                    }`}
                  src={tagsWhiteIcon}
                  alt="Tags Icon"
                  onClick={toggleModal}
                />
              ) : (
                <img
                  className={`max-[1000px]:w-[17px] ${!selectedTags.length ? '' : 'hidden'
                    }`}
                  src={tagsIcon}
                  alt="Tags Icon"
                  onClick={toggleModal}
                />
              )}
              <img
                className={`max-[1000px]:w-[17px] ${selectedTags.length ? '' : 'hidden'
                  }`}
                src={tagsWhiteIcon}
                alt="Tags Icon"
                onClick={toggleModal}
              />
              <span
                className={`ml-[8px] max-[1000px]:ml-[4px] whitespace-nowrap max-[1000px]:text-[13px] ${selectedTags.length > 0
                  ? 'text-white'
                  : 'text-[#1A1A1A dark:text-white'
                  }`}
              >
                {localize('tags')}{' '}
                {selectedTags.length > 0 && `(${selectedTags.length})`}
              </span>
              {selectedTags.length > 0 && (
                <span className="flex items-center whitespace-nowrap bg-[#FFFFFF]/[0.3] py-[6.5px] max-[1000px]:py-[4.5px] max-[1000px]:pr-[14px] px-[8px] rounded-full ml-3 max-[1000px]:ml-1">
                  <button
                    onClick={clearTags}
                    className="ml-2 text-white font-bold text-lg cursor-pointer"
                  >
                    <img className="min-w-[18px] h-[18px]" src={cancelIcon} />
                  </button>
                  <p className="text-white max-[1000px]:text-[13px]">
                    Сбросить
                  </p>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {isModuleModalOpen && (
        <div className="fixed inset-0 z-[10000] hidden max-md:flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white dark:bg-black dark:text-white px-4 py-4 rounded-lg w-[90%] relative max-w-[500px] h-[600px] overflow-y-auto">
            <button
              onClick={toggleModal}
              className="absolute flex w-full right-[10px] top-0 justify-end text-[24px] text-[#AFB7CA] hover:text-gray-700"
            >
              &times;
            </button>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-[24px]">Модули</h2>
            </div>
            <section className="flex flex-col gap-3 mb-8 w-[100%] max-[1000px]:gap-1">
              <div className="mb-1">
                <CategoryList
                  categories={topCategories}
                  totalCourses={totalCourses}
                  theme={theme}
                  setModuleIndex={setModuleIndex}
                  selectedModuleIndex={moduleIndex}
                  modules={modules}
                  moduleCourses={moduleCourses}
                  myCourse={myCourse}
                />
              </div>
            </section>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-[24px]">Тэги</h2>
            </div>
            <div className="flex flex-wrap gap-2 mb-4">
              {tags.map((tag, index) => (
                <span
                  key={index}
                  className={`py-1 px-3 rounded-full text-sm cursor-pointer text-[13px] ${selectedTags.includes(tag)
                    ? 'bg-blue-500 text-white'
                    : 'bg-[#F0F3FA] dark:bg-[#1A1A1A] dark:text-white '
                    }`}
                  onClick={() => handleTagClick(tag)}
                >
                  {tag}
                </span>
              ))}
            </div>
            <div className="flex items-center mt-10 gap-[8px]">
              <button
                className="bg-[#F12D2D] gap-[8px] px-4 w-[50%] py-[12px] rounded-full justify-center flex items-center text-white"
                onClick={toggleModal}
              >
                <img src={cancelIcon} />
                Отменить
              </button>
              <button
                onClick={applyFilters}
                className="bg-[#3BBC30] gap-[8px] px-4 w-[50%] py-[12px] rounded-full justify-center flex items-center text-white"
              >
                <img src={applyIcon} />
                Применить
              </button>
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className="fixed inset-0 max-md:hidden z-[10000] flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white dark:bg-black p-6 rounded-lg w-[80%] relative max-w-[500px]">
            <button
              onClick={toggleModal}
              className="absolute flex w-full right-[10px] top-0 justify-end text-[24px] text-[#AFB7CA] hover:text-gray-700"
            >
              &times;
            </button>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-[24px] dark:text-white">Тэги</h2>
            </div>
            <div className="flex flex-wrap gap-2 mb-4">
              {tags.map((tag, index) => (
                <span
                  key={index}
                  className={`py-1 px-3 rounded-full text-sm cursor-pointer text-[13px] ${selectedTags.includes(tag)
                    ? 'bg-blue-500 text-white'
                    : 'bg-[#F0F3FA] dark:bg-[#1A1A1A] dark:text-white '
                    }`}
                  onClick={() => handleTagClick(tag)}
                >
                  {tag}
                </span>
              ))}
            </div>
            <div className="flex items-center mt-10 gap-[8px]">
              <button
                className="bg-[#F12D2D] gap-[8px] w-[50%] py-[12px] rounded-full justify-center flex items-center text-white"
                onClick={toggleModal}
              >
                <img src={cancelIcon} />
                Отменить
              </button>
              <button
                onClick={applyFilters}
                className="bg-[#3BBC30] gap-[8px] w-[50%] py-[12px] rounded-full justify-center flex items-center text-white"
              >
                <img src={applyIcon} />
                Применить
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="bg-[#F0F3FA] dark:bg-[#000]  min-h-screen">
        <div className="max-w-[1280px] mx-auto max-[1400px]:px-3">
          <div className="py-4 grid grid-cols-12 col-span-12 gap-3">
            {moduleCourses
              ?.sort(modulesSortByProgress(progressArray))
              .map(c => {
                const passedPercentage = getPassedPercentage(
                  c.id,
                  progressArray
                )
                return (
                  <div
                    key={c.id}
                    className="col-span-3 max-xl:col-span-4 max-md:col-span-6 max-sm:col-span-12"
                  >
                    <div className="h-full flex">
                      <PlatformCourseCard
                        course={c}
                        passedPercentage={passedPercentage}
                        platformLang={platformLang}
                        className="h-full flex flex-col"
                      />
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

// const ChatAI = () => {
//   const [modal, setModal] = useState(true)
//   const [socket, setSocket] = useState({})
//   const [threadId, setThreadId] = useState('')
//   const [messages, setMessages] = useState([])
//   const [userInput, setUserInput] = useState('')

//   const toggleModal = () => {
//     setModal(!modal)
//   }

//   useEffect(() => {
//     if (modal) {
//       document.body.classList.add('overflow-hidden')
//     } else {
//       document.body.classList.remove('overflow-hidden')
//     }

//     return () => {
//       document.body.classList.remove('overflow-hidden')
//     }
//   }, [modal])

//   useEffect(() => {
//     if ('on' in socket) {
//       socket.on('connect', () => {
//         socket.emit('assist_start', { question: 'привет' })
//         socket.on('get_messages', data => {
//           const parsed = JSON.parse(data)
//           if (parsed.thread_id && !threadId.length) {
//             setThreadId(parsed.thread_id)
//           }
//           setMessages(
//             parsed?.messages
//               .map(msg => ({
//                 text: msg.content[0]?.text?.value || '',
//                 assistant_id: msg.assistant_id
//               }))
//               .reverse()
//               .filter(msg => msg.text !== 'привет')
//           )
//         })
//       })
//     }
//   }, [socket])

//   useEffect(() => {
//     const _socket = io(API_HOST)
//     setSocket(_socket)
//   }, [])

//   const onFormSubmit = e => {
//     e.preventDefault()
//     socket.emit('assist_create_message', {
//       thread_id: threadId,
//       question: userInput
//     })
//     setMessages(m => [...m, { text: userInput, assistant_id: null }])
//     setUserInput('')
//   }
//   const message = [
//     {
//       message: 'Text of a possible question of interest'
//     },
//     {
//       message: 'Lorem ipsum dolor sit'
//     },
//     {
//       message: 'Lorem ipsum dolor sit amet'
//     },
//     {
//       message:
//         'Lorem ipsum dolor sit amet consectetur. Sit nulla pellentesque in sem lacus risus.'
//     }
//   ]

//   return (
//     <div className="bg-[#3D3E41] flex flex-col items-center justify-center align-middle rounded-l-[20px]">
//       <div
//         className="my-5 ml-3 cursor-pointer relative z-10 bg-[#3D3E41] "
//         onClick={toggleModal}
//       >
//         <img src={AI} />
//         <p className="text-center text-[#FFFFFF]">AI Chat</p>
//         <p className="text-center text-basic">помощник</p>
//       </div>

//       <div
//         className={`fixed top-[5px] z-0 right-28 w-full  h-full flex items-center justify-center transition-transform transform ${modal ? 'translate-x-[510px]' : 'translate-x-full'
//           }`}
//       >
//         <div
//           onClick={toggleModal}
//           className="fixed top-0 left-0 opacity-50"
//         ></div>
//         <div className="bg-[#3D3E41] p-4 pb-0 pl-0 rounded-lg shadow-lg w-[700px] relative z-10">
//           <button
//             className="p-2 text-white relative top-[-50px] left-[90%]"
//             onClick={toggleModal}
//           >
//             <img src={close} />
//           </button>
//           <div className="pl-4">
//             <h2 className="text-[28px] text-white font-light mt-5">
//               AI Chat помощник
//             </h2>
//             <div className="flex items-center gap-2 pt-24 border-b pb-4 opacity-0">
//               <img src={light} />
//               <p className="text-white">Предложения</p>
//             </div>
//             <div className="flex flex-1 flex-wrap gap-2 mt-4 cursor-pointer mb-2 opacity-0">
//               {message.map((m, index) => (
//                 <p
//                   key={index}
//                   className="text-white border text-[13px] py-1 px-2 rounded-[5px]"
//                 >
//                   {m.message}
//                 </p>
//               ))}
//             </div>
//           </div>
//           <div className="bg-white w-[700px] pl-[0px] pt-5 h-[200px] overflow-y-scroll">
//             {messages.map(msg => (
//               <div
//                 key={msg.id}
//                 className={`flex flex-row ${msg.assistant_id ? 'justify-start' : 'justify-end'
//                   }`}
//               >
//                 <div className="bg-[#C4CFFF] w-[45%] pr-2 pl-4 rounded-t-[15px] rounded-br-[15px] ml-4">
//                   {msg.text}
//                 </div>
//               </div>
//             ))}
//           </div>
//           <div>
//             <div className="bg-[#FFF] relative pb-5">
//               <form onSubmit={onFormSubmit}>
//                 <input
//                   value={userInput}
//                   onChange={e => setUserInput(e.target.value)}
//                   type="textarea"
//                   placeholder="Введите запрос"
//                   className="bg-[#F8FAFC] items-center w-[95%] pt-2 border border-[#CCD5DD] rounded-[15px] pb-10 ml-4 pl-2 color-[#CCD5DD]"
//                 />
//                 <img className="absolute right-10 top-7" src={arrow} />
//               </form>
//             </div>
//           </div >
//         </div >
//       </div >
//     </div >
//   )
// }

import { Button, Divider, List } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useState } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  deleteModer,
  getModuleAdmin,
  moduleStats,
  searchModerators,
  searchModuleAdmin
} from '../../../actions/module'
import { getOrgStats } from '../../../actions/organization'
import { addModuleAdmin } from '../../../actions/user'
import { useRooms } from '../../../hooks/courses'
import accountIcon from './accounts.svg'
import add from './add.svg'
import addAdminIcon from './addAdminIcon.svg'
import arrowDown from './aroowDownIcon.svg'
import arrowUp from './arrowUpIcon.svg'
import asc from './asc.svg'
import ascSelected from './ascSelected.svg'
import avgCourse from './avg-course.svg'
import avgDay from './avg-day.svg'
import avgLecture from './avg-lecture.svg'
import avgTime from './avg-time.svg'
import backWhite from './back_white.svg'
import bucket from './bucket.svg'
import certificateIcon from './certificate.svg'
import classes from './classes.svg'
import closeIcon from './closeIcon.svg'
import deleteIcon from './delete.svg'
import desc from './desc.svg'
import descSelected from './descSelected.svg'
import leftIcon from './leftArrow.svg'
import moduleRight from './moduleRight.svg'
import plusWhite from './plusWhite.svg'
import rightArrowIcon from './rightArrowIcon.svg'
import searchBlueIcon from './searchBlueIcon.svg'
import selectedArrow from './selectedRightArrow.svg'
import studentsIcon from './students.svg'
import teachers from './teachers.svg'
import warningIcon from './warningIcon.svg'
import whiteSearchIcon from './whiteSearchIcon.svg'

const StatisticsOrg = () => {
  const { userId, organizationId } = useParams()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const moduleId = queryParams.get('moduleId')
  const navigate = useNavigate()
  const [topicSelected, setTopicSelected] = useState('')
  const [moduleStat, setModuleStat] = useState({})
  const [selectedCourse] = useState(undefined)
  const [initialUserSelected, setInitialUserSelected] = useState(false)
  const { students } = useRooms(selectedCourse?.id)
  const [organizationInfo, setOrganizationInfo] = useState({})
  const [organizationStats, setOrganizationStats] = useState({})
  const [isExpanded, setIsExpanded] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [addAdminMode, setAddAdminMode] = useState(false)
  const [moduleAdmins, setModuleAdmins] = useState([])
  const [uniqueSearchQuery, setUniqueSearchQuery] = useState('')
  const [uniqueSearchResults, setUniqueSearchResults] = useState([])
  const toggleExpand = () => setIsExpanded(!isExpanded)
  const [orgModerSearchQuery, setOrgModerSearchQuery] = useState('')
  const [isSearchCleared, setIsSearchCleared] = useState(false)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedAdmin, setSelectedAdmin] = useState(null)
  const [filteredOrgModerators, setFilteredOrgModerators] = useState([])
  const [sortField, setSortField] = useState('firstName')
  const [sortDirection, setSortDirection] = useState('asc')
  const [sortConfig, setSortConfig] = useState({
    key: 'firstname',
    direction: 'asc'
  })

  const handleAddAdminClick = admin => {
    setSelectedAdmin(admin)
    setIsAddModalOpen(true)
  }

  const handleConfirmAddAdmin = async adminId => {
    handleAddAdmin(adminId)
    setIsAddModalOpen(false)
    setSelectedAdmin(null)
  }

  const handleCloseModal = () => {
    setIsAddModalOpen(false)
    setSelectedAdmin(null)
  }

  const handleDeleteAdminClick = (moduleId, admin) => {
    setSelectedAdmin(admin)
    setIsDeleteModalOpen(true)
  }

  const handleConfirmDeleteAdmin = async (moduleId, admin) => {
    handleDeleteModerator(moduleId, admin)
    setIsDeleteModalOpen(false)
    setSelectedAdmin(null)
  }

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false)
    setSelectedAdmin(null)
  }

  const handleOrgModerSearchChange = event => {
    setOrgModerSearchQuery(event.target.value)
  }

  useEffect(() => {
    let filteredData = organizationStats?.adminDetails?.filter(admin =>
      `${admin.firstname} ${admin.lastname}`
        .toLowerCase()
        .includes(orgModerSearchQuery.toLowerCase())
    )

    if (filteredData) {
      filteredData.sort((a, b) => {
        let valueA, valueB

        switch (sortField) {
          case 'firstName':
            valueA = a.firstname.toLowerCase()
            valueB = b.firstname.toLowerCase()
            break
          case 'email':
            valueA = a.email.toLowerCase()
            valueB = b.email.toLowerCase()
            break
          default:
            valueA = ''
            valueB = ''
        }

        if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1
        if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1
        return 0
      })
    }

    setFilteredOrgModerators(filteredData)
  }, [organizationStats, orgModerSearchQuery, sortField, sortDirection])

  const sortData = field => {
    setSortField(field)
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
  }

  const handleModuleClick = module => {
    setTopicSelected(module?.id)
  }

  const handleStatisticsOrgClick = () => {
    setTopicSelected('organization')
  }

  const fetchModule = async id => {
    const response = await moduleStats(id)
    if (response.success) {
      setModuleStat(response.module.module)
    }
  }

  const fetchOrganizationStats = async id => {
    const response = await getOrgStats(id)
    if (response.success) {
      setOrganizationInfo(response.organization)
      setOrganizationStats(response.statistics)
      setTopicSelected(moduleId ? Number(moduleId) : 'organization')
    }
  }

  useEffect(() => {
    setTopicSelected(Number(moduleId))
  }, [moduleId])

  const debounce = (func, wait) => {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), wait)
    }
  }

  const handleSearchInputChange = e => {
    setSearchQuery(e.target.value)
  }

  const handleDeleteModerator = async (topicSelected, userId) => {
    const result = await deleteModer(topicSelected, userId)
    if (result.success) {
      setIsSearchCleared(true)
      const fetchModuleAdmins = async () => {
        const result = await getModuleAdmin(topicSelected)
        if (result.success) {
          const admins = result.body.map(item => item)
          setModuleAdmins(admins)
        }
      }

      fetchModuleAdmins()
    }
  }

  // const handleDelete = () => {
  //   handleDeleteModerator(topicSelected, userId);
  // };

  useEffect(() => {
    if (isSearchCleared) {
      setUniqueSearchQuery('')
      setIsSearchCleared(false)
    }
  }, [isSearchCleared])

  const handleAddAdmin = async userId => {
    const data = await addModuleAdmin(topicSelected, userId)
    if (data.success) {
      setSearchResults(prevResults =>
        prevResults.filter(admin => admin.id !== userId)
      )
      setSearchQuery('')
      setSearchResults([])
    }
  }

  const fetchSearchResults = async query => {
    const data = await searchModuleAdmin(topicSelected, query)
    if (data.success) {
      setSearchResults(data.users)
    } else {
      setSearchResults([])
    }
  }

  const debouncedSearch = debounce(query => {
    if (query) {
      fetchSearchResults(query)
    } else {
      setSearchResults([])
    }
  }, 300)

  useEffect(() => {
    debouncedSearch(searchQuery)
  }, [searchQuery])

  const uniqueDebounce = (func, delay) => {
    let timeoutId
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(() => {
        func.apply(null, args)
      }, delay)
    }
  }

  const uniqueFetchSearchResults = async query => {
    const result = await searchModerators(topicSelected, query)
    if (result.success) {
      setUniqueSearchResults(result.users)
    } else {
      setUniqueSearchResults([])
    }
  }

  const uniqueDebouncedSearch = uniqueDebounce(query => {
    if (query) {
      uniqueFetchSearchResults(query)
    } else {
      setUniqueSearchResults([])
    }
  }, 300)

  useEffect(() => {
    uniqueDebouncedSearch(uniqueSearchQuery)
  }, [uniqueSearchQuery])

  useEffect(() => {
    fetchOrganizationStats(organizationId)
  }, [organizationId])

  useEffect(() => {
    if (topicSelected && topicSelected != 'organization') {
      fetchModule(topicSelected)
    }
  }, [topicSelected])

  useEffect(() => {
    if (topicSelected) {
      setSearchQuery('')
      setSearchResults([])
      setUniqueSearchQuery('')
      setUniqueSearchResults([])
    }
  }, [topicSelected, addAdminMode])

  useEffect(() => {
    if (!initialUserSelected) {
      const found = students.find(s => s.id === parseInt(userId))
      if (found) {
        setInitialUserSelected(true)
      }
    }
  }, [userId, students, initialUserSelected])

  useEffect(() => {
    const fetchModuleAdmins = async () => {
      if (topicSelected === '' || topicSelected == 'organization') {
        return
      }

      const result = await getModuleAdmin(topicSelected)
      if (result.success) {
        const admins = result.body.map(item => item)
        setModuleAdmins(admins)
      }
    }

    fetchModuleAdmins()
  }, [topicSelected, uniqueSearchResults])

  const selectedModuleStats = organizationStats.modulesStatistics?.find(
    module => module?.id === topicSelected
  )
  const averageTimeLessonInMinutes = moduleStat
    ? Math.round(moduleStat?.statistics?.averageTimeLesson / 60)
    : 0

  function formatTime(seconds) {
    const days = Math.floor(seconds / 86400)
    const hours = Math.floor((seconds % 86400) / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)

    if (days > 0) {
      return (
        <span>
          <span>
            {days}{' '}
            <span className="text-2xl relative left-[-5px] text-[#AFB7CA] font-light">
              дней
            </span>
          </span>
          {hours > 0 && (
            <span>
              {hours}{' '}
              <span className="text-2xl relative left-[-5px] text-[#AFB7CA] font-light">
                ч
              </span>
            </span>
          )}
        </span>
      )
    } else if (hours > 0) {
      return (
        <span>
          <span>
            {hours}{' '}
            <span className="text-2xl relative left-[-5px] text-[#AFB7CA] font-light">
              ч
            </span>
          </span>
          <span>
            {minutes}{' '}
            <span className="text-2xl relative left-[-5px] text-[#AFB7CA] font-light">
              мин
            </span>
          </span>
        </span>
      )
    } else {
      return (
        <span>
          {minutes}{' '}
          <span className="text-2xl relative left-[-5px] text-[#AFB7CA] font-light">
            мин
          </span>
        </span>
      )
    }
  }

  const sortedAdmins = React.useMemo(() => {
    let sortableAdmins = [...moduleAdmins]
    if (sortConfig !== null) {
      sortableAdmins.sort((a, b) => {
        let aValue = a.user[sortConfig.key] || ''
        let bValue = b.user[sortConfig.key] || ''

        if (sortConfig.direction === 'asc') {
          return aValue.localeCompare(bValue)
        }
        return bValue.localeCompare(aValue)
      })
    }
    return sortableAdmins
  }, [moduleAdmins, sortConfig])

  const requestSort = key => {
    let direction = 'asc'
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    setSortConfig({ key, direction })
  }

  const getSortingIcons = key => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? (
        <div className="flex flex-col">
          <img
            src={desc}
            alt="desc"
            className="inline-block w-[12px] h-[7px]"
          />
          <img
            src={ascSelected}
            alt="asc"
            className="inline-block w-[12px] h-[7px] mt-[6px]"
          />
        </div>
      ) : (
        <div className="flex flex-col">
          <img
            src={descSelected}
            alt="desc"
            className="inline-block w-[12px] h-[7px]"
          />
          <img
            src={asc}
            alt="asc"
            className="inline-block w-[12px] h-[7px] mt-[6px]"
          />
        </div>
      )
    } else {
      return (
        <div className="flex flex-col">
          <img
            src={desc}
            alt="desc"
            className="inline-block w-[12px] h-[7px]"
          />
          <img
            src={asc}
            alt="asc"
            className="inline-block w-[12px] h-[7px] mt-[6px]"
          />
        </div>
      )
    }
  }

  const certificatesReceived = organizationStats.certificatesReceived
  const activatedAccounts = organizationStats.activatedAccounts
  const totalStudents = organizationStats.students

  const percentageCertified = (certificatesReceived / totalStudents) * 100
  const percentageActivated = (activatedAccounts / totalStudents) * 100
  const percentageCertificates = selectedModuleStats?.students
    ? (selectedModuleStats?.certificatesReceived /
      selectedModuleStats?.students) *
    100
    : 0
  const percentageActivatedAccounts = selectedModuleStats?.students
    ? (selectedModuleStats?.activatedAccounts / selectedModuleStats?.students) *
    100
    : 0

  return (
    <>
      {organizationInfo.modules ? (
        <div className="flex flex-col h-full">
          <div className="p-[10px]">
            <div className="gap-5 text-[32px] flex flex-row items-center">
              <button onClick={() => navigate(`/admin/statistics`)}>
                <img src={backWhite} />
                <img src={search} width={0} />
              </button>
              <div className="text-black">{organizationInfo?.title}</div>
            </div>
          </div>
          <div className="pl-[10px] flex mr-[32px]">
            <div className="w-[340px] mr-[16px]">
              <div
                className={`flex flex-col bg-white p-1 h-full rounded-[12px]`}
              >
                {organizationInfo?.modules?.length && (
                  <div
                    className={`text-black rounded-xl py-3 ml-2 pl-2 mr-2 mt-2 font-medium text-[14px] ${topicSelected === 'organization'
                      ? 'bg-[#0068FF] text-white'
                      : ''
                      }`}
                    onClick={handleStatisticsOrgClick}
                  >
                    Статистика Организации
                  </div>
                )}
                <div className="flex flex-col bg-white rounded-[12px] px-2">
                  {organizationInfo?.modules?.length && (
                    <div className={'col-span-12 flex flex-col mt-4'}>
                      <div className="flex flex-row justify-between">
                        <p className={'font-bold text-[#5F6B88] text-[14px]'}>
                          Модули
                        </p>
                      </div>
                      <Divider />
                      <List className={'text-unix-black'}>
                        {organizationInfo?.modules?.map(m => (
                          <React.Fragment key={m.moduleId || m?.id}>
                            <div
                              className={`flex items-center justify-between rounded-xl px-[8px] py-[16px] mb-[8px] font-medium text-[14px] ${topicSelected === (m.moduleId || m?.id)
                                ? 'bg-[#0068FF] text-white'
                                : ''
                                }`}
                              onClick={() => handleModuleClick(m)}
                            >
                              {m.title}
                              {topicSelected === (m.moduleId || m.id) ? (
                                <img src={selectedArrow} />
                              ) : (
                                <img src={rightArrowIcon} />
                              )}
                            </div>
                          </React.Fragment>
                        ))}
                      </List>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex-grow">
              {topicSelected != 'organization' && (
                <div>
                  <div className="bg-white p-[24px] rounded-xl flex-grow flex-1">
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-[24px]">
                        <h2
                          className={`font-bold text-black  ${(topicSelected === 'organization'
                            ? organizationInfo.title.length
                            : organizationInfo.modules?.find(
                              m => m.id === topicSelected
                            )?.title?.length || 0) > 30
                            ? 'text-[24px] max-xl:text-[16px]'
                            : 'text-[40px]  max-xl:text-[24px]'
                            }`}
                        >
                          {topicSelected === 'organization'
                            ? organizationInfo.title
                            : organizationInfo.modules?.find(
                              m => m.id === topicSelected
                            )?.title}
                        </h2>
                        {topicSelected === 'organization' ? (
                          ''
                        ) : (
                          <Link
                            className="text-[#0068FF] border border-[#0068FF] h-[40px] ml-1 items-center rounded-[8px] flex text-[14px] whitespace-nowrap px-[16px] py-[8px] mr-[24px] min-w-[200px]"
                            to={`/admin/modules/statistics/${topicSelected}`}
                          >
                            <p className="text-center text-[14px] whitespace-nowrap mr-[6px]">
                              На страницу модуля
                            </p>
                            <img
                              className=""
                              width={24}
                              height={24}
                              src={moduleRight}
                            />
                          </Link>
                        )}
                      </div>
                      <div className="flex items-center min-w-[165px]">
                        <Button className="text-[14px]" onClick={toggleExpand}>
                          {isExpanded ? (
                            <div className="flex bg-[#F0F3FA] rounded-[8px] py-[8px] px-[16px]">
                              <p className="mr-[6px]">Статистика</p>
                              <img src={arrowUp} />
                            </div>
                          ) : (
                            <div className="flex bg-[#F0F3FA] rounded-[8px] py-[8px] px-[16px]">
                              <p className="mr-[6px]">Статистика</p>
                              <img src={arrowDown} />
                            </div>
                          )}
                        </Button>
                      </div>
                    </div>
                    {isExpanded && selectedModuleStats && (
                      <div className="flex mt-[32px] justify-between">
                        <div className="flex flex-col w-[30%] border-r-2">
                          <div className="flex flex-col rounded-2xl">
                            <div className="flex mb-4">
                              <img src={studentsIcon} />
                              <p className="font-medium ml-2 text-[14px] text-[#5F6B88]">
                                Студентов
                              </p>
                            </div>
                            <p className="">
                              <span className="text-4xl font-bold text-[#0068FF]">
                                {selectedModuleStats.students}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col w-[30%] border-r-2">
                          <div className="flex mb-4">
                            <img src={teachers} />
                            <p className="font-medium ml-2 text-[14px] text-[#5F6B88]">
                              Преподователей
                            </p>
                          </div>
                          <p className="font-bold text-[36px]">
                            <span className="text-[#0068FF]">
                              {selectedModuleStats.teachers}
                            </span>
                          </p>
                        </div>
                        <div className="flex flex-col rounded-2xl w-[30%]">
                          <div className="flex mb-4">
                            <img src={classes} alt="" />
                            <p className="font-medium ml-2 text-[14px] text-[#5F6B88]">
                              Администраторов
                            </p>
                          </div>
                          <p className="font-bold text-[36px]">
                            <span className="text-[#0068FF]">
                              {sortedAdmins.length}
                            </span>
                          </p>
                        </div>
                      </div>

                      // <div className="flex max-xl:gap-[2vw] gap-0 justify-between pr-2">
                      //     <div className="flex flex-col py-4 rounded-2xl border-r-[#F0F3FA]">
                      //     <div className="flex flex-col rounded-2xl">
                      //       <div className="flex mb-4">
                      //         <img src={studentsIcon} />
                      //         <p className="font-medium ml-2 text-[14px] text-[#5F6B88]">
                      //           Студентов
                      //         </p>
                      //       </div>
                      //       <p className="">
                      //         <span className="text-4xl font-bold text-[#0068FF]">
                      //           {selectedModuleStats.students}
                      //         </span>
                      //       </p>
                      //     </div>
                      //   </div>
                      //   <div className="flex flex-col py-4 rounded-2xl">
                      //     <div className="flex mb-4">
                      //       <img src={teachers} />
                      //       <p className="font-medium ml-2 text-[14px] text-[#5F6B88]">
                      //         Преподователей
                      //       </p>
                      //     </div>
                      //     <p className="font-bold text-[36px]">
                      //       <span className="text-[#0068FF]">
                      //         {selectedModuleStats.teachers}
                      //       </span>
                      //     </p>
                      //   </div>
                      //   <div className="flex flex-col py-4 rounded-2xl">
                      //     <div className="flex mb-4">
                      //       <img src={classes} alt="" />
                      //       <p className="font-medium ml-2 text-[14px] text-[#5F6B88]">
                      //         Администраторов
                      //       </p>
                      //     </div>
                      //     <p className="font-bold text-[36px]">
                      //       <span className="text-[#0068FF]">
                      //         {selectedModuleStats.adminsCount}
                      //       </span>
                      //     </p>
                      //   </div>
                      // </div>
                    )}
                  </div>
                  {isExpanded && selectedModuleStats && (
                    <div className="flex flex-col justify-between mt-[16px]">
                      <div className="flex gap-3 mb-3">
                        <div className="flex-1">
                          <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                            <div className="flex justify-between items-center">
                              <p className="font-medium text-[14px] text-[#5F6B88]">
                                Среднее время просмотра видео/урока
                              </p>
                              <img src={avgTime} alt="Average Time Icon" />
                            </div>
                            <p className="mb-[10px]">
                              {!isNaN(averageTimeLessonInMinutes) ? (
                                <span className="text-4xl font-bold text-[#0068FF]">
                                  {averageTimeLessonInMinutes}
                                </span>
                              ) : (
                                <span className="text-4xl font-bold text-[#0068FF]">
                                  -
                                </span>
                              )}
                              <span className="text-2xl ml-2 text-[#AFB7CA]">
                                мин
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                            <div className="flex justify-between items-center">
                              <p className="font-medium text-[14px] text-[#5F6B88]">
                                Среднее время прохождения лекции
                              </p>
                              <img
                                src={avgLecture}
                                alt="Average Lecture Time Icon"
                              />
                            </div>
                            <p className="text-[#0068FF] font-bold text-[36px]">
                              {formatTime(
                                moduleStat?.statistics?.averageTimeTopic
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                            <div className="flex justify-between items-center">
                              <p className="font-medium text-[14px] text-[#5F6B88]">
                                Среднее время прохождения курса
                              </p>
                              <img
                                src={avgCourse}
                                alt="Average Course Time Icon"
                              />
                            </div>
                            <p className="">
                              <p className="text-[#0068FF] font-bold text-[36px]">
                                {formatTime(
                                  moduleStat?.statistics
                                    ?.averageTimeCourse
                                )}
                              </p>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <div className="flex-1">
                          <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                            <div className="flex justify-between items-center">
                              <p className="font-medium text-[14px] text-[#5F6B88]">
                                Сертификатов получено
                              </p>
                              <img
                                src={certificateIcon}
                                alt="certificates Icon"
                              />
                            </div>
                            <div className="flex items-center justify-between">
                              <p className="font-bold text-[40px] text-[#0068FF]">
                                {selectedModuleStats.certificatesReceived}
                              </p>
                              <div className="w-[48px] h-[48px]">
                                <CircularProgressbar
                                  value={percentageCertificates}
                                  text={`${percentageCertificates.toFixed(1)}%`}
                                  strokeWidth={15}
                                  styles={buildStyles({
                                    textSize: '20px',
                                    fontWeight: '500',
                                    pathColor: '#0068FF',
                                    textColor: '#5F6B88',
                                    trailColor: '#F0F3FA'
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                            <div className="flex justify-between items-center">
                              <p className="font-medium text-[14px] text-[#5F6B88]">
                                Аккаунтов активировано
                              </p>
                              <img
                                src={accountIcon}
                                alt="Average Daily Time Icon"
                              />
                            </div>
                            <div className="flex items-center justify-between">
                              <p className="font-bold text-[40px] text-[#0068FF]">
                                {selectedModuleStats.activatedAccounts}
                              </p>
                              <div className="w-[48px] h-[48px]">
                                <CircularProgressbar
                                  value={percentageActivatedAccounts}
                                  text={`${percentageActivatedAccounts.toFixed(1)}%`}
                                  strokeWidth={15}
                                  styles={buildStyles({
                                    textSize: '20px',
                                    fontWeight: '500',
                                    pathColor: '#0068FF',
                                    textColor: '#5F6B88',
                                    trailColor: '#F0F3FA'
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                            <div className="flex justify-between items-center">
                              <p className="font-medium text-[14px] text-[#5F6B88]">
                                Среднее время активности студентов
                              </p>
                              <img src={avgDay} alt="Average Daily Time Icon" />
                            </div>
                            <p className="font-bold text-[40px] text-[#0068FF]">
                              -
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="bg-white">
                    {topicSelected === 'organization' ? (
                      <div className="px-6 py-3 mt-3">
                        <input
                          type="text"
                          placeholder="Поиск"
                          className="bg-[#F0F3FA] max-xl:h-[43px] w-[100%] stroke-none border-none rounded-[8px]"
                        />
                      </div>
                    ) : (
                      <div className="flex bg-white ml-1 p-3 mt-3 gap-2">
                        {addAdminMode ? (
                          <input
                            type="text"
                            placeholder="Поиск"
                            value={searchQuery}
                            onChange={handleSearchInputChange}
                            className="bg-[#F0F3FA] flex-grow text-[#000] h-[43px] max-xl:w-[60%] xl:w-[53%] stroke-none border-none rounded-[8px]"
                          />
                        ) : (
                          <input
                            type="text"
                            placeholder="Поиск"
                            value={uniqueSearchQuery}
                            onChange={e => setUniqueSearchQuery(e.target.value)}
                            className="bg-[#F0F3FA] flex-grow text-[#000] h-[43px] max-xl:w-[60%] xl:w-[53%] stroke-none border-none rounded-[8px]"
                          />
                        )}

                        <button className="bg-[#0068FF] text-[#0068FF] h-[43px] bg-opacity-20 p-1 border border-opacity-50 rounded-[8px] border-[#0068FF] flex items-center gap-2 pr-3">
                          <button
                            className={`${addAdminMode ? '' : 'bg-[#0068FF]'
                              } h-[35px] max-xl:text-[14px] min-w-[107px] items-center gap-2 flex text-white rounded-[6px] px-3 py-2`}
                            onClick={() => setAddAdminMode(false)}
                          >
                            {addAdminMode ? (
                              <img src={searchBlueIcon} />
                            ) : (
                              <img src={whiteSearchIcon} />
                            )}
                            <p
                              className={`${addAdminMode ? 'text-[#0068FF]' : ''
                                } max-2xl:block 2xl:hidden pr-2`}
                            >
                              Поиск
                            </p>
                            <p
                              className={`${addAdminMode ? 'text-[#0068FF]' : ''
                                } max-2xl:hidden`}
                            >
                              Поиск в модуле
                            </p>
                          </button>
                          <div
                            className={`flex items-center w-fit min-w-[240px] gap-2 h-[35px] rounded-[6px] px-3 py-2 ${addAdminMode ? 'bg-[#0068FF]' : ''
                              }`}
                            onClick={() => setAddAdminMode(true)}
                          >
                            {addAdminMode ? (
                              <img src={addAdminIcon} />
                            ) : (
                              <img src={add} />
                            )}
                            <p
                              className={`font-medium max-xl:text-[14px] max-xl:font-normal whitespace-nowrap xl:pl-2 ${addAdminMode ? 'text-white' : ''
                                }`}
                            >
                              Добавить администратора!
                            </p>
                          </div>
                        </button>
                      </div>
                    )}
                  </div>

                  <section className="flex flex-col py-4 pr-2 pl-4 mt-4 bg-white rounded-xl max-md:max-w-full">
                    <div className="flex gap-4 px-2 pt-2 pb-4 rounded-lg max-md:flex-wrap">
                      <div
                        className="text-sm font-medium leading-4 text-slate-500"
                        style={{ minWidth: '50px' }}
                      >
                        №
                      </div>
                      <div
                        className="flex-1 flex items-center text-sm font-medium text-left leading-4 text-slate-500 cursor-pointer"
                        onClick={() => requestSort('firstname')}
                      >
                        <span className="mr-[6px]">Администратор</span>
                        {getSortingIcons('firstname')}
                      </div>
                      <div
                        className="flex-1 flex items-center text-sm font-medium text-left leading-4 text-slate-500 cursor-pointer max-xl:ml-6"
                        onClick={() => requestSort('email')}
                      >
                        <span className="mr-[6px]">Почта</span>
                        {getSortingIcons('email')}
                      </div>
                      <div className="flex-1 text-sm font-medium leading-4 text-left pr-4 text-slate-500">
                        Активность за неделю!
                      </div>
                    </div>
                    {addAdminMode &&
                      searchResults?.map((admin, index) => (
                        <div
                          key={admin.id}
                          className="flex gap-2 items-center p-2 text-base border-t border-solid border-slate-100"
                        >
                          <div
                            style={{ minWidth: '50px' }}
                            className="text-sm font-medium leading-4 text-slate-500"
                          >
                            {index + 1}
                          </div>
                          <div className="flex-0 w-[30%] flex gap-3 items-center">
                            <div className="justify-center items-center px-2.5 pt-2 font-semibold text-center text-white bg-amber-200 h-[38px] leading-[120%] rounded-full w-[38px]">
                              <span className="relative left-[-2.5px] top-[1px]">
                                {admin.firstname[0]}
                                {admin.lastname[0]}
                              </span>
                            </div>
                            <div className="flex-0 my-auto font-medium leading-[130%] text-ellipsis text-zinc-900 max-xl:text-[14px]">
                              {admin.firstname} {admin.lastname}
                            </div>
                          </div>
                          <div className="flex-0 w-[30%] my-auto font-medium leading-[130%] text-slate-500 max-xl:ml-6">
                            {admin.email}
                          </div>
                          <div className="flex min-w-[171px]  max-[1310px]:left-[-15px] max-xl:w-[165px] items-center w-fit xl:pl-2 bg-[#3BBC30] text-white rounded-[8px] h-[43px] pr-3 pl-3">
                            <img src={plusWhite} />
                            <p
                              className="font-medium max-xl:text-[14px] max-xl:font-normal"
                              onClick={() => handleAddAdminClick(admin)}
                            >
                              Добавить администратора
                            </p>
                          </div>
                        </div>
                      ))}
                    {console.log('topic selected ', topicSelected)}
                    {!addAdminMode && uniqueSearchResults.length === 0 ? (
                      sortedAdmins?.map((admin, index) => (
                        <div key={index}>
                          <AdminStatItem
                            number={index + 1}
                            admin={admin}
                            initials={`${admin.user.firstname.charAt(0)}${admin.user.lastname.charAt(0)}`}
                            selectedModule={selectedModuleStats?.id}
                            userId={admin.userId}
                            moduleId={topicSelected}
                            name={`${admin.user.firstname} ${admin.user.lastname}`}
                            email={admin.user.email}
                            handleDeleteAdminClick={handleDeleteAdminClick}
                          />
                        </div>
                      ))
                    ) : (
                      <div>
                        {uniqueSearchResults?.map((moderator, index) => (
                          <div key={moderator.id}>
                            <AdminStatItem
                              number={index + 1}
                              admin={moderator}
                              initials={`${moderator.firstname.charAt(
                                0
                              )}${moderator.lastname.charAt(0)}`}
                              userId={moderator.id}
                              selectedModule={selectedModuleStats.id}
                              moduleId={topicSelected}
                              name={`${moderator.firstname} ${moderator.lastname}`}
                              email={moderator.email}
                              handleDeleteAdminClick={handleDeleteAdminClick}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </section>
                </div>
              )}
              <AdminAddConfirmModal
                isOpen={isAddModalOpen}
                admin={selectedAdmin}
                onClose={handleCloseModal}
                onConfirm={handleConfirmAddAdmin}
              />
              <AdminDeleteConfirmModal
                isOpen={isDeleteModalOpen}
                admin={selectedAdmin}
                organizationInfo={organizationInfo}
                topicSelected={topicSelected}
                moduleId={topicSelected}
                onClose={handleCloseDeleteModal}
                onConfirm={handleConfirmDeleteAdmin}
              />

              {topicSelected == 'organization' && (
                <div>
                  <div className="bg-white p-[24px] rounded-xl">
                    <div className="flex items-center">
                      <h2
                        className={`font-bold text-black mr-[24px] ${organizationInfo?.title.length > 20
                          ? 'text-[24px] max-xl:text-[16px]'
                          : 'text-[40px]  max-xl:text-[24px]'
                          }`}
                      >
                        {organizationInfo?.title}
                      </h2>
                      <Button className="text-[14px]" onClick={toggleExpand}>
                        {isExpanded ? (
                          <div className="flex bg-[#F0F3FA] rounded-[8px] py-[8px] px-[16px]">
                            <p className="mr-[6px]">Статистика</p>
                            <img src={arrowUp} />
                          </div>
                        ) : (
                          <div className="flex bg-[#F0F3FA] rounded-[8px] py-[8px] px-[16px]">
                            <p className="mr-[6px]">Статистика</p>
                            <img src={arrowDown} />
                          </div>
                        )}
                      </Button>
                    </div>
                    {isExpanded && (
                      <div className="flex mt-[32px] justify-between">
                        <div className="flex flex-col w-[30%] border-r-2">
                          <div className="flex flex-col rounded-2xl">
                            <div className="flex mb-4">
                              <img src={studentsIcon} />
                              <p className="font-medium ml-2 text-[14px] text-[#5F6B88]">
                                Студентов
                              </p>
                            </div>
                            <p className="">
                              <span className="text-4xl font-bold text-[#0068FF]">
                                {organizationStats?.students}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-col w-[30%] border-r-2">
                          <div className="flex mb-4">
                            <img src={teachers} />
                            <p className="font-medium ml-2 text-[14px] text-[#5F6B88]">
                              Преподователей
                            </p>
                          </div>
                          <p className="font-bold text-[36px]">
                            <span className="text-[#0068FF]">
                              {organizationStats?.teachers}
                            </span>
                          </p>
                        </div>
                        <div className="flex flex-col rounded-2xl w-[30%]">
                          <div className="flex mb-4">
                            <img src={classes} alt="" />
                            <p className="font-medium ml-2 text-[14px] text-[#5F6B88]">
                              Администраторов
                            </p>
                          </div>
                          <p className="font-bold text-[36px]">
                            <span className="text-[#0068FF]">
                              {organizationStats?.admins}
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  {isExpanded && (
                    <div className="flex flex-col justify-between mt-[16px]">
                      <div className="flex gap-3 mb-3">
                        <div className="flex-1">
                          <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                            <div className="flex justify-between items-center">
                              <p className="font-medium text-[14px] text-[#5F6B88]">
                                Среднее время просмотра видео/урока
                              </p>
                              <img src={avgTime} alt="Average Time Icon" />
                            </div>
                            <p className="mb-[10px]">
                              <span className="text-4xl font-bold text-[#0068FF]">
                                {formatTime(
                                  organizationStats?.organizationStatistics
                                    .averageTimeLesson
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                            <div className="flex justify-between items-center">
                              <p className="font-medium text-[14px] text-[#5F6B88]">
                                Среднее время прохождения лекции
                              </p>
                              <img
                                src={avgLecture}
                                alt="Average Lecture Time Icon"
                              />
                            </div>
                            <p className="">
                              <span className="font-bold text-[36px] text-[#0068FF]">
                                {formatTime(
                                  organizationStats?.organizationStatistics
                                    .averageTimeTopic
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4 ">
                            <div className="flex justify-between items-center">
                              <p className="font-medium text-[14px] text-[#5F6B88]">
                                Среднее время прохождения курса
                              </p>
                              <img
                                src={avgCourse}
                                alt="Average Course Time Icon"
                              />
                            </div>
                            <p className="">
                              <span className="font-bold text-[36px] text-[#0068FF]">
                                {formatTime(
                                  organizationStats?.organizationStatistics
                                    .averageTimeCourse
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-3">
                        <div className="flex-1">
                          <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                            <div className="flex justify-between items-center">
                              <p className="font-medium text-[14px] text-[#5F6B88]">
                                Сертификатов получено
                              </p>
                              <img
                                src={certificateIcon}
                                alt="Average Course Time Icon"
                              />
                            </div>
                            <div className="flex items-center justify-between">
                              <p className="font-bold text-[36px] text-[#0068FF]">
                                {organizationStats?.certificatesReceived}
                              </p>
                              <div className="w-[46px] h-[46px] font-medium">
                                <CircularProgressbar
                                  value={percentageCertified}
                                  text={`${percentageCertified?.toFixed(1)}%`}
                                  strokeWidth={10}
                                  styles={buildStyles({
                                    textSize: '20px',
                                    pathColor: '#0068FF',
                                    textColor: '#5F6B88',
                                    trailColor: '#F0F3FA'
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                            <div className="flex justify-between items-center">
                              <p className="font-medium text-[14px] text-[#5F6B88]">
                                Аккаунтов активировано
                              </p>
                              <img
                                src={accountIcon}
                                alt="Average Daily Time Icon"
                              />
                            </div>
                            <div className="flex items-center justify-between">
                              <p className="font-bold text-[40px] text-[#0068FF]">
                                {organizationStats?.activatedAccounts}
                              </p>
                              <div className="w-[48px] h-[48px] font-medium">
                                <CircularProgressbar
                                  value={percentageActivated}
                                  text={`${Math.round(percentageActivated)}%`}
                                  strokeWidth={10}
                                  styles={buildStyles({
                                    textSize: '20px',
                                    fontWeight: '500',
                                    pathColor: '#0068FF',
                                    textColor: '#5F6B88',
                                    trailColor: '#F0F3FA'
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex-1">
                          <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                            <div className="flex justify-between items-center">
                              <p className="font-medium text-[14px] text-[#5F6B88]">
                                Среднее время активности студентов
                              </p>
                              <img src={avgDay} alt="Average Daily Time Icon" />
                            </div>
                            <p className="font-bold text-[40px] text-[#0068FF]">
                              -
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="bg-white">
                    <div className="px-6 py-3 mt-3">
                      <input
                        type="text"
                        placeholder="Поиск"
                        className="bg-[#F0F3FA] text-black max-xl:h-[43px] w-[100%] stroke-none border-none rounded-[8px]"
                        value={orgModerSearchQuery}
                        onChange={handleOrgModerSearchChange}
                      />
                    </div>
                  </div>
                  {topicSelected === 'organization' ? (
                    <section className="flex flex-col py-4 pr-2 pl-4 mt-4 bg-white rounded-xl max-md:max-w-full">
                      <div className="flex gap-4 px-2 pt-2 pb-4 rounded-lg max-md:flex-wrap">
                        <div
                          className="text-sm font-medium leading-4 text-slate-500 cursor-pointer"
                          style={{ minWidth: '50px' }}
                        >
                          №
                        </div>
                        <div
                          className="flex-1 text-sm font-medium flex items-center leading-4 text-slate-500 cursor-pointer"
                          onClick={() => sortData('firstName')}
                        >
                          Администратор
                          <span className="ml-1 text-base flex flex-col gap-[4px]">
                            {sortField === 'firstName' &&
                              sortDirection === 'asc' && (
                                <>
                                  <span>
                                    <img
                                      width={'12px'}
                                      height={'7px'}
                                      src={desc}
                                    />
                                  </span>
                                  <span>
                                    <img
                                      width={'12px'}
                                      height={'7px'}
                                      src={ascSelected}
                                    />
                                  </span>
                                </>
                              )}
                            {sortField === 'firstName' &&
                              sortDirection === 'desc' && (
                                <>
                                  <span>
                                    <img
                                      width={'12px'}
                                      height={'7px'}
                                      src={descSelected}
                                    />
                                  </span>
                                  <span>
                                    <img
                                      width={'12px'}
                                      height={'7px'}
                                      src={asc}
                                    />
                                  </span>
                                </>
                              )}
                            {sortField !== 'firstName' && (
                              <>
                                <span>
                                  <img
                                    width={'12px'}
                                    height={'7px'}
                                    src={desc}
                                  />
                                </span>
                                <span>
                                  <img
                                    width={'12px'}
                                    height={'7px'}
                                    src={asc}
                                  />
                                </span>
                              </>
                            )}
                          </span>
                        </div>
                        <div
                          className="flex-1 text-sm font-medium flex items-center leading-4 text-slate-500 cursor-pointer max-xl:ml-6"
                          onClick={() => sortData('email')}
                        >
                          Почта
                          <span className="ml-1 text-base flex flex-col gap-[4px]">
                            {sortField === 'email' &&
                              sortDirection === 'asc' && (
                                <>
                                  <span>
                                    <img
                                      width={'12px'}
                                      height={'7px'}
                                      src={desc}
                                    />
                                  </span>
                                  <span>
                                    <img
                                      width={'12px'}
                                      height={'7px'}
                                      src={ascSelected}
                                    />
                                  </span>
                                </>
                              )}
                            {sortField === 'email' &&
                              sortDirection === 'desc' && (
                                <>
                                  <span>
                                    <img
                                      width={'12px'}
                                      height={'7px'}
                                      src={descSelected}
                                    />
                                  </span>
                                  <span>
                                    <img
                                      width={'12px'}
                                      height={'7px'}
                                      src={asc}
                                    />
                                  </span>
                                </>
                              )}
                            {sortField !== 'email' && (
                              <>
                                <span>
                                  <img
                                    width={'12px'}
                                    height={'7px'}
                                    src={desc}
                                  />
                                </span>
                                <span>
                                  <img
                                    width={'12px'}
                                    height={'7px'}
                                    src={asc}
                                  />
                                </span>
                              </>
                            )}
                          </span>
                        </div>
                        <div className="flex-1 text-sm font-medium leading-4 text-center text-slate-500">
                          Активность за неделю
                        </div>
                      </div>
                      {filteredOrgModerators?.map((admin, index) => (
                        <AdminStatItem
                          key={admin.id}
                          number={index + 1}
                          initials={`${admin.firstname.charAt(
                            0
                          )}${admin.lastname.charAt(0)}`}
                          userId={admin.id}
                          moduleId={topicSelected}
                          name={`${admin.firstname} ${admin.lastname}`}
                          email={admin.email}
                          handleDeleteModerator={handleDeleteModerator}
                        />
                      ))}
                    </section>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center min-h-[200px]">
          <LoadingSpinner />
        </div>
      )}
    </>
  )
}

const LoadingSpinner = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <CircularProgress />
    </div >
  )
}

const AdminStatItem = ({
  number,
  initials,
  admin,
  name,
  selectedModule,
  email,
  moduleId,
  handleDeleteAdminClick
}) => {
  const onDeleteClick = () => {
    handleDeleteAdminClick(moduleId, admin)
  }

  return (
    <div className="flex gap-2 items-center  p-2 text-base border-t border-solid border-slate-100">
      <div
        style={{ minWidth: '50px' }}
        className="text-sm font-medium leading-4 text-slate-500"
      >
        {number}
      </div>
      <div className="flex-1 flex gap-3 items-center justify-center">
        <div className="justify-center items-center px-2.5 pt-2 font-semibold text-center text-white bg-amber-200 h-[38px] leading-[120%] rounded-full w-[38px]">
          <span className="relative left-[-2.5px] top-[1px]">{initials}</span>
        </div>
        <div className="flex-1 my-auto font-medium leading-[130%] text-ellipsis text-zinc-900 max-xl:text-[14px]">
          {name}
        </div>
      </div>
      <div className="flex-1 my-auto font-medium leading-[130%] text-slate-500 max-xl:ml-6">
        {email}
      </div>
      {!selectedModule && (
        <div className="flex-1 flex relative flex-col text-xs font-medium leading-3 text-center text-slate-500"></div>
      )}
      {selectedModule && (
        <div className="flex-1 flex relative flex-col text-xs font-medium leading-3 text-center text-slate-500">
          <img
            className="absolute right-0 top-[-5px] bg-[#F12D2D]/[20%] rounded-[6px] opacity-80"
            width={20}
            height={20}
            src={deleteIcon}
            onClick={onDeleteClick}
          />
        </div>
      )}
    </div>
  )
}

const AdminAddConfirmModal = ({ isOpen, admin, onClose, onConfirm }) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-4 rounded-lg shadow-lg w-[560px]">
          <div className="flex justify-end mb-4 cursor-pointer">
            <img onClick={onClose} src={closeIcon} />
          </div>
          <div className="flex items-center gap-4 text-[20px]">
            <img src={warningIcon} />
            <h4 className="text-[#5F6B88]">
              Вы уверены что хотите добавить администратора{' '}
              <span className="text-[#1A1A1A]">
                {admin.firstname} {admin.lastname}
              </span>
              ?
            </h4>
          </div>
          <div className="flex justify-end mt-12">
            <button
              onClick={onClose}
              className="bg-[#5F6B88] text-white h-[48px] rounded-[8px] w-full flex items-center justify-center gap-2"
            >
              <img src={leftIcon} />
              Вернуться
            </button>
            <button
              onClick={() => onConfirm(admin.id)}
              className="bg-[#3BBC30] text-white h-[48px] ml-3 rounded-[8px] w-full flex items-center justify-center gap-2"
            >
              <img src={plusWhite} />
              Добавить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const AdminDeleteConfirmModal = ({
  isOpen,
  admin,
  moduleId,
  onClose,
  onConfirm,
  organizationInfo,
  topicSelected
}) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-4 rounded-lg shadow-lg w-[560px]">
          <div className="flex justify-end mb-4 cursor-pointer">
            <img onClick={onClose} src={closeIcon} />
          </div>
          <div className="flex items-center gap-4 text-[20px]">
            <img src={warningIcon} />
            <h4 className="text-[#5F6B88]">
              Вы уверены что хотите удалить администратора{' '}
              <span className="text-[#1A1A1A]">
                {admin.user.firstname} {admin.user.lastname}
              </span>{' '}
              из модуля{' '}
              <span className="text-[#1a1a1a]">
                {
                  organizationInfo.modules?.find(m => m?.id === topicSelected)
                    ?.title
                }
              </span>
              ?
            </h4>
          </div>
          <div className="flex justify-end mt-12">
            <button
              onClick={onClose}
              className="bg-[#5F6B88] text-white h-[48px] rounded-[8px] w-full flex items-center justify-center gap-2"
            >
              <img src={leftIcon} />
              Вернуться
            </button>
            <button
              onClick={() => onConfirm(moduleId, admin.userId)}
              className="bg-[#F12D2D] text-white h-[48px] ml-3 rounded-[8px] w-full flex items-center justify-center gap-2"
            >
              <img src={bucket} />
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatisticsOrg

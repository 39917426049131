import { useState } from 'react'

import { NavLink, Outlet } from 'react-router-dom'
import { logOut } from '../../../actions/user'
import shield from '../../../assets/shield.svg'
import unixLogo from '../../../assets/unix_logo.svg'
import { useGlobalUserState } from '../../../stores/user'
import closeSidebar from './closeSidebar.svg'
import openSidebar from './openSidebar.svg'

const access = {
  Организации: ['ADMIN', 'SUPERADMIN'],
  Классы: ['TEACHER'],
  Курсы: ['TEACHER'],
  Статистика: ['ADMIN', 'SUPERADMIN'],
  Отзывы: ['SUPERADMIN'],
  Платформа: [],
  Уведомления: ['SUPERADMIN', 'TEACHER'],
  'Проверка ДЗ': ['TEACHER'],
  Выйти: []
}

const menu = [
  // {
  //     label: "Главная",
  //     path: '/',
  //     icon: () => (
  //         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  //             <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6808 6.4865C13.6808 6.4865 13.8305 6.6279 13.9136 6.81634C13.9136 6.81634 13.9966 7.00478 13.9999 7.21067L14 13C14 13 14 13.4142 13.7071 13.7071C13.7071 13.7071 13.4142 14 13 14H3C3 14 2.58579 14 2.29289 13.7071C2.29289 13.7071 2 13.4142 2 13V7.21876C2 7.21876 2.0034 7.00478 2.08644 6.81633C2.08644 6.81633 2.16948 6.62789 2.31919 6.4865L7.32623 1.93623C7.32623 1.93623 7.61158 1.67517 8 1.67517C8 1.67517 8.38842 1.67517 8.675 1.93735L13.6734 6.47966L13.6808 6.4865ZM12.9953 7.21463L8 2.67517L7.99877 2.67629L3.00581 7.21352C3.00581 7.21352 3.00305 7.21612 3.00152 7.21959C3.00152 7.21959 3.00081 7.22121 3.00043 7.22114C3.00043 7.22114 3 7.22105 3 7.21881V13H13L13.0001 7.22685C13.0001 7.22685 13 7.22306 12.9985 7.21959C12.9985 7.21959 12.9973 7.21684 12.9953 7.21463Z" fill="white"/>
  //         </svg>
  //     )
  // },
  {
    label: 'Организации',
    path: '/admin/orgs',
    icon: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 7H5C4.46957 7 3.96086 7.21071 3.58579 7.58579C3.21071 7.96086 3 8.46957 3 9V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H19C19.5304 20 20.0391 19.7893 20.4142 19.4142C20.7893 19.0391 21 18.5304 21 18V9C21 8.46957 20.7893 7.96086 20.4142 7.58579C20.0391 7.21071 19.5304 7 19 7Z"
          stroke="#AFB7CA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 7V5C8 4.46957 8.21071 3.96086 8.58579 3.58579C8.96086 3.21071 9.46957 3 10 3H14C14.5304 3 15.0391 3.21071 15.4142 3.58579C15.7893 3.96086 16 4.46957 16 5V7"
          stroke="#AFB7CA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 12V12.01"
          stroke="#AFB7CA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 13C5.79158 14.4067 8.87403 15.1394 12 15.1394C15.126 15.1394 18.2084 14.4067 21 13"
          stroke="#AFB7CA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
  {
    label: 'Классы',
    path: '/admin/teacher/courses',
    icon: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11Z"
          stroke="#AEB7CA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.5 21C7.56087 21 8.57828 20.5786 9.32843 19.8284C10.0786 19.0783 10.5 18.0609 10.5 17C10.5 15.9391 10.0786 14.9217 9.32843 14.1716C8.57828 13.4214 7.56087 13 6.5 13C5.43913 13 4.42172 13.4214 3.67157 14.1716C2.92143 14.9217 2.5 15.9391 2.5 17C2.5 18.0609 2.92143 19.0783 3.67157 19.8284C4.42172 20.5786 5.43913 21 6.5 21Z"
          stroke="#AEB7CA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5 21C18.5609 21 19.5783 20.5786 20.3284 19.8284C21.0786 19.0783 21.5 18.0609 21.5 17C21.5 15.9391 21.0786 14.9217 20.3284 14.1716C19.5783 13.4214 18.5609 13 17.5 13C16.4391 13 15.4217 13.4214 14.6716 14.1716C13.9214 14.9217 13.5 15.9391 13.5 17C13.5 18.0609 13.9214 19.0783 14.6716 19.8284C15.4217 20.5786 16.4391 21 17.5 21Z"
          stroke="#AEB7CA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
  {
    label: 'Курсы',
    path: '/admin/course/',
    icon: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
          stroke="#AFB7CA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
          stroke="#AFB7CA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
  {
    label: 'Проверка ДЗ',
    path: '/admin/homework',
    icon: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.5 12.5V6.5C2.5 6.22386 2.27614 6 2 6C1.72386 6 1.5 6.22386 1.5 6.5V12.5C1.5 12.9142 1.79289 13.2071 1.79289 13.2071C2.08579 13.5 2.5 13.5 2.5 13.5H13.5C13.9142 13.5 14.2071 13.2071 14.2071 13.2071C14.5 12.9142 14.5 12.5 14.5 12.5V5.5C14.5 5.08579 14.2071 4.79289 14.2071 4.79289C13.9142 4.5 13.5 4.5 13.5 4.5H8C7.72386 4.5 7.5 4.72386 7.5 5C7.5 5.27614 7.72386 5.5 8 5.5H13.5V12.5H2.5Z"
          fill="#AFB7CA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.4296 6.80123C6.4296 6.80123 6.16433 6.99849 5.8338 6.99999L5.83125 7H2C1.72386 7 1.5 6.77614 1.5 6.5V4C1.5 4 1.5 3.58579 1.79289 3.29289C1.79289 3.29289 2.08579 3 2.5 3L5.83331 3C5.83331 3 6.16413 3.00136 6.43104 3.19984L8.29979 4.59984C8.33785 4.62835 8.37165 4.66215 8.40016 4.70021C8.47967 4.80634 8.51376 4.93971 8.49494 5.07098C8.47611 5.20224 8.40592 5.32065 8.29979 5.40016L6.4296 6.80123ZM5.82941 6C5.82941 6 5.83134 5.99993 5.8329 5.99877L7.16607 5L5.83146 4.00016C5.83146 4.00016 5.83125 4 5.82919 4L2.5 4V6H5.82941Z"
          fill="#AFB7CA"
        />
        <path
          d="M6.5 9.75H9.5C9.77614 9.75 10 9.52614 10 9.25C10 8.97386 9.77614 8.75 9.5 8.75H6.5C6.22386 8.75 6 8.97386 6 9.25C6 9.52614 6.22386 9.75 6.5 9.75Z"
          fill="#AFB7CA"
        />
        <path
          d="M7.5 7.75V10.75C7.5 11.0261 7.72386 11.25 8 11.25C8.27614 11.25 8.5 11.0261 8.5 10.75V7.75C8.5 7.47386 8.27614 7.25 8 7.25C7.72386 7.25 7.5 7.47386 7.5 7.75Z"
          fill="#AFB7CA"
        />
      </svg>
    )
  },
  {
    label: 'Статистика',
    path: '/admin/statistics',
    icon: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.99972 3.2007C8.43542 3.56334 6.99664 4.3379 5.8326 5.44403C4.66856 6.55017 3.82166 7.9476 3.37975 9.49137C2.93783 11.0351 2.917 12.669 3.31941 14.2236C3.72182 15.7781 4.53281 17.1967 5.66827 18.3321C6.80372 19.4676 8.22229 20.2786 9.77683 20.681C11.3314 21.0834 12.9653 21.0626 14.509 20.6207C16.0528 20.1788 17.4502 19.3319 18.5564 18.1678C19.6625 17.0038 20.4371 15.565 20.7997 14.0007C20.7997 13.7355 20.6944 13.4811 20.5068 13.2936C20.3193 13.1061 20.0649 13.0007 19.7997 13.0007H15.9997C15.8179 13.6997 15.4547 14.3383 14.9468 14.8518C14.4389 15.3653 13.8044 15.7356 13.1074 15.9251C12.4105 16.1146 11.6758 16.1166 10.9779 15.9309C10.2799 15.7453 9.6433 15.3785 9.13259 14.8678C8.62188 14.3571 8.25514 13.7206 8.06949 13.0226C7.88384 12.3246 7.88586 11.5899 8.07536 10.893C8.26486 10.196 8.6351 9.56149 9.14862 9.05361C9.66214 8.54572 10.3007 8.1825 10.9997 8.0007V4.0007C10.9876 3.88212 10.9519 3.76715 10.8948 3.66249C10.8378 3.55783 10.7605 3.46558 10.6674 3.39112C10.5743 3.31665 10.4673 3.26146 10.3527 3.22877C10.2381 3.19608 10.1181 3.18653 9.99972 3.2007Z"
          stroke="#AFB7CA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 3.5C16.2697 3.94708 17.423 4.6733 18.3748 5.62516C19.3267 6.57702 20.0529 7.73028 20.5 9H16C15.6934 8.64097 15.359 8.3066 15 8V3.5Z"
          stroke="#AFB7CA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
  {
    label: 'Отзывы',
    path: '/admin/feedback',
    icon: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 21V8C4 7.20435 4.31607 6.44129 4.87868 5.87868C5.44129 5.31607 6.20435 5 7 5H17C17.7956 5 18.5587 5.31607 19.1213 5.87868C19.6839 6.44129 20 7.20435 20 8V14C20 14.7956 19.6839 15.5587 19.1213 16.1213C18.5587 16.6839 17.7956 17 17 17H8L4 21Z"
          stroke="#AFB7CA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 8V11"
          stroke="#AFB7CA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 14V14.01"
          stroke="#AFB7CA"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
  },
  {
    label: 'Платформа',
    path: '/platform',
    icon: () => (
      <svg
        fill="#AFB7CA"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 495.398 495.398"
      >
        <g>
          <g>
            <g>
              <path
                d="M487.083,225.514l-75.08-75.08V63.704c0-15.682-12.708-28.391-28.413-28.391c-15.669,0-28.377,12.709-28.377,28.391
                                v29.941L299.31,37.74c-27.639-27.624-75.694-27.575-103.27,0.05L8.312,225.514c-11.082,11.104-11.082,29.071,0,40.158
                                c11.087,11.101,29.089,11.101,40.172,0l187.71-187.729c6.115-6.083,16.893-6.083,22.976-0.018l187.742,187.747
                                c5.567,5.551,12.825,8.312,20.081,8.312c7.271,0,14.541-2.764,20.091-8.312C498.17,254.586,498.17,236.619,487.083,225.514z"
              />
              <path
                d="M257.561,131.836c-5.454-5.451-14.285-5.451-19.723,0L72.712,296.913c-2.607,2.606-4.085,6.164-4.085,9.877v120.401
                                c0,28.253,22.908,51.16,51.16,51.16h81.754v-126.61h92.299v126.61h81.755c28.251,0,51.159-22.907,51.159-51.159V306.79
                                c0-3.713-1.465-7.271-4.085-9.877L257.561,131.836z"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  },
  {
    label: 'Уведомления',
    path: '/admin/notifications',
    icon: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.01838 1.2729C5.5688 1.2729 3.57805 3.26365 3.57805 5.71323V7.85199C3.57805 8.30342 3.38563 8.99168 3.15622 9.37651L2.30515 10.79C1.77971 11.6633 2.14234 12.6328 3.10441 12.9584C6.29405 14.0241 9.73531 14.0241 12.925 12.9584C13.8204 12.6624 14.2126 11.6041 13.7242 10.79L12.8731 9.37651C12.6511 8.99168 12.4587 8.30342 12.4587 7.85199V5.71323C12.4587 3.27105 10.4606 1.2729 8.01838 1.2729Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.02301 5.71323C3.02301 2.95711 5.26226 0.717855 8.01838 0.717855C10.7671 0.717855 13.0138 2.96451 13.0138 5.71323V7.85199C13.0138 8.011 13.0497 8.24374 13.118 8.49243C13.1853 8.73811 13.2724 8.95647 13.3516 9.0951L14.2002 10.5044C14.8703 11.6214 14.3346 13.0764 13.1 13.4851C9.79644 14.5886 6.23204 14.5885 2.92853 13.4848L2.92647 13.4841C2.30304 13.2731 1.8355 12.8368 1.62646 12.2723C1.41718 11.7071 1.48837 11.0709 1.82956 10.5039L2.67946 9.09229L2.68002 9.09135C2.76106 8.9551 2.84984 8.73756 2.91834 8.49076C2.98706 8.24323 3.02301 8.01083 3.02301 7.85199V5.71323ZM8.01838 1.82794C5.87534 1.82794 4.13309 3.57019 4.13309 5.71323V7.85199C4.13309 8.14458 4.07283 8.48203 3.98798 8.78769C3.90308 9.09352 3.78119 9.41209 3.63297 9.66072L3.63172 9.66281L2.78074 11.0762C2.59658 11.3823 2.58629 11.6676 2.66747 11.8868C2.74882 12.1065 2.94333 12.3176 3.28137 12.4323C6.35655 13.4595 9.67394 13.4594 12.7491 12.4319L12.7507 12.4314C13.3064 12.2477 13.5548 11.5866 13.2483 11.0757L12.3923 9.65392C12.2501 9.40751 12.1311 9.09114 12.0474 8.78602C11.9639 8.48152 11.9037 8.14441 11.9037 7.85199V5.71323C11.9037 3.57759 10.154 1.82794 8.01838 1.82794Z"
          fill="#AFB7CA"
        />
        <path
          d="M9.38733 1.48751C9.15791 1.42091 8.92109 1.3691 8.67687 1.3395C7.96642 1.25069 7.28557 1.3025 6.64912 1.48751C6.86374 0.939871 7.39658 0.555042 8.01822 0.555042C8.63987 0.555042 9.17271 0.939871 9.38733 1.48751Z"
          fill="#AFB7CA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.13235 1.28499C6.42796 0.530668 7.16187 0 8.01822 0C8.87457 0 9.60849 0.530668 9.9041 1.28499C9.98127 1.4819 9.93983 1.70555 9.79724 1.86174C9.65464 2.01793 9.43568 2.07951 9.23258 2.02054C9.02728 1.96094 8.82008 1.91596 8.61009 1.89051L8.60803 1.89026C7.96832 1.81029 7.36387 1.85776 6.80406 2.02049C6.60094 2.07954 6.38192 2.018 6.23928 1.86181C6.09663 1.70562 6.05517 1.48193 6.13235 1.28499Z"
          fill="#AFB7CA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.35317 13.2248C6.35317 13.6781 6.54174 14.1011 6.84185 14.4012C7.14196 14.7013 7.565 14.8899 8.0183 14.8899C8.93285 14.8899 9.68342 14.1393 9.68342 13.2248H10.7935C10.7935 14.7524 9.54593 16 8.0183 16C7.2579 16 6.55605 15.6853 6.0569 15.1862C5.55776 14.687 5.24309 13.9852 5.24309 13.2248H6.35317Z"
          fill="#AFB7CA"
        />
      </svg>
    )
  },
  {
    label: 'Выйти',
    path: '/admin',
    onClick: () => logOut(true),
    icon: () => (
      <svg
        fill="#AFB7CA"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 492.5 492.5"
      >
        <g>
          <path
            d="M184.646,0v21.72H99.704v433.358h31.403V53.123h53.539V492.5l208.15-37.422v-61.235V37.5L184.646,0z M222.938,263.129
                        c-6.997,0-12.67-7.381-12.67-16.486c0-9.104,5.673-16.485,12.67-16.485s12.67,7.381,12.67,16.485
                        C235.608,255.748,229.935,263.129,222.938,263.129z"
          />
        </g>
      </svg>
    )
  }
]
const renderMenuItem = (item, setSidebarVisible) => {
  const shouldDisableActive = label => label === 'Выйти'

  return (
    <NavLink
      to={item.path}
      className={({ isActive }) =>
        `flex flex-row items-center nav-link rounded-[8px] text-[#5F6B88] ${isActive && !shouldDisableActive(item.label) ? 'nav-link-selected bg-[#F0F3FA]' : ''}`
      }
      onClick={e => {
        if (item.onClick) item.onClick(e)
        setSidebarVisible(false)
      }}
    >
      <span className={({ isActive }) => `${isActive ? 'text-[#000]' : ''}`}>
        {item.icon()}
      </span>
      <span className={'ml-2 text-[12px] w-[70%] font-medium'}>
        {item.label}
      </span>
    </NavLink>
  )
}

const renderMenuItemAdaptive = (item, setSidebarVisible) => {
  const shouldDisableActive = label => label === 'Выйти'

  return (
    <NavLink
      to={item.path}
      className={({ isActive }) =>
        `flex flex-row items-center nav-link rounded-[8px] text-[#5F6B88] ${isActive && !shouldDisableActive(item.label) ? 'nav-link-selected bg-[#F0F3FA]' : ''}`
      }
      onClick={e => {
        if (item.onClick) item.onClick(e)
        setSidebarVisible(false)
      }}
    >
      <span className={({ isActive }) => `${isActive ? 'text-[#000]' : ''}`}>
        {item.icon()}
      </span>
    </NavLink>
  )
}

export default function Root() {
  const userState = useGlobalUserState()
  const user = userState.get()
  const [isSidebarVisible, setSidebarVisible] = useState(false)

  const toggleSidebar = () => setSidebarVisible(!isSidebarVisible)

  return (
    <div
      className={
        'flex flex-row min-h-screen bg-gray-400 text-white overflow-hidden'
      }
    >
      <div
        className={`w-[200px] min-h-screen menu-col flex flex-col ${isSidebarVisible ? 'block absolute z-50' : 'hidden'}`}
      >
        <div className={'flex flex-row relative left-[-10px] mt-6 mb-24'}>
          <img
            src={unixLogo}
            width={'125px'}
            className="relative left-[30px]"
            alt="logo"
          />
        </div>
        <div className="relative left-[-5px] ml-3">
          <p className="text-black" onClick={toggleSidebar}>
            {isSidebarVisible ? (
              <img
                src={closeSidebar}
                alt="Close Sidebar"
                className="close-sidebar-class relative left-[25px] top-[-10px]"
              />
            ) : (
              <img
                src={openSidebar}
                alt="Open Sidebar"
                className="open-sidebar-class relative left-[25px] top-[-10px]"
              />
            )}
          </p>
          {menu
            .filter(
              m =>
                access[m.label].includes(user.roles?.[0]) ||
                !access[m.label].length
            )
            .map(menuItem => renderMenuItem(menuItem, setSidebarVisible))}
        </div>
      </div>
      <div
        className={`min-h-screen menu-col flex flex-col ${isSidebarVisible ? 'hidden' : 'block'}`}
      >
        <div className={'flex flex-row relative left-[0px] mt-6 mb-24'}>
          <img
            src={shield}
            className="w-10 h-10 relative left-[30px]"
            alt="logo"
          />
        </div>
        <div className="relative left-[-5px] ml-3">
          <p className="text-black" onClick={toggleSidebar}>
            {isSidebarVisible ? (
              <img
                src={closeSidebar}
                alt="Close Sidebar"
                className={`close-sidebar-class relative left-[25px] top-[-10px]`}
              />
            ) : (
              <img
                src={openSidebar}
                alt="Open Sidebar"
                className="open-sidebar-class relative left-[25px] top-[-10px]"
              />
            )}
          </p>
          <div>
            {menu
              .filter(
                m =>
                  access[m.label].includes(user.roles?.[0]) ||
                  !access[m.label].length
              )
              .map(menuItem =>
                renderMenuItemAdaptive(menuItem, setSidebarVisible)
              )}
          </div>
        </div>
      </div>
      <div
        className={`main-col min-h-screen h-screen overflow-y-auto overflow-x-hidden w-full ${isSidebarVisible ? 'ml-24' : ''}`}
      >
        <Outlet />
      </div>
    </div>
  )
}

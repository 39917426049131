import { CreateOrganizationComponent } from "./CreateOrganizationComponent";

export const CreateOrganization = () => {
    return (
        <div className={"grid grid-cols-12"}>
            <div className={"col-span-12"}>
                <CreateOrganizationComponent />
            </div>
        </div>
    )
}
import { topicsApi } from "../api"
import { showSnackbar } from "./snackbar"

const createTopic = async (body) => {
    const data = {}
    try {
        const response = await topicsApi.create(body)
        if (response.data.id) {
            showSnackbar()
            data.success = true
            data.topic = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const updateTopicPost = async (id, body) => {
    const data = {}
    try {
        const response = await topicsApi.update(id, body)
        if (response.data.id) {
            showSnackbar()
            data.success = true
            data.topic = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const topicDelete = async (id) => {
    const data = {}
    try {
        const response = await topicsApi.deleteTopic(id)
        if (response.data.id) {
            showSnackbar()
            data.success = true
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getTopic = async (id) => {
    const data = {}
    try {
        const response = await topicsApi.getTopic(id)
        if (response.data.id) {
            data.success = true
            data.topic = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

export {
    createTopic,
    updateTopicPost,
    getTopic,
    topicDelete
}
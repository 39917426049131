import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useOrganization } from "../../../hooks/organizations";
import { CreateOrganizationComponent } from "./CreateOrganizationComponent";
import { Autocomplete, Button, Divider, List, ListItem, TextField } from "@mui/material";
import { useModules } from "../../../hooks/modules";
import { addModulesToOrganization, getOrganization } from "../../../actions/organization";
import arrowBlack from './arrow-black.svg'
import SelectAdminsComponent from "./SelectAdminsComponent";

export const Organization = () => {
    const { organizationId } = useParams()
    const { organization: organizationRes, admins: adminsRes } = useOrganization(organizationId)
    const [organization, setOrganization] = useState({})
    const [admins, setAdmins] = useState([])
    const modules = useModules()
    const navigate = useNavigate()
    const [selectedModules, setSelectedModules] = useState([])

    const fetchOrganization = async () => {
        const response = await getOrganization(organizationId)
        if (response.success) {
            setOrganization(response.organization)
            setAdmins(response.admins)
        }
    }
    useEffect(() => {
        if (organizationRes && adminsRes) {
            setOrganization(organizationRes)
            setAdmins(adminsRes)
        }
    }, [organizationRes, adminsRes])
    const addModulesToOrg = async (modulesArr) => {
        const reqBody = {
            modules: modulesArr.map(m => m.id)
        }
        const response = await addModulesToOrganization(organizationId, reqBody)
        if (response.success) {
            setSelectedModules([])
            fetchOrganization()
        }
    }

    const submitModules = () => {
        if (selectedModules.length) {
            addModulesToOrg(selectedModules)
        }
    }

    const createModule = () => {
        navigate(`/admin/modules/create?organizationId=${organizationId}`)
    }

    const goToModules = () => {
        navigate(`/admin/modules?organizationId=${organizationId}`)
    }

    return (
        <div className={"grid grid-cols-12"}>
            <div className={"col-span-12"}>
                <CreateOrganizationComponent organization={organization} />
            </div>
            <div className={"col-span-6"}>
                <SelectAdminsComponent
                    admins={admins}
                    fetchOrganization={fetchOrganization}
                    organizationId={organizationId}
                />
            </div>
            <div className={"col-span-6"}>
                <div className={"unix-card ml-5 mr-5 grid grid-cols-12 px-5 py-6"}>
                    <div className={"col-span-12 flex flex-row justify-between items-center"}>
                        <span className={"text-sm text-[#797979]"}>
                            Модули
                        </span>
                        <Button onClick={createModule}>
                            Создать новый модуль
                        </Button>
                    </div>
                    <div className={"col-span-12 mt-5"}>
                        <Autocomplete
                            multiple
                            options={modules}
                            getOptionLabel={(option) => `${option.id}. ${option.title}`}
                            defaultValue={modules[0]}
                            filterSelectedOptions
                            value={selectedModules}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(e, newVal) => setSelectedModules(newVal)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Выберите модули"
                                    placeholder="Модули"
                                />
                            )}
                        />
                    </div>
                    <div className={"col-span-12 flex flex-row justify-end mt-3"}>
                        <button onClick={submitModules} className={"px-4 py-2.5 rounded-lg bg-unix-green text-base text-black/[.85]"}>
                            Сохранить
                        </button>
                    </div>
                    {organization.modules?.length && (
                        <div className={"col-span-12 flex flex-col mt-4"}>
                            <div className="flex flex-row justify-between">
                                <p className={"font-bold text-unix-black text-lg"}>Текущие модули</p>
                                <button onClick={goToModules}>
                                    <img src={arrowBlack} />
                                </button>
                            </div>
                            <List className={"text-unix-black"}>
                                {organization.modules.map(m => (
                                    <>
                                        <ListItem>
                                            <Link className="text-[#1976d2]" to={`/admin/modules/${m.id}`}>{m.id}. {m.title}</Link>
                                        </ListItem>
                                        <Divider />
                                    </>
                                ))}
                            </List>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
import { Autocomplete, Chip, TextField } from "@mui/material"
import { setOrgAdmin, userSearch } from "../../../actions/user"
import { useEffect, useState } from "react"

const SelectAdminsComponent = ({ admins, fetchOrganization, organizationId, compact = false }) => {
    const [name, setName] = useState('')
    const [results, setResults] = useState([])
    const [selectedUser, setSelectedUser] = useState(undefined)

    const setAdmin = async (email, organizationId) => {
        console.log(organizationId)
        const reqBody = {
            email,
            role: "ADMIN",
            organizationId
        }
        const response = await setOrgAdmin(reqBody)
        if (response.success) {
            fetchOrganization()
            setTimeout(() => {
                setResults([])
                setSelectedUser(undefined)
                setName('')
            }, 1)
        }
    }

    const searchByName = async (query) => {
        const response = await userSearch(query)
        if (response.success) {
            setResults(response.users)
        }
    }

    const submitAdmin = () => {
        if (selectedUser?.email) {
            setAdmin(selectedUser?.email, organizationId)
        }
    }

    useEffect(() => {
        if (name.length) {
            searchByName(name)
        }
    }, [name])

    console.log(name)

    return (
        <div className={"unix-card ml-5 mr-5 grid grid-cols-12 px-5 pt-6 pb-[23px]"}>
            <div className={"col-span-12 py-2"}>
                <span className={"text-sm text-[#797979]"}>
                    Администратор организации
                </span>
            </div>
            <div className={"col-span-12 mt-5"}>
                <Autocomplete
                    inputValue={name}
                    onInputChange={(e, newVal) => {
                        setName(newVal)
                    }}
                    value={selectedUser}
                    onChange={(e, newVal) => {
                        setSelectedUser(newVal)
                    }}
                    clearOnBlur={false}
                    options={results}
                    renderInput={(params) => <TextField {...params} label="Поиск" />}
                    getOptionLabel={(option) => `${option.firstname} ${option.lastname} (${option.email})`}
                    key={`${JSON.stringify(selectedUser)}`}
                />
            </div>
            <div className={"col-span-12 flex flex-row justify-end mt-3"}>
                <button onClick={submitAdmin} className={"px-4 py-2.5 rounded-lg bg-unix-green text-base text-black/[.85]"}>
                    Сохранить
                </button>
            </div>
            {admins.length && (
                <div className={`col-span-12 flex flex-col ${compact && 'h-[338px]'}`}>
                    <p className={"font-bold text-unix-black text-lg"}>Текущие администраторы</p>
                    <div className={`flex flex-row flex-wrap ${compact && 'overflow-y-scroll'}`}>
                        {admins.map(admin => (
                            <Chip key={admin.id} className="mr-3 mb-4" label={`${admin.id}. ${admin.firstname} ${admin.lastname} (${admin.email})`} />
                        ))}
                    </div>
                    {/* <List className="text-unix-black">
                        {admins.map(admin => (
                            <ListItem>
                                {admin.id}. {admin.firstname} {admin.lastname} ({admin.email})
                            </ListItem>
                        ))}
                    </List> */}
                </div>
            )}
        </div>
    )
}

export default SelectAdminsComponent
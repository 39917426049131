import { useGlobalMiscState } from "../../../stores/misc"
import tickWhite from '../../../assets/tick-white.svg'
import tickGreen from '../../pages/statistics/green_tick.svg';
import redCross from '../../pages/statistics/red_cross.svg';
import { additionalQuizClasses } from "../../../use-cases/constants";

const AdditionalQuiz = ({ lessonId, currentAdditionalQuestion, currentAdditionalAnswer, setCurrentAdditionalAnswer, show, mode = "video" }) => {
    const miscState = useGlobalMiscState()
    const additionalQuestionsShow = !!miscState.get('additionalQuestionsShow')
    const additionalQuizzesAnswered = miscState.getOrEmpty('additionalQuizzesAnswered')
    const showQuiz = show && additionalQuestionsShow
    const additionalClass = additionalQuizClasses[mode]

    const hadAdditionalQuestionBeenAnswered = (id) => lessonId in additionalQuizzesAnswered && additionalQuizzesAnswered[lessonId].includes(id)

    const submitAdditionalQuestion = () => {
        if (hadAdditionalQuestionBeenAnswered(currentAdditionalQuestion?.id)) {
            miscState.set('additionalQuestionsShow', false)
            const video = document.querySelector('video')
            video?.play()
        } else {
            if (lessonId in additionalQuizzesAnswered) {
                miscState.mergeChild('additionalQuizzesAnswered', lessonId, [currentAdditionalQuestion?.id])
            } else {
                miscState.merge('additionalQuizzesAnswered', {
                    [lessonId]: [currentAdditionalQuestion?.id]
                })
            }
        }
    }

    return (
        <div className={`${additionalClass} ${showQuiz ? 'flex' : 'hidden'}`}>
            <div className="bg-white rounded-xl p-6 flex flex-col w-full xl:w-[640px] items-start additional-quiz-modal">
                <p className="font-medium text-[32px] text-unix-text-black break-all">
                    {currentAdditionalQuestion?.questionText}
                </p>
                <p className="text-[15px] text-unix-gray">
                    Просмотр продолжится после выбора ответа
                </p>
                <div className="flex flex-col mt-5 w-full">
                    {currentAdditionalQuestion?.additionalAnswers?.map(a => (
                        <div key={a.id} className={`${a.id === currentAdditionalAnswer.id ? 'bg-[#F1EFE7]' : 'bg-[#F7F7FB]'} ${hadAdditionalQuestionBeenAnswered(currentAdditionalQuestion?.id) ? `${a.isCorrect ? 'border-2 border-[#3BBC30]' : !a.isCorrect && a.id === currentAdditionalAnswer.id ? 'border-2 border-[#F12D2D]' : ''}` : ''} p-6 rounded-lg mb-2 flex flex-row items-center cursor-pointer`} onClick={() => setCurrentAdditionalAnswer(a)}>
                            <input className={`${hadAdditionalQuestionBeenAnswered(currentAdditionalQuestion?.id) ? 'text-[#AFB7CA]' : ''}`} type="radio" checked={a.id === currentAdditionalAnswer.id} />
                            <p className="text-unix-text-black text-[15px] ml-4 break-all">
                                {a.answerText}
                            </p>
                            {hadAdditionalQuestionBeenAnswered(currentAdditionalQuestion?.id) && a.isCorrect && (
                                <img
                                    className="w-6 h-6 ml-4"
                                    src={tickGreen}
                                />
                            )}
                            {hadAdditionalQuestionBeenAnswered(currentAdditionalQuestion?.id) && !a.isCorrect && a.id === currentAdditionalAnswer.id && (
                                <img
                                    className="w-6 h-6 ml-4"
                                    src={redCross}
                                />
                            )}
                        </div>
                    ))}
                </div>
                <button className="mt-6 bg-[#0068FF] rounded-lg py-3 px-8 flex flex-row items-center" onClick={submitAdditionalQuestion}>
                    <img src={tickWhite} />
                    <p className="ml-2 text-white font-semibold text-[15px]">
                        {hadAdditionalQuestionBeenAnswered(currentAdditionalQuestion?.id) ? 'Далее' : 'Ответить'}
                    </p>
                </button>
            </div>
        </div>
    )
}

export default AdditionalQuiz
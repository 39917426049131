import { userApi } from '../api'
import { accessGlobalUserState } from '../stores/user'
import { showSnackbar } from './snackbar'

const signIn = async (login, password) => {
  const data = {}
  try {
    const reqBody = {
      login,
      password
    }
    const response = await userApi.signIn(reqBody)
    if (response.data.token) {
      accessGlobalUserState().set(response.data)
      data.success = true
    }
  } catch (e) {
    console.log(e)
    data.success = false
    console.log(e)
    data.error = e.response?.data?.message?.length
      ? e.response.data.message[0]
      : `${e.code}; ${e.message}; ${e.name}`
  }
  return data
}

const addRoomDeadline = async (topicIds, roomIds, deadline) => {
  const data = {}
  try {
    const reqBody = {
      topicIds: [topicIds],
      roomIds: [roomIds],
      deadline
    }
    const response = await userApi.addRoomDeadline(reqBody)
    if (response.data) {
      data.success = true
    }
  } catch (e) {
    console.log(e)
    data.success = false
    console.log(e)
    data.error = e.response?.data?.message?.length
      ? e.response.data.message[0]
      : `${e.code}; ${e.message}; ${e.name}`
  }
  return data
}

const updateProfile = async body => {
  const data = {}
  try {
    const response = await userApi.updateProfile(body)
    if (response.data.id) {
      data.user = response.data
      showSnackbar()
      data.success = true
    }
  } catch (e) {
    console.error(e)
    data.success = false
  }
  return data
}

const resetPasswordEmail = async email => {
  const data = {}
  try {
    const response = await userApi.forgetPassword({
      email
    })
    if (response.data.code) {
      showSnackbar(response.data.code)
      data.success = true
    } else {
      showSnackbar(
        'Произошла ошибка. Возможно, пользователь с таким e-mail не найден. Пожалуйста, попробуйте еще раз.',
        'error'
      )
      data.success = false
    }
  } catch (e) {
    showSnackbar(
      e.response?.data?.message?.length &&
        e.response?.data?.message instanceof Array
        ? e.response?.data?.message[0]
        : 'Произошла ошибка. Возможно, пользователь с таким e-mail не найден. Пожалуйста, попробуйте еще раз.',
      'error'
    )
    data.success = false
  }
  return data
}

const userTeacherSearch = async (courseId, name, field) => {
  const data = {}
  try {
    const response = await userApi.teacherSearch(courseId, field, name)
    if (response.data?.length) {
      data.users = response.data
      data.success = true
    }
  } catch (e) {
    console.error(e)
    data.success = false
  }
  return data
}

const roomSearch = async (roomId, name) => {
  const data = {}
  try {
    const response = await userApi.roomSearch(roomId, name)
    console.log('response fial ', response)
    if (response.data) {
      console.log('resp ', response)
      data.users = response.data
      data.success = true
    }
  } catch (e) {
    console.error(`Room search error for roomId ${roomId} and name ${name}:`, e)
    if (e.response) {
      console.error('Response data:', e.response.data)
      console.error('Response status:', e.response.status)
      console.error('Response headers:', e.response.headers)
    }
    data.success = false
  }
  return data
}
const userSearch = async name => {
  const data = {}
  try {
    const response = await userApi.search(name)
    if (response.data.users) {
      data.users = response.data.users
      data.success = true
    }
  } catch (e) {
    console.error(e)
    data.success = false
  }
  return data
}

const setOrgAdmin = async body => {
  const data = {}
  try {
    const response = await userApi.orgAdmin(body)
    if (response.data) {
      showSnackbar()
      data.success = true
    }
  } catch (e) {
    console.error(e)
    data.success = false
  }
  return data
}

const getEngagementData = async () => {
  const data = {}
  try {
    const response = await userApi.getEngagementData()
    if (response.data) {
      data.success = true
      data.engagementData = response.data
    }
  } catch (e) {
    console.error(e)
    data.success = false
  }
  return data
}

const logOut = (admin = false) => {
  accessGlobalUserState().set({})
  window.location = `/${admin ? 'admin' : 'platform'}/login`
}

const deleteStudent = async (courseId, body) => {
  console.log('body ', body)
  const data = {}
  try {
    const response = await userApi.deleteStudent(courseId, body)
    console.log('response ', response)
    if (response.data) {
      showSnackbar()
      data.success = true
    }
  } catch (e) {
    console.error(e)
    data.success = false
  }
  return data
}

const addModuleAdmin = async (moduleId, userId) => {
  const data = {}
  try {
    const response = await userApi.addModuleAdmin(moduleId, userId)
    console.log('Response:', response)
    if (response.data.id) {
      data.admin = response.data
      showSnackbar()
      data.success = true
    } else {
      data.success = false
    }
  } catch (e) {
    console.error('Error:', e)
    data.success = false
  }
  return data
}

const addCourseTeacher = async (moduleId, userId) => {
  const data = {}
  try {
    const response = await userApi.addCourseTeacher(moduleId, userId)
    console.log('Response:', response)
    if (response.data.id) {
      data.admin = response.data
      showSnackbar()
      data.success = true
    } else {
      data.success = false
    }
  } catch (e) {
    console.error('Error:', e)
    data.success = false
  }
  return data
}

export {
  signIn,
  userSearch,
  setOrgAdmin,
  logOut,
  updateProfile,
  resetPasswordEmail,
  userTeacherSearch,
  getEngagementData,
  roomSearch,
  deleteStudent,
  addModuleAdmin,
  addCourseTeacher,
  addRoomDeadline
}

import ru from "date-fns/locale/ru";
import { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { showSnackbar } from "../../../../actions/snackbar";
import { createTopic, updateTopicPost } from "../../../../actions/topic";
import crossDarkGray from '../../../../assets/cross-dark-gray.svg';
import tickWhite from '../../../../assets/tick-white.svg';
import { inputClass } from "../../../../use-cases/constants";
import { LanguageSelector } from "./LanguageSelector";
import { applyToForm, getCourseLocalizedKey } from "../../../../utils";
import { useGlobalMiscState } from "../../../../stores/misc";
registerLocale("ru", ru)

export const CreateTopicComponent = ({ course, order, type, onClose, triggerFetch, mode = "add", topic }) => {
    const miscState = useGlobalMiscState()
    const courseSelectedLang = miscState.get('courseSelectedLang')
    const isEdit = mode === "edit"
    const [form, setForm] = useState({})

    useEffect(() => {
        const formObject = {
            order,
            type,
            title: "",
            deadline: "",
            courseId: course?.id,
            moduleId: course?.moduleId,
            statusSession: 'current',
            isPublic: true
        }
        if ('id' in topic && isEdit) {
            formObject.id = topic.id
            formObject.title = topic.title
            formObject.deadline = topic.deadline
            formObject.type = topic.type
            formObject.order = topic.order
            formObject.isPublic = topic.isPublic
            applyToForm('RU', formObject, topic)
            applyToForm('KZ', formObject, topic)
        }
        setForm(formObject)
    }, [course?.id, order, topic?.id])

    const updateForm = (key, value) => () => {
        setForm(f => ({
            ...f,
            [key]: value
        }))
    }

    const updateFormNative = (key) => (e) => {
        const localizedKey = getCourseLocalizedKey(key, courseSelectedLang)
        setForm(f => ({
            ...f,
            [localizedKey]: e.target.value
        }))
    }

    const validateForm = () => {
        if (form.title.replace(/[0-9]/g, "").length === 0) {
            showSnackbar("Название не может состоят только из цифр", "error")
            return false
        }
        return true
    }

    const submit = async () => {
        const validated = validateForm()
        if (!validated) {
            return
        }
        let response
        form.isPublic = '' + form.isPublic
        if (form.id) {
            response = await updateTopicPost(form.id, form);
        } else {
            let finalForm = form
            if (type === "Minor") {
                const { deadline: _, ...rest } = form
                finalForm = rest
            }
            response = await createTopic(finalForm);
        }
        if (response.success) {
            onClose()
            triggerFetch()
        }

    }

    console.log(form)

    if ('courseId' in form) {
        return (
            <div className="flex flex-col">
                <div className="flex flex-row mb-4">
                    <LanguageSelector />
                </div>
                <div className="flex flex-row">
                    <div className="flex flex-col flex-[9]">
                        <p className="admin-form-label mb-2">
                            Название лекции {type}
                        </p>
                        <input
                            value={form[getCourseLocalizedKey('title', courseSelectedLang)] || ""}
                            onChange={updateFormNative('title')}
                            className="admin-form-input"
                        />
                    </div>
                    {type === "Major" && (
                        <div className="flex flex-col flex-[3] pl-2">
                            <p className="admin-form-label mb-2">
                                Дедлайн
                            </p>
                            <DatePicker
                                className={inputClass}
                                showIcon
                                dateFormat="d MMM yyyy"
                                wrapperClassName="topic-deadline-datepicker"
                                selected={form.deadline instanceof Date || form.deadline?.length === 0 ? form.deadline : new Date(form.deadline)}
                                locale="ru"
                                onChange={(date) => updateForm('deadline', date)()}
                            />
                        </div>
                    )}
                    <div className="flex flex-col items-start flex-[1] pl-4">
                        <p className="admin-form-label mb-6">
                            Публичный
                        </p>
                        <input
                            type="checkbox"
                            checked={form.isPublic}
                            onChange={updateForm('isPublic', !form.isPublic)}
                        />
                    </div>
                </div>
                <div className="flex flex-row justify-center items-center mt-6">
                    <button
                        className="flex-1 unix-button bg-white border border-unix-gray flex flex-row items-center justify-center mr-2.5"
                        onClick={onClose}
                    >
                        <img src={crossDarkGray} />
                        <span className="text-unix-gray font-semibold text-[15px] ml-2">Отмена</span>
                    </button>
                    <button
                        className="flex-1 unix-button bg-[#3BBC30] flex flex-row items-center justify-center"
                        onClick={submit}
                    >
                        <img src={tickWhite} />
                        <span className="text-white font-semibold text-[15px] ml-2">Сохранить</span>
                    </button>
                </div>
            </div>
        )
    }
}
import { getOrganizations } from "../../../actions/organization";
import { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import "react-multi-carousel/lib/styles.css";

export const StatisticsRoot = () => {

    const [organizations, setOrganizations] = useState([])
    const getOrgs = async () => {
        const response = await getOrganizations()
        if (response.success) {
            setOrganizations(response.list)
        }
    }
    useEffect(() => {
        getOrgs()
    }, [])

    return (
        <div className="grid grid-cols-12 mt-1 h-screen bg-[#F0F3FA] px-[16px] py-[24px] rounded-t-[12px] ml-2">
            <div className="mt-4 px-2 grid grid-cols-12 col-span-12">
                <div className="col-span-12 flex flex-col gap-5 text-black">
                    <div className={"col-span-12 mt-12"}>
                        <p className={"platform-black font-bold text-[22px] flex flex-row items-center"}>
                            <span className="text-black">
                                Статистика по Организации KBTU
                            </span>
                        </p>
                    </div>
                    <table className="orgs-table bg-white rounded-[12px] text-primary-black w-full table-auto">
                        <thead>
                            <tr className="text-left">
                                <th>Организация</th>
                                <th>Описание</th>
                            </tr>
                        </thead>
                        <tbody>
                            {organizations.map(org => (
                                <tr key={org.id} className={"py-2"}>
                                    <td>
                                        <Link className={"flex flex-row"} to={`/admin/statistics/organization/${org.id}`}>
                                            <div className="flex flex-row">
                                                <span className={"m-2.5"}>{org.title}</span>
                                            </div>
                                        </Link>
                                    </td>
                                    <td>{org.description}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

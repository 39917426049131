import { hookstate, useHookstate } from '@hookstate/core';

const store = hookstate({
    text: '',
    open: false,
    severity: 'success',
    duration: 3000
})

const wrapState = (s) => ({
    setText: (text) => s.text.set(text),
    setOpen: (newVal) => s.open.set(newVal),
    setSeverity: (severity) => s.severity.set(severity),
    get: () => s.value,
    set: (newVal) => s.set(newVal)
})


export const accessGlobalSnackBarState = () => wrapState(store)
export const useGlobalSnackBarState = () => wrapState(useHookstate(store))
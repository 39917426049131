import { useRef, useState } from 'react'
import { useOutsideClick } from '../../../hooks/ui'
import selectArrow from './select_arrow.svg'

const UnixSelect = ({
    selectedValue,
    options,
    optionLabelKey,
    customOptionLabelGet,
    label,
    additionalClassName = '',
    wrapperClassName = '',
    setValue = () => { },
    disabled = false
}) => {
    const [open, setOpen] = useState(false)
    const wrapperRef = useRef(null)
    useOutsideClick(wrapperRef, () => setOpen(false))

    const toggleOpen = () => {
        setOpen(!open)
    }

    const onSelect = (option) => () => {
        console.log('onSelect')
        setValue(option)
    }

    return (
        <div className={`relative ${wrapperClassName}`}>
            <div ref={wrapperRef}>
                <div
                    className={`cursor-pointer py-[15px] px-4 rounded-lg bg-[#FFFFFF] text-[#1A1A1A] text-[15px] font-medium pr-13 ${additionalClassName}`}
                    {...(!disabled ? { onClick: toggleOpen } : {})}
                >
                    <span>{selectedValue ? customOptionLabelGet ? customOptionLabelGet(selectedValue) : selectedValue?.[optionLabelKey] : 'Выберите...'}</span>
                </div>
                <p
                    className="cursor-pointer absolute left-4 top-[9px] text-[#AFB7CA] text-xs font-medium"
                    onClick={toggleOpen}
                >
                    {label}
                </p>
                {!disabled && (
                    <img
                        src={selectArrow}
                        className="cursor-pointer absolute right-3 top-[11px]"
                        onClick={toggleOpen}
                    />
                )}
            </div>
            {open && (
                <div className="absolute z-[50] top-[54px] w-full bg-white flex flex-col text-unix-black max-h-[50vh] overflow-y-auto">
                    {options?.map((o, i) => (
                        <div key={o} className='hover:bg-[#F0F3FA] px-4 py-2 cursor-pointer' onClick={onSelect(o)}>
                            <span>
                                {customOptionLabelGet ? customOptionLabelGet(o) : o?.[optionLabelKey]}
                            </span>
                            {i !== options?.length - 1 && (
                                <hr className='relative top-[7px]' />
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default UnixSelect
import { useEffect, useState } from "react"
import { simpleGetter } from "../actions/common"
import { userApi } from "../api"
import { getCertificates, getImage } from "../actions/profile"

const useTeacherNotifications = () => {
    const [data, setData] = useState([])

    const fetchData = async () => {
        const response = await simpleGetter(userApi.teacherNotifications)
        if (response?.length) {
            setData(response)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return data
}

const useNotifications = () => {
    const [data, setData] = useState([])

    const fetchData = async () => {
        const response = await simpleGetter(userApi.notifications)
        if (response?.notifications?.length) {
            setData(response.notifications)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return data
}

const useCertificate = (id) => {
    const [data, setData] = useState({})

    const fetchData = async () => {
        const response = await getCertificates()
        if (response.success) {
            console.log(response.certificates)
            const found = response.certificates.find((c) => c.certificate?.id === id)
            if (found) {
                setData(found)
            }
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return data
}

const useProfileImage = () => {
    const [data, setData] = useState({})

    const fetchData = async () => {
        const response = await getImage()
        if (response.success) {
            setData(response.data.filePath)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return data
}

export {
    useTeacherNotifications,
    useNotifications,
    useCertificate,
    useProfileImage
}
import { none, useHookstate } from "@hookstate/core"
import { Button, Checkbox, Divider, FormControlLabel, Radio, TextField } from "@mui/material"
import React, { useEffect } from "react"

const questionTemplate = {
    questionText: "",
    examAnswers: []
}

const answerTemplate = {
    answerText: "",
    isCorrect: false
}

const Exam = React.memo(({ type, triggerUpdate, examQuestions, update = () => { } }) => {
    const state = useHookstate(examQuestions)
    const isEdit = type === "edit"
    const addQuestion = () => {
        state.merge([JSON.parse(JSON.stringify(questionTemplate))])
    }
    const updateQuestion = (idx, key, value) => {
        state[idx][key].set(value)
    }
    const updateAnswer = (idx, answerIdx, answerKey, value) => {
        state[idx].examAnswers[answerIdx][answerKey].set(value)
    }
    const updateAnswerIsCorrect = (idx, answerIdx) => {
        state[idx].examAnswers.set(answers => answers.map((a, i) => ({
            ...a,
            isCorrect: i === answerIdx ? !a.isCorrect : false
        })))
    }
    const addAnswer = (idx) => {
        console.log(answerTemplate)
        state[idx].examAnswers.merge([{ ...answerTemplate }])
    }
    const removeQuestion = (idx) => {
        state[idx].set(none)
    }
    const removeAnswer = (idx, answerIdx) => {
        state[idx].examAnswers[answerIdx].set(none)
    }
    const renderQuestionHeader = (question, idx) => {
        if (isEdit) {
            return (
                <>
                    <TextField variant="filled" sx={{ flexGrow: 1, marginRight: '2rem' }} value={question.questionText} onChange={e => updateQuestion(idx, 'questionText', e.target.value)} label="Вопрос" />
                    <Button onClick={() => addAnswer(idx)} sx={{ marginRight: '2rem' }} variant="contained" color="success">
                        Добавить вариант ответа
                    </Button>
                    <Button variant="contained" color="error" onClick={() => removeQuestion(idx)}>
                        Удалить вопрос
                    </Button>
                </>
            )
        }
        return (
            <p className="text-lg font-semibold">{idx + 1}. {question.questionText}</p>
        )
    }
    const renderQuestionAnswer = (answer, answerIdx, idx) => {
        if (isEdit) {
            return (
                <>
                    <TextField variant="filled" sx={{ flexGrow: 1 }} value={answer.answerText} onChange={e => updateAnswer(idx, answerIdx, 'answerText', e.target.value)} label="Вариант ответа" />
                    <FormControlLabel sx={{ ml: '2rem' }} onChange={() => updateAnswerIsCorrect(idx, answerIdx)} checked={answer.isCorrect} control={<Checkbox />} label="Правильный ответ" />
                    <Button variant="contained" color="error" onClick={() => removeAnswer(idx, answerIdx)}>
                        Удалить вариант ответа
                    </Button>
                </>
            )
        }
        return (
            <FormControlLabel value={answer.id} label={answer.answerText} control={<Radio />} />
        )
    }
    useEffect(() => {
        update([...state.get()])
    }, [triggerUpdate])

    return (
        <div className="flex flex-col items-start px-3 py-4">
            {isEdit && (
                <Button variant="contained" onClick={addQuestion}>
                    Добавить вопрос
                </Button>
            )}
            <div className="grid grid-cols-12 mt-4 w-full">
                {state.get().map((question, idx) => (
                    <>
                        {isEdit && (
                            <div className="col-span-12 mb-4">
                                <h4 className="font-semibold">Вопрос {idx + 1}</h4>
                            </div>
                        )}
                        <div className="col-span-10 flex flex-row">
                            {renderQuestionHeader(question, idx)}
                        </div>
                        {question.examAnswers.map((answer, answerIdx) => (
                            <div key={answer} className="col-span-8 flex flex-row mt-4">
                                {renderQuestionAnswer(answer, answerIdx, idx)}

                            </div>
                        ))}
                        <Divider className="col-span-12" sx={{ marginTop: '2rem', marginBottom: '2rem' }} />
                    </>
                ))}
            </div>
        </div>
    )
})

Exam.displayName = 'Exam'

export default Exam
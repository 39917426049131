import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { courseDelete, getCourse } from "../../../actions/course";
import { getModule, moduleDelete } from "../../../actions/module";
import { getOrganization } from "../../../actions/organization";
import { topicDelete } from "../../../actions/topic";
import { CreateOrganizationComponent } from "../orgs/CreateOrganizationComponent";
import SelectAdminsComponent from "../orgs/SelectAdminsComponent";
import closeIcon from './Close icon.svg';
import add from './add.svg';
import closedThree from './closed-three.svg';
import expandedThree from './expanded-three.svg';
import house from './house.svg';
import search from './search.svg';
import share from './share.svg';

export const Modules = () => {
    const [searchParams] = useSearchParams();
    const organizationId = searchParams.get('organizationId');
    const [organization, setOrganization] = useState({});
    const [admins, setAdmins] = useState([]);
    const [moduleInfo, setModuleInfo] = useState({});
    const [courseInfo, setCourseInfo] = useState({});
    const [moduleExpanded, setModuleExpanded] = useState(-1);
    const [courseExpanded, setCourseExpanded] = useState([]);
    const [coursesSelected, setCoursesSelected] = useState([]);
    const [topicsSelected, setTopicsSelected] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteType, setDeleteType] = useState(null); // 'module', 'course', or 'topic'
    const [deleteIds, setDeleteIds] = useState({});

    const openDeleteModal = (type, ids) => {
        setDeleteType(type);
        setDeleteIds(ids);
        setIsModalOpen(true);
    };

    const handleDelete = async () => {
        if (deleteType === 'module') {
            await moduleDelete(deleteIds.moduleId);
            fetchOrganization();
        } else if (deleteType === 'course') {
            await courseDelete(deleteIds.courseId, deleteIds.moduleId);
            getModuleInfo(deleteIds.moduleId);
        } else if (deleteType === 'topic') {
            await topicDelete(deleteIds.topicId, deleteIds.courseId);
            getCourseInfo(deleteIds.courseId);
        }
        setIsModalOpen(false);
    };

    const expand = (id) => {
        setModuleExpanded(e => e === id ? -1 : id);
    };
    const expandCourse = (id) => {
        setCourseExpanded(ce => ce.includes(id) ? ce.filter(i => i !== id) : [...ce, id]);
    };
    const getModuleInfo = async (id) => {
        const response = await getModule(id);
        if (response.success) {
            setModuleInfo(i => ({
                ...i,
                [id]: response.module
            }));
        }
    };
    const getCourseInfo = async (id) => {
        const response = await getCourse(id);
        if (response.success) {
            setCourseInfo(i => ({
                ...i,
                [id]: response.course
            }));
        }
    };
    useEffect(() => {
        if (moduleExpanded !== -1 && !moduleInfo[moduleExpanded]) {
            getModuleInfo(moduleExpanded);
        }
    }, [moduleExpanded]);
    useEffect(() => {
        for (let id of courseExpanded.filter(id => !courseInfo[id])) {
            getCourseInfo(id);
        }
    }, [courseExpanded]);
    const fetchOrganization = async () => {
        const response = await getOrganization(organizationId);
        if (response.success) {
            setOrganization(response.organization);
            setAdmins(response.admins);
        }
    };
    useEffect(() => {
        fetchOrganization();
    }, []);

    const modules = organization.modules || [];

    return (
        <div>
            <div className="ml-4 mt-8 mb-4">
                <img src={search} alt="search" />
            </div>
            <div className={"grid grid-cols-6"}>
                <div className="col-span-6 pl-5 mb-7">
                    <p className={"text-[#2B2F38] text-sm flex flex-row items-center"}>
                        <Link to={`/admin`} className="mr-[7px]">
                            <img src={house} alt="house" />
                        </Link>
                        /
                        {" "}<Link className="mx-[7px]" to={`/admin/orgs/${organizationId}`}>Организация</Link>
                        /
                        {" "}<span className="mx-[7px]">Модули</span>
                    </p>
                </div>
                <div className="col-span-6 sm:col-span-3">
                    <CreateOrganizationComponent organization={organization} compact />
                </div>
                <div className="col-span-6 sm:col-span-3">
                    <SelectAdminsComponent
                        organizationId={organizationId}
                        fetchOrganization={fetchOrganization}
                        admins={admins}
                        compact
                    />
                </div>
                {modules.map(m => (
                    <div className="col-span-6 sm:col-span-3" key={m.id}>
                        <div className="flex flex-col mt-3">
                            <div className="unix-card mx-5 mb-3 pl-8 pr-6 py-[30px]">
                                <div className="flex flex-row justify-between cursor-pointer" onClick={() => expand(m.id)}>
                                    <div className="flex flex-row">
                                        <img className="w-16 h-16 rounded-2xl object-cover" src={m.imageLink} alt="module" />
                                        <div className="flex flex-col ml-5">
                                            <p className="font-bold text-black text-lg">
                                                {m.title}
                                            </p>
                                            <p className="text-[#202020] text-sm font-medium opacity-80 mt-1">
                                                {organization.label}
                                            </p>
                                        </div>
                                    </div>
                                    <img src={m.id === moduleExpanded ? expandedThree : closedThree} alt="expand" />
                                </div>
                                {m.id === moduleExpanded && (
                                    <div>
                                        <div className="flex justify-between mt-8">
                                            <div>
                                                <div className={"py-2 px-8 border border-solid bg-[#f1f2f4] border-[#f1f2f4] rounded-md flex flex-row items-center"}>
                                                    <span className={"text-xs text-[#acafb2] font-bold uppercase"}>Выбрать прогресс</span>
                                                </div>
                                            </div>
                                            <div>
                                                <button className={"py-2 px-8 border border-solid border-[#FF6C65] rounded-md flex flex-row items-center"} onClick={() => openDeleteModal('module', { moduleId: m.id })}>
                                                    <span className={"text-xs text-[#D70000]"}>Удалить</span>
                                                </button>
                                            </div>
                                        </div>
                                        <p className={"text-[#202020] text-sm font-medium opacity-80 mt-5 mb-10"}>
                                            Курсы:
                                        </p>
                                        <div className="flex flex-col">
                                            {moduleInfo[m.id]?.courses.map(course => (
                                                <div className="flex flex-col" key={course.id}>
                                                    <div className="flex flex-row cursor-pointer py-2 px-4 items-center mb-5" onClick={() => expandCourse(course.id)}>
                                                        <input className="rounded-[7px] checked-module" type="checkbox" checked={coursesSelected.includes(course.id)} onClick={(e) => { e.stopPropagation(); setCoursesSelected(cs => cs.includes(course.id) ? cs.filter(c => c !== course.id) : [...cs, course.id]) }} />
                                                        <div className="input-bg"></div>
                                                        <img className="ml-3 w-[42px] h-[42px] rounded-2xl object-cover" src={course.imageLink} alt="course" />
                                                        <div className={`px-3 py-2.5 ml-5 rounded-[42px] ${courseExpanded.includes(course.id) ? 'course-expanded-title' : ''}`}>
                                                            <span className="text-[13px] text-black opacity-[.85]">
                                                                {course.title}
                                                            </span>
                                                        </div>
                                                        {coursesSelected.includes(course.id) ? (
                                                            <div className="flex p-2 ml-2 rounded-[10px]">
                                                                <img className="mr-2" src={closeIcon} height="12px" width="12px" alt="close" />
                                                                <span className="ml-1 text-[13px] font-medium text-[#D70000] cursor-pointer" onClick={(e) => { e.stopPropagation(); openDeleteModal('course', { courseId: course.id, moduleId: m.id }) }}>
                                                                    Удалить
                                                                </span>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                    {courseExpanded.includes(course.id) && courseInfo[course.id] ? (
                                                        <div className="flex flex-col ml-10">
                                                            {courseInfo[course.id].topics.map(topic => (
                                                                <div className="flex flex-row py-5 items-center" key={topic.id}>
                                                                    <img src={share} alt="share" />
                                                                    <input className="ml-5 rounded-[7px] checked-module" type="checkbox" checked={topicsSelected.includes(topic.id)} onClick={(e) => { e.stopPropagation(); setTopicsSelected(ts => ts.includes(topic.id) ? ts.filter(c => c !== topic.id) : [...ts, topic.id]) }} />
                                                                    <span className="ml-7 text-[13px] text-black opacity-[.85]">
                                                                        {topic.title}
                                                                    </span>
                                                                    {topicsSelected.includes(topic.id) ? (
                                                                        <div className="ml-2 flex p-2 rounded-[10px]">
                                                                            <img className="mr-2" src={closeIcon} height="12px" width="12px" alt="close" />
                                                                            <span className="ml-1 font-medium text-[13px] text-[#D70000] cursor-pointer" onClick={(e) => { e.stopPropagation(); openDeleteModal('topic', { topicId: topic.id, courseId: course.id }) }}>
                                                                                Удалить
                                                                            </span>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
                <div className="col-span-6 sm:col-span-3">
                    <div className="flex flex-col mt-3">
                        <a className="unix-card mx-5 mb-3 pl-8 pr-6 py-[34px] flex flex-row items-center" href={`/admin/modules/create?organizationId=${organizationId}`}>
                            <img src={add} alt="add" />
                            <p className="text-[#202020] font-medium ml-5">Создать модуль</p>
                        </a>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <h4 className="text-black">Вы уверены, что хотите удалить этот элемент?</h4>
                        <div className="flex justify-end mt-4">
                            <button className="mr-2 px-4 py-2 rounded text-white bg-red-500" onClick={handleDelete}>Удалить</button>
                            <button className="px-4 py-2 rounded text-black border" onClick={() => setIsModalOpen(false)}>Отмена</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

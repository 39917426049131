import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTeacherCourses } from "../../../hooks/courses";

const CourseInfoRoot = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const modules = useTeacherCourses();

  useEffect(() => {
    if (!courseId && modules.length > 0) {
      const firstCourse = modules[0].courses[0];
      if (firstCourse) {
        navigate(`/admin/course/${firstCourse.id}`);
      }
    }
  }, [courseId, modules, navigate]);


  return (
    <div>
      <div className="p-5">

      </div>
    </div>
  );
};

export default CourseInfoRoot;

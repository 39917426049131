import ru from "date-fns/locale/ru";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CreateModuleComponent } from "./CreteModuleComponent";

registerLocale("ru", ru)

export const CreateModule = () => {
    return (
        <div className={"grid grid-cols-12"}>
            <div className={"col-span-12"}>
                <CreateModuleComponent />
            </div>
        </div>
    )
}
import { useNavigate } from "react-router-dom";
import { useTeacherCourses } from "../../../hooks/courses";

export const TeacherCourses = () => {
    const modules = useTeacherCourses()
    const navigate = useNavigate()
    const goToCourse = (id) => {
        navigate(`/admin/teacher/courses/${id}`)
    }
    console.log(modules)
    return (
        <div className={"grid grid-cols-12"}>
            <div className={"col-span-12"}>
                <div className="flex flex-col mt-3">
                    {modules.map(m => (
                        <div key={m.id} className="unix-card mx-5 mb-3 pl-8 pr-6 pt-7 pb-16">
                            <div className="flex flex-row">
                                <div className="flex flex-col">
                                    <p className="font-bold text-black text-lg">
                                        {m.title}
                                    </p>
                                    <p className="text-[#202020] text-sm font-medium opacity-80 mt-1">
                                        {m.description}
                                    </p>
                                </div>
                            </div>
                            {m.courses?.map(c => (
                                <div key={c.id} className="unix-card border-solid border-gray border  m-3 p-3 cursor-pointer" onClick={() => goToCourse(c.id)}>
                                    <div className="flex flex-row">
                                        <img className="w-24 h-24 mr-8 object-cover" src={c.imageLink} />
                                        <div className="flex flex-col">
                                            <p className="font-bold text-black text-lg">
                                                {c.title}
                                            </p>
                                            <p className="text-[#202020] text-sm font-medium opacity-80 mt-1">
                                                {c.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
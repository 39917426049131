import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Modal
} from "@mui/material";
import Plyr from "plyr";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getCourse } from "../../../actions/course";
import {
  getLesson,
  getQuiz,
  quizSubmit,
  videoWatched,
} from "../../../actions/lesson";
import { getModule } from "../../../actions/module";
import { getOrganization } from "../../../actions/organization";
import { showSnackbar } from "../../../actions/snackbar";
import { getTopic } from "../../../actions/topic";
import { useAdditionalQuiz } from "../../../hooks/courses";
import { getStudentHW, insertStudentHW, insertedHW } from "../../../hooks/homework";
import { useGlobalMiscState } from "../../../stores/misc";
import { useGlobalUserState } from "../../../stores/user";
import {
  additionalMaterialIcons,
  localize
} from "../../../use-cases/constants";
import {
  getFileSize,
  historyToQuestions,
  isAnswerCorrect,
  isVideoPaused,
  isVideoPlaying,
  quizQuestionClass,
  totalQuizStats,
} from "../../../utils";
import UnixRadio from "../../common/UI/Radio/UnixRadio";
import tickGreen from "../../pages/teacher/green_tick.svg";
import redCross from "../../pages/teacher/red_cross.svg";
import CourseNavigation from "../courses/CourseNavigation";
import CourseNavigationDetailed from "../courses/CourseNavigationDetailed";
import AdditionalQuiz from "./AdditionalQuiz";
import RateLesson from "./RateLesson";
import additionalDownload from "./additional_download.svg";
import additionalEye from "./additional_eye.svg";
import back from "./back.svg";
import deleteIon from "./deleteIcon.svg";
import insertIcon from "./inserIcon.svg";
import material from "./material.svg";
import next from "./next.svg";
import test from "./test.svg";
import whiteTick from "./whiteTick.svg";
const boxShadowStyle = "0px 0px 4px rgba(0, 0, 0, 0.1)";
const boxShadowStyle2 = "0px 2px 2px rgba(0, 0, 0, 0.1)";
const boxShadowStyle3 = "0px 0px 0px rgba(0, 0, 0, 0.1)";

export const PlatformLesson = () => {
  const { lessonId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [lesson, setLesson] = useState({});
  const [topic, setTopic] = useState(location.state?.topic || {});
  const [course, setCourse] = useState(location.state?.course || {});
  const [module, setModule] = useState(location.state?.module || {});
  const [organization, setOrganization] = useState(
    location.state?.organization || {}
  );
  const isCoursePage = location.pathname.includes("/courses/");
  const isLessonPage = location.pathname.includes("/lessons/");
  const [quizMeta, setQuizMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [quiz, setQuiz] = useState({});
  const [videoMeta, setVideoMeta] = useState({
    duration: 0,
    start: "",
    lastWatchedAtTheStart: 0,
    watchedSeconds: [],
  });
  const [selectedQuestion, setSelectedQuestion] = useState(undefined);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [videoEnded, setVideoEnded] = useState(false);
  const [expanded, setExpanded] = useState({});
  const expandedRef = useRef(null);
  const [hideLastWatched, setHideLastWatched] = useState(true);
  const [additionalExpanded, setAdditionalExpanded] = useState("");
  const userState = useGlobalUserState();
  const miscState = useGlobalMiscState();
  const lastWatchedTime =
    miscState.getOrEmpty("lastWatchedTime")[lesson.id] || 0;
  const additionalQuestionsShow = !!miscState.get("additionalQuestionsShow");
  const tabExits = miscState.getOrEmpty("tabExits");
  const user = userState.get()?.user || {};
  const [currentAdditionalQuestion, setCurrentAdditionalQuestion] = useState(
    {}
  );
  const [currentAdditionalAnswer, setCurrentAdditionalAnswer] = useState({});
  const [showQuizMessage, setShowQuizMessage] = useState(false);
  const additionalQuizzes = useAdditionalQuiz(lessonId);
  const [homework, setHomework] = useState(null);
  const [file, setFile] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [hwDetails, setHwDetails] = useState(null);
  const [timer, setTimer] = useState(0);
  const [cancelable, setCancelable] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

  useEffect(() => {
    expandedRef.current = additionalExpanded;
  }, [additionalExpanded]);

  const disableControls = (video) => {
    var supposedCurrentTime = 0;
    video.addEventListener("timeupdate", function () {
      if (!video.seeking) {
        supposedCurrentTime = video.currentTime;
      }
    });
    video.addEventListener("seeking", function () {
      var delta = video.currentTime - supposedCurrentTime;
      if (delta > 0.01 && supposedCurrentTime !== 0) {
        video.currentTime = supposedCurrentTime;
      }
    });
    video.addEventListener("ended", function () {
      supposedCurrentTime = 0;
    });
  };

  const updateLastWatched = (video) => (event) => {
    if (
      !(
        event.target?.currentTime === 0 &&
        miscState.getOrEmpty("lastWatchedTime")[lesson.id] !== 0
      ) &&
      miscState.getOrEmpty("lastWatchedTime")[lesson.id] !==
      Math.round(event.target?.currentTime || 0)
    ) {
      miscState.merge("lastWatchedTime", {
        [lesson.id]: Math.round(event.target?.currentTime || 0),
      });
    }
    mergeVideoMeta(
      "watchedSeconds",
      Math.round(event.target?.currentTime || 0)
    );
    const additionalQuizzesAnswered = miscState.getOrEmpty(
      "additionalQuizzesAnswered"
    );
    const question = additionalQuizzes.find(
      (q) => q.seconds === Math.round(event.target?.currentTime || 0)
    );
    if (
      !!question &&
      (!(lessonId in additionalQuizzesAnswered) ||
        (lessonId in additionalQuizzesAnswered &&
          !additionalQuizzesAnswered[lessonId].includes(question.id)))
    ) {
      setCurrentAdditionalQuestion(question);
      video.pause();
      miscState.set("additionalQuestionsShow", true);
      setTimeout(() => {
        if (miscState.get("additionalQuestionsShow")) {
          miscState.set("additionalQuestionsShow", false);
          video.currentTime = 0;
          showSnackbar("Вы не ответили на вопрос в течение 15 секунд!");
        }
      }, 15000);
    }
  };

  const addTabExit = () => {
    if (expandedRef.current === "quiz") {
      const addObj = {
        exitedAt: new Date().toLocaleString(),
      };
      if (!(lessonId in tabExits)) {
        miscState.merge("tabExits", {
          [lessonId]: [addObj],
        });
      } else {
        miscState.mergeChild("tabExits", lessonId, [addObj]);
      }
    }
  };

  const setVideoLastWatched = (last) => () => {
    setHideLastWatched(true);
    const video = document.querySelector("video");
    if (!!lastWatchedTime && last) {
      video.currentTime = lastWatchedTime;
      updateVideoMeta("lastWatchedAtTheStart", lastWatchedTime);
    }
    video.play();
  };

  const mergeVideoMeta = (key, value) => {
    setVideoMeta((v) => ({
      ...v,
      [key]: [...new Set([...v[key], value])],
    }));
  };

  const updateVideoMeta = (key, value) => {
    setVideoMeta({
      ...videoMeta,
      [key]: value,
    });
  };
  useEffect(() => {
    const fetchHomeworkData = async () => {
      const homeworkData = await getStudentHW(lessonId);
      setHomework(homeworkData.homework);
    };

    fetchHomeworkData();
  }, [lessonId]);

  useEffect(() => {
    const fetchInsertedHW = async () => {
      const id = lessonId;
      const result = await insertedHW(id);
      if (result.success) {
        setHwDetails(result.body);
      }
    };

    fetchInsertedHW();
  }, []);

  console.log('hw details ', hwDetails)

  useEffect(() => {
    if (lesson.id) {
      miscState.reset("tabExits", lesson.id);
      miscState.set("additionalQuestionsShow", false);
      if (lastWatchedTime) {
        setHideLastWatched(false);
      }
      const video = document.querySelector("video");
      // eslint-disable-next-line no-unused-vars
      const _ = new Plyr(video, {
        controls: [
          "play-large",
          "restart",
          "rewind",
          "play",
          "current-time",
          "duration",
          "volume",
          "fullscreen",
          ...(lesson.canSkip ? ["fast-forward"] : []),
        ],
      });
      const onTimeUpdate = updateLastWatched(video);
      if (!lesson.canSkip) {
        disableControls(video);
      }
      video.addEventListener("timeupdate", onTimeUpdate);
      video.onloadedmetadata = () => {
        updateVideoMeta("duration", Math.floor(video.duration));
      };
      document.addEventListener("visibilitychange", function () {
        if (document.hidden) {
          addTabExit();
          if (isVideoPlaying(video)) {
            video.pause();
            window.videoPausedByUs = true;
          }
          resetQuiz();
        } else if (
          !document.hidden &&
          isVideoPaused(video) &&
          window.videoPausedByUs
        ) {
          video.play();
          window.videoPausedByUs = false;
        }
      });
      return () => {
        video.removeEventListener("timeupdate", onTimeUpdate);
      };
    }
  }, [lesson.id]);

  useEffect(() => {
    if (lesson.additionalMaterial?.length) {
      getFileSize(lesson.additionalMaterial);
    }
  }, [lesson.additionalMaterial]);

  const fetchLesson = async (id) => {
    const response = await getLesson(id);
    if (response.success) {
      setLesson(response.lesson);
      if ("result" in response.lesson && "history" in response.lesson) {
        setSelectedQuestion(-1);
      }
    }
  };
  const fetchTopic = async (id) => {
    const response = await getTopic(id);
    if (response.success) {
      setTopic(response.topic);
    }
  };
  const fetchCourse = async (id) => {
    const response = await getCourse(id);
    if (response.success) {
      setCourse(response.course);
    }
  };
  const fetchModule = async (id) => {
    const response = await getModule(id);
    if (response.success) {
      setModule(response.module);
    }
  };
  const fetchOrganization = async (id) => {
    const response = await getOrganization(id);
    if (response.success) {
      setOrganization(response.organization);
    }
  };
  const fetchQuiz = async (id, unexpand = true) => {
    const response = await getQuiz(id);
    if (response.success) {
      setQuiz(response.quiz);
      if (response.quiz.questions?.length) {
        setSelectedQuestion(response.quiz.questions[0].id);
      }
      if (unexpand) {
        setExpanded((e) => ({
          ...e,
          quiz: {
            expanded: false,
            label: localize("test"),
          },
        }));
      }
    }
  };
  const goBack = () => {
    if (course.id) {
      navigate(`/platform/courses/${course.id}`);
    }
  };
  const handleExpandedChange = (key) => {
    setExpanded((e) => ({
      ...e,
      [key]: {
        ...e[key],
        expanded: !e[key].expanded,
      },
    }));
  };

  useEffect(() => {
    if (!lesson.id) {
      fetchLesson(lessonId);
    }
  }, [lessonId, lesson]);
  useEffect(() => {
    if (Object.keys(expanded).length === 0) {
      const obj = {
        video: {
          expanded: true,
          label: localize("video_lesson"),
        },
        ...(lesson.additionalMaterial && lesson.additionalMaterial.length
          ? {
            additional: {
              expanded: false,
              label: localize("materials"),
            },
          }
          : {}),
      };
      setExpanded((e) => ({
        ...e,
        ...obj,
      }));
    }
  }, [lesson, expanded]);
  useEffect(() => {
    if (lesson.id && !quiz.id) {
      fetchQuiz(lesson.id);
    }
  }, [lesson, quiz]);
  const resetQuiz = async () => {
    await fetchQuiz(lesson.id, false);
    setQuizMeta({});
  };
  useEffect(() => {
    if (lesson.topicId && !topic.lessons) {
      fetchTopic(lesson.topicId);
    }
  }, [lesson, topic]);
  useEffect(() => {
    if (topic.courseId && !course.id) {
      fetchCourse(topic.courseId);
    }
  }, [topic, course]);
  useEffect(() => {
    if (course.moduleId && !module.id) {
      fetchModule(course.moduleId);
    }
  }, [course]);
  useEffect(() => {
    if (module.organizationId && !organization.id) {
      fetchOrganization(module.organizationId);
    }
  }, [module]);

  const selectAnswer = (questionSelected, answer) => () => {
    const qObj = { ...quiz };
    const question = qObj.questions.find((q) => q.id === questionSelected.id);
    if (question) {
      if (!("userAnswerIds" in question)) {
        question.userAnswerIds = [];
      }
      if (question.userAnswerIds.includes(answer.id)) {
        question.userAnswerIds = question.userAnswerIds.filter(
          (i) => i !== answer.id
        );
      } else {
        if (question.isMultiple) {
          question.userAnswerIds.push(answer.id);
        } else {
          question.userAnswerIds = [answer.id];
        }
      }
    }
    setQuiz(qObj);
  };
  const submitQuiz = async (id, body) => {
    setLoading(true);
    const response = await quizSubmit(id, body);
    setLoading(false);
    if (response.meta) {
      setQuizMeta(response.meta);
      setSelectedQuestion(-1);
    }
    if (!course.lessonratings?.length && !response.meta?.necessaryResult) {
      setRatingModalOpen(true);
    }
  };
  const nextQuestion = () => {
    const pos = quiz.questions.map((q) => q.id).indexOf(selectedQuestion);
    if (pos === quiz.questions.length - 1) {
      if (!quiz.questions?.every((q) => !!q.userAnswerIds?.length)) {
        showSnackbar("Пожалуйста, заполните все вопросы", "error");
      } else {
        const body = {
          answers: quiz.questions.map((question) => ({
            questionId: question.id,
            allAnswersIds: question.answers.map((a) => a.id),
            userAnswersIds: question.userAnswerIds,
            isMultiple: question.isMultiple,
          })),
          tabSwitch: tabExits[lesson.id]?.length || 0,
        };
        submitQuiz(quiz.id, body);
      }
    } else {
      if (
        quiz.questions[pos]?.userAnswerIds?.length &&
        quiz.questions[pos + 1]
      ) {
        setSelectedQuestion(quiz.questions[pos + 1].id);
      }
    }
  };
  const onVideoPlay = () => {
    if (videoMeta.start === "") {
      updateVideoMeta("start", new Date().getTime());
    }
  };
  const onVideoPause = () => {
  };

  const onVideoEnded = async () => {
    miscState.reset("lastWatchedTime", lesson.id);
    const videoWatchedValue =
      Math.abs(
        videoMeta.duration -
        (videoMeta.watchedSeconds.length +
          Math.floor(videoMeta.lastWatchedAtTheStart))
      ) === 1
        ? videoMeta.duration
        : videoMeta.watchedSeconds.length +
        Math.floor(videoMeta.lastWatchedAtTheStart);
    const response = await videoWatched(lesson.id, {
      videoDuration: videoMeta.duration,
      videoWatched: videoWatchedValue,
    });
    if (response.success) {
      setVideoEnded(true);
    }
  };
  const resetVideo = async () => {
    setVideoEnded(false);
  };
  const goToNextLesson = () => {
    if (topic.lessons?.length) {
      const currentLessonIndex = topic.lessons
        .map((l) => l.id)
        .indexOf(lesson.id);
      if (currentLessonIndex !== -1) {
        if (currentLessonIndex !== topic.lessons.length - 1) {
          window.location = `/platform/lessons/${topic.lessons[currentLessonIndex + 1]?.id
            }`;
        } else {
          navigate(`/platform/courses/${course.id}`);
        }
      }
    }
  };

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "application/pdf",
    maxSize: 2 * 1024 * 1024 * 1024,
    multiple: false,
  });

  const downloadFile = () => {
    const url = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.href = url;
    a.download = file.name;
    a.click();
    URL.revokeObjectURL(url);
  };

  const deleteFile = () => {
    setFile(null);
  };


  const readFile = () => {
    const url = URL.createObjectURL(file);
    window.open(url, "_blank");
    URL.revokeObjectURL(url);
  };

  const handleSubmit = async () => {
    if (file) {
      setSubmitting(true);
      setCancelable(true);
      setTimer(60);

      const timeout = setTimeout(async () => {
        setCancelable(false);

        try {
          const body = {
            lessonId: lessonId,
            homeWorkFile: file,
          };

          const response = await insertStudentHW(body);
          if (response.success) {
            console.log('Homework submitted successfully:', response.body);
            setFile(null);
          } else {
            console.error('Failed to submit homework');
          }
        } catch (error) {
          console.error('Error submitting homework:', error);
        } finally {
          setSubmitting(false);
        }
      }, 60000);

      return () => clearTimeout(timeout);
    }
  };

  useEffect(() => {
    let interval;
    if (cancelable && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setCancelable(false);
    }

    return () => clearInterval(interval);
  }, [cancelable, timer]);

  const cancelSubmission = () => {
    if (cancelable) {
      setCancelable(false);
      setSubmitting(false);
      setTimer(0);
    }
  };




  const renderQuizResult = (quizMeta, lesson, quizStats) => {
    if ("amountRightAnswers" in quizMeta) {
      return (
        <div
          className={"p-10 w-full bg-[#ECF2F7] rounded-xl grid grid-cols-12"}
        >
          <div className="col-span-12 md:col-span-7 flex flex-col mr-4">
            <p className="text-black font-bold text-[22px] md:text-base">
              <span>
                {user.firstname || "Студент"} {user.lastname || ""}
              </span>
              <br />
              <span>Ваш результат менее 70%</span>
            </p>
            <p className="text-black text-base mt-5 md:w-[90%]">
              <span>
                Вы можете посмотреть вопросы, на которых допустили ошибку и
                пройти тест еще раз
              </span>
              <br />
              <span>Желаем вам удачи!</span>
            </p>
            <button
              className="rounded-md bg-[#E53B35] w-[60%] mb-4  mt-5 text-white py-4"
              onClick={resetQuiz}
            >
              Пройти заново
            </button>
          </div>
          <div className="col-span-12 md:col-span-5">
            <div className="w-full bg-white rounded-[25px]  p-5 md:p-5 flex flex-col items-center">
              <p className="font-bold text-[22px] text-black">
                {localize("your_result")}
              </p>
              <button
                className="w-14 h-14 rounded-full bg-[#E53B35] flex flex-col justify-center items-center mt-5"
                onClick={resetQuiz}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 20.75C10.0772 20.75 8.23311 19.9862 6.87348 18.6265C5.51384 17.2669 4.75 15.4228 4.75 13.5C4.75 11.5772 5.51384 9.73311 6.87348 8.37348C8.23311 7.01384 10.0772 6.25 12 6.25H14.5C14.6989 6.25 14.8897 6.32902 15.0303 6.46967C15.171 6.61032 15.25 6.80109 15.25 7C15.25 7.19891 15.171 7.38968 15.0303 7.53033C14.8897 7.67098 14.6989 7.75 14.5 7.75H12C10.8628 7.75 9.75105 8.08723 8.80547 8.71905C7.85989 9.35087 7.1229 10.2489 6.68769 11.2996C6.25249 12.3502 6.13862 13.5064 6.36048 14.6218C6.58235 15.7372 7.12998 16.7617 7.93414 17.5659C8.73829 18.37 9.76284 18.9177 10.8782 19.1395C11.9936 19.3614 13.1498 19.2475 14.2004 18.8123C15.2511 18.3771 16.1491 17.6401 16.781 16.6945C17.4128 15.7489 17.75 14.6372 17.75 13.5C17.75 13.3011 17.829 13.1103 17.9697 12.9697C18.1103 12.829 18.3011 12.75 18.5 12.75C18.6989 12.75 18.8897 12.829 19.0303 12.9697C19.171 13.1103 19.25 13.3011 19.25 13.5C19.2474 15.422 18.4827 17.2645 17.1236 18.6236C15.7645 19.9827 13.922 20.7474 12 20.75Z"
                    fill="white"
                  />
                  <path
                    d="M12.0002 10.75C11.9016 10.7504 11.804 10.7312 11.713 10.6934C11.6219 10.6557 11.5394 10.6001 11.4702 10.53C11.3297 10.3893 11.2508 10.1987 11.2508 9.99997C11.2508 9.80122 11.3297 9.6106 11.4702 9.46997L13.9402 6.99997L11.4702 4.52997C11.3965 4.46131 11.3374 4.37851 11.2964 4.28651C11.2554 4.19451 11.2333 4.0952 11.2316 3.99449C11.2298 3.89379 11.2483 3.79376 11.286 3.70037C11.3238 3.60698 11.3799 3.52215 11.4511 3.45093C11.5223 3.37971 11.6072 3.32357 11.7006 3.28585C11.7939 3.24813 11.894 3.2296 11.9947 3.23138C12.0954 3.23316 12.1947 3.2552 12.2867 3.29619C12.3787 3.33718 12.4615 3.39628 12.5302 3.46997L15.5302 6.46997C15.6706 6.6106 15.7495 6.80122 15.7495 6.99997C15.7495 7.19872 15.6706 7.38935 15.5302 7.52997L12.5302 10.53C12.4609 10.6001 12.3784 10.6557 12.2873 10.6934C12.1963 10.7312 12.0987 10.7504 12.0002 10.75Z"
                    fill="white"
                  />
                </svg>
              </button>
              <p className="mt-5 font-bold text-6xl text-black md:text-4xl lg:text-6xl">
                {quizStats.totalUserCorrectAnswers} /{" "}
                {quizStats.totalCorrectAnswers}
              </p>
            </div>
          </div>
        </div>
      );
    } else if (
      ("result" in quizMeta && !("amountRightAnswers" in quizMeta)) ||
      "result" in lesson
    ) {
      return (
        <div
          className={"ml-4 p-10 bg-[#ECF2F7] rounded-b-xl grid grid-cols-12"}
        >
          <div className="col-span-12 md:col-span-7 flex flex-col">
            <p className="text-black font-bold text-[22px]">
              <span>
                {user.firstname || "Студент"} {user.lastname || ""}
              </span>
              <br />
              <span>Вы успешно прошли тест!</span>
            </p>
            <p className="text-black text-base mt-5">
              <span>Вы можете перейти к следующему уроку</span>
              <br />
              <span>Желаем вам удачи!</span>
            </p>
            <button
              className="rounded-md bg-[#1E78E9] w-1/2 mt-5 text-white py-4"
              onClick={goToNextLesson}
            >
              Перейти на след урок
            </button>
            <p
              className="text-base text-black mt-8 cursor-pointer"
              onClick={() => navigate("/")}
            >
              Вернуться на главную
            </p>
          </div>
          <div className="col-span-12 md:col-span-5">
            <div className="w-full bg-white rounded-[25px] p-10 flex flex-col items-center">
              <p className="font-bold text-[22px] text-black">
                {localize("your_result")}
              </p>
              <button className="w-14 h-14 rounded-full bg-[#10C900] flex flex-col justify-center items-center mt-5">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.99992 18.25C8.89921 18.2466 8.80025 18.2227 8.70911 18.1797C8.61798 18.1367 8.53658 18.0756 8.46992 18L3.46992 13C3.37458 12.9382 3.29454 12.8556 3.23583 12.7583C3.17712 12.661 3.14127 12.5517 3.131 12.4385C3.12073 12.3254 3.1363 12.2114 3.17654 12.1051C3.21677 11.9989 3.28062 11.9031 3.36326 11.8252C3.44591 11.7472 3.54518 11.689 3.6536 11.6551C3.76201 11.6211 3.87673 11.6122 3.98909 11.629C4.10145 11.6458 4.20852 11.688 4.30221 11.7523C4.3959 11.8165 4.47376 11.9013 4.52992 12L8.99992 16.44L19.4699 6.00003C19.6109 5.90864 19.7784 5.86722 19.9457 5.88241C20.113 5.89759 20.2704 5.96851 20.3926 6.08379C20.5148 6.19907 20.5947 6.35203 20.6196 6.51817C20.6445 6.68431 20.6129 6.85399 20.5299 7.00003L9.52991 18C9.46325 18.0756 9.38185 18.1367 9.29072 18.1797C9.19958 18.2227 9.10062 18.2466 8.99992 18.25Z"
                    fill="white"
                  />
                </svg>
              </button>
              <p className="mt-5 font-bold text-6xl text-black">
                {quizStats.totalUserCorrectAnswers} /{" "}
                {quizStats.totalCorrectAnswers}
              </p>
            </div>
          </div>
        </div>
      );
    }
  };

  const handleRatingModalClose = () => setRatingModalOpen(false);

  const handleQuizClick = () => {
    setShowQuizMessage(false);
    setAdditionalExpanded("quiz");
  };

  {
    console.log("hw ", homework);
  }

  const renderLessonCard = (key) => {
    const poster = lesson.backgroundImage
      ? {
        poster: lesson.backgroundImage,
      }
      : {};
    switch (key) {
      case "video":
        {
          const minutes = Math.floor(lastWatchedTime / 60);
          let seconds = lastWatchedTime % 60;
          if (seconds < 10) {
            seconds = `0${seconds}`;
          }
          return (
            <>
              <div className="video-wrapper relative">
                <AdditionalQuiz
                  lessonId={lessonId}
                  currentAdditionalQuestion={currentAdditionalQuestion}
                  currentAdditionalAnswer={currentAdditionalAnswer}
                  setCurrentAdditionalAnswer={setCurrentAdditionalAnswer}
                  show={!isMobile}
                />
                <div
                  className={`absolute video-overlay w-full h-full flex-col items-center text-center justify-center ${videoEnded ? "flex" : "hidden"
                    }`}
                >
                  <p className="text-white text-2xl font-bold">
                    Вы завершили просмотр видеоурока
                  </p>
                  {quiz.id ? (
                    <button
                      className="bg-[#FFDD33] text-black font-bold rounded mt-3 px-2 py-1"
                      onClick={handleQuizClick}
                    >
                      Перейти к тесту
                    </button>
                  ) : (
                    <div>Hello</div>
                  )}
                  <button
                    className="bg-[#ECF2F7] text-black font-bold rounded mt-3 px-2 py-1"
                    onClick={resetVideo}
                  >
                    Посмотреть заново
                  </button>
                </div>
                <div
                  className={`absolute video-overlay w-full h-full flex-col items-center justify-center ${additionalQuestionsShow && !isMobile ? "flex" : "hidden"
                    }`}
                ></div>
                <div
                  className={`absolute video-overlay w-full h-full flex-col items-center text-center justify-center ${!!lastWatchedTime && !hideLastWatched ? "flex" : "hidden"
                    }`}
                >
                  <p className="text-white text-2xl font-bold">
                    Хотите продолжить просмотр с того места, на котором
                    остановились?
                  </p>
                  <button
                    className="bg-[#FFDD33] text-black font-bold rounded mt-3 px-2 py-1"
                    onClick={setVideoLastWatched(true)}
                  >
                    Продолжить с {minutes}:{seconds}
                  </button>
                  <button
                    className="bg-[#ECF2F7] text-black font-bold rounded mt-3 px-2 py-1"
                    onClick={setVideoLastWatched(false)}
                  >
                    Смотреть заново
                  </button>
                </div>
                {"videoLink" in lesson && (
                  <video
                    {...poster}
                    onEnded={onVideoEnded}
                    controlsList="nodownload"
                    onContextMenu={(e) => e.preventDefault()}
                    onPlay={onVideoPlay}
                    onPause={onVideoPause}
                    controls
                    id="video"
                    src={
                      lesson.videoLink?.includes("https://")
                        ? lesson.videoLink
                        : "https://" + lesson.videoLink
                    }
                  >
                    <source
                      src={
                        lesson.videoLink?.includes("https://")
                          ? lesson.videoLink
                          : "https://" + lesson.videoLink
                      }
                    />
                  </video>
                )}
              </div>
            </>
          );
        }
      case "additional_material":
        {
          const dotSplitted = lesson.additionalMaterial.split(".");
          if (dotSplitted.length > 1) {
            const extension = dotSplitted[dotSplitted.length - 1];
            const filePath = dotSplitted
              .slice(0, dotSplitted.length - 1)
              .join(".");
            const slashSplitted = filePath.split("/");
            const fileName = slashSplitted[slashSplitted.length - 1];
            const icon =
              additionalMaterialIcons.find((m) =>
                m.extensions.includes(extension)
              )?.icon ||
              additionalMaterialIcons[additionalMaterialIcons.length - 1].icon;
            return (
              <div
                className="py-10 px-4 grid grid-cols-12 ml-4"
                style={{ boxShadow: boxShadowStyle }}
              >
                <div className="col-span-12 md:col-span-3 border-[#F0F3FA] border rounded-lg p-6">
                  <div className="flex flex-row justify-between items-center">
                    <img src={icon} />
                    <div className="flex flex-row">
                      <a
                        className="cursor-pointer"
                        href={lesson.additionalMaterial}
                        target="_blank" rel="noreferrer"
                      >
                        <img src={additionalEye} />
                      </a>
                    </div>
                  </div>
                  <p className="mt-4 font-medium text-unix-text-black line-clamp-2">
                    {fileName}
                  </p>
                  <p className="text-[13px] font-semibold text-[#AFB7CA] mt-6">
                    {new Date(lesson.createdAt)
                      .toJSON()
                      .slice(0, 10)
                      .split("-")
                      .reverse()
                      .join("/")
                      .replaceAll("/", ".")}
                  </p>
                </div>
              </div>
            );
          }
        }
        break
      case "hw":
        {
          const { comment, deadline, link } = homework;
          const extension = link.split(".").pop();
          const fileName = link.split("/").pop().split(".")[0];
          const icon =
            additionalMaterialIcons.find((m) => m.extensions.includes(extension))
              ?.icon ||
            additionalMaterialIcons[additionalMaterialIcons.length - 1].icon;

          return (
            <div
              className="py-10 px-4 grid grid-cols-12 ml-4 max-[540px]:ml-0"
              style={{ boxShadow: boxShadowStyle }}
            >
              <div className="col-span-12">
                <div className="flex max-[550px]:flex-wrap">
                  <div className="text-[#5F6B88] flex flex-col">
                    <p>Комментарий</p>
                    <p className="text-[#1A1A1A] w-[80%] max-[550px]:w-[100%]">
                      {comment}
                    </p>
                  </div>
                  <div className="text-[#5F6B88] flex flex-col items-start max-[550px]:mt-4">
                    <p>Дедлайн</p>
                    <p className="text-[#1A1A1A]">
                      {new Date(deadline).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                <p className="mt-6 mb-4">Загруженные файлы</p>
                <div className="flex items-center gap-2 max-[550px]:flex-wrap">
                  <div className="col-span-12 md:col-span-3 border-[#F0F3FA] border rounded-lg p-6">
                    <div className="flex flex-row justify-between items-center">
                      <img src={icon} />
                      <div className="flex flex-row">
                        <a
                          className="cursor-pointer"
                          href={`https://safety-fiirst.fra1.cdn.digitaloceanspaces.com/homeworks/teachers/${link}`}
                          target="_blank" rel="noreferrer"
                        >
                          <img src={additionalEye} />
                        </a>
                        <a
                          className="ml-3 cursor-pointer"
                          href={`https://safety-fiirst.fra1.cdn.digitaloceanspaces.com/homeworks/teachers/${link}`}
                          download
                        >
                          <img src={additionalDownload} />
                        </a>
                      </div>
                    </div>
                    <p className="mt-4 font-medium text-unix-text-black line-clamp-2">
                      {fileName}
                    </p>
                    <p className="text-[13px] font-semibold text-[#AFB7CA] mt-6">
                      {new Date(homework.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-12 max-xl:mt-6 bg-[#F0F3FA] rounded-[12px] h-[600px] flex flex-col justify-center items-center">
                <p className="text-[16px]">
                  Ваша домашняя работа
                </p>
                {!hwDetails ? (
                  !file ? (
                    <div {...getRootProps({ className: "flex items-center border justify-center gap-2 rounded-[6px] border-[#0068FF] h-[198px] w-[300px] max-[450px]:w-[200px] cursor-pointer" })}>
                      <input {...getInputProps()} />
                      <img src={insertIcon} alt="Insert icon" />
                      <p className="text-[#0068FF] font-bold">
                        {isDragActive ? "Отпустите файл для загрузки" : "Загрузить файл"}
                      </p>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center bg-white h-[198px] w-[300px] max-[450px]:w-[200px] rounded-[6px]">
                      <div className="flex">
                        <img src={icon} />
                        <div>
                          <button onClick={readFile} className="cursor-pointer">
                            <img src={additionalEye} />
                          </button>
                          <button onClick={downloadFile} className="cursor-pointer">
                            <img src={additionalDownload} />
                          </button>
                          <button onClick={deleteFile} className="cursor-pointer">
                            <img src={deleteIon} alt="Delete file" />
                          </button>
                        </div>
                      </div>
                      <p className="text-sm">{file.path}</p>
                      <p className="text-sm">{file.size} bytes</p>
                      <div className="flex gap-2 mt-4"></div>
                    </div>
                  )
                ) : (
                  <div className="mt-4">
                    <p className="text-[#FF0000]">Вы уже отправили эту работу.</p>
                  </div>
                )}
                <p className="opacity-[30%]">PDF | Не более 2 ГB</p>
                {!cancelable && !submitting ? (
                  <button onClick={handleSubmit} disabled={!file || submitting} className={`flex items-center px-[48px] py-[8px] max-[450px]:px-[6px] max-[450px]:text-[14px] mt-10 rounded-[8px] text-white bg-[#3BBC30] ${file ? '' : 'opacity-30'}`}>
                    <img src={whiteTick} alt="White tick" />
                    <p className="font-bold ml-2">Отправить на проверку</p>
                  </button>
                ) : (
                  <div className="mt-4">
                    <p className="text-[#FF0000]">Отправка через: {timer} сек.</p>
                    <button onClick={cancelSubmission} disabled={!cancelable} className="text-[#FF0000] mt-2">
                      Отменить отправку
                    </button>
                  </div>
                )}
              </div>
            </div>
          );
        }
      case "quiz":
        {
          let quizStats = {
            totalUserCorrectAnswers: 5,
            totalCorrectAnswers: 5,
          };
          let renderQuestions = [];
          if (lesson.history?.length) {
            renderQuestions = historyToQuestions(
              lesson.questions,
              lesson.history
            );
          } else if (quiz.questions?.length) {
            renderQuestions = quiz.questions;
          }
          if (
            "result" in lesson ||
            "amountRightAnswers" in quizMeta ||
            ("result" in quizMeta && !("amountRightAnswers" in quizMeta))
          ) {
            quizStats = totalQuizStats(
              "result" in quizMeta
                ? quiz.questions
                : { pastHistory: lesson.history },
              "result" in quizMeta ? quizMeta : { history: lesson.questions }
            );
          }
          const question = renderQuestions.find((q) => q.id === selectedQuestion);
          const questionIndex = renderQuestions
            .map((q) => q.id)
            .indexOf(selectedQuestion);
          const isQuizPassed =
            "result" in lesson ||
            ("result" in quizMeta && !("amountRightAnswers" in quizMeta));
          return "result" in lesson ||
            "amountRightAnswers" in quizMeta ||
            ("result" in quizMeta && !("amountRightAnswers" in quizMeta)) ? (
            <>
              <div
                className="flex flex-row justify-between ml-[17px] pt-[40px] rounded-tr-lg"
                style={{ boxShadow: boxShadowStyle }}
              >
                <div className="flex flex-row w-full items-center px-4">
                  <p className="text-sm font-medium text-unix-gray">Вопрос №</p>
                  <div className="flex flex-row">
                    {renderQuestions.map((q, i) => (
                      <div
                        key={q.id}
                        onClick={() => setSelectedQuestion(q.id)}
                        className={`cursor-pointer py-[12.5px] px-[17px] rounded-lg text-[15px] mx-3 ${q.id === selectedQuestion
                          ? `border border-[#0068FF]`
                          : ``
                          } ${quizQuestionClass(
                            q,
                            "result" in quizMeta
                              ? quizMeta
                              : { history: lesson.questions }
                          ).className
                          }`}
                      >
                        {i + 1}
                      </div>
                    ))}
                    <div
                      className={`rounded-lg py-2 px-4 flex flex-row items-center justify-center ${isQuizPassed
                        ? "bg-question-correct"
                        : "bg-question-incorrect"
                        } ml-3 cursor-pointer ${selectedQuestion === -1 ? `border border-[#0068FF]` : ``
                        }`}
                      onClick={() => setSelectedQuestion(-1)}
                    >
                      <p className="font-medium text-sm text-unix-text-black mr-2">
                        Результат
                      </p>
                      <div
                        className={`rounded-full py-1 px-3 flex flex-row justify-center items-center font-medium text-white ${isQuizPassed ? "bg-[#3BBC30]" : "bg-[#F12D2D]"
                          }`}
                      >
                        {quizStats.totalUserCorrectAnswers} /{" "}
                        {quizStats.totalCorrectAnswers}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {selectedQuestion === -1 && (
                <div
                  className={
                    "md:py-10 p-4 bg-white question-card rounded-b-xl flex flex-col ml-4"
                  }
                  style={{ boxShadow: boxShadowStyle2 }}
                >
                  {renderQuizResult(quizMeta, lesson, quizStats)}
                </div>
              )}
              {question && (
                <div
                  className={
                    "md:pt-10 p-4 pr-1 bg-white question-card rounded-b-xl flex flex-col ml-4"
                  }
                  style={{ boxShadow: boxShadowStyle2 }}
                >
                  <div>
                    <p className={"select-none mb-5"}>
                      <span className="text-[#AFB7CA] font-light text-[32px]">
                        {questionIndex + 1}.
                      </span>
                      <span className="text-unix-text-black font-medium text-[32px]">
                        {question.questionText}
                      </span>
                    </p>
                    {!!question.isMultiple && (
                      <p className="text-[15px] text-unix-gray">
                        <span>Можно выбрать</span>
                        <span className="font-semibold"> несколько </span>
                        <span>вариантов</span>
                      </p>
                    )}
                    <div className={"flex flex-col mt-5"}>
                      {question.answers?.map((ans) => {
                        const isCorrect = isAnswerCorrect(
                          ans.id,
                          question,
                          "result" in quizMeta
                            ? quizMeta
                            : { history: lesson.questions }
                        );
                        const isIncorrectAndSelected =
                          !isCorrect &&
                          [...(question?.userAnswerIds || [])].includes(ans.id);
                        return (
                          <div
                            key={ans.id}
                            className={`flex flex-row rounded-lg px-6 py-6 items-center mb-2 cursor-pointer ${isCorrect
                              ? "border-2 border-[#3BBC30]"
                              : isIncorrectAndSelected
                                ? "border-2 border-[#F12D2D]"
                                : ""
                              } ${[...(question?.userAnswerIds || [])].includes(
                                ans.id
                              )
                                ? `bg-[#F1EFE7]`
                                : `bg-gray-cool`
                              }`}
                          >
                            <UnixRadio
                              checked={[
                                ...(question?.userAnswerIds || []),
                              ].includes(ans.id)}
                              disabled
                            />
                            <p className="text-[20px] text-unix-text-black ml-4">
                              {ans.answerText}
                            </p>
                            {(isCorrect || isIncorrectAndSelected) && (
                              <img
                                className="w-6 h-6 ml-4"
                                src={isCorrect ? tickGreen : redCross}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <button
                      className={`flex flex-row mt-8 justify-center items-center ${loading ? "opacity-60" : ""
                        }`}
                      onClick={nextQuestion}
                      disabled={loading}
                    >
                      <img className={"cursor-pointer"} src={next} />
                    </button>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div
                className="flex flex-row justify-between ml-[17px] mt-[-40px] pt-[40px] rounded-tr-lg"
                style={{ boxShadow: boxShadowStyle }}
              >
                <div className="flex flex-row w-full items-center px-4">
                  <p className="text-sm font-medium text-unix-gray">Вопрос №</p>
                  <div className="flex flex-row">
                    {quiz.questions?.map((q, i) => (
                      <div
                        key={q.id}
                        onClick={() => setSelectedQuestion(q.id)}
                        className={`cursor-pointer py-[12.5px] px-[17px] rounded-lg text-[15px] mx-3 ${q.id === selectedQuestion
                          ? `font-semibold text-white bg-[#0068FF]`
                          : `text-unix-text-black bg-[#F0F3FA]`
                          }`}
                      >
                        {i + 1}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {question && (
                <div
                  className={
                    "md:pt-10 p-4 pr-1 bg-white question-card rounded-b-xl flex flex-col ml-4"
                  }
                  style={{ boxShadow: boxShadowStyle2 }}
                >
                  <div>
                    <p className={"select-none mb-5"}>
                      <span className="text-[#AFB7CA] font-light text-[32px]">
                        {questionIndex + 1}.
                      </span>
                      <span className="text-unix-text-black font-medium text-[32px]">
                        {question.questionText}
                      </span>
                    </p>
                    {!!question.isMultiple && (
                      <p className="text-[15px] text-unix-gray">
                        <span>Можно выбрать</span>
                        <span className="font-semibold"> несколько </span>
                        <span>вариантов</span>
                      </p>
                    )}
                    <div className={"flex flex-col mt-5"}>
                      {question.answers?.map((ans) => (
                        <div
                          key={ans.id}
                          className={`flex flex-row rounded-lg px-6 py-6 items-center mb-2 cursor-pointer ${[...(question?.userAnswerIds || [])].includes(ans.id)
                            ? `bg-[#F1EFE7]`
                            : `bg-gray-cool`
                            }`}
                          onClick={selectAnswer(question, ans)}
                        >
                          <UnixRadio
                            checked={[
                              ...(question?.userAnswerIds || []),
                            ].includes(ans.id)}
                          />
                          <p className="text-[20px] text-unix-text-black ml-4">
                            {ans.answerText}
                          </p>
                        </div>
                      ))}
                    </div>
                    <button
                      className={`flex flex-row mt-8 justify-center items-center ${loading ? "opacity-60" : ""
                        }`}
                      onClick={nextQuestion}
                      disabled={loading}
                    >
                      <img className={"cursor-pointer"} src={next} />
                    </button>
                  </div>
                </div>
              )}
            </>
          );
        }
    }
  };

  const [style, setStyle] = useState("#F7F7FB");
  console.log(additionalExpanded);

  return (
    <div className="grid grid-cols-12 mt-10">
      <div className={"col-span-12"}>
        <p className={"platform-black text-lg font-medium md:block max-md:hidden"}>
          {module.title && course.title && lesson.title ? (
            <>
              {localize("module")}\{" "}
              <p>{module.title}</p>{" "}
              \{" "}
              <Link to={`/platform/courses/${course.id}`}>{course.title}</Link>{" "}
              \ {lesson.title}
            </>
          ) : (
            " "
          )}
        </p>
        <div className={"flex flex-row mt-4 items-center"}>
          <img className={"cursor-pointer"} src={back} onClick={goBack} />
          <p
            className={"text-base text-black ml-6 cursor-pointer"}
            onClick={goBack}
          >
            {localize("back")}
          </p>
        </div>
      </div>
      <div className="hidden md:block col-span-1 md:col-span-5 lg:col-span-4 xl:col-span-3 bg-[#F7F7FB] rounded-[20px] py-3 mx-4 mt-0 md:mt-[60px]">
        {isCoursePage && (
          <div className="hidden md:block col-span-1 md:col-span-4 bg-[#F7F7FB] rounded-[20px] px-2 py-3 mx-4 mt-0 md:mt-[60px]">
            <CourseNavigation course={course} organization={organization} />
          </div>
        )}
        {isLessonPage && (
          <div className="hidden md:block col-span-1 md:col-span-5 lg:col-span-4 xl:col-span-3 bg-[#F7F7FB] rounded-[20px] py-3 mx-4 mt-0 md:mt-[0px]">
            <CourseNavigationDetailed
              course={course}
              organization={organization}
            />
          </div>
        )}
      </div>
      <div className="col-span-12 md:col-span-7 lg:col-span-8 xl:col-span-9 grid grid-cols-10 mt-[60px]">
        <div className={"col-span-12"}>
          {Object.entries(expanded)
            .filter(([k]) => k === "video")
            .map(([key, val]) => (
              <Accordion
                key={key}
                className={"course-accordion lesson-accordion"}
                expanded={val.expanded}
                onChange={() => handleExpandedChange(key)}
              >
                <AccordionSummary
                  className={"border-0 shadow-none"}
                  expandIcon={<ExpandMore />}
                >
                  <p className={"text-[35px] font-light"}>{val.label}</p>
                </AccordionSummary>
                <AccordionDetails className="flex flex-col">
                  {renderLessonCard(key)}
                </AccordionDetails>
              </Accordion>
            ))}
          <div className="flex flex-row pl-4 max-[540px]:pl-0">
            {[
              "quiz" in expanded
                ? { label: localize("test"), value: "quiz", imgSrc: test }
                : {},
              lesson.additionalMaterial
                ? {
                  label: "Материалы",
                  value: "additional_material",
                  imgSrc: material,
                }
                : {},
            ]
              .filter((obj) => !!Object.keys(obj).length)
              .map((additionalTab) => (
                <a
                  key={additionalTab.value}
                  onClick={() => {
                    if (additionalTab.value === "quiz") {
                      if (lesson.isWatched || videoEnded) {
                        setAdditionalExpanded(additionalTab.value);
                        setShowQuizMessage(false);
                      } else {
                        setShowQuizMessage(true);
                      }
                    } else if (additionalTab.value === "additional_material") {
                      setAdditionalExpanded(additionalTab.value);
                    }
                    setStyle("white");
                  }}
                  className={`${additionalTab.value === additionalExpanded
                    ? "bg-[#FFFFFF]"
                    : "bg-[#F7F7FB]"
                    } cursor-pointer border border-[#F1EFE7] rounded-[10px] rounded-b-[0px] border-b-0 py-3 px-3 md:text-lg text-[#292731] font-medium mr-2 md:mr-2 flex items-center text-[14px] max-[450px]:text-[12px] max-[450px]:mr-1 max-[450px]:mt-5`}
                  style={{ boxShadow: boxShadowStyle3 }}
                >
                  <img
                    src={additionalTab.imgSrc}
                    alt={additionalTab.label}
                    className="w-6 h-6 mr-2 icon-display"
                  />
                  <span className="max-[450px]:relative max-[400px]:p-[-5px] inline-block flex-0 max-sm:whitespace-nowrap">
                    {additionalTab.label}
                  </span>
                </a>
              ))}
            {[
              homework
                ? { label: "Домашнее задание", value: "hw", imgSrc: material }
                : {},
            ]
              .filter((obj) => !!Object.keys(obj).length)
              .map((additionalTab) => (
                <div key={additionalTab.value}>
                  <a
                    onClick={() => {
                      if (additionalTab.value === "hw") {
                        setAdditionalExpanded(additionalTab.value);
                      }
                      setStyle("white");
                    }}
                    className={`border border-[#F1EFE7] rounded-[10px] rounded-b-[0px] border-b-0 cursor-pointer py-3 px-3 md:text-lg text-[#292731] font-medium mr-2 md:mr-2 flex items-center text-[14px] max-[450px]:text-[12px] max-[450px]:mt-5 max-[493px]:w-[95px]`}
                    style={{
                      boxShadow: boxShadowStyle3,
                      backgroundColor: style,
                    }}
                  >
                    <img
                      src={additionalTab.imgSrc}
                      alt={additionalTab.label}
                      className="w-6 h-6 mr-2 max-[450px]:relative top-[5px] icon-display"
                    />
                    <span className="max-[450px]:relative top-[5px] max-[450px]:top-[0px] ">
                      {additionalTab.label}
                    </span>
                  </a>
                </div>
              ))}
          </div>
          {showQuizMessage && (
            <div
              style={{ boxShadow: boxShadowStyle }}
              className="flex ml-[17px] justify-center py-8 bg-[white] text-[#AFB7CA] rounded-tr-lg rounded-br-lg rounded-bl-lg px-2"
            >
              <p className="bg-[#F7F7FB] w-[95%] text-center py-10 rounded-[12px] max-[450px]:text-[14px]">
                Тестовое задание доступно после просмотра видео
              </p>
            </div>
          )}
          <div
            className={`${additionalExpanded === "additional_material" ? "" : ""
              }`}
          >
            {!!additionalExpanded.length &&
              renderLessonCard(additionalExpanded)}
          </div>
        </div>
      </div>
      <Modal open={ratingModalOpen} onClose={handleRatingModalClose}>
        <Box
          className="rate-modal"
          style={{
            border: 0,
          }}
        >
          <RateLesson onClose={handleRatingModalClose} lessonId={lessonId} />
        </Box>
      </Modal>
      <Modal open={isMobile && additionalQuestionsShow} onClose={() => { }}>
        <AdditionalQuiz
          lessonId={lessonId}
          currentAdditionalQuestion={currentAdditionalQuestion}
          currentAdditionalAnswer={currentAdditionalAnswer}
          setCurrentAdditionalAnswer={setCurrentAdditionalAnswer}
          mode="modal"
          show={isMobile}
        />
      </Modal>
    </div>
  );
};

const UnixRadio = ({ checked, onClick = () => { }, additionalClassname = "", disabled = false }) => {
    return (
        <div className={`cursor-pointer min-w-6 min-h-6 w-6 h-6 py-1.5 rounded-full flex flex-row justify-center items-center border-2 ${disabled ? 'border-unix-radio-disabled' : 'border-[#0068FF]'} ${additionalClassname}`} onClick={onClick}>
            {checked && (
                <div className={`w-3 h-3 rounded-full ${disabled ? 'bg-unix-radio-disabled' : 'bg-[#0068FF]'} `} />
            )}
        </div>
    )
}

export default UnixRadio
import { progressApi } from "../api"

const getProgress = async (id) => {
    const data = {}
    try {
        const response = await progressApi.getUserProgress(id)
        if (response.data instanceof Array) {
            data.success = true
            data.progress = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getCourseProgress = async (id, courseId) => {
    const data = {}
    try {
        const response = await progressApi.getUserCourseProgress(id, courseId)
        if (response.data.id) {
            data.success = true
            data.progress = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

export {
    getProgress,
    getCourseProgress
}

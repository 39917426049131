import { ExpandMore } from "@mui/icons-material"
import { useEffect, useRef, useState } from "react"
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from "react-router-dom"
import { getTopic } from "../../../actions/topic"
import lectureFinished from './LectureFinished.svg'
import lectureStarted from './LectureInactive.svg'
import done from './done.svg'
import inProgress from './in-progress.svg'
import lessonDone from './lessonDone.svg'
import lessonUnbegun from './lessonInactive.svg'
import lessonStarted from './lessonStarted.svg'

const CourseNavigation = ({ course, organization }) => {
    const isTablet = useMediaQuery({ query: '(max-width: 767.98px)' })
    const navigate = useNavigate()
    const [lessons, setLessons] = useState({})
    const [expanded, setExpanded] = useState({})
    const [lastWatchedLesson, setLastWatchedLesson] = useState(null)
    const lastWatchedRef = useRef(null)

    const goToLesson = (l, t) => {
        window.location = `/platform/lessons/${l.id}`
        localStorage.setItem('lastWatchedLesson', l.id)
        setLastWatchedLesson(l.id)
        navigate(`/platform/lessons/${l.id}`, { state: { course, organization, topic: t, lesson: l } })
    }

    const isLastWatchedLesson = (lessonId) => {
        return lessonId == lastWatchedLesson
    }

    useEffect(() => {
        if (Object.keys(lessons).length !== 0) {
            const initialExpandedState = {}
            course.topics.forEach(topic => {
                const isAnyLessonWatched = lessons[topic.id]?.some(lesson => isLastWatchedLesson(lesson.id))
                if (isAnyLessonWatched) {
                    initialExpandedState[topic.id] = true
                } else {
                    initialExpandedState[topic.id] = false
                }
            })
            setExpanded(initialExpandedState)
        }
    }, [lessons])

    useEffect(() => {
        const lastWatched = localStorage.getItem('lastWatchedLesson')
        if (lastWatched) {
            setLastWatchedLesson(lastWatched)
        }
    }, [])

    useEffect(() => {
        if (lastWatchedRef.current) {
            lastWatchedRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }, [expanded])

    const getLessons = async (topics) => {
        const obj = {}
        const promises = topics.map(topic => getTopic(topic.id))
        const res = await Promise.allSettled(promises)
        for (let response of res.filter(r => r.status === "fulfilled")) {
            if (response.value?.topic?.lessons) {
                obj[response.value?.topic.id] = response.value?.topic?.lessons
            }
        }
        setLessons(obj)
    }

    const renderLessonIcon = (lesson, isTablet) => {
        if (!lesson.isWatched) {
            return (
                <svg className={isTablet ? "w-3/4 go-button-circle" : "go-button-circle w-[32px] h-[32px]"} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 48 48" fill="none">
                    <circle cx="24" cy="24" r="23.5" fill="#F1EFE7" stroke="#F1EFE7" />
                    <path d="M31.039 18.9522L16.2943 29.2766M25.4681 17.9699L31.039 18.9522L25.4681 17.9699ZM31.039 18.9522L30.0567 24.5231L31.039 18.9522Z" stroke="#9C7A2A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            )
        } else {
            if (!lesson.isHasQuiz || (lesson.isHasQuiz && lesson.isQuizPassed)) {
                return (
                    <img src={done} className="course-progress-icon" />
                )
            }
            return (
                <img src={inProgress} className="course-progress-icon" />
            )
        }
    }

    const renderLessonIcon2 = (lesson) => {
        if (!lesson.isWatched) {
            return (
                <img src={lessonUnbegun} />
            )
        } else {
            if (!lesson.isHasQuiz || (lesson.isHasQuiz && lesson.isQuizPassed)) {
                return (
                    <img src={lessonDone} />
                )
            }
            return (
                <img src={lessonStarted} />
            )
        }
    }

    const renderTopLine = (finished, parent = false, parentIdx = 0) => {
        return (
            <div style={{
                height: parent ? '2rem' : '1.5rem',
                width: 2,
                backgroundColor: !parent || (parent && parentIdx !== 0) ? finished ? "#10C900" : "#F1EFE7" : 'transparent'
            }} />
        )
    }

    const renderTopicBottomLine = (finished, isLastTopic) => {
        if (isLastTopic) {
            return null
        }
        return (
            <div style={{
                width: 2,
                backgroundColor: finished ? "#10C900" : "#F1EFE7",
                flexGrow: 1
            }} />
        )
    }

    const renderBottomLine = (finished, isLastLesson, isLastTopic) => {
        if (isLastTopic && isLastLesson) {
            return null
        }
        return (
            <div style={{
                width: 2,
                backgroundColor: finished ? "#10C900" : "#F1EFE7",
                flexGrow: 1
            }} />
        )
    }

    const isLessonFinished = (lesson) => {
        if (!lesson.isHasQuiz) {
            return lesson.isWatched
        } else {
            return lesson.isWatched && lesson.isQuizPassed
        }
    }

    const handleExpandedChange = (topicId) => {
        setExpanded(e => {
            return {
                ...e,
                [topicId]: !e[topicId]
            }
        })
    }

    useEffect(() => {
        if (course.topics?.length) {
            if (Object.keys(expanded).length === 0) {
                setExpanded(course.topics.map(t => ({ [t.id]: false })).reduce((acc, curr) => ({ ...acc, ...curr }), {}))
            }
            if (Object.keys(lessons).length === 0) {
                getLessons(course.topics)
            }
        }
    }, [course.topics?.length])

    return (
        <div className="grid grid-cols-12 relative course-navigation-container">
            {course.topics && course.topics.map((topic, index) => {
                const isLastTopic = index === course.topics.length - 1
                return (
                    <div key={topic.id} className={`col-span-12 grid grid-cols-12  ${expanded[topic.id] ? "rounded-[10px] my-3" : ""}`}>
                        <div className="col-span-1 flex flex-col items-center">
                            {renderTopLine(index === 0 ? false : lessons[course.topics[index - 1].id]?.every(isLessonFinished), true, index)}
                            <div className="relative">
                                <div
                                    className={`
                                    absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[24px] font-bold
                                    ${lessons[topic.id]?.every(isLessonFinished) ? 'text-[#10C900]' : 'text-[#9C7A2A]'}
                                `}
                                >
                                    {index + 1}
                                </div>
                                {lessons[topic.id]?.every(isLessonFinished) ? (
                                    <div style={{ width: '40px', height: '40' }}>
                                        <img src={lectureFinished} style={{ width: '100%', height: '100%', maxWidth: 'none', maxHeight: 'none' }} />
                                    </div>
                                ) : (
                                    <img src={lectureStarted} style={{ width: '40px', height: '40px', maxWidth: 'none', maxHeight: 'none' }} />
                                )}
                            </div>
                            {isLastTopic && (
                                <div
                                    className="topic-bottom-line"
                                    style={{
                                        width: 2,
                                        backgroundColor: lessons[topic.id]?.every(isLessonFinished) ? "#10C900" : "#F1EFE7",
                                        flexGrow: 1,
                                        display: expanded[topic.id] ? 'block' : 'none' // Show when expanded, hide when not expanded
                                    }}
                                />
                            )}
                            {renderTopicBottomLine(
                                lessons[topic.id]?.every(isLessonFinished),
                                isLastTopic // Check if it's the last topic
                            )}
                        </div>
                        <div className="col-span-11 ">
                            <div className="flex flex-row items-center justify-between pt-8 px-4 cursor-pointer" onClick={() => handleExpandedChange(topic.id)}>
                                <p className={"text-[20px] font-semibold mb-2"}>
                                    {topic.title}
                                </p>
                                <ExpandMore />
                            </div>
                        </div>
                        {expanded[topic.id] && lessons[topic.id] && lessons[topic.id].map((lesson, idx) => (
                            <>
                                <div className="col-span-1 flex flex-col items-center">
                                    {renderTopLine(isLessonFinished(idx === 0 ? lesson : lessons[topic.id][idx - 1]))}
                                    {renderLessonIcon2(lesson)}
                                    {renderBottomLine(isLessonFinished(lesson), idx === lessons[topic.id].length - 1, index === course.topics.length - 1)}
                                </div>
                                <div ref={isLastWatchedLesson(lesson.id) ? lastWatchedRef : null} className="col-span-11 mt-2">
                                    <div className={`topic-lesson-card p-3 cursor-pointer rounded-[15px] ${isLastWatchedLesson(lesson.id) ? 'ml-2 bg-[#fff]' : ''}`} onClick={() => goToLesson(lesson, topic)}>
                                        {isTablet ? (
                                            <>
                                                <div className={"flex flex-row justify-between items-center mt-3 mb-6"}>
                                                    <p className={`text-[16px] font-light basis-10/12 ${isLastWatchedLesson(lesson.id) ? 'font-bold' : ''}`}>
                                                        {lesson.title}
                                                    </p>
                                                    <div className="basis-2/12 flex flex-row justify-end">
                                                        {renderLessonIcon(lesson, isTablet)}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="text-[16px] mb-1 font-light flex items-center justify-between relative">
                                                    <p
                                                        className={`w-[80%] ${isLastWatchedLesson(lesson.id) ? 'font-medium' : ''}`}
                                                    >
                                                        {lesson.title}
                                                    </p>
                                                    <div className="">
                                                        {renderLessonIcon(lesson, isTablet)}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                )
            })}
        </div>
    )
}

export default CourseNavigation

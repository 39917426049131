import { useRef } from "react";
import DraggableList from "react-draggable-list";
import { updateLessonPost } from "../../../../actions/lesson";
import { showSnackbar } from "../../../../actions/snackbar";
import { LessonListItem } from "./LessonListItem";

export const TopicLessonsList = ({ list, selectedLesson, updateSelectedLesson, triggerCourseFetch }) => {
    const listRef = useRef(null);

    const onMoveEnd = async (newList) => {
        for (let idxRaw in newList) {
            const idx = parseInt(idxRaw)
            const lesson = newList[idx]
            if (lesson.order !== idx + 1) {
                const formData = new FormData()
                formData.append('order', idx + 1)
                formData.append('topicId', lesson.topicId)
                const result = await updateLessonPost(lesson.id, formData)
                if (!result.success) {
                    showSnackbar("Произошла ошибка. Пожалуйста, повторите позднее", "error")
                    return
                }
            }
        }
        triggerCourseFetch()
        showSnackbar("Успешно")
    }

    return (
        <div ref={listRef} className="flex flex-col">
            <DraggableList
                itemKey="id"
                template={(props) => (
                    <LessonListItem
                        {...props}
                        selectedLesson={selectedLesson}
                        updateSelectedLesson={updateSelectedLesson}
                    />
                )}
                list={list}
                onMoveEnd={onMoveEnd}
                container={() => listRef.current}
            />
        </div>
    )
}
import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useParams } from 'react-router-dom'
import { editTeacherHW } from '../../../actions/homework'
import { getRooms } from '../../../actions/room'
import { showSnackbar } from '../../../actions/snackbar'
import { addRoomDeadline } from '../../../actions/user'
import { useCourse, useTeacherCourses } from '../../../hooks/courses'
import {
  createHW,
  deleteTeacherHW,
  getTeacherHW
} from '../../../hooks/homework'
import pdfIcon from './../../../assets/PDF.svg'
import additionalEye from './additional_eye.svg'
import additionalDelete from './additionalDelete.svg'
import additionalDownload from './additionalDownload.svg'
import calendarIcon from './calendarIcon.svg'
import closeIcon from './closeIcon.svg'
import editBlueIcon from './editBlueIcon.svg'
import editIcon from './editIcon.svg'
import examsIcon from './examsIcon.svg'
import greenTick from './green_tick.svg'
import hwsIcon from './hwsIcon.svg'
import insertFile from './insertFile.svg'
import lecturesIcon from './lecturesIcon.svg'
import leftIcon from './leftArrowIcon.svg'
import lessonsIcon from './lessonsIcon.svg'
import plus from './plus.svg'
import redCross from './red_cross.svg'
import studentsIcon from './studentsIcon.svg'
import testsIcon from './testsIcon.svg'
import tick from './tick.svg'

const CourseInfo = () => {
  const { courseId } = useParams()
  const course = useCourse(courseId)
  const modules = useTeacherCourses()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [hwModal, setHwModal] = useState(false)
  const [selectedLessonId, setSelectedLessonId] = useState(null)
  const [selectedLessonTitle, setSelectedLessonTitle] = useState('')
  const [comment, setComment] = useState('')
  const [deadline, setDeadline] = useState(new Date())
  const [fileLink, setFileLink] = useState('')
  const [file, setFile] = useState(null)
  const [topicSelected, setTopicSelected] = useState(null)
  const [hwData, setHwData] = useState({})
  const [homeWork, setHomeWork] = useState({})
  const fileInputRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [rooms, setRooms] = useState([])
  const [updatedDeadlines, setUpdatedDeadlines] = useState({})

  const handleFileChange = e => {
    setFile(e.target.files[0])
  }

  const selectedTopic = course?.topics?.find(t => t.id === topicSelected)

  console.log('selected lesson ', selectedLessonId)

  const handleTopicClick = id => {
    setTopicSelected(id)
  }

  const fetchHomeWork = async lessonId => {
    const data = await getTeacherHW(lessonId)
    if (data.success) {
      setHomeWork(prev => ({ ...prev, [lessonId]: data.homework }))
    } else {
      setHomeWork(prev => ({ ...prev, [lessonId]: null }))
    }
  }

  const fetchRooms = async () => {
    const response = await getRooms(courseId)
    if (response.success) {
      setRooms(response.rooms.sort((a, b) => a.id - b.id))
      // setStudents(response.users)
      // setUserCheat(response.usersWithProbabilities)
      return response.rooms
    }
  }

  console.log('room ', rooms)

  const handleEditClick = lesson => {
    setSelectedLessonId(lesson.id)
    setSelectedLessonTitle(lesson.title)
    setHwModal(true)
    fetchHomeWork(lesson.id)
  }

  const handleSubmitDeadline = async () => {
    setLoading(true)
    try {
      const data = await Promise.all(
        rooms.map(async room => {
          console.log('data ', data)
          const deadline = updatedDeadlines[room.id]
          if (deadline) {
            return await addRoomDeadline(selectedTopic.id, room.id, deadline)
          }
          return null
        })
      )
      // Handle success (e.g., show a success message, close the modal, etc.)
    } catch (error) {
      console.error('Error updating deadlines:', error)
      // Handle error (e.g., show an error message)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async () => {
    if (!file && !homeWork[selectedLessonId]?.homeWorkFile) {
      alert('Please upload a file or keep the existing one')
      return
    }

    setLoading(true)

    const formData = new FormData()
    formData.append('lessonId', selectedLessonId)
    formData.append('comment', comment)
    formData.append('deadline', deadline.toISOString())

    if (file) {
      formData.append('homeWorkFile', file)
    }
    if (hwData) {
      formData.append('homeWorkFile', hwData)
    }

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`)
    }

    try {
      const data = await (homeWork[selectedLessonId]
        ? editTeacherHW(formData)
        : createHW(formData))

      if (data.success) {
        showSnackbar()
        handleCloseModal()
      } else {
        console.log('error')
      }
    } catch (error) {
      console.error('Error:', error)
    } finally {
      setLoading(false) // End loading
    }
  }

  const handleDateChange = date => {
    setDeadline(date)
  }

  const handleRoomDateChange = (date, roomId) => {
    setUpdatedDeadlines(prev => ({
      ...prev,
      [roomId]: date
    }))
  }

  useEffect(() => {
    if (selectedTopic) {
      selectedTopic.lessons.forEach(lesson => fetchHomeWork(lesson.id))
    }
  }, [selectedTopic, hwModal])

  useEffect(() => {
    fetchRooms()
  }, [])

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className="flex items-center bg-[#F0F3FA] rounded-[8px] w-[136px]">
      <input
        value={value}
        onClick={onClick}
        ref={ref}
        style={{
          backgroundColor: '#F0F3FA',
          fontSize: '15px',
          padding: '8px 12px',
          borderRadius: '8px',
          outline: 'none',
          border: 'none',
          width: '105px'
        }}
      />
      <img
        onClick={onClick}
        src={calendarIcon}
        alt="Calendar Icon"
        style={{ width: '24px', height: '24px' }}
      />
    </div>
  ))

  CustomInput.displayName = 'CustomInput'

  const formatDate = date => {
    if (!date) return ''
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return `${day}.${month}.${year}`
  }

  const formatDeadline = dateString => {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based
    const year = date.getFullYear()
    return `${day}.${month}.${year}`
  }

  const fetchFileFromLink = async url => {
    try {
      const response = await fetch(url, { mode: 'no-cors' })
      const blob = await response.blob()
      const file = new File([blob], hwData.link, { type: blob.type })
      setFile(file)
    } catch (error) {
      console.error('Error fetching file:', error)
    }
  }

  useEffect(() => {
    if (hwData?.link) {
      const fileUrl = `https://safety-fiirst.fra1.cdn.digitaloceanspaces.com/homeworks/teachers/${hwData.link}`
      fetchFileFromLink(fileUrl)
    }
  }, [hwData])

  useEffect(() => {
    if (hwModal && selectedLessonId && homeWork[selectedLessonId]) {
      const hwData = homeWork[selectedLessonId]
      setComment(hwData.comment || '')
      setDeadline(new Date(hwData.deadline))
      const link = hwData.link
      if (link) {
        setFileLink(link)
        setHwData(hwData)
      }
    }
  }, [hwModal, selectedLessonId, homeWork])

  const handleDelete = async lessonId => {
    if (lessonId) {
      const response = await deleteTeacherHW(lessonId)
      if (response.success) {
        handleCloseModal()
      } else {
        alert('Failed to delete homework')
      }
    }
  }

  console.log('file ', file)

  const handleCloseModal = () => {
    setHwModal(false)
    setSelectedLessonId(null)
    setHomeWork({})
    setDeadline(new Date())
    setComment('')
    setFileLink('')
    setFile(null)
  }

  const date = new Date(file?.lastModifiedDate)
  const formattedDate = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`

  const sizeInMB = (file?.size / (1024 * 1024)).toFixed(2)

  const handleDownload = (event, fileName) => {
    event.preventDefault()

    const corsProxy = 'https://cors-anywhere.herokuapp.com/'
    const fileUrl = `https://safety-fiirst.fra1.cdn.digitaloceanspaces.com/homeworks/teachers/${fileName}`
    const proxyUrl = corsProxy + fileUrl

    fetch(proxyUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)

        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
        window.URL.revokeObjectURL(url)
      })
      .catch(error => console.error('Error downloading file:', error))
  }

  return (
    <div>
      <div className="p-5 ">
        <span className="mb-5  text-black   items-center">
          <span className="flex w-fit items-center bg-white rounded-[12px] p-2">
            <p className="mr-2">Курс</p>
            {modules.map(module => (
              <span key={module.id} className="flex items-center space-x-4">
                {module.courses.map(course => (
                  <a
                    key={course.id}
                    className={`cursor-pointer block px-4 py-2  text-[16px] ml-1 rounded-[8px] ${
                      courseId == course.id ? 'bg-[#0068FF] text-white' : ''
                    }`}
                    href={`/admin/course/${course.id}`}
                    // onClick={() => {
                    //     setSelectedCourse(course)
                    // }}
                  >
                    {course.title}
                  </a>
                ))}
              </span>
            ))}
          </span>
        </span>
      </div>
      <div className="bg-white rounded-[12px] ml-5 mr-[32px]">
        <div className="flex items-center pl-[24px] pt-[24px]">
          <img
            className="gird-cols-4 h-[96px] rounded-[8px]"
            src={course.imageLink}
          />
          <p className="text-black text-[48px] font-semibold ml-[16px]">
            {course.title}
          </p>
        </div>
        <div className="pl-[24px] mt-[32px] pb-[24px] flex justify-around">
          <div className="border-r-2 pr-[42px] border-[#F0F3FA] flex flex-col justify-between max-[1100px]:pr-[24px]">
            <div className="flex items-center gap-2">
              <img src={studentsIcon} />
              <p className="text-[#5F6B88] font-medium text-[14px]">
                Мои студенты
              </p>
            </div>
            <div className="text-[#0068FF] text-[36px] font-medium">512</div>
          </div>
          <div className="border-r-2 pr-[42px] border-[#F0F3FA] ml-[1vw] flex flex-col justify-between max-[1100px]:pr-[24px]">
            <div className="flex items-center gap-2">
              <img src={lecturesIcon} />
              <p className="text-[#5F6B88] font-medium text-[14px]">Лекций</p>
            </div>
            <div className="text-[#0068FF] text-[36px] font-medium">13</div>
          </div>
          <div className="border-r-2 pr-[42px] border-[#F0F3FA] ml-[1vw] flex flex-col justify-between max-[1100px]:pr-[24px]">
            <div className="flex items-center gap-2">
              <img src={lessonsIcon} />
              <p className="text-[#5F6B88] font-medium text-[14px] ">Уроков</p>
            </div>
            <div className="text-[#0068FF] text-[36px] font-medium">80</div>
          </div>
          <div className="border-r-2 pr-[42px] border-[#F0F3FA] ml-[1vw] flex flex-col justify-between max-[1100px]:pr-[24px]">
            <div className="flex items-center gap-2">
              <img src={hwsIcon} />
              <p className="text-[#5F6B88] font-medium text-[14px] leading-[16px]">
                Домашних <br /> заданий
              </p>
            </div>
            <div className="text-[#0068FF] text-[36px] font-medium">12</div>
          </div>
          <div className="border-r-2 pr-[42px] border-[#F0F3FA] ml-[1vw] flex flex-col justify-between max-[1100px]:pr-[24px]">
            <div className="flex items-center gap-2">
              <img src={examsIcon} />
              <p className="text-[#5F6B88] font-medium text-[14px]">
                Экзаменов
              </p>
            </div>
            <div className="text-[#0068FF] text-[36px] font-medium">4</div>
          </div>
          <div className="pr-[24px] ml-[1vw] flex flex-col justify-between">
            <div className="flex items-center gap-2">
              <img src={testsIcon} />
              <p className="text-[#5F6B88] font-medium text-[14px]">Тестов</p>
            </div>
            <div className="text-[#0068FF] text-[36px] font-medium">5</div>
          </div>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-12 p-[20px] flex-grow">
          <div className="col-span-4 pr-4">
            <div className={`flex flex-col bg-white p-4 h-full rounded-[12px]`}>
              <div className="flex justify-between items-center text-[#5F6B88] text-sm font-medium border-b pb-4 mb-4">
                <h1>Лекция</h1>
                <p className="text-right">Дедлайн</p>
              </div>
              {course?.topics?.map(t => (
                <div
                  key={t.id}
                  className={`relative rounded-2xl py-3 px-4 font-medium text-xl flex flex-row items-center justify-between ${
                    t.id === topicSelected
                      ? 'bg-[#0068FF] text-white'
                      : 'text-[#1A1A1A]'
                  }`}
                  onClick={() => 'id' in t && handleTopicClick(t.id)}
                >
                  <span className="w-[60%]">{t.title}</span>
                  <div className="flex gap-2 items-center right-[10px]">
                    <p className="text-[15px]">{formatDeadline(t.deadline)}</p>
                    <img
                      className="w-[24px] h-[24px]"
                      src={editIcon}
                      onClick={() => setIsModalOpen(true)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
              <div className="bg-white p-8 rounded-lg w-[400px]">
                <div className="flex justify-end mb-2 ml-[0px]">
                  <img
                    onClick={() => setIsModalOpen(false)}
                    className="text-end"
                    src={closeIcon}
                  />
                </div>
                <p className="text-[15px] font-normal mb-0 text-[#5F6B88]">
                  Дедлайны лекции
                </p>
                <p className="text-[#1A1A1A] text-[24px]">
                  {selectedTopic.title}
                </p>
                <div className="flex items-center justify-between text-[#5F6B88] font-medium mt-[32px] mb-4">
                  <p>Класс</p>
                  <p>Дедлайн</p>
                </div>
                {rooms.map(r => {
                  const topicsRoomMap = r.topicsRoomsMaps.find(
                    map => map.topicId === selectedTopic.id
                  )
                  const roomDeadline = topicsRoomMap
                    ? new Date(topicsRoomMap.deadline)
                    : null

                  const displayDeadline =
                    roomDeadline || new Date(selectedTopic.deadline)

                  return (
                    <div
                      key={r.id}
                      className="text-[#1A1A1A] flex items-center justify-between border-t-2 border-[#F0F3FA] py-[16px]"
                    >
                      <p>{r.title}</p>
                      <div className="flex items-center bg-[#F0F3FA] px-[12px] py-[8px] rounded-[8px]">
                        <DatePicker
                          customInput={<CustomInput />}
                          selected={updatedDeadlines[r.id] || displayDeadline}
                          onChange={date => handleRoomDateChange(date, r.id)}
                          dateFormat="dd.MM.yyyy"
                          value={
                            updatedDeadlines[r.id]
                              ? formatDate(updatedDeadlines[r.id])
                              : formatDate(displayDeadline)
                          }
                        />
                      </div>
                    </div>
                  )
                })}
                <div className="flex justify-center mt-6 gap-3">
                  <button
                    className="flex w-full items-center px-4 py-3 text-white rounded bg-[#5F6B88] hover:bg-gray-600 justify-center gap-2 font-bold"
                    onClick={() => setIsModalOpen(false)}
                  >
                    <img src={leftIcon} />
                    Вернуться
                  </button>
                  <button
                    className="flex w-full items-center px-4 py-3 bg-[#3BBC30] hover:bg-green-500 text-white rounded justify-center gap-2 font-bold"
                    onClick={handleSubmitDeadline}
                    disabled={loading}
                  >
                    {loading ? (
                      'Загружается...'
                    ) : (
                      <>
                        <img src={tick} alt="Save" />
                        Сохранить
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="col-span-8 px-4">
            {selectedTopic && (
              <div className="bg-white p-6 rounded-xl">
                {' '}
                <div className="flex flex-col space-y-4">
                  <table className="min-w-full bg-white text-[14px]">
                    <thead>
                      <tr>
                        <th className="py-2 text-left text-[#5F6B88] font-medium text-sm">
                          Урок
                        </th>
                        <th className="py-2 text-left text-[#5F6B88] font-medium text-sm lg:text-end min-w-28">
                          Длительность видеоурока
                        </th>
                        <th className="py-2 text-left text-[#5F6B88] font-medium text-sm lg:text-center px-4">
                          Наличие видеоквиза
                        </th>
                        <th className="py-2 text-[#5F6B88] font-medium text-sm px-8">
                          Наличие теста
                        </th>
                        <th className="py-2 text-left text-[#5F6B88] font-medium text-sm">
                          Домашнее задание
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedTopic.lessons.map(lesson => (
                        <tr key={lesson.id} className="border-t text-black">
                          <td className="py-2 font-medium text-[15px] text-unix-text-black">
                            {lesson.title}
                          </td>
                          <td className="py-2 flex justify-end items-center">
                            <p>
                              <span className="text-[#0068FF] text-[32px] pb-1 mr-1">
                                {lesson.videoWatched ? (
                                  Math.floor(lesson.videoWatched / 60)
                                ) : (
                                  <span className="text-[#AFB7CA]">-</span>
                                )}
                              </span>
                              <span className="text-[#AFB7CA] text-[15px] font-light ml-1">
                                min
                              </span>
                            </p>
                          </td>
                          <td className="py-2">
                            <div className="flex flex-row items-center justify-center">
                              {lesson.id == '74' ? (
                                <img
                                  className="w-6 h-6"
                                  src={greenTick}
                                  alt="Completed"
                                />
                              ) : (
                                <img
                                  src={redCross}
                                  className=""
                                  alt="Not Completed"
                                />
                              )}
                            </div>
                          </td>
                          <td className="py-2 flex items-center justify-center">
                            <div className="flex items-center">
                              <p className="text-[#0068FF] text-[32px] font-[500]">
                                {lesson.quizes && lesson.quizes.length > 0 ? (
                                  <img
                                    className="w-6 h-6"
                                    src={greenTick}
                                    alt="Completed"
                                  />
                                ) : (
                                  <img
                                    className="w-6 h-6"
                                    src={redCross}
                                    alt="Not Completed"
                                  />
                                )}
                              </p>
                            </div>
                          </td>
                          <td className="py-2">
                            {homeWork[lesson.id] ? (
                              <div
                                className="flex flex-row gap-1 px-4 text-[#0068FF] text-[15px] font-bold rounded-[8px] border border-[#0068FF] justify-center py-2"
                                onClick={() => handleEditClick(lesson)}
                              >
                                <img src={editBlueIcon} alt="Edit" />
                                Редактировать
                              </div>
                            ) : (
                              <div
                                className="flex flex-row gap-2 text-[#3BBC30] text-[15px] font-bold rounded-[8px] border border-[#3BBC30] justify-center py-2"
                                onClick={() => {
                                  setSelectedLessonId(lesson.id)
                                  setSelectedLessonTitle(lesson.title)
                                  setHwModal(true)
                                }}
                              >
                                <img src={plus} alt="Add" />
                                Добавить
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {hwModal && (
              <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="bg-white p-8 rounded-lg w-[520px]">
                  <div className="flex justify-end mb-2 ml-[0px]">
                    <img
                      onClick={() => handleCloseModal()}
                      className="text-end"
                      src={closeIcon}
                      alt="Close"
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-[15px] font-normal text-[#5F6B88]">
                        Домашняя работа урока
                      </p>
                      <p className="text-[24px] font-normal mb-[32px] text-[#1A1A1A]">
                        {selectedLessonTitle}
                      </p>
                    </div>
                    {fileLink ? (
                      <button
                        className="text-red-500"
                        onClick={() => handleDelete(selectedLessonId)}
                      >
                        Удалить домашнюю работу
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                  <p className="text-[#5F6B88] mb-[8px]">Дедлайн</p>
                  <DatePicker
                    customInput={<CustomInput />}
                    selected={deadline}
                    onChange={handleDateChange}
                    dateFormat="dd.MM.yyyy"
                    value={deadline ? formatDate(deadline) : ''}
                  />
                  <p className="text-[#5F6B88] mt-[32px]">Комментарий</p>
                  <div className="mt-2">
                    <textarea
                      placeholder="Текст комментария"
                      className="resize-none w-[456px] pl-2 pt-2 text-black bg-[#F0F3FA] h-[177px] rounded-[8px] border-none"
                      value={comment}
                      onChange={e => setComment(e.target.value)}
                    />
                  </div>
                  <div className="mb-2">
                    <div className="relative w-[100%] mt-4">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-2">
                          {file && (
                            <p
                              className="text-[#0068FF] text-[14px] flex items-center justify-between
															border-2  border-[#F0F3FA] rounded-[8px] p-[12px]"
                            >
                              <div className="flex items-center">
                                <img
                                  className="h-[42px] w-[35px] "
                                  src={pdfIcon}
                                />
                                <div className="ml-[12px]">
                                  <span
                                    className={
                                      'text-[14px] font-medium text-[#1A1A1A]'
                                    }
                                  >
                                    {file.name}
                                  </span>
                                  <p
                                    className={
                                      'text-[#5F6B88] text-[13px] font-semibold'
                                    }
                                  >
                                    {formattedDate}{' '}
                                    {sizeInMB != 0 && `• ${sizeInMB} MB`}{' '}
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-center gap-[8px]">
                                <a
                                  className="cursor-pointer"
                                  href={`https://safety-fiirst.fra1.cdn.digitaloceanspaces.com/homeworks/teachers/${file.name}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img src={additionalEye} alt="View file" />
                                </a>
                                <div
                                  className="cursor-pointer"
                                  onClick={event =>
                                    handleDownload(event, file.name)
                                  }
                                >
                                  <img
                                    src={additionalDownload}
                                    alt="View file"
                                  />
                                </div>
                                {!fileLink && (
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => setFile(null)}
                                  >
                                    <img
                                      src={additionalDelete}
                                      alt="View file"
                                    />
                                  </div>
                                )}
                              </div>
                            </p>
                          )}
                        </div>
                        <button
                          type="button"
                          className="relative flex items-center justify-center gap-[8px] border border-[#0068FF] text-[#0068FF] w-[100%] px-4 py-2 rounded-md font-[700] text-[15px]"
                        >
                          <img src={insertFile} alt="Upload icon" />
                          {file ? 'Поменять Файл' : 'Загрузить Файл'}
                          <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            className="absolute inset-0 opacity-0 cursor-pointer"
                          />
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="flex justify-center mt-6 gap-3">
                    <button
                      className="flex w-full items-center px-4 py-3 text-white rounded bg-[#5F6B88] hover:bg-gray-600 justify-center gap-2 font-bold"
                      onClick={() => setHwModal(false)}
                    >
                      <img src={leftIcon} alt="Back" />
                      Вернуться
                    </button>
                    <button
                      className="flex w-full items-center px-4 py-3 bg-[#3BBC30] hover:bg-green-500 text-white rounded justify-center gap-2 font-bold"
                      onClick={handleSubmit}
                    >
                      <img src={tick} alt="Save" />
                      Сохранить
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CourseInfo

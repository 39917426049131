import { useEffect, useState } from "react"
import { additionalMaterialIcons } from "../../../../use-cases/constants"
import eyeGrayBox from '../../../../assets/eye-gray-box.svg'
import uploadBlue from '../../../../assets/upload-blue.svg'

export const LessonMaterialUpload = ({ lesson, file, updateFile, meta }) => {
    const [base64, setBase64] = useState('')

    useEffect(() => {
        if(file?.name?.length && meta.shouldReadBase64) {
            const reader = new FileReader();
            reader.onload = function(e) {
                setBase64(e.target.result)
            }
            reader.readAsDataURL(file);
        }
    }, [file?.name])

    const renderExistingFile = () => {
        let extension = '', fileName = '', icon, link
        if(base64.length) {
            link = base64
        }
        if(file?.name?.length) {
            fileName = file.name.split('.').at(-2)
            extension = file.name.split('.').at(-1)
        } else if(meta.existingField in lesson || meta.existingFieldType === "custom") {
            if(meta.existingFieldType === "custom") {
                return meta.existingField(lesson)
            }
            if(!lesson[meta.existingField]?.length) {
                return null
            }
            link = lesson[meta.existingField]
            const splitted = link.split('.')
            if(splitted.length > 2) {
                extension = splitted.at(-1)
                const linkSplitted = splitted.at(-2).split('/')
                fileName = linkSplitted.at(-1)
            }
        }
        const extensionIcon = additionalMaterialIcons.find(i => i.extensions.includes(extension))
        if(extensionIcon) {
            icon = extensionIcon.icon
        }
        return (
            <div className="mt-2 p-3 border border-unix-background-blue flex flex-col items-center rounded-lg">
                <div className="flex flex-row justify-between items-center w-full">
                    {Boolean(icon) && (
                        <img className="mr-3 w-[35px] h-[42px]" src={icon} />
                    )}
                    <p className="text-sm text-unix-text-black truncate flex-1">
                        {fileName}.{extension}
                    </p>
                    {Boolean(!file?.name?.length || (file?.name?.length && base64.length)) && (
                        <div className="flex flex-row">
                            <a href={link} download={base64.length ? `${fileName}.${extension}` : false} target="_blank" rel="noreferrer">
                                <img src={eyeGrayBox} />
                            </a>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col mb-4">
            <p className="text-unix-text-black">
                {meta.label}
            </p>
            <p className="text-[15px] text-unix-gray">
                Загруженные файлы
            </p>
            {renderExistingFile()}
            <label className="mt-8 cursor-pointer">
                <input hidden type="file" onChange={updateFile(meta.field)} />
                <div className="flex flex-row w-full border border-unix-blue py-3 items-center justify-center rounded-md">
                    <img src={uploadBlue} />
                    <span className="ml-2 text-[15px] font-bold text-unix-blue">
                        Загрузить файл
                    </span>
                </div>
            </label>
        </div>
    )
}
import { Box, CircularProgress, Modal } from '@mui/material'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { useNavigate } from 'react-router-dom'
import { simpleGetter } from '../../../actions/common'
import {
  deleteImage,
  getCertificates,
  getImage,
  uploadImage
} from '../../../actions/profile'
import { showSnackbar } from '../../../actions/snackbar'
import { userApi } from '../../../api'
import { useAvatar } from '../../../contexts/AvatarContext'
import { useGlobalUserState } from '../../../stores/user'
import { localize } from '../../../use-cases/constants'
import invisibleIcon from '../auth/Invisible.svg'
import visibleIcon from '../auth/Visible.svg'
import bucket from './bucket.svg'
import closeIcon from './closeIcon.svg'
import deletePhoto from './deletePhoto.svg'
import doorIcon from './doorIcon.svg'
import editPhoto from './editPhoto.svg'
import exitIcon from './exitIcon.svg'
import inserPhoto from './insertPhoto.svg'
import leftIcon from './leftArrowIcon.svg'
import tick from './tick.svg'
import warningIcon from './warningIcon.svg'
import cross from './white-cross.svg'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

const AvatarEditorComponent = ({ image, onSave, onCancel }) => {
  const editorRef = useRef(null)
  const [scale, setScale] = useState(1)

  const handleSave = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImage().toDataURL()
      onSave(canvas)
    }
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded shadow-lg">
        <AvatarEditor
          ref={editorRef}
          image={image}
          width={250}
          height={250}
          border={50}
          borderRadius={125}
          color={[255, 255, 255, 0.6]}
          scale={scale}
          rotate={0}
          style={{ cursor: 'move' }}
        />
        <div className="controls flex items-center mt-4 gap-2">
          <p className="text-[#5F6B88] text-[24px]">-</p>
          <input
            type="range"
            value={scale}
            min="1"
            max="3"
            step="0.1"
            onChange={e => {
              setScale(parseFloat(e.target.value))
            }}
            className="slider w-full"
          />
          <p className="text-[#5F6B88] text-[24px]">+</p>
        </div>
        <div className="flex justify-end gap-4 mt-4">
          <button
            onClick={onCancel}
            className="bg-[#F12D2D] flex gap-2 items-center justify-center rounded-full w-[50%] text-white py-2 px-4 h-[48px]"
          >
            <img src={cross} alt="Cancel" />
            <p>Отменить</p>
          </button>
          <button
            onClick={handleSave}
            className="bg-[#3BBC30] flex gap-2 items-center justify-center rounded-full w-[50%] text-white py-2 px-4 h-[48px] mr-2"
          >
            <img src={tick} alt="Save" />
            <p>Сохранить</p>
          </button>
        </div>
      </div>
    </div>
  )
}

const base64ToFile = (base64String, filename) => {
  const [header, data] = base64String.split(',')
  const mimeString = header.split(':')[1].split(';')[0]
  const byteCharacters = atob(data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  return new File([byteArray], filename, { type: mimeString })
}

export const PlatformProfile = () => {
  const navigate = useNavigate()
  const userState = useGlobalUserState()
  const user = userState.get()?.user || {}
  const [modalOpen, setModalOpen] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false)
  const [certificates, setCertificates] = useState([])
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const avatarObject = useAvatar()
  const avatarUrl = avatarObject?.avatarUrl || ""
  const setAvatarUrl = avatarObject?.setAvatarUrl || (() => {})
  const [showEditor, setShowEditor] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isExitModal, setIsExitModal] = useState(false)

  let avatar = ''
  if (user.firstname?.length && user.lastname?.length) {
    const firstNameParts = user.firstname.trim().split(' ')
    let firstNameInitials = ''

    if (firstNameParts.length > 0) {
      firstNameInitials += firstNameParts[0].charAt(0)
    }
    if (firstNameParts.length > 1) {
      firstNameInitials += firstNameParts[1].charAt(0)
    }

    const lastNameInitial = user.lastname.charAt(0)

    avatar += firstNameInitials + lastNameInitial
  }

  const handleModalClose = () => setModalOpen(false)

  const updatePassword = async () => {
    if (repeatNewPassword !== newPassword) {
      showSnackbar('Пароли не совпадают', 'error')
      return
    }
    if (newPassword.length < 6) {
      showSnackbar('Минимальная длина пароля - 6 символов', 'error')
      return
    }
    setResetPasswordLoading(true)
    const response = await simpleGetter(userApi.updatePassword, {
      password: newPassword
    })
    setResetPasswordLoading(false)
    if (response.id) {
      setModalOpen(false)
      showSnackbar('Пароль успешно изменён!')
    }
  }

  const getStudentCertificates = async () => {
    const response = await getCertificates()
    console.log('response ', response)
    if (response.success) {
      setCertificates(response.certificates)
    }
  }
  const togglePasswordVisibility = setFunc => () => {
    setFunc(s => !s)
  }
  useEffect(() => {
    getStudentCertificates()
  }, [])

  useEffect(() => {
    const elements1 = document.querySelectorAll('.css-1q0v17x')
    const elements2 = document.querySelectorAll('.css-11plsb8')
    elements1.forEach(element => (element.style.padding = '0'))
    elements2.forEach(element => (element.style.padding = '0'))

    return () => {
      elements1.forEach(element => (element.style.padding = ''))
      elements2.forEach(element => (element.style.padding = ''))
    }
  }, [])

  const fetchAvatar = async () => {
    const response = await getImage()
    if (response.success) {
      setAvatarUrl(response.data.filePath)
    }
  }

  useEffect(() => {
    fetchAvatar()
  }, [])

  const handleFileChange = event => {
    const file = event.target.files[0]
    if (file) {
      setSelectedFile(file)
      setShowEditor(true)
      setShowMenu(false)
    }
  }

  const handleFileUpload = async file => {
    if (file) {
      const formData = new FormData()
      formData.append('file', file)

      const response = await uploadImage(file)
      if (response.success) {
        setAvatarUrl(response.img)
        showSnackbar('Image uploaded successfully!', 'success')
      } else {
        showSnackbar('Failed to upload image', 'error')
      }
      fetchAvatar()
    }
  }

  const handleSave = image => {
    const file = base64ToFile(image, 'cropped-image.png')
    handleFileUpload(file)
    setShowEditor(false)
  }

  const handleDeletePhoto = async () => {
    const result = await deleteImage()
    if (result.success) {
      setAvatarUrl(null)
      setShowMenu(false)
      setIsDeleteModalOpen(false)
    } else {
      console.error('Failed to delete photo:', result.error)
    }
  }

  const formatDate = dateString => {
    const date = new Date(dateString)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()

    return `${day}.${month}.${year}`
  }

  const goToCertificate = (id) => () => {
    navigate(`/platform/profile/certificates/${id}`)
  }

  const formatDuration = totalSeconds => {
    const hours = Math.floor(totalSeconds / 3600)
    const minutes = Math.floor((totalSeconds % 3600) / 60)
    const formattedMinutes = String(minutes).padStart(2, '0')

    if (hours > 0) {
      return `${hours} ч ${formattedMinutes} мин`
    } else {
      return `${formattedMinutes} мин`
    }
  }

  return (
    <div className={'pt-7 dark:bg-[#1a1a1a]'}>
      <div className={'flex flex-row col-span-12 mb-6 max-w-[1280px] mx-auto'}>
        <p
          className={
            'text-black dark:text-white font-bold text-[35px] ml-4 dark:'
          }
        >
          {localize('profile')}
        </p>
      </div>
      <div className="bg-[#F0F3FA] dark:bg-black h-screen">
        <div className="grid grid-cols-12 max-w-[1280px] mx-auto">
          <div className="col-span-4 max-[1000px]:col-span-12">
            <p className="text-[24px] text-[#AFB7CA] mt-[32px] ml-[20px]">
              {localize('personal_info')}
            </p>
            <div className="bg-[white] dark:bg-[#1A1A1A] rounded-[20px] profile-card py-8 mt-[24px] w-[413px] max-xl:w-[307px] ml-[20px] max-[1000px]:max-w-[950px] max-[1000px]:w-[95%] max-[1000px]:mx-auto">
              <div className="mb-6 md:mb-0 flex flex-col justify-center items-center">
                <div className="rounded-full relative bg-[#f5bfb9] w-[140px] h-[140px] flex items-center justify-center">
                  <div className="relative">
                    {avatarUrl ? (
                      <img
                        src={avatarUrl}
                        className="rounded-full object-cover w-full h-full"
                      />
                    ) : (
                      <span className="text-white font-extrabold text-[48px] ">
                        {avatar}
                      </span>
                    )}
                  </div>
                  <img
                    className="absolute right-2 bottom-0 cursor-pointer"
                    src={editPhoto}
                    width={36}
                    height={36}
                    onClick={() => setShowMenu(!showMenu)}
                  />
                  {showMenu && (
                    <div className="w-[250px] absolute bottom-[-115px] right-[-90px] bg-white dark:bg-black shadow-lg rounded-lg p-4">
                      <input
                        type="file"
                        onChange={handleFileChange}
                        className="hidden"
                        id="fileInput"
                      />
                      <label
                        htmlFor="fileInput"
                        className="flex gap-2 dark:text-[#AFB7CA] items-center cursor-pointer py-2"
                      >
                        <img src={inserPhoto} />
                        {localize('insert_photo')}
                      </label>
                      <button
                        className="whitespace-nowrap dark:text-[#AFB7CA] gap-2 w-full text-left py-2 flex items-center"
                        onClick={() => setIsDeleteModalOpen(true)}
                      >
                        <img src={deletePhoto} />
                        {localize('delete_photo')}
                      </button>
                    </div>
                  )}
                </div>
                {isDeleteModalOpen && (
                  <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white dark:bg-black p-4 rounded-[16px] shadow-lg w-[520px] max-[900px]:w-[450px] max-[500px]:w-[340px]">
                      <div className="flex justify-end mb-4 cursor-pointer">
                        <img
                          onClick={() => setIsDeleteModalOpen(false)}
                          src={closeIcon}
                        />
                      </div>
                      <div className="flex items-center gap-4 max-[500px]:w-[90%]">
                        <img src={warningIcon} />
                        <h4 className="text-[#5F6B88] dark:text-white w-[400px]">
                          {localize('delete_profile')}
                        </h4>
                      </div>
                      <div className="flex justify-end mt-12">
                        <button
                          onClick={() => setIsDeleteModalOpen(false)}
                          className="bg-[#5F6B88] text-white h-[48px] rounded-full w-full flex items-center justify-center gap-2"
                        >
                          <img src={leftIcon} />
                          Вернуться
                        </button>
                        <button
                          onClick={handleDeletePhoto}
                          className="bg-[#F12D2D] text-white h-[48px] ml-3 rounded-full w-full flex items-center justify-center gap-2"
                        >
                          <img src={bucket} />
                          Удалить
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex flex-col text-center justify-center items-center">
                  <p className="text-[#1A1A1A] dark:text-white font-semibold mt-[10px] text-[24px]">
                    {user.firstname} {user.lastname}
                  </p>
                  <p className="text-[#AFB7CA] text-[16px] dark:text-white">
                    {user.email}
                  </p>
                  <div className="flex justify-center  items-center border border-[#F12D2D]  py-[12px] rounded-full mt-[32px] w-[349px] max-xl:w-[243px]">
                    <div
                      className="mx-auto flex items-center"
                      onClick={() => setIsExitModal(true)}
                    >
                      <img className="w-[24px] h-[24px]" src={exitIcon} />
                      <p className="text-[15px] text-[#F12D2D] font-bold ml-[8px] whitespace-nowrap">
                        {localize('account_out')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <LogoutModal
            isExitModal={isExitModal}
            setIsExitModal={setIsExitModal}
          />
          {showEditor && (
            <AvatarEditorComponent
              image={URL.createObjectURL(selectedFile)}
              onSave={handleSave}
              onCancel={() => setShowEditor(false)}
            />
          )}
          <div className="col-span-8 max-[1000px]:col-span-12 bg-[#F0F3FA] dark:bg-black pb-10">
            <p className="text-[24px] text-[#AFB7CA] mt-[32px] ml-[20px]">
              {localize('certificates')}
            </p>
            <div className="grid grid-cols-8 max-[1000px]:grid-cols-12 ml-[20px] mt-[24px] gap-[20px]">
              {certificates?.map(cert => (
                <>
                  <div
                    className={`group col-span-4 max-[1000px]:col-span-6 text-[16px] bg-white dark:bg-[#1a1a1a] max-xl:w-[306px] rounded-[20px] p-8 items-center hover:bg-[#0068FF] max-[1000px]:w-[95%] max-[1000px]:p-4 max-[640px]:col-span-12 ${cert?.certificate?.link ? 'cursor-pointer' : 'cursor-default'}`}
                    onClick={goToCertificate(cert.certificate?.id)}
                  >
                    <p className="text-[#AFB7CA] group-hover:text-white group-hover:opacity-50">
                      {localize('course')}
                    </p>
                    <div className="text-[24px] font-semibold text-[#1a1a1a] dark:text-white group-hover:text-white">
                      {cert?.courseTitle}
                    </div>
                    <div className="text-[#AFB7CA] group-hover:text-white">
                      <span className="group-hover:opacity-50">
                        {localize('from_module')}
                      </span>{' '}
                      <span className="text-[#5F6B88] group-hover:opacity-100 group-hover:font-bold dark:text-white group-hover:text-white font-medium">
                        {cert?.moduleTitle}
                      </span>
                    </div>
                    <div className="flex justify-between mt-[58px]">
                      <div>
                        <p className="text-[#AFB7CA] xl:whitespace-nowrap max-xl:whitespace-wrap group-hover:text-white group-hover:opacity-50">
                          {localize('completed_date')}
                        </p>
                        <p className="font-medium group-hover:text-white dark:text-white">
                          {formatDate(cert?.completionDate)}
                        </p>
                      </div>
                      <div>
                        <p className="text-[16px] text-[#AFB7CA] xl:whitespace-nowrap max-xl:whitespace-wrap group-hover:text-white group-hover:opacity-50">
                          {localize('total_duration')}
                        </p>
                        <p className="group-hover:text-white dark:text-white">
                          {formatDuration(cert?.totalVideoWatched)}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className="flex flex-col">
            <span className={'text-platform-black text-base font-medium mb-3'}>
              Введите новый пароль
            </span>
            <div className="relative">
              <input
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                type={showNewPassword ? 'text' : 'password'}
                placeholder="Введите свой пароль"
                className="py-3 px-5 block w-full border-basic rounded-md border-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2.5"
                style={{ paddingRight: '60px' }}
              />
              <button
                className="absolute top-[47%] right-[5%]"
                type="button"
                onClick={togglePasswordVisibility(setShowNewPassword)}
                style={{ zIndex: '1' }}
              >
                <img
                  src={showNewPassword ? visibleIcon : invisibleIcon}
                  width="16px"
                  height="16px"
                  alt="Toggling password visibility"
                />
              </button>
            </div>
            <div className="relative">
              <input
                value={repeatNewPassword}
                onChange={e => setRepeatNewPassword(e.target.value)}
                type={showRepeatNewPassword ? 'text' : 'password'}
                placeholder="Введите свой пароль"
                className="py-3 px-5 block w-full border-basic rounded-md border-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mt-2.5"
                style={{ paddingRight: '60px' }}
              />
              <button
                className="absolute top-[47%] right-[5%]"
                type="button"
                onClick={togglePasswordVisibility(setShowRepeatNewPassword)}
                style={{ zIndex: '1' }}
              >
                <img
                  src={showRepeatNewPassword ? visibleIcon : invisibleIcon}
                  width="16px"
                  height="16px"
                  alt="Toggling password visibility"
                />
              </button>
            </div>
            <button
              className={
                'bg-platform-blue px-2.5 py-2.5 text-white cursor-pointer mt-4 rounded-lg text-center platform-auth-button'
              }
              onClick={updatePassword}
              disabled={resetPasswordLoading}
            >
              {resetPasswordLoading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#FFFFFF'
                  }}
                />
              ) : (
                <p className={'text-xl font-medium'}>Сбросить пароль</p>
              )}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

const LogoutModal = ({ isExitModal, setIsExitModal }) => {
  const navigate = useNavigate()
  const userState = useGlobalUserState()

  const logOut = () => {
    userState.set({})
    navigate('/platform/login')
  }

  return (
    isExitModal && (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white dark:bg-black p-4 rounded-[16px] shadow-lg w-[520px] max-[900px]:w-[450px] max-[500px]:w-[340px]">
          <div className="flex justify-end cursor-pointer">
            <img
              onClick={() => setIsExitModal(false)}
              src={closeIcon}
              alt="Close"
            />
          </div>
          <div className="flex items-center gap-4">
            <img src={warningIcon} alt="Warning" />
            <h4 className="text-[#5F6B88] dark:text-white text-[15px]">
              {localize('log_modal')}
            </h4>
          </div>
          <div className="flex justify-start gap-[12px] mt-[32px]">
            <button
              onClick={() => setIsExitModal(false)}
              className="bg-[#5F6B88] text-white h-[48px] rounded-full w-[222px] flex items-center justify-center gap-2"
            >
              <img src={leftIcon} alt="Back" />
              Вернуться
            </button>
            <button
              onClick={logOut}
              className="bg-[#F12D2D] text-white h-[48px] ml-3 rounded-full w-[222px] flex items-center justify-center gap-2"
            >
              <img className="w-[24px] h-[24px]" src={doorIcon} alt="Logout" />
              Выйти
            </button>
          </div>
        </div>
      </div>
    )
  )
}

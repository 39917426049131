import {hookstate, useHookstate} from '@hookstate/core';
import { localstored } from '@hookstate/localstored';

const store = hookstate({}, localstored({
    key: 'user-store',
    engine: localStorage
}))

const wrapState = (s) => ({
    get: () => s.value,
    set: (newVal) => s.set(newVal),
    setUser: (body) => s.user.set(body)
})

export const accessGlobalUserState = () => wrapState(store)
export const useGlobalUserState = () => wrapState(useHookstate(store))
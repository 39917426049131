import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { simpleGetter } from "../../../actions/common"
import { userApi } from "../../../api"
import { NotFound } from "../../pages"

export const VerifyCertificate = () => {
    const { certificateId } = useParams()
    const [notFound, setNotFound] = useState(false)

    const getCertificate = async () => {
        const response = await simpleGetter(userApi.getCertificateByProductId(certificateId))
        if(response.link) {
            location = response.link
        } else {
            setNotFound(true)
        }
    }

    useEffect(() => {
        getCertificate()
    }, [])

    if(notFound) {
        return (
            <NotFound />
        )
    }
}
import axios from "axios"
import { roomsApi } from "../api"
import { accessGlobalUserState } from "../stores/user"
import { showSnackbar } from "./snackbar"

const createRoomFile = async (id, body) => {
    const data = {}
    try {
        const response = await roomsApi.createRoomFile(id, body)
        if (response.data.createdUsers) {
            showSnackbar()
            data.success = true
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const updateRoom = async (id, roomId, body) => {
    const data = {}
    try {
        const response = await roomsApi.updateRoom(id, roomId, body)
        if (response.data.id) {
            showSnackbar()
            data.success = true
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const createRoom = async (id) => {
    const data = {}
    try {
        const response = await roomsApi.createRoom(id)
        if (response.data.id) {
            showSnackbar()
            data.success = true
            data.id = response.data.id
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const downloadRooms = async (id) => {
    const data = {}
    try {
        const response = await axios({
            url: `https://uni-x-api-dev.kz/courses/${id}/download`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${accessGlobalUserState().get().token}`
            }
        })
        const data = await response.data
        const link = document.createElement('a');
        link.href = URL.createObjectURL(data)
        link.download = `room_${id}.xlsx`;
        document.body.append(link);
        link.click();
        link.remove();
        showSnackbar()
    }
    catch (e) {
        console.log(e)
        data.success = false
    }
    return data
}

const deleteRoom = async (id) => {
    const data = {}
    try {
        const response = await roomsApi.delete(id)
        if (response.data.id) {
            showSnackbar()
            data.success = true
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const addUser = async (id, body) => {
    const data = {}
    try {
        const response = await roomsApi.addUser(id, body)
        if (response.data.id) {
            showSnackbar()
            data.success = true
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getRooms = async (id) => {
    const data = {}
    try {
        const response = await roomsApi.getRooms(id)
        if (response.data?.rooms instanceof Array) {
            data.success = true
            data.rooms = response.data.rooms
            data.users = response.data.users
            data.usersWithProbabilities = response.data.usersWithProbabilities
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

export {
    createRoomFile,
    createRoom,
    addUser,
    downloadRooms,
    deleteRoom,
    getRooms,
    updateRoom
}
import { PieChart } from '@mui/x-charts/PieChart'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getCourse } from '../../../actions/course'
import { getModule } from '../../../actions/module'
import { getOrganization } from '../../../actions/organization'
import { getTopic } from '../../../actions/topic'
import { useCourseStats } from '../../../hooks/courses'
import { localize } from '../../../use-cases/constants'
import back from './../../platform/courses/back.svg'
import RightArrow from './right-arrow.svg'
import Teachers from './teacher.svg'

export const StatisticsCourse = () => {
  const { courseId } = useParams()
  const [course, setCourse] = useState({})
  const [organization, setOrganization] = useState({})
  const [expanded, setExpanded] = useState({})
  const [lessons, setLessons] = useState({})
  const stats = useCourseStats(courseId, course.moduleId)
  // const [expandedAccordion1, setExpandedAccordion1] = useState(false);
  // const [expandedAccordion2, setExpandedAccordion2] = useState(false);
  const navigate = useNavigate()
  const location = useLocation()

  const [module, setModule] = useState(location.state?.module || {})
  const fetchCourse = async id => {
    const response = await getCourse(id)
    if (response.success) {
      setCourse(response.course)
    }
  }
  const fetchModule = async id => {
    const response = await getModule(id)
    if (response.success) {
      setModule(response.module)
    }
  }
  const fetchOrganization = async id => {
    const response = await getOrganization(id)
    if (response.success) {
      setOrganization(response.organization)
    }
  }
  const getLessons = async topics => {
    const obj = {}
    for (let topic of topics) {
      const response = await getTopic(topic.id)
      if (response.topic?.lessons) {
        obj[topic.id] = response.topic?.lessons
      }
    }
    setLessons(obj)
  }

  const goBack = () => {
    if (module.id) {
      navigate(`/admin/statistics/`)
    }
  }

  useEffect(() => {
    fetchCourse(courseId)
  }, [courseId])
  useEffect(() => {
    if (course.moduleId && !module.id) {
      fetchModule(course.moduleId)
    }
    if (course.topics && course.topics.length) {
      if (Object.keys(expanded).length === 0) {
        setExpanded(
          course.topics
            .map(t => ({ [t.id]: false }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {})
        )
      }
      if (Object.keys(lessons).length === 0) {
        getLessons(course.topics)
      }
    }
  }, [course])
  useEffect(() => {
    if (module.organizationId && !organization.id) {
      fetchOrganization(module.organizationId)
    }
  }, [module])
  console.log(expanded)

  return (
    <div className="grid grid-cols-12 mt-10 ml-4">
      <div className={'col-span-12'}>
        <p className={'platform-black text-black text-lg font-medium'}>
          Главная
        </p>
        <div className={'flex flex-row mt-4 items-center'}>
          <img className={'cursor-pointer'} src={back} onClick={goBack} />
          <p
            className={'text-base text-black ml-6 cursor-pointer'}
            onClick={goBack}
          >
            {localize('back')}
          </p>
        </div>
      </div>
      <div className="col-span-12 grid grid-cols-10">
        <div className={'col-span-12'}>
          <div className={'flex gap-2 items-center px-1 py-7'}>
            <p className={'text-xl text-[#263238] font-medium'}>
              Cтатистика по
            </p>
            <p className="text-[#263238] text-2xl font-black">{course.title}</p>
          </div>
        </div>
        {/* <div className={"md:col-span-6 md:col-start-5 col-span-12 col-start-0"}>
                    <CourseNavigation course={course} organization={organization}/>
                </div> */}
      </div>
      <div className="col-span-12 grid grid-cols-12 gap-12">
        <div className="col-span-4 text-black bg-white rounded-xl pt-5 pl-5">
          <p className="font-black">Информация о курсе</p>
          <div className="flex flex-col gap-3 mt-5">
            <p>Лекций: {course.topics?.length}</p>
            <p>Уроков: {stats.lessonCount}</p>
            {/* <p>Общая длительность курса: # недель</p> */}
          </div>
        </div>
        <div className="col-span-5 flex flex-col gap-5 text-black">
          <div className="bg-white rounded-xl py-2 pl-4 flex justify-between">
            <p className="text-xl">Общее кол-во студентов на курсе</p>
            <div>
              <PieChart
                series={[
                  {
                    data: [
                      { id: 2, value: stats.userTotalCount, color: '#26cd1a' }
                    ],
                    innerRadius: 30,
                    outerRadius: 25,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    startAngle: 0,
                    endAngle: 360,
                    cx: 40,
                    cy: 40
                  }
                ]}
                width={100}
                height={75}
              >
                <text
                  x="45"
                  y="45"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fontSize="16"
                  fill="black"
                >
                  {stats.userTotalCount}
                </text>
              </PieChart>
            </div>
          </div>
          <div className="bg-white rounded-xl py-2 pl-4 flex justify-between">
            <div>
              <p className="text-xl">Активные аккаунты</p>
              <p className="text-[#BCBCBC]">Кол-во</p>
              <p className="mt-[-5px] text-[#BCBCBC]">
                активированных аккаунтов
              </p>
            </div>
            <div>
              <PieChart
                series={[
                  {
                    data: [
                      { id: 2, value: stats.activeUsers, color: '#26cd1a' }
                    ],
                    innerRadius: 30,
                    outerRadius: 25,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    startAngle: 0,
                    endAngle: (stats.activeUsers / stats.userTotalCount) * 360,
                    cx: 40,
                    cy: 40
                  }
                ]}
                width={100}
                height={75}
              >
                <text
                  x="45"
                  y="45"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fontSize="16"
                  fill="black"
                >
                  {stats.activeUsers}
                </text>
              </PieChart>
            </div>
          </div>
          <div className="bg-white rounded-xl py-2 pl-4 flex justify-between">
            <p className="text-xl">Получено сертификатов</p>
            <div>
              <PieChart
                series={[
                  {
                    data: [
                      { id: 2, value: stats.certificateCount, color: '#26cd1a' }
                    ],
                    innerRadius: 30,
                    outerRadius: 25,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    startAngle: 0,
                    endAngle:
                      (stats.certificateCount / stats.userTotalCount) * 360,
                    cx: 40,
                    cy: 40
                  }
                ]}
                width={100}
                height={75}
              >
                <text
                  x="45"
                  y="45"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fontSize="16"
                  fill="black"
                >
                  {stats.certificateCount}
                </text>
              </PieChart>
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-12 grid grid-cols-12 gap-6 text-black mt-4">
        {/* <div className="col-span-6 bg-white rounded-xl pt-5 pl-5 text-lg">
                    Студенты
                     <ChartContainer
                    width={500}
                    height={300}
                    series={[{ data: uData, label: uData, type: 'bar' }]}
                    xAxis={[{ scaleType: 'band', data: xLabels }]}
                    >
                        <BarPlot />
                        <xAxis dataKey="name" />
                        <yAxis />
                    </ChartContainer>
                    <div>
                        <p className="mt-4 mb-[-20px] text-sm">Кол-во людей, <br/> сдавших на <br/> такой балл</p>
                        <BarChart
                        xAxis={[{ scaleType: 'band', data: ['70%', '75%', '80%', '85%', '90%', '95%','100%'] }]}
                        series={[{
                            data: [5,6,7,8,2,8,6],
                        }]}
                        
                        width={500}
                        height={300}
                        />
                    </div>
                </div> */}
        <div className="col-span-6 bg-white rounded-xl pt-5 pl-5 mr-2">
          <p className="text-lg">Преподователи</p>
          <p className="text-[#BCBCBC]">График активности</p>
          <div className="flex flex-col">
            {stats.teachers?.map(t => (
              <div key={t.id} className="py-2 border-b-2 mr-10 flex justify-between">
                <div className="flex gap-2">
                  <img className="" src={Teachers} />
                  <div className="flex flex-col font-light">
                    <p className="">
                      {t.firstname || ''} {t.lastname || ''}
                    </p>
                    <p className="">{t.email}</p>
                  </div>
                </div>
                <img src={RightArrow} />
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <div className="flex flex-col h-full">
                        <p className="text-2xl">{currentExam.title}</p>
                        <Exam type="view" examQuestions={currentExam.examQuestions} />
                    </div>
                </Box>
            </Modal> */}
    </div>
  )
}

import { useEffect, useState } from "react"
import { useGlobalMiscState } from "../../../../stores/misc"

export const LessonMaterialVideoUpload = ({ lesson, file, updateFile, meta }) => {
    const miscState = useGlobalMiscState()
    const courseSelectedLang = miscState.get('courseSelectedLang')
    const [base64, setBase64] = useState('')

    useEffect(() => {
        if (file?.name?.length && meta.shouldReadBase64) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setBase64(e.target.result)
            }
            reader.readAsDataURL(file);
        }
    }, [file?.name])

    const renderPoster = () => {
        if (base64.length || lesson.backgroundImage?.length) {
            return (
                <img
                    src={base64 || lesson.backgroundImage}
                    className="w-full h-full object-contain"
                />
            )
        } else {
            return (
                <div className="flex flex-row items-center">
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 3.5V7.5C14 7.76522 14.1054 8.01957 14.2929 8.20711C14.4804 8.39464 14.7348 8.5 15 8.5H19" stroke="#0068FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M17 21.5H7C6.46957 21.5 5.96086 21.2893 5.58579 20.9142C5.21071 20.5391 5 20.0304 5 19.5V5.5C5 4.96957 5.21071 4.46086 5.58579 4.08579C5.96086 3.71071 6.46957 3.5 7 3.5H14L19 8.5V19.5C19 20.0304 18.7893 20.5391 18.4142 20.9142C18.0391 21.2893 17.5304 21.5 17 21.5Z" stroke="#0068FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12 11.5V17.5" stroke="#0068FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 14.5L12 11.5L15 14.5" stroke="#0068FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span className="ml-2 hover:text-white text-[15px] font-semibold leading-[18px] text-[#0068FF]">
                        Загрузить обложку
                    </span>
                </div>
            )
        }
    }

    const renderVideoField = () => {
        if (meta.type === "image") {
            return (
                <div className="flex flex-col">
                    <label className={`flex flex-col rounded-lg ${!(base64.length || lesson.backgroundImage?.length) ? 'hover-poster' : ''} bg-[rgba(0,104,255,0.12)] h-52 justify-center items-center cursor-pointer`}>
                        <input
                            hidden
                            type="file"
                            name="org-logo"
                            onChange={updateFile(meta.field)}
                            onClick={(e)=> { 
                                e.target.value = null
                            }}
                            {...(meta.accept?.length ? { accept: meta.accept } : {})}
                        />
                        {renderPoster()}
                    </label>
                    <span className="mt-0.5 self-center font-medium text-xs text-unix-light-gray">
                        {meta.bottomText}
                    </span>
                </div>
            )
        } else {
            const existingFieldKey = courseSelectedLang === "EN" ? 'existingField' : `existingField${courseSelectedLang}`
            const checkName = (file?.name?.length || lesson[meta[existingFieldKey]]?.length) ? (file?.name || lesson[meta[existingFieldKey]].split('/').at(-1)) : meta.buttonText
            return (
                <div className="flex flex-col">
                    <label className="border border-unix-blue rounded-md py-3 flex flex-row justify-center items-center cursor-pointer">
                        <img src={meta.icon} />
                        <span className="ml-2 font-semibold text-[15px] text-unix-blue overflow-hidden text-ellipsis whitespace-nowrap max-w-[80%]">
                            {checkName}
                        </span>
                        <input
                            hidden
                            type="file"
                            onChange={updateFile(meta.field)}
                            onClick={(e)=> { 
                                e.target.value = null
                            }}
                            {...(meta.accept?.length ? { accept: meta.accept } : {})}
                        />
                    </label>
                    <span className="mt-0.5 self-center font-medium text-xs text-unix-light-gray">
                        {meta.bottomText}
                    </span>
                </div>
            )
        }
    }

    return (
        <div className="flex flex-col mb-4">
            {renderVideoField()}
        </div>
    )
}
import * as React from 'react'
import adv1 from './adv1.jpg'
import adv2 from './Adv2.svg'
import kbtu from './kbtuLogo.svg'
import partners2 from './partners2.svg'
import partners3 from './partners3.svg'

const AdvantageItemWithImg = () => (
  <div className="relative max-xl:w-[100%]">
    <img src={adv1} />
    <p className="relative bottom-[15px] left-[10px] md:bottom-[80px] lg:w-[70%] md:w-[70%] md:left-[20px] text-[14px] w-[90%] max-[500px]:w-[65%] max-[500px]:bottom-[90px] max-[500px]:left-[15px]">
      Текст приемущества ИЛЛЮСТРАЦИИ должны нести смысл
    </p>
  </div>
)

const AdvantageItemWithIcon = () => (
  <div className="relative max-xl:left-0 max-xl:w-[100%] left-[-50px]">
    <img src={adv2} />
    <p className="relative bottom-[15px] left-[10px] md:bottom-[80px] lg:w-[70%] md:w-[70%] md:left-[20px] text-[14px] w-[90%] max-[500px]:w-[65%] max-[500px]:bottom-[90px] max-[500px]:left-[15px]">
      Текст приемущества Иконка должны нести смысл
    </p>
  </div>
)

function AdvSection() {
  return (
    <main className="flex flex-col flex-wrap gap-5 max-w-[1435px] mx-auto mt-20 max-sm:mt-0">
      <section className="flex-auto">
        <div className="flex flex-wrap items-end">
          <div className="flex flex-col w-full max-md:w-full">
            <div className="flex flex-col px-5 max-md:mt-2.5">
              <h1 className="text-6xl font-medium text-black leading-[64px] max-md:text-4xl max-md:leading-[52px]">
                Блок преимуществ
              </h1>
              <h2 className="mt-9 text-4xl tracking-tight text-black">
                Почему выбирают нас:
              </h2>
              <div className="mt-12 max-md:mt-10">
                <div className="flex max-md:flex-col max-md:items-center max-md:justify-center max-md:align-middle">
                  <AdvantageItemWithImg {...advantages[0]} />
                  <AdvantageItemWithIcon {...advantages[1]} />
                  <AdvantageItemWithIcon {...advantages[2]} />
                </div>
              </div>
            </div>
          </div>
          <aside className="flex relative top-[-65px] px-5">
            <div className="flex flex-col mt-10 font-semibold">
              <h3 className="text-[42px] font-semibold text-black">
                Партнеры:
              </h3>
              <div className="flex gap-5 items-center mt-1">
                <img
                  loading="lazy"
                  src={kbtu}
                  alt="Partner 1 Logo"
                  className="w-fit"
                />
                <img
                  loading="lazy"
                  src={partners2}
                  alt="Partner 1 Logo"
                  className="w-fit"
                />
                <img
                  loading="lazy"
                  src={partners3}
                  alt="Partner 1 Logo"
                  className="w-fit max-[375px]:hidden"
                />
              </div>
              <p className="mt-3 text-base text-neutral-600 max-[500px]:mt-10">
                Наши надежные партнеры
              </p>
            </div>
          </aside>
          <aside className="flex flex-col relative top-[-70px] ml-5">
            <div className="mb-10">
              <h3 className="text-[42px] font-semibold">24\7</h3>
              <p className="text-[18px]">Тех.поддержка</p>
            </div>
            <p className="text-[16px]">Добросовестная команда</p>
          </aside>
        </div>
      </section>
    </main>
  )
}

export default AdvSection

const advantages = [
  {
    src1: 'https://cdn.builder.io/api/v1/image/assets/TEMP/aa0ce010745ca00498878b86a416658e5cd78012b66ea7cc591de360ae637c8f?apiKey=f0565c05649044c09c5a9cc7ba5651cb&',
    src2: 'https://cdn.builder.io/api/v1/image/assets/TEMP/b5f20273a899b9f8199249aab069e1cc7dd95ed08de8a379dd65ef9a9a079212?apiKey=f0565c05649044c09c5a9cc7ba5651cb&',
    src3: 'https://cdn.builder.io/api/v1/image/assets/TEMP/22d31e9eddc657453e2df3914c6b815c36a1262c7025b63e95298083e008b7c8?apiKey=f0565c05649044c09c5a9cc7ba5651cb&',
    src4: 'https://cdn.builder.io/api/v1/image/assets/TEMP/32fd4caff859b0f6a32e3b2bad33881f84d1c0b89d047c14058ddd25cbcbca6b?apiKey=f0565c05649044c09c5a9cc7ba5651cb&',
    text: 'Текст приемущества'
  },
  {
    src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/ffd9803155d358474c1e5f24c9aff5cc61c58dfe9e8cc012249f0ac5a2041414?apiKey=f0565c05649044c09c5a9cc7ba5651cb&',
    icon: 'ic',
    text: 'Текст приемущества'
  },
  {
    src: 'https://cdn.builder.io/api/v1/image/assets/TEMP/ffd9803155d358474c1e5f24c9aff5cc61c58dfe9e8cc012249f0ac5a2041414?apiKey=f0565c05649044c09c5a9cc7ba5651cb&',
    icon: 'ic',
    text: 'Текст приемущества'
  }
]

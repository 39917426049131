import { Box, CircularProgress, Modal } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { resetPasswordEmail, signIn } from '../../../actions/user'
import { ThemeContext } from '../../../contexts'
import { useGlobalSnackBarState } from '../../../stores/snackbar'
import { inputClass, localize } from '../../../use-cases/constants'
import darkModeIcon from './darkModeIcon.svg'
import invisibleIcon from './Invisible.svg'
import lightModeIcon from './lightModeIcon.svg'
import passwordNotVisible from './passwordVisible.svg'
import passwordVisible from './passwordVisibleIcon.svg'
import logo from './unix_logo.svg'
import visibleIcon from './Visible.svg'
import './login.css'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
}

export const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [forgetEmail, setForgetEmail] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const snackbarState = useGlobalSnackBarState()
  const navigate = useNavigate()
  const [forgot, setForgot] = useState(false)
  const { theme, setTheme } = React.useContext(ThemeContext)

  console.log('theme color ', theme)

  const toggleTheme = () => {
    setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'))
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const login = async event => {
    event.preventDefault() // Prevent the default form submission
    if (email.length && password.length) {
      const response = await signIn(email, password)
      if (response.success) {
        navigate('/admin')
      } else {
        snackbarState.setText(response.error)
        snackbarState.setSeverity('error')
        snackbarState.setOpen(true)
      }
    }
  }

  const handleModalClose = () => setModalOpen(false)
  const resetPassword = async () => {
    setResetPasswordLoading(true)
    console.log('Current email:', forgetEmail)

    const response = await resetPasswordEmail(forgetEmail)

    setResetPasswordLoading(false)
    if (response.success) {
      console.log('Password reset successful')
      setForgetEmail('')
      handleModalClose()
    } else {
      console.log('Password reset failed', response.error)
    }
  }

  return (
    <div
      className={
        'bg-[#F0F3FA] overflow-y-hidden overflow-x-hidden flex items-center justify-center'
      }
    >
      <div className="customContainer">
        <div
          className={
            'flex flex-col h-[560px] w-[480px] bg-white dark:bg-[#1A1A1A] rounded-[12px] z-[10]'
          }
        >
          <div className={'flex flex-col justify-center'} onClick={login}>
            <div
              className={
                'flex flex-col items-center justify-center mb-[32px] mt-[32px]'
              }
            >
              <img width={'168px'} height={'56px'} src={logo} />
            </div>
          </div>
          <div
            className={'flex flex-col items-center justify-start text-center'}
          >
            {forgot ? (
              <>
                <p
                  className={
                    'text-[#AFB7CA] font-semibold text-[24px] mb-[32px]'
                  }
                >
                  Сброс пароля{' '}
                </p>
                <p className="text-[#5F6B88] text-[13px] w-[416px] max-[640px]:w-[320px] mb-[32px]">
                  Введите E-mail связанный с вашим аккаунтом и мы вышлем письмо
                  с инструкцией по смене пароля
                </p>
                <form
                  onSubmit={login}
                  className={'flex flex-col justify-center'}
                >
                  <div className={'flex flex-col'}>
                    <span
                      className={
                        'text-platform-black dark:text-[#AFB7CA] text-base self-start font-medium'
                      }
                    >
                      {localize('email')}
                    </span>
                    <input
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      type="email"
                      placeholder="Введите адрес электронной почты"
                      className={`max-[640px]:w-[320px] py-3 px-5 block text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 mt-[8px] bg-[#F0F3FA] rounded-[8px] border-0 w-[416px] dark:bg-[#f0f3fa]/[12%] dark:placeholder:text-[#5F6B88] dark:text-white ${
                        theme ? 'dark' : ''
                      }`}
                    />
                    <span
                      className="font-medium text-[14px] text-left cursor-pointer mt-[32px] text-[#0068FF]"
                      onClick={() => setForgot(false)}
                    >
                      Обычная форма входа{' '}
                    </span>
                    <button
                      className={
                        'bg-platform-blue h-[44px] justify-center text-white cursor-pointer mt-[32px] rounded-[8px] text-center platform-auth-button'
                      }
                      onClick={resetPassword}
                      disabled={resetPasswordLoading}
                    >
                      {resetPasswordLoading ? (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: '#FFFFFF'
                          }}
                        />
                      ) : (
                        <p className={'text-[18px] font-medium'}>
                          Сбросить пароль
                        </p>
                      )}
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <>
                <form
                  onSubmit={login}
                  className={'flex flex-col justify-center'}
                >
                  <div className={'flex flex-col'}>
                    <span
                      className={
                        'text-platform-black dark:text-[#AFB7CA] text-base self-start font-medium'
                      }
                    >
                      {localize('email')}
                    </span>
                    <input
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      type="email"
                      placeholder="Введите адрес электронной почты"
                      className={`max-[640px]:w-[320px] py-3 px-5 block text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 mt-2.5 bg-[#F0F3FA] rounded-[8px] border-0 w-[416px] dark:bg-[#f0f3fa]/[12%] dark:placeholder:text-[#5F6B88] dark:text-white ${
                        theme ? 'dark' : ''
                      }`}
                    />
                    <span
                      className={
                        'text-platform-black dark:text-[#AFB7CA] text-base self-start font-medium mt-7'
                      }
                    >
                      {localize('password')}
                    </span>
                    <div className="relative">
                      <input
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Введите свой пароль"
                        className={`max-[640px]:w-[320px] py-3 px-5 block text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6 mt-2.5 bg-[#F0F3FA] rounded-[8px] border-0 w-[416px] dark:bg-[#f0f3fa]/[12%] dark:placeholder:text-[#5F6B88] dark:text-white ${
                          theme ? 'dark' : ''
                        }`}
                      />
                      <button
                        className="absolute top-[47%] right-[5%]"
                        type="button"
                        onClick={togglePasswordVisibility}
                        style={{ zIndex: '1' }}
                      >
                        {theme == 'dark' ? (
                          <img
                            src={
                              showPassword
                                ? passwordVisible
                                : passwordNotVisible
                            }
                            width="16px"
                            height="16px"
                            alt="Toggling password visibility"
                          />
                        ) : (
                          <img
                            src={showPassword ? visibleIcon : invisibleIcon}
                            width="16px"
                            height="16px"
                            alt="Toggling password visibility"
                          />
                        )}
                      </button>
                    </div>
                    <span
                      className="font-medium text-base text-left cursor-pointer mt-[32px] text-[#0068FF]"
                      onClick={() => setForgot(true)}
                    >
                      Забыли пароль?
                    </span>
                    <button
                      className={
                        'bg-platform-blue px-2.5 py-2.5 text-white cursor-pointer mt-12 rounded-[8px] text-center platform-auth-button'
                      }
                      type="submit"
                    >
                      <p className={'text-xl font-medium'}>
                        {localize('sign_in')}
                      </p>
                    </button>
                  </div>
                </form>
              </>
            )}
            <div
              className="flex items-center justify-between p-1 mt-[28px] bg-[#F0F3FA] dark:bg-[#f0f3fa]/[12%] rounded-full cursor-pointer dark:bg-[#F0F3FA][0.13]"
              onClick={toggleTheme}
            >
              {theme == 'dark' && (
                <img
                  src={darkModeIcon}
                  className={`transition-transform duration-300 transform ${
                    theme === 'light' ? 'ml-2' : 'mr-2'
                  }`}
                />
              )}
              <div
                className={`w-6 h-6 bg-white dark:bg-[#1A1A1A] rounded-full transition-transform duration-300 transform ${
                  theme === 'light' ? 'translate-x-0' : 'translate-x-[0px]'
                }`}
              />
              {theme == 'light' && (
                <img
                  src={lightModeIcon}
                  className={`transition-transform duration-300 transform ${
                    theme === 'light' ? 'ml-2' : 'mr-2'
                  }`}
                />
              )}
            </div>
          </div>
        </div>
        <div className="overflow-hidden">
          <div className="shape blue-shape"></div>
          <div className="shape orange-shape"></div>
        </div>
      </div>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div className="flex flex-col">
            <span className={'text-platform-black text-base font-medium mb-3'}>
              Введите почту для сброса пароля
            </span>
            <input
              value={forgetEmail}
              onChange={e => setForgetEmail(e.target.value)}
              type="email"
              placeholder="Введите адрес электронной почты"
              className={inputClass}
            />
            <button
              className={
                'bg-platform-blue px-2.5 py-2.5 text-white cursor-pointer mt-4 rounded-lg text-center platform-auth-button'
              }
              onClick={resetPassword}
              disabled={resetPasswordLoading}
            >
              {resetPasswordLoading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: '#FFFFFF'
                  }}
                />
              ) : (
                <p className={'text-xl font-medium'}>Сбросить пароль</p>
              )}
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

import React from "react"
import { renderCourseLocalized } from "../../../../utils"
import { accessGlobalMiscState } from "../../../../stores/misc"

export const LessonListItem = ({ dragHandleProps, item: lesson, selectedLesson, updateSelectedLesson }) => {
    const courseSelectedLang = accessGlobalMiscState().get('courseSelectedLang')
    return (
        <div 
            className="flex flex-row mt-2 cursor-pointer"
        >
            <div 
                className={`p-2 w-[35px] flex flex-row items-center justify-center rounded-lg bg-[rgba(0,104,255,0.12)]`}
                {...dragHandleProps}
            >
                <span className="text-[15px] leading-5 text-unix-gray">
                    {lesson?.order}
                </span>
            </div>
            <div 
                className={`ml-2 p-2 rounded-lg flex-1 bg-[rgba(0,104,255,0.12)] ${selectedLesson?.id === lesson?.id && 'border-2 border-unix-blue'}`}
                onClick={updateSelectedLesson(lesson)}
            >
                {renderCourseLocalized('title', lesson, courseSelectedLang, 'font-medium text-[15px] leading-5 text-unix-text-black', 'mr-1')}
            </div>
        </div>
    )
}
import React from 'react';
import { useEffect, useState } from "react";
import { getOrganizations } from "../../../actions/organization";
import orgDefault from './org-default.svg'
import './organizations.css'
import { Link } from "react-router-dom";
//

export const AllOrganizations = () => {
    const [organizations, setOrganizations] = useState([])
    const getOrgs = async () => {
        const response = await getOrganizations()
        if (response.success) {
            setOrganizations(response.list)
        }
    }
    useEffect(() => {
        getOrgs()
    }, [])
    return (
        <div className={"grid grid-cols-12"}>
            <div className={"col-span-12 mb-3"}>
                <Link className={"unix-link text-2xl mx-5"} to="/admin/orgs/create">Создать организацию</Link>
            </div>
            <div className={"col-span-12"}>
                <div className="unix-card mx-5 pl-8 pr-6 pt-7 pb-16">
                    <table className="orgs-table text-primary-black w-full table-auto">
                        <thead>
                            <tr className="text-left">
                                <th>Организация</th>
                                <th>Описание</th>
                            </tr>
                        </thead>
                        <tbody>
                            {organizations.map(org => (
                                <tr key={org.id} className={"py-2"}>
                                    <td>
                                        <Link className={"flex flex-row"} to={`/admin/orgs/${org.id}`}>
                                            <div className="org-title flex flex-row">
                                                <div className={`${!org.logo?.length && 'p-[11.5px] bg-basic'} rounded-full flex flex-col justify-center items-center`}>
                                                    <img className={`${!org.logo?.length ? 'w-5 h-5' : 'w-[42px] h-[42px] object-cover'}`} src={org.logo?.length ? org.logo : orgDefault} />
                                                </div>
                                                <span className={"m-2.5"}>{org.title}</span>
                                            </div>
                                        </Link>
                                    </td>
                                    <td>{org.description}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
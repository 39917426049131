import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  bindHover,
  bindPopover,
  usePopupState
} from 'material-ui-popup-state/hooks'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import 'moment/locale/ru'
import moment from 'moment/moment'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { getImage } from '../../../actions/profile'
import { ThemeContext } from '../../../contexts'
import { useAvatar } from '../../../contexts/AvatarContext'
import { useOutsideClick } from '../../../hooks/ui'
import { useNotifications, useTeacherNotifications } from '../../../hooks/users'
import { accessGlobalMiscState, useGlobalMiscState } from '../../../stores/misc'
import { useGlobalUserState } from '../../../stores/user'
import { localize } from '../../../use-cases/constants'
import useOutside from '../../common/useOutside'
import arrowRightGray from './arrow-right-gray.svg'
import closeDrawerIcon from './closeDrawerIcon.svg'
import darkDrawerCloseIcon from './darkDrawerClose.svg'
import darkHamburgerIcon from './darkHamburgerIcon.svg'
import darkModeIcon from './darkModeIcon.svg'
import darkNotificationIcon from './darkNotificationIcon.svg'
import drawerIcon from './drawerIcon.svg'
import exitIcon from './exitIcon.svg'
import kazakhstan from './kazakhstan.png'
import lightModeIcon from './lightModeIcon.svg'
import notExpanded from './notExpanded.svg'
import notificationIcon from './notificationIcon.svg'
import russia from './russia.png'
import unitedKingdom from './united-kingdom.png'
import logo from './unix_logo.svg'

moment.locale('ru')

const images = {
  RU: russia,
  KZ: kazakhstan,
  EN: unitedKingdom
}

const drawerWidth = 270
const drawerWidth2 = 192

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0
  })
)

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    '@media (max-width: 1024px)': {
      width: '100%',
      marginLeft: 0
    }
  })
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

export default function PersistentDrawerLeft() {
  const [open, setOpen] = React.useState(false)
  const [notificationsDrawerOpen, setNotificationsDrawerOpen] = useState(false)
  const notificationsWrapperRef = useRef(null)
  const [blurred] = useState(false)
  const [search, setSearch] = useState('')
  const avatarObject = useAvatar()
  const avatarUrl = avatarObject?.avatarUrl || ""
  let setAvatarUrl = avatarObject?.setAvatarUrl
  if (!setAvatarUrl) {
    setAvatarUrl = () => { }
  }
  const teacherNotifications = useTeacherNotifications()
  const adminNotifications = useNotifications()
  const notifications = [...teacherNotifications, ...adminNotifications].sort(
    (a, b) => b.createdAt?.localeCompare(a.createdAt)
  )
  const isSmallScreen = useMediaQuery('(max-width:1000px)')
  const miscState = useGlobalMiscState()
  const readNotificationIds = miscState.get('readNotificationIds') || []
  console.log(readNotificationIds)
  const lang = miscState.get('lang')

  const { theme, setTheme } = React.useContext(ThemeContext)

  const toggleTheme = () => {
    setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'))
  }

  useOutsideClick(
    notificationsWrapperRef,
    () => setNotificationsDrawerOpen(o => (o ? false : o)),
    ['notifications-open-button']
  )

  console.log('avatar url ', avatarUrl)

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover'
  })

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = event => {
    event.stopPropagation()
    setOpen(false)
  }

  const fetchAvatar = async () => {
    try {
      const response = await getImage()
      if (response.success) {
        const newAvatarUrl = `${response.data.filePath}?t=${new Date().getTime()}`
        const img = new Image()
        img.src = newAvatarUrl
        img.onload = () => setAvatarUrl(newAvatarUrl)
        img.onerror = () => setAvatarUrl(null)
      } else {
        setAvatarUrl(null)
      }
    } catch (error) {
      console.error('Failed to fetch avatar:', error)
      setAvatarUrl(null)
    }
  }

  const navigate = useNavigate()
  const location = useLocation()

  const menuRef = useRef(null)
  const searchRef = useRef(null)

  useOutside(menuRef, () => setOpen(false))

  useEffect(() => {
    const handleClickOutside = event => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearch('')
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (notificationsDrawerOpen) {
      miscState.set('readNotificationIds', [
        ...new Set([...readNotificationIds, ...notifications.map(n => n.id)])
      ])
    }
  }, [notificationsDrawerOpen])

  const userState = useGlobalUserState()
  const user = userState.get()?.user || {}
  const userRoot = userState.get() || {}
  let avatar = ''
  const logOut = () => {
    userState.set({})
    navigate('/platform/login')
  }
  if (user.firstname?.length && user.lastname?.length) {
    const firstNameParts = user.firstname.trim().split(' ')
    let firstNameInitials = ''

    if (firstNameParts.length > 0) {
      firstNameInitials += firstNameParts[0].charAt(0)
    }
    if (firstNameParts.length > 1) {
      firstNameInitials += firstNameParts[1].charAt(0)
    }

    const lastNameInitial = user.lastname.charAt(0)

    avatar += firstNameInitials + lastNameInitial
  }

  useEffect(() => {
    fetchAvatar()
  }, [])

  const imageKey = avatarUrl ? `${avatarUrl}?t=${new Date().getTime()}` : ''

  const blur = blurred || !!search.length

  const displayFirstTwoWords = fullName => {
    if (!fullName) return ''
    const words = fullName.trim().split(' ')
    return words.slice(0, 2).join(' ')
  }

  const activeClassName =
    'text-[#0068FF] py-[14.5px] px-[16px] rounded-full bg-[rgba(0,104,255,0.12)] font-bold'

  return (
    <Box
      sx={{ display: 'flex', position: 'relative' }}
      className={`${blur ? 'blurred' : ''}`}
    >
      <AppBar
        sx={{
          boxShadow: 'none',
          border: 'none',
          backgroundColor: 'none',
          outline: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 }
        }}
        position="fixed"
        elevation={0}
      >
        <Toolbar
          sx={{
            boxShadow: 'none',
            backgroundColor: 'none',
            outline: 'none',
            borderBottom: 'none',
            height: '89px'
          }}
          className="bg-[#fff] dark:bg-[#1a1a1a] border-b-[#1a1a1a]"
        >
          <div className="max-w-[1280px] flex items-center justify-between w-[100%] mx-auto pt-3 toolbal-container toolbar-height ">
            <div
              className={`gap-4 md:gap-4 flex flex-row lg:pr-[22px] relative pb-[23px] top-[12px]`}
            >
              <div
                className={
                  'flex flex-row lg:col-span-4 col-span-6 items-center '
                }
              >
                <img
                  src={logo}
                  className="cursor-pointer max-[999px]:w-[112px]  max-[999px]:h-[32px]"
                  alt="logo"
                  onClick={() => navigate('/platform')}
                />
              </div>
            </div>
            <div className="max-[999px]:hidden">
              <ul className="flex flex-row items-center gap-5 text-[#0068FF]">
                <li
                  className={`list-item text-[15px] ${location.pathname === '/platform'
                    ? activeClassName
                    : 'text-[#1A1A1A]'
                    }`}
                >
                  {location.pathname === '/platform' ? (
                    <span>{localize('home')}</span>
                  ) : (
                    <a
                      href="/platform"
                      className="hover:text-[#0068FF] dark:text-white"
                    >
                      {localize('home')}
                    </a>
                  )}
                </li>
                <li
                  className={`list-item text-[15px] ${location.pathname === '/platform/profile'
                    ? activeClassName
                    : 'text-[#1A1A1A]'
                    }`}
                >
                  <a
                    href="/platform/profile"
                    className="hover:text-[#0068FA] dark:text-white"
                  >
                    {localize('profile')}
                  </a>
                </li>
                {!!userRoot.roles?.filter(role =>
                  [
                    'SUPERADMIN',
                    'ADMIN',
                    'MODERATOR',
                    'TEACHER',
                    'CONTENTMAKER'
                  ].includes(role)
                ).length && (
                    <li
                      className={`list-item text-[15px] ${location.pathname.startsWith('/admin')
                        ? activeClassName
                        : 'text-[#1A1A1A]'
                        }`}
                    >
                      <a
                        href="/admin/"
                        className="hover:text-[#0068FA] dark:text-white"
                      >
                        Админ-панель
                      </a>
                    </li>
                  )}
              </ul>
            </div>
            {/* <div onMouseEnter={searchMouseEnter} onMouseLeave={searchMouseLeave} className='relative hidden search-visible search-container search-components' onClick={handleDrawerClose} ref={searchRef}>
            <input value={search} onChange={e => setSearch(e.target.value)} placeholder="Поиск по курсам" className={`lg:w-[450px] md:w-[320px]  md:py-6 py-2 px-12 md:text-[16px] text-[12px] rounded-[60px] flex ${blur ? 'bg-white border border-gray-100 shadow-none search-input-blurred focus:border-gray-100' : 'bg-[#F0F4F9] border-none'} ${!!searchResults.length && 'rounded-b-none rounded-t-[45px]'}   search-components search-input text-black`} type="text" />
            <img className="absolute left-5 top-[14px] md:top-[30px] w-[14px] h-[14px] md:w-[18px] md:h-[18px] search-components" src={Search}/>
            {!!search.length && (
              <img onClick={clearSearch} className="cursor-pointer absolute right-5 top-[14px] md:top-[30px] w-[18px] h-[18px] search-components" src={close}/>
            )}
            {!!searchResults.length && (
              <div className={`absolute w-full bg-white top-[4.25rem] px-8 py-4 rounded-b-[2.5rem] border border-gray-100 max-h-[80vh] overflow-y-scroll border-t-0 ${!blur ? 'hidden' : ''}`}>
                {searchResults.map(r => (
                  <div className="flex flex-row cursor-pointer mb-3 justify-between items-center" onClick={onResultClick(r)}>
                    <div className="flex flex-col">
                      <span className="text-black font-bold text-lg">{r.title}</span>
                      <span className="text-black text-xs opacity-55">{entityMapper(r.entityType)}</span>
                    </div>
                    <img src={arrow} />
                  </div>
                ))}
              </div>
            )}
          </div> */}
            <div className="flex-row gap-[10px] items-center flex relative">
              {theme == 'dark' ? (
                <>
                  <div className="relative inline-block">
                    <img
                      className="w-[48px] max-[1000px]:w-[36px] mr-[6px] cursor-pointer notifications-open-button"
                      src={darkNotificationIcon}
                      onClick={() => setNotificationsDrawerOpen(true)}
                    />
                    {notifications.filter(
                      n => !readNotificationIds.includes(n.id)
                    ).length > 0 && (
                        <span className="absolute top-[4px] right-[10px]  transform translate-x-1/2 -translate-y-1/2 bg-red-500 border-1px-[white] text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                          {notifications.length}
                        </span>
                      )}
                  </div>
                </>
              ) : (
                <>
                  <div className="relative inline-block">
                    <img
                      className="w-[48px] max-[1000px]:w-[36px] mr-[6px] cursor-pointer notifications-open-button"
                      src={notificationIcon}
                      onClick={() => setNotificationsDrawerOpen(true)}
                    />
                    {notifications.filter(
                      n => !readNotificationIds.includes(n.id)
                    ).length > 0 && (
                        <span className="absolute top-[4px] right-[10px] transform translate-x-1/2 -translate-y-1/2 bg-red-500 border-1px-[white] text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                          {notifications.length}
                        </span>
                      )}
                  </div>
                </>
              )}
              <IconButton
                color="#284C6B"
                aria-label={open ? 'close drawer' : 'open drawer'}
                onClick={open ? handleDrawerClose : handleDrawerOpen}
                edge="start"
                sx={{ display: isSmallScreen ? 'block' : 'none' }}
              >
                {open ? (
                  theme == 'dark' ? (
                    <img
                      style={{ height: 36, width: 36 }}
                      alt="close icon"
                      src={darkDrawerCloseIcon}
                    />
                  ) : (
                    <img
                      style={{ height: 36, width: 36 }}
                      alt="close icon"
                      src={closeDrawerIcon}
                    />
                  )
                ) : theme == 'dark' ? (
                  <img
                    style={{ height: 36, width: 36 }}
                    alt="menu icon"
                    src={darkHamburgerIcon}
                  />
                ) : (
                  <img
                    style={{ height: 36, width: 36 }}
                    alt="menu icon"
                    src={drawerIcon}
                  />
                )}
              </IconButton>
              <div
                className={
                  'max-[1000px]:hidden flex flex-row lg:col-span-4 col-span-6 justify-end'
                }
              >
                <div
                  className={
                    'flex h-[48px] gap-10 pr-4 max-xl:pr-0 items-center rounded-full bg-[#F0F3FA] dark:bg-[#f0f3fa]/[12%] p-1 cursor-pointer'
                  }
                >
                  <div
                    className="flex items-center gap-[12px]"
                    onClick={() => navigate('/platform/profile')}
                  >
                    <span
                      className={
                        'text-white bg-[#f5bfb9] h-[36px] w-[36px] rounded-[100%] relative top-[0px] font-extrabold max-xl:left-[-2px]'
                      }
                    >
                      {avatarUrl ? (
                        <img
                          key={imageKey}
                          src={avatarUrl}
                          className="object-cover rounded-full"
                        />
                      ) : (
                        <span className="text-white font-extrabold text-[16px] relative left-[6px] top-[6px]">
                          {avatar}
                        </span>
                      )}
                    </span>
                    <span className="max-xl:hidden">
                      <span className="text-[#1A1A1A] relative top-[3px] dark:text-white flex">
                        {displayFirstTwoWords(user.firstname)}{' '}
                        {user.lastname?.length > 20
                          ? `${user.lastname.slice(0, 20)}...`
                          : user.lastname}
                      </span>
                      <span className="text-[#5F6B88] relative bottom-[3px] dark:text-[#AFB7CA]">
                        {user.email}
                      </span>
                    </span>
                  </div>
                  <img
                    onClick={logOut}
                    className="max-xl:hidden"
                    src={exitIcon}
                  />
                </div>
              </div>
              <div
                className="max-[1000px]:hidden flex flex-row items-center bg-[#F0F3FA] dark:bg-[#f0f3fa]/[12%] rounded-full px-4 gap-8"
                {...bindHover(popupState)}
              >
                <div className="h-[48px] text-black dark:text-white text-base font-bold cursor-pointer flex flex-row items-center">
                  <img src={images[lang]} className="w-7 h-7" />
                  <span className="ml-2">{lang}</span>
                </div>
                <img src={notExpanded} />
              </div>
              <HoverPopover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <div
                  className={
                    'flex flex-col bg-[#F0F3FA] dark:bg-[#f0f3fa]/[12%]'
                  }
                >
                  {['KZ', 'RU', 'EN'].map(l => (
                    <div
                      key={l}
                      onClick={() => accessGlobalMiscState().set('lang', l)}
                      className={`flex flex-row items-center text-black text-sm py-2 px-3 cursor-pointer hover:bg-[#fff] ${lang === l ? 'bg-[#F0F3FA] dark:bg-[#f0f3fa]/[12%]' : ''
                        }`}
                    >
                      <img src={images[l]} className="w-7 h-7" />
                      <span className="ml-3">{l}</span>
                    </div>
                  ))}
                </div>
              </HoverPopover>
              <div
                className="max-[1000px]:hidden flex items-center justify-between p-1 bg-slate-100 dark:bg-[#f0f3fa]/[12%] rounded-full cursor-pointer dark:bg-[#F0F3FA][0.13] h-[36px]"
                onClick={toggleTheme}
              >
                {theme == 'dark' && (
                  <img
                    src={darkModeIcon}
                    className={`transition-transform duration-300 transform ${theme === 'light' ? 'ml-2' : 'mr-2'
                      }`}
                  />
                )}
                <div
                  className={`w-6 h-6 bg-white dark:bg-[#1A1A1A] rounded-full transition-transform duration-300 transform ${theme === 'light' ? 'translate-x-0' : 'translate-x-[0px]'
                    }`}
                />
                {theme == 'light' && (
                  <img
                    src={lightModeIcon}
                    className={`transition-transform duration-300 transform ${theme === 'light' ? 'ml-2' : 'mr-2'
                      }`}
                  />
                )}
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={blur ? `blurred` : ''}
        sx={{
          position: 'absolute',
          width: open ? drawerWidth : drawerWidth2,
          flexShrink: 0,
          zIndex: 10000,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: theme === 'dark' ? '#1a1a1a' : '#ffffff',
            boxShadow: open ? '0px 2px 4px rgba(0, 0, 0, 0.20)' : '',
            '@media (max-width: 768px)': {
              width: open ? drawerWidth : drawerWidth
            }
          }
        }}
        variant="temporary"
        anchor="right"
        open={open}
        onEscapeKeyDown={handleDrawerClose}
        onBackdropClick={handleDrawerClose}
        ModalProps={{
          disableBackdropClick: true,
          BackdropProps: {
            invisible: true
          }
        }}
      >
        <div
          className={`fixed min-h-screen profile-side ${open ? 'active' : ''}`}
        >
          <div
            className={
              'flex flex-col pt-4 lg:px-[10px] px-3 min-h-screen justify-between'
            }
          >
            <ul className="flex flex-col items-center gap-[5vh] text-[#A5896D]">
              <li className="flex w-full gap-[10px] justify-end text-end">
                {theme == 'dark' ? (
                  <>
                    <div className="relative top-[8px] inline-block">
                      <img
                        className="w-[48px] max-[1000px]:w-[36px] mr-[6px] cursor-pointer notifications-open-button"
                        src={darkNotificationIcon}
                        onClick={() => setNotificationsDrawerOpen(true)}
                      />
                      {notifications.filter(
                        n => !readNotificationIds.includes(n.id)
                      ).length > 0 && (
                          <span className="absolute top-[-5px] right-0 transform bg-red-500 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                            {notifications.length}
                          </span>
                        )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="relative top-[8px] inline-block">
                      <img
                        className="w-[48px] max-[1000px]:w-[36px] mr-[6px] cursor-pointer notifications-open-button"
                        src={notificationIcon}
                        onClick={() => setNotificationsDrawerOpen(true)}
                      />
                      {notifications.filter(
                        n => !readNotificationIds.includes(n.id)
                      ).length > 0 && (
                          <span className="absolute top-[-5px] right-0 transform bg-red-500 text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                            {notifications.length}
                          </span>
                        )}
                    </div>
                  </>
                )}
                <IconButton
                  color="#284C6B"
                  aria-label={open ? 'close drawer' : 'open drawer'}
                  onClick={open ? handleDrawerClose : handleDrawerOpen}
                  edge="start"
                  sx={{ mr: 2 }}
                >
                  {open ? (
                    theme == 'dark' ? (
                      <img
                        style={{ height: 36, width: 36 }}
                        alt="close icon"
                        src={darkDrawerCloseIcon}
                      />
                    ) : (
                      <img
                        style={{ height: 36, width: 36 }}
                        alt="close icon"
                        src={closeDrawerIcon}
                      />
                    )
                  ) : theme == 'dark' ? (
                    <img
                      style={{ height: 36, width: 36 }}
                      alt="menu icon"
                      src={darkHamburgerIcon}
                    />
                  ) : (
                    <img
                      style={{ height: 36, width: 36 }}
                      alt="menu icon"
                      src={drawerIcon}
                    />
                  )}
                </IconButton>
              </li>
              <li>
                <div
                  className={
                    'flex flex-row lg:col-span-4 col-span-6 justify-end'
                  }
                >
                  <div
                    className={
                      'flex items-center rounded-full p-2 w-[250px] justify-between bg-[#F0F3FA] dark:bg-[#f0f3fa]/[12%] cursor-pointer'
                    }
                  >
                    <div
                      className="flex items-center justify-between"
                      onClick={() => navigate('/platform/profile')}
                    >
                      <span
                        className={
                          'text-white bg-[#0068FF] p-2 rounded-[100%] relative top-[0px] font-extrabold max-xl:left-[-2px]'
                        }
                      >
                        {avatar}
                      </span>
                      <span className="pl-[12px] flex flex-col">
                        <span className="text-[#1A1A1A] flex text-[15px] dark:text-white whitespace-nowrap">
                          {user.firstname}{' '}
                          {user.lastname?.length > 20
                            ? `${user.lastname.slice(0, 20)}...`
                            : user.lastname}
                        </span>
                        <span className="text-[#5F6B88] text-[13px] dark:text-[#AFB7CA]">
                          {user.email}
                        </span>
                      </span>
                    </div>
                    <img onClick={logOut} src={exitIcon} />
                  </div>
                </div>
              </li>
              <li>
                <div className="flex flex-row py-3 items-center bg-[#F0F3FA] dark:bg-[#f0f3fa]/[12%]  rounded-full">
                  <div className="flex flex-row items-center w-[250px] justify-around">
                    {['KZ', 'RU', 'EN'].map(l => (
                      <div
                        key={l}
                        onClick={() => accessGlobalMiscState().set('lang', l)}
                        className={`flex flex-row items-center text-base px-2 font-bold cursor-pointer py-2 rounded-full ${lang === l
                          ? 'bg-[#0068FF] text-white '
                          : 'bg-transparent text-black dark:text-white'
                          }`}
                      >
                        <img src={images[l]} className="w-7 h-7" />
                        <span className="ml-2">{l}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </li>
              <li className="list-item max-md:text-[16px]">
                {location.pathname === '/platform' ? (
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: '#0068FF',
                      background: 'rgba(0, 104, 255, 0.08)',
                      padding: '1em 80px',
                      borderRadius: '1000px'
                    }}
                  >
                    {localize('home')}
                  </span>
                ) : (
                  <a
                    href="/platform"
                    className="text-[#1A1A1A] dark:text-[#fff] hover:text-[#0068FA]"
                  >
                    {localize('home')}
                  </a>
                )}
              </li>
              <li className="list-item text-center max-md:text-[16px]">
                {location.pathname === '/platform/profile' ? (
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: '#0068FF',
                      width: '250px',
                      background: 'rgba(0, 104, 255, 0.08)',
                      padding: '1em 83px',
                      borderRadius: '1000px'
                    }}
                  >
                    {localize('profile')}
                  </span>
                ) : (
                  <a
                    href="/platform/profile"
                    className="text-[#1a1a1a] dark:text-[#fff] hover:text-[#0068FA]"
                  >
                    {localize('profile')}
                  </a>
                )}
              </li>
              {!!userRoot.roles?.filter(role =>
                [
                  'SUPERADMIN',
                  'ADMIN',
                  'MODERATOR',
                  'TEACHER',
                  'CONTENTMAKER'
                ].includes(role)
              ).length && (
                  <li className="list-item max-md:text-[16px] whitespace-nowrap text-[#1a1a1a] hover:text-[#0068FA] dark:text-white">
                    <a href="/admin/">Админ-панель</a>
                  </li>
                )}
              <li>
                <div
                  className="flex items-center justify-between p-1 bg-slate-100 dark:bg-[#f0f3fa]/[12%] rounded-full cursor-pointer dark:bg-[#F0F3FA][0.13]"
                  onClick={toggleTheme}
                >
                  {theme == 'dark' && (
                    <img
                      src={darkModeIcon}
                      className={`transition-transform duration-300 transform ${theme === 'light' ? 'ml-2' : 'mr-2'
                        }`}
                    />
                  )}
                  <div
                    className={`w-6 h-6 bg-white dark:bg-[#1A1A1A] rounded-full transition-transform duration-300 transform ${theme === 'light' ? 'translate-x-0' : 'translate-x-[0px]'
                      }`}
                  />
                  {theme == 'light' && (
                    <img
                      src={lightModeIcon}
                      className={`transition-transform duration-300 transform ${theme === 'light' ? 'ml-2' : 'mr-2'
                        }`}
                    />
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Drawer>
      {/* <div className="absolute py-0 block top-[63px] second-header h-[20px] z-10 w-full">
        <div className="flex justify-center"> */}
      {/* <div
            className="relative z-0  search-components"
            onClick={handleDrawerClose}
          >
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Поиск по курсам"
              className={`lg:w-[450px] w-[220px]  md:py-3 py-1 px-8 md:text-[16px] text-[12px] rounded-[40px] flex bg-[#F0F4F9] border-none ${
                !!searchResults.length && "rounded-b-none rounded-t-[25px]"
              } search-components search-input text-black`}
              type="text"
            />
            <img
              className="absolute left-3 top-[10px] md:top-[14px] w-3 h-3 md:w-[14px] md:h-[14px] search-components"
              src={Search}
            />
            {!!search.length && (
              <img
                onClick={clearSearch}
                className="absolute right-3 cursor-pointer top-[9px] md:top-[14px] w-4 h-4 md:w-[14px] md:h-[14px] search-components"
                src={close}
              />
            )}
            {!!searchResults.length && (
              <div
                className={`absolute w-full bg-white top-[2.5rem] px-4 py-2 rounded-b-[1.5rem] border max-h-[80vh] overflow-y-scroll border-gray-100 border-t-0`}
              >
                {searchResults.map((r) => (
                  <div
                    className="flex flex-row cursor-pointer mb-2 justify-between items-center"
                    onClick={onResultClick2(r)}
                  >
                    <div className="flex flex-col">
                      <span className="text-black font-bold text-base md:text-lg">
                        {r.title}
                      </span>
                      <span className="text-black text-xs opacity-55">
                        {entityMapper(r.entityType)}
                      </span>
                    </div>
                    <img src={arrow} />
                  </div>
                ))}
              </div>
            )}
          </div> */}
      {/* </div>
      </div> */}
      <div
        ref={notificationsWrapperRef}
        className={`fixed py-0 top-[100px] w-[91vw] md:w-[460px] max-h-[50vh] right-6 z-50 rounded-xl bg-white notifications-wrapper overflow-y-auto ${notificationsDrawerOpen ? 'block' : 'hidden'
          }`}
      >
        <div className="flex items-center dark:bg-black">
          <div className="w-full py-[16px] px-3 flex flex-row">
            <p className="font-medium text-unix-text-black dark:text-white">
              Уведомления
            </p>
          </div>
          <div className="flex justify-end mr-2 mt-2 cursor-pointer">
            <img
              className=""
              src={closeDrawerIcon}
              onClick={() => setNotificationsDrawerOpen(false)}
            />
          </div>
        </div>
        <div className="flex flex-col p-2 bg-[#F0F3FA] overflow-y-auto dark:bg-black">
          {notifications.map(n => (
            <div
              key={n.id}
              className="flex flex-col p-4 bg-white mb-2 rounded-[8px] dark:bg-[#1a1a1a]"
            >
              <div className="flex flex-row items-center mb-2">
                <p className="text-xs font-medium text-unix-gray">
                  {n.moduleTitle || n.room?.course?.module?.title}
                </p>
                {!!n.courseTitle ||
                  (n.room?.course?.title && (
                    <>
                      <img className="mx-1" src={arrowRightGray} />
                      <p className="text-xs font-medium text-unix-gray">
                        {n.courseTitle || n.room?.course?.title}
                      </p>
                    </>
                  ))}
              </div>
              <p className="text-[15px] font-medium text-unix-text-black break-all dark:text-white">
                {n.title}
              </p>
              <p className="text-[13px] font-[400] text-unix-text-black break-all mb-2 dark:text-white">
                {n.notificationText}
              </p>
              <div className="flex flex-row justify-between">
                <p className="text-unix-gray text-[13px] font-semibold dark:text-white">
                  {!!n.room?.curator &&
                    `${n.room?.curator?.firstname} ${n.room?.curator?.lastname}`}
                </p>
                <p className="text-unix-gray text-[13px] font-semibold dark:text-white">
                  {moment(n.createdAt).fromNow()}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Main className="dark:bg-[#1A1A1A]" open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  )
}

import { useParams } from "react-router-dom"
import { useCertificate, useProfileImage } from "../../../hooks/users"
import { localize } from "../../../use-cases/constants"
import { useCourse } from "../../../hooks/courses"
import { useModule } from "../../../hooks/modules"
import { useGlobalUserState } from "../../../stores/user"
import greenShield from "../../../assets/green_shield.svg"
import downloadBlue from "../../../assets/download_blue.svg"
import unixLogo from '../../../assets/unix_logo.svg'
import kbtuLogo from '../../../assets/kbtuLogo.svg'
import certificateBgXl from '../../../assets/certificate_bg_xl.png'
import certificateBgLg from '../../../assets/certificate_bg_lg.png'
import certificateBgMd from '../../../assets/certificate_bg_md.png'
import certificateBgSm from '../../../assets/certificate_bg_sm.png'
import QRCode from "react-qr-code"
import { useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import { formatDuration } from "../../../utils"

export const PlatformCertificate = () => {
    const userState = useGlobalUserState();
    const user = userState.get()?.user || {};
    const { id } = useParams()
    const certificate = useCertificate(parseInt(id))
    const course = useCourse(certificate.courseId)
    const module = useModule(course.moduleId)
    const profileImage = useProfileImage()
    const isXl = useMediaQuery({ query: '(min-width: 1280px)' })
    const isLg = useMediaQuery({ query: '(min-width: 1024px)' })
    const isMd = useMediaQuery({ query: '(min-width: 768px)' })

    useEffect(() => {
        const elements1 = document.querySelectorAll('.css-1q0v17x')
        elements1.forEach(element => {
            element.style.padding = '0'
            element.classList.add('flex')
            element.classList.add('flex-col')
            element.classList.add('flex-col')
        })

        return () => {
            elements1.forEach(element => (element.style.padding = ''))
        }
    }, [])

    let certificateBg
    if (isXl) {
        certificateBg = certificateBgXl
    } else if (isLg) {
        certificateBg = certificateBgLg
    } else if (isMd) {
        certificateBg = certificateBgMd
    } else {
        certificateBg = certificateBgSm
    }

    const renderDownloadButton = (wrapperClassname) => (
        <a
            className={`${wrapperClassname} mt-6 flex flex-row justify-center items-center rounded-full border border-unix-blue py-3`}
            href={certificate.certificate?.link}
            target="_blank"
            rel="noreferrer"
        >
            <img src={downloadBlue} />
            <span className="ml-2 font-semibold text-unix-blue text-[15px] leading-[18px]">
                Скачать сертификат (PDF)
            </span>
        </a>
    )

    return (
        <div className={"pt-7 dark:bg-[#1a1a1a]"}>
            <div className={"flex flex-row col-span-12 mb-6 max-w-[1280px] mx-auto"}>
                <p
                    className={
                        "text-black dark:text-white font-bold text-[35px] ml-4 dark:"
                    }
                >
                    {localize("certificate")}
                </p>
            </div>
            <div className="bg-[#F0F3FA] dark:bg-black h-screen px-2">
                <div className="grid grid-cols-12 grid- max-w-[1280px] mx-auto pt-8">
                    <div className="col-span-12 order-2 md:order-1 md:col-span-6 lg:col-span-5 xl:col-span-4 flex flex-col">
                        <div className="bg-white dark:bg-[#1a1a1a] p-8 rounded-[28px] flex flex-col">
                            <span className="font-medium text-unix-light-gray">
                                Курс
                            </span>
                            <span className="font-semibold text-2xl text-unix-text-black dark:text-white">
                                {course.title}
                            </span>
                            <p className="mt-1">
                                <span className="font-medium text-unix-light-gray">
                                    Из модуля
                                </span>
                                <span className="ml-0.5 font-medium text-unix-gray">
                                    {module.title}
                                </span>
                            </p>
                            <span className="mt-8 font-medium text-unix-light-gray">
                                Успешно пройден пользователем
                            </span>
                            <div className="bg-unix-background-blue dark:bg-[#373737] self-start flex flex-row items-center mt-1.5 rounded-full p-1.5 pr-4">
                                <img
                                    src={profileImage}
                                    className="object-cover rounded-full w-9 h-9"
                                />
                                <span className="ml-3 font-medium text-xl text-unix-text-black dark:text-white">
                                    {user.firstname} {user.lastname}
                                </span>
                            </div>
                            <div className="my-8 flex flex-row">
                                <div className="flex flex-col pr-8">
                                    <span className="font-medium text-unix-light-gray leading-5">
                                        Дата завершения
                                    </span>
                                    <span className="mt-1 font-medium text-unix-text-black dark:text-white leading-5">
                                        {new Date(certificate.completionDate).toLocaleDateString('en-GB').replaceAll("/", ".")}
                                    </span>
                                </div>
                                <div className="flex flex-col">
                                    <span className="font-medium text-unix-light-gray leading-5">
                                        Время прохождения
                                    </span>
                                    <span className="mt-1 font-medium text-unix-text-black dark:text-white leading-5">
                                        {formatDuration(certificate.totalVideoWatched || 0)}
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-row items-center rounded-xl bg-[#3BBC300F] p-4">
                                <img
                                    src={greenShield}
                                />
                                <span className=" ml-2 text-[13px] text-[#3BBC30] leading-4">
                                    KBTU и UniX подтвердили личность этого человека и его участие в курсе
                                </span>
                            </div>
                        </div>
                        {renderDownloadButton('hidden md:flex')}
                    </div>
                    <div className="col-span-12 order-1 md:order-2 md:col-span-6 lg:col-span-7 xl:col-span-8 flex flex-col pl-0 md:pl-5">
                        <div
                            className="flex flex-col rounded-[28px] xl:px-[50px] xl:pt-12 xl:pb-[50px] lg:px-[33px] lg:pt-[31px] lg:pb-[35px] md:px-[20px] md:pt-[19px] md:pb-[19px] px-[18px] pt-[17px] pb-[18px] bg-cover bg-center bg-no-repeat"
                            style={{
                                backgroundImage: `url(${certificateBg})`
                            }}
                        >
                            <div className="flex flex-row justify-between items-center">
                                <span className="font-semibold xl:text-[64px] xl:leading-[64px] lg:text-[42px] lg:leading-[42px] text-[23px] leading-[23px] text-unix-light-gray">
                                    Сертификат
                                </span>
                                <div className="flex flex-row items-center">
                                    <img
                                        src={unixLogo}
                                        className="xl:w-[125px] xl:h-[39px] lg:w-[81px] lg:h-[25px] md:w-[50px] md:h-[16px] w-[45px] h-[14px]"
                                    />
                                    <img
                                        className="xl:ml-[46px] xl:w-[125px] xl:h-[39px] lg:ml-8 lg:w-[48px] lg:h-[32px] md:ml-[19px] md:w-[30px] md:h-[20px] ml-[18px] w-[27px] h-[18px]"
                                        src={kbtuLogo}
                                    />
                                </div>
                            </div>
                            <span className="xl:mt-[120px] font-medium xl:text-[48px] xl:leading-[48px] lg:mt-[78px] lg:text-[31px] lg:leading-[31px] md:mt-[48px] md:text-[19px] md:leading-[19px] mt-[44px] text-[17px] leading-[17px] text-unix-text-black">
                                {user.firstname} {user.lastname}
                            </span>
                            <div className="xl:mt-8 lg:mt-[22px] md:mt-[14px] mt-[11px] flex flex-row">
                                <div className="xl:w-[7px] xl:h-[85px] lg:w-[5px] lg:h-[56px] w-[3px] h-[56px]  bg-[#FF6B00]" />
                                <div className="ml-5 flex flex-col">
                                    <span className="xl:text-base lg:text-[12px] text-[10px] font-medium text-unix-light-gray">
                                        Успешно прошёл(ла) курс
                                    </span>
                                    <span className="xl:mt-4 lg:mt-[10px] mt-[6px] xl:text-[32px] xl:leading-[32px] lg:text-[21px] lg:leading-[21px] md:text-base md:leading-[16px] text-[12px] leading-[12px] font-semibold text-unix-text-black">
                                        {course.title}
                                    </span>
                                    <p className="mt-1">
                                        <span className="xl:text-base lg:text-[12px] text-[10px] leading-[10px] font-medium text-unix-light-gray">
                                            Из модуля
                                        </span>
                                        <span className="xl:text-base lg:text-[12px] text-[10px] leading-[10px] ml-0.5 font-medium text-unix-text-black">
                                            {module.title}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="xl:mt-10 lg:mt-[26px] md:mt-[18px] mt-[16px] flex flex-row justify-between">
                                <span className="xl:text-base lg:text-[12px] text-[10px] flex-[4] self-end font-medium text-unix-text-black">
                                    {new Date(certificate.completionDate).toLocaleDateString('en-GB').replaceAll("/", ".")}
                                </span>
                                <div className="flex-[6] flex flex-row justify-end">
                                    <div className="flex flex-col justify-end mr-5">
                                        <a
                                            className="text-[#0055D1] underline font-medium xl:text-xs xl:leading-[14px] lg:text-[12px] lg:leading-[9.6px] text-[10px] leading-[10px] text-right"
                                            href={`${window.location.origin}/verify/${certificate.certificate?.productId}`}
                                        >
                                            {window.location.origin}/verify/{certificate.certificate?.productId}
                                        </a>
                                        <span className="mt-2.5 text-unix-light-gray font-medium xl:text-xs xl:leading-[14px] lg:text-[12px] lg:leading-[9.6px] text-[10px] leading-[10px] text-right">
                                            KBTU и UniX подтвердили личность этого человека и его участие в курсе
                                        </span>
                                    </div>
                                    <QRCode
                                        className="xl:w-[100px] xl:h-[100px] lg:w-[66px] lg:h-[66px] md:w-[50px] md:h-[50px] w-[40px] h-[40px]"
                                        value={`${window.location.origin}/verify/${certificate.certificate?.productId}`}
                                        viewBox={`0 0 100 100`}
                                    />
                                </div>
                            </div>
                        </div>
                        {renderDownloadButton('flex md:hidden mb-5')}
                    </div>
                </div>
            </div>
        </div>
    )
}
import { Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import 'react-circular-progressbar/dist/styles.css'
import 'react-multi-carousel/lib/styles.css'
import React, { useEffect, useState } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { searchTeacher } from '../../../actions/course'
import {
  deleteModer,
  getModuleAdmin,
  moduleStats,
  searchModerators,
  searchModuleAdmin
} from '../../../actions/module'
import { getOrgStats } from '../../../actions/organization'
import { addCourseTeacher, addModuleAdmin } from '../../../actions/user'
import { useGlobalMiscState } from '../../../stores/misc'
import Accounts from './accounts.svg'
import achieve from './achieve.svg'
import add from './add.svg'
import addAdminIcon from './addAdminIcon.svg'
import arrowDown from './aroowDownIcon.svg'
import arrowUp from './arrowUpIcon.svg'
import asc from './asc.svg'
import ascSelected from './ascSelected.svg'
import avgCourse from './avg-course.svg'
import avgLecture from './avg-lecture.svg'
import avgTime from './avg-time.svg'
import bucket from './bucket.svg'
import Certificate from './certificate.svg'
import classes from './classes.svg'
import closeIcon from './closeIcon.svg'
import deleteIcon from './delete.svg'
import desc from './desc.svg'
import descSelected from './descSelected.svg'
import GoBack from './goBack.svg'
import Lectures from './lectures.svg'
import leftIcon from './leftArrow.svg'
import Lessons from './lessons.svg'
import plusWhite from './plusWhite.svg'
import searchBlueIcon from './searchBlueIcon.svg'
import { default as Students, default as studentsIcon } from './students.svg'
import teachers from './teachers.svg'
import Tests from './tests.svg'
import warningIcon from './warningIcon.svg'
import whiteSearchIcon from './whiteSearchIcon.svg'

const PlatformCourseCard = ({ course, onSelect, selectedCourse }) => {
  const isSelected = selectedCourse && selectedCourse.id === course.id

  return (
    <div
      className={`rounded-[10px] w-full flex cursor-pointer flex-row items-center
          ${isSelected ? 'bg-blue-600 text-white' : 'text-black'}
        `}
      onClick={() => onSelect(course)}
    >
      <img
        className={`rounded-[15px] relative left-[5px] ${isSelected ? '' : ''}`}
        src={course.imageLink}
        style={{ height: '60px', width: '100px' }}
      />
      <p
        style={{
          maxWidth: '170px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
        className={`text-lg mt-5 card-title-clip ${isSelected ? 'text-white' : 'text-black'
          } ml-4`}
      >
        {course.title.length > 30
          ? `${course.title.substring(0, 30)}...`
          : course.title}
      </p>
    </div>
  )
}

export const StatisticsModule = () => {
  const miscState = useGlobalMiscState()
  const navigate = useNavigate()
  const location = useLocation()

  console.log('navigate ', navigate)

  const moduleStatsLastSelectedCourse = miscState.get(
    'moduleStatsLastSelectedCourse'
  )
  const { moduleId } = useParams()
  const [module, setModule] = useState(null)
  const [selectedCourse, setSelectedCourse] = useState(
    moduleStatsLastSelectedCourse || null
  )

  console.log(moduleStatsLastSelectedCourse || null, selectedCourse)
  const [mouseState] = useState(-1)
  const [isExpanded, setIsExpanded] = useState(true)
  const [organizationStats, setOrganizationStats] = useState({})
  const [addAdminMode, setAddAdminMode] = useState(false)
  let [coursesStatistics, setCoursesStatistics] = useState(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [moduleAdmins, setModuleAdmins] = useState([])
  const [uniqueSearchQuery, setUniqueSearchQuery] = useState('')
  const [uniqueSearchResults, setUniqueSearchResults] = useState([])
  const [teacherSearchQuery, setTeacherSearchQuery] = useState('')
  const [teacherSearchResults, setTeacherSearchResults] = useState([])
  const [selectedAdmin, setSelectedAdmin] = useState(null)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isSearchCleared, setIsSearchCleared] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isTeacherModalOpen, setIsTeacherModalOpen] = useState(false)
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const courseId = queryParams.get('courseId')
  const [sortConfig, setSortConfig] = useState({
    key: 'firstname',
    direction: 'asc'
  })
  const [teacherSortConfig, setTeacherSortConfig] = useState({
    key: 'firstname',
    direction: 'asc'
  })

  const handleAddTeacherClick = admin => {
    setSelectedAdmin(admin)
    setIsTeacherModalOpen(true)
  }

  const handleConfirmAddTeacher = async adminId => {
    handleAddTeacher(adminId)
    setIsTeacherModalOpen(false)
    setSelectedAdmin(null)
  }

  const handleCloseTeacherModal = () => {
    setIsTeacherModalOpen(false)
    setSelectedAdmin(null)
  }

  const handleAddAdminClick = admin => {
    setSelectedAdmin(admin)
    setIsAddModalOpen(true)
  }

  const handleConfirmAddAdmin = async adminId => {
    handleAddAdmin(adminId)
    setIsAddModalOpen(false)
    setSelectedAdmin(null)
  }

  const handleCloseModal = () => {
    setIsAddModalOpen(false)
    setSelectedAdmin(null)
  }

  const handleDeleteAdminClick = (moduleId, admin) => {
    setSelectedAdmin(admin)
    setIsDeleteModalOpen(true)
  }

  const handleConfirmDeleteAdmin = async (moduleId, admin) => {
    handleDeleteModerator(moduleId, admin)
    setIsDeleteModalOpen(false)
    setSelectedAdmin(null)
  }

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false)
    setSelectedAdmin(null)
  }

  const fetchOrganizationStats = async id => {
    const response = await getOrgStats(id)
    if (response.success) {
      setOrganizationStats(response.statistics)
    }
  }

  useEffect(() => {
    if (selectedCourse?.id == moduleId) {
      setCoursesStatistics(selectedCourse?.coursesStatistics)
    }
  }, [selectedCourse, moduleId])

  const foundCourse = module?.coursesStatistics?.find(course => {
    return course.courseId === selectedCourse?.id
  })

  const percentageGrade = foundCourse?.averageGradePercentage || 0

  let adjustedPercentageGrade = percentageGrade
  if (percentageGrade > 100) {
    adjustedPercentageGrade = percentageGrade / 2
  }

  useEffect(() => {
    if (module?.organizationId) {
      fetchOrganizationStats(module.organizationId)
    }
  }, [module])

  useEffect(() => {
    const fetchModuleData = async () => {
      const result = await moduleStats(moduleId)
      if (result.success) {
        setModule(result.module.module)
      }
    }
    fetchModuleData()
  }, [moduleId])

  useEffect(() => {
    if (module && selectedCourse == null) {
      setSelectedCourse(module)
      console.log('checker')
    }
  })

  useEffect(() => {
    if (module?.courses && !selectedCourse) {
      const matchingCourse = module.courses.find(c => c?.id == courseId)

      setSelectedCourse(matchingCourse || null)
    }
  }, [courseId, module])

  console.log('selected course ', selectedCourse)

  // useEffect(() => {
  //   const isCourseSelected = module.courses?.some(c => c.id === courseId)
  //   setSelectedCourse(isCourseSelected)
  // }, [courseId])

  const toggleExpand = () => setIsExpanded(!isExpanded)

  const handleCourseSelect = course => {
    setSelectedCourse(course)
    miscState.set('moduleStatsLastSelectedCourse', course)
  }

  useEffect(() => {
    const currentPath = location.pathname

    return () => {
      if (
        !currentPath.includes(`/admin/courses/${selectedCourse?.id}`) &&
        !location.pathname.includes(`/admin/courses/${selectedCourse?.id}`)
      ) {
        miscState.set('moduleStatsLastSelectedCourse', null)
      }
    }
  }, [location, selectedCourse, miscState])

  const handleModuleTitleClick = () => {
    setSelectedCourse(module)
  }

  const selectedModuleStats = organizationStats?.modulesStatistics?.find(m => {
    return m.id == Number(moduleId)
  })

  useEffect(() => {
    const fetchModuleAdmins = async () => {
      const result = await getModuleAdmin(moduleId)
      if (result.success) {
        const admins = result.body.map(item => item)
        setModuleAdmins(admins)
      }
    }

    fetchModuleAdmins()
  }, [selectedModuleStats, uniqueSearchResults])

  const averageTimeLessonInMinutes = module
    ? Math.round(module?.statistics?.averageTimeLesson / 60)
    : 0

  const formattedAverageGrade = selectedModuleStats
    ? (selectedModuleStats.averageGrade * 10).toFixed(1)
    : null

  const debounce = (func, wait) => {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func.apply(this, args), wait)
    }
  }

  const handleSearchInputChange = e => {
    setSearchQuery(e.target.value)
  }

  const handleAddAdmin = async userId => {
    const data = await addModuleAdmin(moduleId, userId)
    if (data.success) {
      setSearchResults(prevResults =>
        prevResults.filter(admin => admin.id !== userId)
      )
    }
  }

  const handleAddTeacher = async userId => {
    const data = await addCourseTeacher(selectedCourse?.id, userId)
    if (data.success) {
      setSearchResults(prevResults =>
        prevResults.filter(admin => admin.id !== userId)
      )
    }
  }

  const handleTeacherSearchChange = async e => {
    const query = e.target.value
    setTeacherSearchQuery(query)
    if (query && selectedCourse?.id) {
      const data = await searchTeacher(selectedCourse.id, query)
      console.log(data)
      if (data.success) {
        setTeacherSearchResults(data.users)
      } else {
        setTeacherSearchResults([])
      }
    } else {
      setTeacherSearchResults([])
    }
  }
  console.log(teacherSearchResults)

  const fetchSearchResults = async query => {
    const data = await searchModuleAdmin(moduleId, query)
    if (data.success) {
      setSearchResults(data.users)
    } else {
      setSearchResults([])
    }
  }

  const debouncedSearch = debounce(query => {
    if (query) {
      fetchSearchResults(query)
    } else {
      setSearchResults([])
    }
  }, 300)

  useEffect(() => {
    debouncedSearch(searchQuery)
    return () => {
      clearTimeout(debouncedSearch)
    }
  }, [searchQuery])

  const uniqueDebounce = (func, delay) => {
    let timeoutId
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      timeoutId = setTimeout(() => {
        func.apply(null, args)
      }, delay)
    }
  }

  const uniqueFetchSearchResults = async query => {
    const result = await searchModerators(selectedCourse?.id, query)
    if (result.success) {
      setUniqueSearchResults(result.users)
    } else {
      setUniqueSearchResults([])
    }
  }

  const uniqueDebouncedSearch = uniqueDebounce(query => {
    if (query) {
      uniqueFetchSearchResults(query)
    } else {
      setUniqueSearchResults([])
    }
  }, 300)

  useEffect(() => {
    uniqueDebouncedSearch(uniqueSearchQuery)
  }, [uniqueSearchQuery])

  const handleDeleteModerator = async (selectedCourseId, userId) => {
    const result = await deleteModer(selectedCourseId, userId)
    if (result.success) {
      setIsSearchCleared(true)
      const fetchModuleAdmins = async () => {
        const result = await getModuleAdmin(selectedCourseId)
        if (result.success) {
          const admins = result.body.map(item => item)
          setModuleAdmins(admins)
        }
      }

      fetchModuleAdmins()
    }
  }

  useEffect(() => {
    if (selectedCourse?.id) {
      setSearchQuery('')
      setSearchResults([])
      setUniqueSearchQuery('')
      setUniqueSearchResults([])
    }
  }, [selectedCourse, addAdminMode])

  useEffect(() => {
    if (isSearchCleared) {
      setUniqueSearchQuery('')
      setIsSearchCleared(false)
    }
  }, [isSearchCleared])

  function formatTime(seconds) {
    const days = Math.floor(seconds / 86400)
    const hours = Math.floor((seconds % 86400) / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)

    if (days > 0) {
      return (
        <span>
          <span>
            {days}{' '}
            <span className="text-2xl relative left-[-5px] text-[#AFB7CA] font-light">
              дней
            </span>
          </span>
          {hours > 0 && (
            <span>
              {hours}{' '}
              <span className="text-2xl relative left-[-5px] text-[#AFB7CA] font-light">
                ч
              </span>
            </span>
          )}
        </span>
      )
    } else if (hours > 0) {
      return (
        <span>
          <span>
            {hours}{' '}
            <span className="text-2xl relative left-[-5px] text-[#AFB7CA] font-light">
              ч
            </span>
          </span>
          <span>
            {minutes}{' '}
            <span className="text-2xl relative left-[-5px] text-[#AFB7CA] font-light">
              мин
            </span>
          </span>
        </span>
      )
    } else {
      return (
        <span>
          {minutes}{' '}
          <span className="text-2xl relative left-[-5px] text-[#AFB7CA] font-light">
            мин
          </span>
        </span>
      )
    }
  }

  const percentageCertificates = selectedModuleStats?.certificatesReceived
    ? (selectedModuleStats?.certificatesReceived /
      selectedModuleStats?.students) *
    100
    : 0

  const percentageActivatedAccounts = selectedModuleStats?.students
    ? (selectedModuleStats?.activatedAccounts / selectedModuleStats?.students) *
    100
    : 0

  const sortedAdmins = React.useMemo(() => {
    let sortableAdmins = [...moduleAdmins]
    if (sortConfig !== null) {
      sortableAdmins.sort((a, b) => {
        let aValue = a.user[sortConfig.key] || ''
        let bValue = b.user[sortConfig.key] || ''

        if (sortConfig.direction === 'asc') {
          return aValue.localeCompare(bValue)
        }
        return bValue.localeCompare(aValue)
      })
    }
    return sortableAdmins
  }, [moduleAdmins, sortConfig])

  const requestSort = key => {
    let direction = 'asc'
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    setSortConfig({ key, direction })
  }

  const getSortingIcons = key => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? (
        <div className="flex flex-col">
          <img
            src={desc}
            alt="desc"
            className="inline-block w-[12px] h-[7px]"
          />
          <img
            src={ascSelected}
            alt="asc"
            className="inline-block w-[12px] h-[7px] mt-[6px]"
          />
        </div>
      ) : (
        <div className="flex flex-col">
          <img
            src={descSelected}
            alt="desc"
            className="inline-block w-[12px] h-[7px]"
          />
          <img
            src={asc}
            alt="asc"
            className="inline-block w-[12px] h-[7px] mt-[6px]"
          />
        </div>
      )
    } else {
      return (
        <div className="flex flex-col">
          <img
            src={desc}
            alt="desc"
            className="inline-block w-[12px] h-[7px]"
          />
          <img
            src={asc}
            alt="asc"
            className="inline-block w-[12px] h-[7px] mt-[6px]"
          />
        </div>
      )
    }
  }

  const sortedTeachers = React.useMemo(() => {
    let sortableTeachers = [...searchResults]
    if (teacherSortConfig !== null) {
      sortableTeachers.sort((a, b) => {
        let aValue = a[teacherSortConfig.key] || ''
        let bValue = b[teacherSortConfig.key] || ''

        if (teacherSortConfig.direction === 'asc') {
          return aValue.localeCompare(bValue)
        }
        return bValue.localeCompare(aValue)
      })
    }
    return sortableTeachers
  }, [searchResults, teacherSortConfig])

  const requestTeacherSort = key => {
    let direction = 'asc'
    if (
      teacherSortConfig.key === key &&
      teacherSortConfig.direction === 'asc'
    ) {
      direction = 'desc'
    }
    setTeacherSortConfig({ key, direction })
  }

  const getTeacherSortingIcons = key => {
    if (teacherSortConfig.key === key) {
      return teacherSortConfig.direction === 'asc' ? (
        <div className="flex flex-col">
          <img
            src={desc}
            alt="desc"
            className="inline-block w-[12px] h-[7px]"
          />
          <img
            src={ascSelected}
            alt="asc"
            className="inline-block w-[12px] h-[7px] mt-[6px]"
          />
        </div>
      ) : (
        <div className="flex flex-col">
          <img
            src={descSelected}
            alt="desc"
            className="inline-block w-[12px] h-[7px]"
          />
          <img
            src={asc}
            alt="asc"
            className="inline-block w-[12px] h-[7px] mt-[6px]"
          />
        </div>
      )
    } else {
      return (
        <div className="flex flex-col">
          <img
            src={desc}
            alt="desc"
            className="inline-block w-[12px] h-[7px]"
          />
          <img
            src={asc}
            alt="asc"
            className="inline-block w-[12px] h-[7px] mt-[6px]"
          />
        </div>
      )
    }
  }

  return (
    <>
      {module ? (
        <div>
          <div className="text-black flex items-center pt-2 pl-6 bg-[#F0F3FA]">
            <Link
              to={{
                pathname: `/admin/statistics/organization/${module?.organizationId}`,
                search: `?moduleId=${moduleId}`
              }}
            >
              <img src={GoBack} className="w-[50px] h-[50px]" alt="Go back" />
            </Link>
            {module && module?.title && (
              <h1 className="text-[32px] ml-4">{module?.title}</h1>
            )}
          </div>
          <div className="flex pt-6 bg-[#F0F3FA] ml-2">
            <div
              className="min-w-[340px] max-[1240px]:min-w-[280px] max-[1240px]:w-[280px] overflow-y-auto bg-white rounded-[20px]"
              style={{ maxHeight: '900px' }}
            >
              {module?.courses && (
                <div
                  className={`flex h-[66px] items-center p-2 mt-2 mx-2 rounded-md ${selectedCourse?.id == moduleId
                    ? 'bg-blue-600 text-white'
                    : 'bg-white text-black'
                    }`}
                  onClick={handleModuleTitleClick}
                >
                  <button
                    className={`bg-white text-black py-2 px-4 rounded-md
                mr-4 hover:bg-gray-100 transition-colors text-[13px] font-[600]
                ${selectedCourse?.id == moduleId
                        ? ''
                        : 'border-blue-600 border-2'
                      }`}
                  >
                    {module?.title}
                  </button>
                  <span
                    className={`max-xl:text-[15px] leading-5  font-[500] ${selectedCourse?.id == moduleId
                      ? 'text-white'
                      : 'text-black'
                      }`}
                  >
                    Статистика модуля
                  </span>
                </div>
              )}
              {module?.courses?.length && (
                <div className="col-span-12 flex flex-col">
                  <p className="font-bold text-unix-black text-lg ml-4 mt-2">
                    Курсы
                  </p>
                  <div className="text-unix-black">
                    {module?.courses.map(c => (
                      <div className="p-3" key={c.id}>
                        <PlatformCourseCard
                          course={c}
                          selectedCourse={selectedCourse}
                          isHover={mouseState !== c.id}
                          onSelect={handleCourseSelect}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {selectedModuleStats ? (
              <div className="flex-grow">
                {selectedModuleStats && (
                  <div className="ml-[16px] mr-[32px] max-[1280px]:mr-[16px]">
                    {selectedCourse?.id == moduleId && coursesStatistics && (
                      <div className="text-[#5F6B88]">
                        <div className="bg-white rounded-[20px] p-4 ">
                          <div className=" flex items-center">
                            <div className="flex items-center">
                              <div>
                                <p className="text-[48px] max-xl:text-[42px] font-[600] mr-[24px] text-[#1A1A1A]">
                                  {selectedCourse?.title}
                                </p>
                              </div>
                            </div>
                            <Button
                              className="text-[14px]"
                              onClick={toggleExpand}
                            >
                              {isExpanded ? (
                                <div className="flex bg-[#F0F3FA] rounded-[8px] py-[8px] px-[16px]">
                                  <p className="mr-[6px]">Статистика</p>
                                  <img src={arrowUp} />
                                </div>
                              ) : (
                                <div className="flex bg-[#F0F3FA] rounded-[8px] py-[8px] px-[16px]">
                                  <p className="mr-[6px]">Статистика</p>
                                  <img src={arrowDown} />
                                </div>
                              )}
                            </Button>
                          </div>
                          {isExpanded && (
                            <div className="flex mt-[32px] justify-between">
                              <div className="flex flex-col w-[30%] border-[#F0F3FA] border-r-[1px]">
                                <div className="flex flex-col rounded-2xl">
                                  <div className="flex mb-4">
                                    <img src={studentsIcon} />
                                    <p className="font-medium ml-2 text-[14px] text-[#5F6B88]">
                                      Студентов
                                    </p>
                                  </div>
                                  <p className="">
                                    <span className="text-4xl font-bold text-[#0068FF]">
                                      {selectedModuleStats.students}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="flex flex-col w-[30%] border-[#F0F3FA] border-r-[1px]">
                                <div className="flex mb-4">
                                  <img src={teachers} />
                                  <p className="font-medium ml-2 text-[14px] text-[#5F6B88]">
                                    Преподователей
                                  </p>
                                </div>
                                <p className="font-bold text-[36px]">
                                  <span className="text-[#0068FF]">
                                    {selectedModuleStats.teachers}
                                  </span>
                                </p>
                              </div>
                              <div className="flex flex-col rounded-2xl w-[30%]">
                                <div className="flex mb-4">
                                  <img src={classes} alt="" />
                                  <p className="font-medium ml-2 text-[14px] text-[#5F6B88]">
                                    Классов
                                  </p>
                                </div>
                                <p className="font-bold text-[36px]">
                                  <span className="text-[#0068FF]">
                                    {selectedModuleStats.roomsCount}
                                  </span>
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        {isExpanded && (
                          <div className="flex flex-col justify-between mt-[16px]">
                            <div className="flex gap-3 mb-3">
                              <div className="flex-1">
                                <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                                  <div className="flex justify-between items-center">
                                    <p className="font-medium text-[14px] text-[#5F6B88]">
                                      Среднее время просмотра видео/урока
                                    </p>
                                    <img
                                      src={avgTime}
                                      alt="Average Time Icon"
                                    />
                                  </div>
                                  <p className="mb-[10px]">
                                    {!isNaN(averageTimeLessonInMinutes) ? (
                                      <span className="text-4xl font-medium text-[#0068FF]">
                                        {averageTimeLessonInMinutes}
                                      </span>
                                    ) : (
                                      <span className="text-4xl font-medium text-[#0068FF]">
                                        -
                                      </span>
                                    )}
                                    <span className="text-2xl ml-2 text-[#AFB7CA]">
                                      мин
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="flex-1">
                                <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                                  <div className="flex justify-between items-center">
                                    <p className="font-medium text-[14px] text-[#5F6B88]">
                                      Среднее время прохождения лекции
                                    </p>
                                    <img
                                      src={avgLecture}
                                      alt="Average Lecture Time Icon"
                                    />{' '}
                                  </div>
                                  <p className="text-[#0068FF] font-medium text-[36px]">
                                    {formatTime(
                                      module?.statistics?.averageTimeTopic
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className="flex-1">
                                <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                                  <div className="flex justify-between items-center">
                                    <p className="font-medium text-[14px] text-[#5F6B88]">
                                      Среднее время прохождения курса
                                    </p>
                                    <img
                                      src={avgCourse}
                                      alt="Average Course Time Icon"
                                    />
                                  </div>
                                  <p className="">
                                    <p className="text-[#0068FF] font-medium text-[36px]">
                                      {formatTime(
                                        module?.statistics
                                          ?.averageTimeCourse
                                      )}
                                    </p>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-3">
                              <div className="flex-1">
                                <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                                  <div className="flex justify-between items-center">
                                    <p className="font-medium text-[14px] text-[#5F6B88]">
                                      Средняя оценка студентов!
                                    </p>
                                    <img
                                      width={24}
                                      height={24}
                                      src={achieve}
                                      alt="Achievement Icon"
                                    />
                                  </div>
                                  <div className="text-[24px] items-center flex gap-2">
                                    <span className="text-[#0068FF] text-[40px] font-regular">
                                      {(formattedAverageGrade / 10).toFixed(1)}
                                    </span>{' '}
                                    <div className="font-extralight">%</div>
                                  </div>
                                </div>
                              </div>
                              <div className="flex-1">
                                <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                                  <div className="flex justify-between items-center">
                                    <p className="font-medium text-[14px] text-[#5F6B88]">
                                      Сертификатов получено
                                    </p>
                                    <img src={Certificate} />
                                  </div>
                                  <div className="flex items-center justify-between">
                                    <p className="font-regular text-[40px] text-[#0068FF]">
                                      {selectedModuleStats.certificatesReceived}
                                    </p>
                                    <div className="w-[48px] h-[48px]">
                                      <CircularProgressbar
                                        value={percentageCertificates}
                                        text={`${percentageCertificates.toFixed(1)}%`}
                                        strokeWidth={15}
                                        styles={buildStyles({
                                          textSize: '20px',
                                          fontWeight: '500',
                                          pathColor: '#0068FF',
                                          textColor: '#5F6B88',
                                          trailColor: '#F0F3FA'
                                        })}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="flex-1">
                                <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                                  <div className="flex justify-between items-center">
                                    <p className="font-medium text-[14px] text-[#5F6B88]">
                                      Аккаунтов активировано
                                    </p>
                                    <img
                                      src={Accounts}
                                      alt="Average Daily Time Icon"
                                    />
                                  </div>
                                  <div className="flex items-center justify-between">
                                    <p className="font-regular text-[40px] text-[#0068FF]">
                                      {selectedModuleStats.activatedAccounts}
                                    </p>
                                    <div className="w-[48px] h-[48px]">
                                      <CircularProgressbar
                                        value={percentageActivatedAccounts}
                                        text={`${percentageActivatedAccounts.toFixed(
                                          1
                                        )}%`}
                                        strokeWidth={15}
                                        styles={buildStyles({
                                          textSize: '20px',
                                          fontWeight: '500',
                                          pathColor: '#0068FF',
                                          textColor: '#5F6B88',
                                          trailColor: '#F0F3FA'
                                        })}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="flex bg-white rounded-[12px] justify-center ml-1 p-3 mt-3 gap-2">
                          {addAdminMode ? (
                            <input
                              type="text"
                              placeholder="Поиск"
                              value={searchQuery}
                              onChange={handleSearchInputChange}
                              className="bg-[#F0F3FA] text-[#000] h-[43px] flex-grow  stroke-none border-none rounded-[8px]"
                            />
                          ) : (
                            <input
                              type="text"
                              placeholder="Поиск"
                              value={uniqueSearchQuery}
                              onChange={e =>
                                setUniqueSearchQuery(e.target.value)
                              }
                              className="bg-[#F0F3FA] text-[#000] h-[43px] flex-grow stroke-none border-none rounded-[8px]"
                            />
                          )}
                          <button className="bg-[#0068FF] text-[#0068FF] h-[43px] bg-opacity-20 p-1 border border-opacity-50 rounded-[8px] border-[#0068FF] flex items-center gap-2 pr-3">
                            <button
                              className={`${addAdminMode ? '' : 'bg-[#0068FF]'
                                } h-[35px] max-xl:text-[14px] items-center justify-center min-w-[167px] w-[167px] whitespace-nowrap gap-2 flex text-white rounded-[6px] px-3 py-2`}
                              onClick={() => setAddAdminMode(false)}
                            >
                              {addAdminMode ? (
                                <img src={searchBlueIcon} />
                              ) : (
                                <img src={whiteSearchIcon} />
                              )}
                              <p
                                className={`${addAdminMode ? 'text-[#0068FF]' : ''}`}
                              >
                                Поиск в модуле
                              </p>
                            </button>
                            <div
                              className={`flex items-center gap-2 w-fit xl:pl-2 h-[35px] rounded-[6px] px-3 py-2 ${addAdminMode ? 'bg-[#0068FF]' : ''
                                }`}
                              onClick={() => setAddAdminMode(true)}
                            >
                              {addAdminMode ? (
                                <img src={addAdminIcon} />
                              ) : (
                                <img src={add} />
                              )}
                              <p
                                className={`font-medium max-xl:text-[14px] max-xl:font-normal xl:pl-2 whitespace-nowrap ${addAdminMode ? 'text-white' : ''
                                  }`}
                              >
                                Добавить администратора
                              </p>
                            </div>
                          </button>
                        </div>
                        <section className="flex flex-col py-4 pr-2 pl-4 mt-4 bg-white rounded-xl max-md:max-w-full">
                          <div className="flex gap-4 px-2 pt-2 pb-4 rounded-lg max-md:flex-wrap">
                            <div
                              className="text-sm font-medium leading-4 text-slate-500"
                              style={{ minWidth: '50px' }}
                            >
                              №
                            </div>
                            <div
                              className="flex-1 flex items-center text-sm font-medium text-left leading-4 text-slate-500 cursor-pointer"
                              onClick={() => requestSort('firstname')}
                            >
                              <span className="mr-[6px]">Администратор</span>
                              {getSortingIcons('firstname')}
                            </div>
                            <div
                              className="flex-1 flex items-center text-sm font-medium text-left leading-4 text-slate-500 cursor-pointer max-xl:ml-6"
                              onClick={() => requestSort('email')}
                            >
                              <span className="mr-[6px]">Почта</span>
                              {getSortingIcons('email')}
                            </div>
                            <div className="flex-1 text-sm font-medium leading-4 text-left pr-4 text-slate-500">
                              Активность за неделю
                            </div>
                          </div>
                          {addAdminMode &&
                            searchResults.map((admin, index) => (
                              <div
                                key={admin.id}
                                className="flex gap-2 items-center p-2 text-base border-t border-solid border-slate-100"
                              >
                                <div
                                  style={{ minWidth: '50px' }}
                                  className="text-sm font-medium leading-4 text-slate-500"
                                >
                                  {index + 1}
                                </div>
                                <div className="flex-0 w-[30%] flex gap-3 items-center">
                                  <div className="justify-center items-center px-2.5 pt-2 font-semibold text-center text-white bg-amber-200 h-[38px] leading-[120%] rounded-full w-[38px]">
                                    <span className="relative left-[-2.5px] top-[1px]">
                                      {admin.firstname[0]}
                                      {admin.lastname[0]}
                                    </span>
                                  </div>
                                  <div className="flex-0 my-auto font-medium leading-[130%] text-ellipsis text-zinc-900 max-xl:text-[14px]">
                                    {admin.firstname} {admin.lastname}
                                  </div>
                                </div>
                                <div className="flex-0 w-[30%] my-auto font-medium leading-[130%] text-slate-500 max-xl:ml-6">
                                  {admin.email}
                                </div>
                                <div className="flex min-w-[171px]  max-[1310px]:left-[-15px] max-xl:w-[165px] items-center w-fit xl:pl-2 bg-[#3BBC30] text-white rounded-[8px] h-[43px] pr-3 pl-3">
                                  <img src={plusWhite} />
                                  <p
                                    className="font-medium max-xl:text-[14px] max-xl:font-normal"
                                    onClick={() => handleAddAdminClick(admin)}
                                  >
                                    Добавить админа
                                  </p>
                                </div>
                              </div>
                            ))}
                          {!addAdminMode && uniqueSearchResults.length == 0 ? (
                            sortedAdmins.map((admin, index) => (
                              <div key={index}>
                                <AdminStatItem
                                  number={index + 1}
                                  admin={admin}
                                  initials={`${admin.user.firstname.charAt(0)}${admin.user.lastname.charAt(0)}`}
                                  selectedModule={selectedModuleStats.id}
                                  userId={admin.userId}
                                  moduleId={selectedCourse.id}
                                  name={`${admin.user.firstname} ${admin.user.lastname}`}
                                  email={admin.user.email}
                                  handleDeleteAdminClick={
                                    handleDeleteAdminClick
                                  }
                                />
                              </div>
                            ))
                          ) : (
                            <div>
                              {uniqueSearchResults.map((moderator, index) => (
                                <div key={moderator.userId}>
                                  <AdminStatItem
                                    admin={moderator}
                                    userId={moderator.userId}
                                    moduleId={selectedCourse.id}
                                    number={index + 1}
                                    initials={`${moderator.firstname.charAt(
                                      0
                                    )}${moderator.lastname.charAt(0)}`}
                                    name={`${moderator.firstname} ${moderator.lastname}`}
                                    email={moderator.email}
                                    handleDeleteAdminClick={
                                      handleDeleteAdminClick
                                    }
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </section>
                        <div
                          className="overflow-y-auto bg-[white] rounded-[20px] mt-4"
                          style={{ maxHeight: '900px' }}
                        ></div>
                      </div>
                    )}
                    <AdminAddConfirmModal
                      isOpen={isAddModalOpen}
                      admin={selectedAdmin}
                      onClose={handleCloseModal}
                      onConfirm={handleConfirmAddAdmin}
                    />
                    <AdminDeleteConfirmModal
                      isOpen={isDeleteModalOpen}
                      admin={selectedAdmin}
                      moduleId={selectedCourse?.id}
                      onClose={handleCloseDeleteModal}
                      onConfirm={handleConfirmDeleteAdmin}
                    />
                    {selectedCourse && selectedCourse?.id != moduleId && (
                      <div className="text-[#5F6B88]">
                        <div className="bg-white rounded-[20px] p-4 ">
                          <div className=" flex items-center">
                            <div className="flex items-center">
                              <img
                                src={selectedCourse?.imageLink}
                                className="rounded-[15px]"
                                style={{ width: '190px', height: '96px' }}
                              />
                              <Link>
                                <p className="text-[48px] leading-[48px] max-[1160px]:text-[42px] max-[1110px]:text-[36px] max-[1110px]:leading-[36px] max-[1160px]:leading-[42px] font-medium ml-8 text-black">
                                  {selectedCourse?.title}
                                </p>
                              </Link>
                            </div>
                          </div>
                          {isExpanded && (
                            <div className="flex justify-between mt-5">
                              <div className="flex flex-col border-r lg:pr-14 xl:pr-28 lg:ml-2">
                                <div className="flex gap-2">
                                  <img src={Students} />
                                  <h2>Студентов</h2>
                                </div>
                                <h2 className="text-[#0068FF] font-medium text-[32px]">
                                  {foundCourse?.studentsCount}
                                </h2>
                              </div>
                              <div className="flex flex-col  border-r lg:pr-14 xl:pr-28 lg:ml-4">
                                <div className="flex gap-2">
                                  <img src={Lectures} />
                                  <h2>Лекций</h2>
                                </div>
                                <h2 className="text-[#0068FF] font-medium text-[32px]">
                                  {foundCourse?.topicCount}
                                </h2>
                              </div>
                              <div className="flex flex-col  border-r lg:pr-14 xl:pr-28 lg:ml-4">
                                <div className="flex gap-2">
                                  <img src={Lessons} />
                                  <h2>Уроков</h2>
                                </div>
                                <h2 className="text-[#0068FF] font-medium text-[32px]">
                                  {foundCourse?.lessonCount}
                                </h2>
                              </div>
                              <div className="flex flex-col lg:pr-14 xl:pr-28 lg:ml-4">
                                <div className="flex gap-2">
                                  <img src={Tests} />
                                  <h2>Тестов</h2>
                                </div>
                                <h2 className="text-[#0068FF] font-medium text-[32px]">
                                  {foundCourse?.quizCount}
                                </h2>
                              </div>
                            </div>
                          )}
                        </div>
                        {isExpanded && (
                          <div className="flex gap-3 mt-[16px]">
                            <div className="flex-1">
                              <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                                <div className="flex justify-between items-center">
                                  <p className="font-medium text-[14px] text-[#5F6B88]">
                                    Средняя оценка студентов
                                  </p>
                                  <img
                                    width={24}
                                    height={24}
                                    src={achieve}
                                    alt="Achievement Icon"
                                  />
                                </div>
                                <p className="font-regular text-[40px] text-[#0068FF]">
                                  {adjustedPercentageGrade.toFixed(1)}{' '}
                                  <span className="text-[24px] text-[#AFB7CA] font-[300]">
                                    %
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="flex-1">
                              <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                                <div className="flex justify-between items-center">
                                  <p className="font-medium text-[14px] text-[#5F6B88]">
                                    Сертификатов получено
                                  </p>
                                  <img
                                    src={Certificate}
                                    alt="Average Course Time Icon"
                                  />
                                </div>
                                <div className="flex items-center justify-between">
                                  <p className="font-regular text-[40px] text-[#0068FF]">
                                    {foundCourse?.certificatesCount}
                                  </p>
                                  <div className="w-[48px] h-[48px]">
                                    <CircularProgressbar
                                      value={percentageCertificates}
                                      text={`${percentageCertificates.toFixed(1)}%`}
                                      strokeWidth={15}
                                      styles={buildStyles({
                                        textSize: '20px',
                                        fontWeight: '500',
                                        pathColor: '#0068FF',
                                        textColor: '#5F6B88',
                                        trailColor: '#F0F3FA'
                                      })}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex-1">
                              <div className="flex flex-col justify-between h-[150px] rounded-2xl bg-[#FFF] p-4">
                                <div className="flex justify-between items-center">
                                  <p className="font-medium text-[14px] text-[#5F6B88]">
                                    Аккаунтов активировано
                                  </p>
                                  <img
                                    src={Accounts}
                                    alt="Average Daily Time Icon"
                                  />
                                </div>
                                <div className="flex items-center justify-between">
                                  <p className="font-regular text-[40px] text-[#0068FF]">
                                    {foundCourse?.activatedUsersCount}
                                  </p>
                                  <div className="w-[48px] h-[48px]">
                                    <CircularProgressbar
                                      value={percentageActivatedAccounts}
                                      text={`${percentageActivatedAccounts.toFixed(1)}%`}
                                      strokeWidth={15}
                                      styles={buildStyles({
                                        textSize: '20px',
                                        fontWeight: '500',
                                        pathColor: '#0068FF',
                                        textColor: '#5F6B88',
                                        trailColor: '#F0F3FA'
                                      })}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="flex bg-white justify-center ml-1 p-3 mt-3 gap-2">
                          {addAdminMode ? (
                            <input
                              type="text"
                              placeholder="Поиск"
                              value={searchQuery}
                              onChange={handleSearchInputChange}
                              className="bg-[#F0F3FA] text-[#000] h-[43px] max-xl:w-[60%] xl:w-[53%] stroke-none border-none rounded-[8px]"
                            />
                          ) : (
                            <input
                              type="text"
                              placeholder="Поиск"
                              value={teacherSearchQuery}
                              onChange={handleTeacherSearchChange}
                              className="bg-[#F0F3FA] text-[#000] h-[43px] max-xl:w-[60%] xl:w-[53%] stroke-none border-none rounded-[8px]"
                            />
                          )}
                          <button className="bg-[#0068FF] text-[#0068FF] h-[43px] bg-opacity-20 p-1 border border-opacity-50 rounded-[8px] border-[#0068FF] flex items-center gap-2 pr-3">
                            <button
                              className={`${addAdminMode ? '' : 'bg-[#0068FF]'
                                } h-[35px] max-xl:text-[14px] justify-center min-w-[167px] w-[167px] whitespace-nowrap items-center gap-2 flex text-white rounded-[6px] px-3 py-2`}
                              onClick={() => setAddAdminMode(false)}
                            >
                              {addAdminMode ? (
                                <img src={searchBlueIcon} />
                              ) : (
                                <img src={whiteSearchIcon} />
                              )}
                              <p
                                className={`${addAdminMode ? 'text-[#0068FF]' : ''}`}
                              >
                                Поиск в модуле
                              </p>
                            </button>
                            <div
                              className={`flex items-center gap-2 w-fit xl:pl-2 h-[35px] rounded-[6px] px-3 py-2 ${addAdminMode ? 'bg-[#0068FF]' : ''
                                }`}
                              onClick={() => setAddAdminMode(true)}
                            >
                              {addAdminMode ? (
                                <img src={addAdminIcon} />
                              ) : (
                                <img src={add} />
                              )}
                              <p
                                className={`font-medium max-xl:text-[14px] max-xl:font-normal xl:pl-2 whitespace-nowrap ${addAdminMode ? 'text-white' : ''
                                  }`}
                              >
                                Добавить преподователя
                              </p>
                            </div>
                          </button>
                        </div>
                        <section className="flex flex-col py-4 pr-2 pl-4 mt-4 bg-white rounded-xl max-md:max-w-full">
                          <div className="flex gap-4 px-2 pt-2 pb-4 rounded-lg max-md:flex-wrap">
                            <div
                              className="text-sm font-medium leading-4 text-slate-500"
                              style={{ minWidth: '50px' }}
                            >
                              №
                            </div>
                            <div
                              className="flex-1 flex items-center text-sm font-medium text-left leading-4 text-slate-500 cursor-pointer"
                              onClick={() => requestTeacherSort('firstname')}
                            >
                              <span className="mr-[6px]">Преподаватель</span>
                              {getTeacherSortingIcons('firstname')}
                            </div>
                            <div
                              className="flex-1 flex items-center text-sm font-medium text-left leading-4 text-slate-500 cursor-pointer max-xl:ml-6"
                              onClick={() => requestTeacherSort('email')}
                            >
                              <span className="mr-[6px]">Почта</span>
                              {getTeacherSortingIcons('email')}
                            </div>
                            <div className="flex-1 text-sm font-medium leading-4 text-left pr-4 text-slate-500">
                              Активность за неделю
                            </div>
                          </div>
                          {addAdminMode &&
                            sortedTeachers.map((admin, index) => (
                              <div
                                key={admin.id}
                                className="flex gap-2 items-center p-2 text-base border-t border-solid border-slate-100"
                              >
                                <div
                                  style={{ minWidth: '50px' }}
                                  className="text-sm font-medium leading-4 text-slate-500"
                                >
                                  {index + 1}
                                </div>
                                <div className="flex-0 w-[30%] flex gap-3 items-center">
                                  <div className="justify-center items-center px-2.5 pt-2 font-semibold text-center text-white bg-amber-200 h-[38px] leading-[120%] rounded-full w-[38px]">
                                    <span className="relative left-[-2.5px] top-[1px]">
                                      {admin.firstname[0]}
                                      {admin.lastname[0]}
                                    </span>
                                  </div>
                                  <div className="flex-0 my-auto font-medium leading-[130%] text-ellipsis text-zinc-900 max-xl:text-[14px]">
                                    {admin.firstname} {admin.lastname}
                                  </div>
                                </div>
                                <div className="flex-0 w-[30%] my-auto font-medium leading-[130%] text-slate-500 max-xl:ml-6">
                                  {admin.email}
                                </div>
                                <div className="flex min-w-[171px]  max-[1310px]:left-[-15px] items-center w-fit xl:pl-2 bg-[#3BBC30] text-white rounded-[8px] h-[43px] pr-3 pl-3">
                                  <img
                                    className="max-xl:hidden"
                                    src={plusWhite}
                                  />
                                  <p
                                    className="font-medium max-xl:text-[14px] max-xl:font-normal whitespace-nowrap"
                                    onClick={() => handleAddTeacherClick(admin)}
                                  >
                                    Добавить преподователя
                                  </p>
                                </div>
                              </div>
                            ))}
                          {((addAdminMode && !sortedTeachers.length) ||
                            (!addAdminMode &&
                              ((teacherSearchQuery.length &&
                                !teacherSearchResults.length) ||
                                (!teacherSearchQuery.length &&
                                  !selectedCourse?.coursesCuratorsMap
                                    ?.length)))) && (
                              <div className="flex gap-2 justify-center items-center p-2 text-base border-t border-solid border-slate-100">
                                <p className="font-bold">
                                  Преподавателей не найдено
                                </p>
                              </div>
                            )}
                          <TeacherAddConfirmModal
                            isOpen={isTeacherModalOpen}
                            admin={selectedAdmin}
                            onClose={handleCloseTeacherModal}
                            onConfirm={handleConfirmAddTeacher}
                          />
                          {!addAdminMode &&
                            (teacherSearchQuery &&
                              teacherSearchResults.length > 0
                              ? teacherSearchResults.map((teacher, index) => (
                                <Link
                                  to={{
                                    pathname: `/admin/courses/${selectedCourse?.id}/rooms/curator/${teacher.userId}`,
                                    state: { moduleId }
                                  }}
                                  key={teacher.userId}
                                >
                                  <TeacherDetails
                                    index={index}
                                    initials={`${teacher.firstname.charAt(
                                      0
                                    )}${teacher.lastname.charAt(0)}`}
                                    imgSrc={teacher.imgSrc}
                                    name={`${teacher.firstname} ${teacher.lastname}`}
                                    email={teacher.email}
                                  />
                                </Link>
                              ))
                              : !teacherSearchQuery.length
                                ? selectedCourse?.coursesCuratorsMap.map(
                                  (m, index) => (
                                    <Link
                                      to={{
                                        pathname: `/admin/courses/${selectedCourse?.id}/rooms/curator/${m.userId}`,
                                        search: `?moduleId=${moduleId}`
                                      }}
                                      key={m.userId}
                                    >
                                      <TeacherDetails
                                        index={index}
                                        initials={`${m.user.firstname.charAt(
                                          0
                                        )}${m.user.lastname.charAt(0)}`}
                                        imgSrc={m.imgSrc}
                                        name={`${m.user.firstname} ${m.user.lastname}`}
                                        email={m.user.email}
                                      />
                                    </Link>
                                  )
                                )
                                : null)}
                        </section>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className={'w-[100%] mx-auto'}>
                <LoadingSpinner />
              </div>
            )}
          </div>
        </div>
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}

const TeacherDetails = ({ initials, index, name, email }) => {
  return (
    <div className="flex gap-2 items-center p-2 text-base border-t border-solid border-slate-100">
      <div
        style={{ minWidth: '50px' }}
        className="text-sm font-medium leading-4 text-slate-500"
      >
        {index + 1}
      </div>
      <div className="flex-1 flex gap-3 items-center">
        <div className="justify-center items-center px-2.5 pt-2 font-semibold text-center text-white bg-amber-200 h-[38px] leading-[120%] rounded-full w-[38px]">
          {initials}
        </div>
        <div className="flex-1 my-auto w-[130px] font-medium leading-[130%] text-ellipsis text-zinc-900 max-xl:text-[14px]">
          {`${name.split(' ').slice(0, 2).join(' ')}`}{' '}
        </div>
      </div>
      <div className="flex-1 my-auto font-medium leading-[130%] text-slate-500 max-xl:ml-6">
        {email}
      </div>
      <div className="flex-1 flex flex-col py-px text-xs font-medium leading-3 text-center text-slate-500"></div>
    </div>
  )
}

const AdminStatItem = ({
  number,
  initials,
  admin,
  name,
  moduleId,
  email,
  handleDeleteAdminClick
}) => {
  const onDeleteClick = () => {
    handleDeleteAdminClick(moduleId, admin)
  }

  return (
    <div className="flex gap-2 items-center p-2 text-base border-t border-solid border-slate-100">
      <div
        style={{ minWidth: '50px' }}
        className="text-sm font-medium leading-4 text-slate-500"
      >
        {number}
      </div>
      <div className="flex-1 flex gap-3 items-center">
        <div className="justify-center items-center px-2.5 pt-2 font-semibold text-center text-white bg-amber-200 h-[38px] leading-[120%] rounded-full w-[38px]">
          <span className="relative left-[-2.5px] top-[1px]">{initials}</span>
        </div>
        <div className="flex-1 my-auto font-medium leading-[130%] text-ellipsis text-zinc-900 max-xl:text-[14px]">
          {name}
        </div>
      </div>
      <div className="flex-1 my-auto font-medium leading-[130%] text-slate-500 max-xl:ml-6">
        {email}
      </div>
      <div className=""></div>
      <div className="flex-1 flex relative flex-col text-xs font-medium leading-3 text-center text-slate-500">
        <img
          className="absolute right-0 top-[-5px] bg-[#F12D2D]/[20%] rounded-[6px] opacity-80"
          width={20}
          height={20}
          src={deleteIcon}
          onClick={onDeleteClick}
        />
      </div>
    </div>
  )
}

const TeacherAddConfirmModal = ({ isOpen, admin, onClose, onConfirm }) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-60">
        <div className="bg-white p-4 rounded-lg shadow-lg w-[560px]">
          <div className="flex justify-end mb-4 cursor-pointer">
            <img onClick={onClose} src={closeIcon} />
          </div>
          <div className="flex items-center gap-4 text-[20px]">
            <img src={warningIcon} />
            <h4 className="text-[#5F6B88]">
              Вы уверены что хотите добавить преподователя{' '}
              <span className="text-[#1A1A1A]">
                {admin.firstname} {admin.lastname}
              </span>
              ?
            </h4>
          </div>
          <div className="flex justify-end mt-12">
            <button
              onClick={onClose}
              className="bg-[#5F6B88] text-white h-[48px] rounded-[8px] w-full flex items-center justify-center gap-2"
            >
              <img src={leftIcon} />
              Вернуться
            </button>
            <button
              onClick={() => onConfirm(admin.id)}
              className="bg-[#3BBC30] text-white h-[48px] ml-3 rounded-[8px] w-full flex items-center justify-center gap-2"
            >
              <img src={plusWhite} />
              Добавить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const AdminAddConfirmModal = ({ isOpen, admin, onClose, onConfirm }) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-4 rounded-lg shadow-lg w-[560px]">
          <div className="flex justify-end mb-4 cursor-pointer">
            <img onClick={onClose} src={closeIcon} />
          </div>
          <div className="flex items-center gap-4 text-[20px]">
            <img src={warningIcon} />
            <h4 className="text-[#5F6B88]">
              Вы уверены что хотите добавить администратора{' '}
              <span className="text-[#1A1A1A]">
                {admin.firstname} {admin.lastname}
              </span>
              ?
            </h4>
          </div>
          <div className="flex justify-end mt-12">
            <button
              onClick={onClose}
              className="bg-[#5F6B88] text-white h-[48px] rounded-[8px] w-full flex items-center justify-center gap-2"
            >
              <img src={leftIcon} />
              Вернуться
            </button>
            <button
              onClick={() => onConfirm(admin.id)}
              className="bg-[#3BBC30] text-white h-[48px] ml-3 rounded-[8px] w-full flex items-center justify-center gap-2"
            >
              <img src={plusWhite} />
              Добавить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const AdminDeleteConfirmModal = ({
  isOpen,
  admin,
  moduleId,
  onClose,
  onConfirm
}) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-4 rounded-lg shadow-lg w-[560px]">
          <div className="flex justify-end mb-4 cursor-pointer">
            <img onClick={onClose} src={closeIcon} />
          </div>
          <div className="flex items-center gap-4 text-[20px]">
            <img src={warningIcon} />
            <h4 className="text-[#5F6B88]">
              Вы уверены что хотите удалить администратора{' '}
              <span className="text-[#1A1A1A]">
                {admin.user.firstname} {admin.user.lastname}
              </span>
              ?
            </h4>
          </div>
          <div className="flex justify-end mt-12">
            <button
              onClick={onClose}
              className="bg-[#5F6B88] text-white h-[48px] rounded-[8px] w-full flex items-center justify-center gap-2"
            >
              <img src={leftIcon} />
              Вернуться
            </button>
            <button
              onClick={() => onConfirm(moduleId, admin.userId)}
              className="bg-[#F12D2D] text-white h-[48px] ml-3 rounded-[8px] w-full flex items-center justify-center gap-2"
            >
              <img src={bucket} />
              Удалить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const LoadingSpinner = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <CircularProgress />
    </div>
  )
}

import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getCourse } from '../../../actions/course'
import { getLesson } from '../../../actions/lesson'
import { getModule } from '../../../actions/module'
import { getOrganization } from '../../../actions/organization'
import arrowLeftGray from '../../../assets/arrow_left_gray.svg'
import linkBlue from '../../../assets/link_blue.svg'
import { useOnlineLesson } from '../../../hooks/courses'
import { localize, skipDeadlineRenderModuleTitles, weekdays } from '../../../use-cases/constants'
import { getClosestDeadline, getCourseLocalizedValue, getTopicDeadline } from '../../../utils'
import { SingleTopic } from '../../pages/new-entity-redesign/lessons/SingleTopic'
import { useGlobalMiscState } from '../../../stores/misc'

export const PlatformCourse = () => {
  const miscState = useGlobalMiscState()
  const platformLang = miscState.get('lang')
  const { courseId } = useParams()
  const [course, setCourse] = useState({})
  const [organization, setOrganization] = useState({})
  const [lesson, setLesson] = useState({})
  const [expanded, setExpanded] = useState({})
  const navigate = useNavigate()
  const location = useLocation()
  const [module, setModule] = useState(location.state?.module || {})
  const searchParams = new URLSearchParams(location.search)
  const lessonId = searchParams.get('lessonId')

  const fetchCourse = async id => {
    const response = await getCourse(id)
    if (response.success) {
      setCourse(response.course)
    }
  }

  useEffect(() => {
    fetchCourse(courseId)
  }, [courseId])

  const fetchLesson = async id => {
    const response = await getLesson(id)
    if (response.success) {
      setLesson(response.lesson)
    }
  }

  useEffect(() => {
    if (lesson.id) {
      fetchLesson(lessonId)
    }
  }, [lessonId, lesson])

  const fetchModule = async id => {
    const response = await getModule(id)
    if (response.success) {
      setModule(response.module)
    }
  }
  const fetchOrganization = async id => {
    const response = await getOrganization(id)
    if (response.success) {
      setOrganization(response.organization)
    }
  }

  const goBack = () => {
    if (module.id) {
      navigate(`/platform/`)
    }
  }

  const renderDescription = (additionalClassName = 'mt-5') => {
    return (
      <div
        className={`flex flex-col rounded-[28px] p-4 md:p-6 bg-white dark:bg-[#1a1a1a] ${additionalClassName}`}
        onClick={e => e.stopPropagation()}
      >
        <div className="flex flex-col pb-4">
          <p className="text-[13px] text-unix-gray dark:text-white">
            {getCourseLocalizedValue('description', course, platformLang, true)}
          </p>
        </div>
        {renderOnlineLesson()}
        {renderClosestDeadline()}
      </div>
    )
  }

  const renderTopicList = () => {
    return (
      <div
        className="mt-5 rounded-[28px] bg-white dark:bg-[#1a1a1a] p-6 flex-1 overflow-y-auto max-h-[700px]"
        onClick={e => e.stopPropagation()}
      >
        {course.topics?.map((t, idx) => (
          <SingleTopic
            textSize={'20px'}
            key={t.id}
            topic={t}
            listItem={true}
            currentLessonId={parseInt(lessonId)}
            isLast={idx === course.topics.length - 1}
          />
        ))}
      </div>
    )
  }

  const renderClosestDeadline = () => {
    if (skipDeadlineRenderModuleTitles.includes(module.title)) {
      return null
    }
    if (course.topics?.length) {
      const deadlines = course.topics
        .map(t => ({
          id: t.id,
          deadline: t.deadline
        }))
        .filter(t => Boolean(t.deadline))
      const closestDeadlineId = getClosestDeadline(deadlines)
      if (closestDeadlineId) {
        const topic = course.topics.find(t => t.id === closestDeadlineId)
        return (
          <div className="flex flex-col pt-4 border-t border-t-unix-light-gray">
            <div className="flex flex-row justify-between items-center mb-4">
              <span className="text-unix-gray text-sm dark:text-white">
                {localize('closest_deadline')}
              </span>
              <span className="font-medium text-unix-text-black text-sm md:text-base">
                {getTopicDeadline(topic)}
              </span>
            </div>
            <SingleTopic topic={topic} />
          </div>
        )
      }
    }
  }

  const onlineLesson = useOnlineLesson(courseId)

  const renderOnlineLesson = () => {
    if ('link' in onlineLesson) {
      const startDate = new Date(onlineLesson.startDate)
      return (
        <div className="py-4 flex flex-row justify-between border-t border-t-unix-light-gray">
          <div className="flex flex-row items-center">
            <span className="text-sm text-unix-gray">Онлайн урок</span>
            <a
              className="ml-2 flex flex-row items-center bg-unix-background-blue rounded-full px-2.5 py-1"
              target="_blank"
              href={onlineLesson.link}
              rel="noreferrer"
            >
              <img src={linkBlue} />
              <span className="text-xs text-unix-blue">Перейти</span>
            </a>
          </div>
          <span className="text-unix-text-black font-medium text-sm md:text-base">
            {weekdays[startDate.getDay()]}.{' '}
            {onlineLesson.startDate?.substring(11, 16)}-
            {onlineLesson.endDate?.substring(11, 16)}
          </span>
        </div>
      )
    }
  }

  useEffect(() => {
    fetchCourse(courseId)
  }, [courseId])

  useEffect(() => {
    if (course.moduleId && !module.id) {
      fetchModule(course.moduleId)
    }
    if (course.topics && course.topics.length) {
      if (Object.keys(expanded).length === 0) {
        setExpanded(
          course.topics
            .map(t => ({ [t.id]: false }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {})
        )
      }
    }
  }, [course])
  useEffect(() => {
    if (module.organizationId && !organization.id) {
      fetchOrganization(module.organizationId)
    }
  }, [module])

  useEffect(() => {
    const elements1 = document.querySelectorAll('.css-1q0v17x')
    elements1.forEach(element => {
      element.style.padding = '0'
      element.classList.add('flex')
      element.classList.add('flex-col')
      element.classList.add('flex-col')
    })

    return () => {
      elements1.forEach(element => (element.style.padding = ''))
    }
  }, [])

  return (
    <div className={'dark:bg-[#1a1a1a]'}>
      <div
        className={
          'text-[48px] text-[#1A1A1A] dark:text-white max-w-[1280px] max-xl:px-3 font-semibold mx-auto mt-[56px]'
        }
      >
        {getCourseLocalizedValue('title', course, platformLang)}
      </div>
      <div className="grid grid-cols-12 bg-[#F0F3FA] dark:bg-black mt-10 max-md:mt-4 min-h-[1000px]">
        <div className="col-span-12 grid grid-cols-10 mt-[32px] max-w-[1280px] max-xl:px-3 mx-auto">
          <div className={'md:col-span-3 col-span-12'}>
            <div
              className="flex flex-row w-[85px] rounded-full mb-[20px] bg-white dark:bg-[#1a1a1a] py-2 px-3 cursor-pointer items-center"
              onClick={goBack}
            >
              <img src={arrowLeftGray} />
              <span className="ml-1.5 text-xs text-unix-gray dark:text-white font-medium">
                Назад
              </span>
            </div>
            {renderDescription('mt-0')}
          </div>
          <div
            className={
              'md:col-span-8 md:col-start-4 dark:bg-black md:ml-10 col-span-12 col-start-0 md:relative md:top-[-30px] max-[768px]:mt-4 mb-10'
            }
          >
            <div className="overflow-y-auto bg-[#F0F3FA] dark:bg-black">
              {renderTopicList()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

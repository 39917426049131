import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getCourse, roomDetails } from '../../../actions/course'
import { moduleStats } from '../../../actions/module'
import UnixSelect from '../../ui/Select/UnixSelect'
import arrowRightGray from './arrow_right_gray.svg'
import asc from './asc.svg'
import ascSelected from './ascSelected.svg'
import avgCourse from './avgCourse.svg'
import avgLectureIcon from './avgLecture.svg'
import avgLessonIcon from './avgLesson.svg'
import backWhite from './back_white.svg'
import desc from './desc.svg'
import descSelected from './descSelected.svg'

export const StatisticsClass = () => {
  const { courseId, curatorId } = useParams()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const moduleId = queryParams.get('moduleId')
  const navigate = useNavigate()
  const [rooms, setRooms] = useState([])
  const [result, setResult] = useState([])
  const [module, setModule] = useState({})
  const [course, setCourse] = useState({})
  const moduleOptions = Array.isArray(module) ? module : []
  const [loading, setLoading] = useState(true)
  const [roomsStatistics, setRoomsStatistics] = useState([])
  const [users, setUsers] = useState([])
  const [usersProb, setUsersProb] = useState([])
  const [students, setStudents] = useState([])
  const [name] = useState('')
  const [selectedRoom, setSelectedRoom] = useState({})
  const [selectOption] = useState('firstname')
  const [sortDirection, setSortDirection] = useState('asc')
  const [sortColumn, setSortColumn] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [roomStatistics, setRoomStatistics] = useState({ roomStatistics: {} })
  const [selectedCurator, setSelectedCurator] = useState(null)

  const handleCuratorChange = newCurator => {
    navigate(
      `/admin/courses/${courseId}/rooms/curator/${newCurator.userId}?moduleId=${moduleId}`,
      { replace: true }
    )
  }

  useEffect(() => {
    const fetchRoomDetails = async () => {
      const data = await roomDetails(courseId, curatorId)
      if (data) {
        setResult(data.body.result)
        setRooms(data.body.rooms)
        setLoading(false)
        setRoomsStatistics(data.body.roomsStatistics, roomsStatistics)
        setUsers(data.body.users)
        setUsersProb(data.body.usersWithProbabilities)
      } else {
        console.error('Failed to fetch room details')
        setLoading(false)
      }
    }

    fetchRoomDetails()
  }, [courseId, curatorId])

  useEffect(() => {
    const fetchModule = async () => {
      const data = await moduleStats(moduleId)
      if (data) {
        setModule(data.module?.module)
      } else {
        console.error('Error failed to metch modules')
      }
    }
    fetchModule()
  }, [])

  const curators = module?.courses?.filter(c => c.id == courseId)[0]
    .coursesCuratorsMap

  useEffect(() => {
    if (curatorId) {
      const defaultCurator = curators?.find(
        c => c.userId === parseInt(curatorId)
      )
      setSelectedCurator(defaultCurator)
    }
  }, [curatorId, curators])

  useEffect(() => {
    const fetchCourse = async () => {
      const data = await getCourse(courseId)
      if (data) {
        setCourse(data.course)
      } else {
        console.error('Error failed to metch modules')
      }
    }
    fetchCourse()
  }, [])

  const handleRoomSelection = room => {
    setSelectedRoom(room)
    if (Array.isArray(roomsStatistics.roomStatistics.eachRoomStatistics)) {
      const roomStats = roomsStatistics.roomStatistics.eachRoomStatistics.find(
        stat => stat.roomId === room.id
      )
      setRoomStatistics({ roomStatistics: roomStats })
    } else {
      console.error('roomStatistics is not an array')
    }
  }

  const handleClearSelections = () => {
    setSelectedRoom([])
    setStudents([])
  }

  const courseOptions = module.courses || []

  // const fetchRooms = async () => {
  //     const response = await getRooms(courseId);
  //     if (response.success) {
  //         setRooms(response.rooms.sort((a, b) => a.id - b.id));
  //         setStudents(response.users);
  //         return response.rooms;
  //     }
  // };

  const searchByName = async () => { }

  useEffect(() => {
    if (name.length) {
      searchByName(name, selectOption)
    }
  }, [name, selectOption])

  const toggleSortDirection = () => {
    setSortDirection(prevDirection =>
      prevDirection === 'asc' ? 'desc' : 'asc'
    )
  }

  const handleSort = columnName => {
    if (sortColumn === columnName) {
      toggleSortDirection()
    } else {
      setSortColumn(columnName)
      setSortDirection('asc')
    }
  }

  const sortUsers = users => {
    return users.sort((a, b) => {
      switch (sortColumn) {
        case 'firstname':
          return sortDirection === 'asc'
            ? (a.firstname ?? '').localeCompare(b.firstname ?? '', 'kz', {
              sensitivity: 'base'
            })
            : (b.firstname ?? '').localeCompare(a.firstname ?? '', 'kz', {
              sensitivity: 'base'
            })
        case 'lastname':
          return sortDirection === 'asc'
            ? (a.lastname ?? '').localeCompare(b.lastname ?? '', 'kz', {
              sensitivity: 'base'
            })
            : (b.lastname ?? '').localeCompare(a.lastname ?? '', 'kz', {
              sensitivity: 'base'
            })
        case 'id':
          return sortDirection === 'asc' ? a.id - b.id : b.id - a.id
        case 'cheatingProbability':
          return sortDirection === 'asc'
            ? a.cheatingProbability - b.cheatingProbability
            : b.cheatingProbability - a.cheatingProbability
        case 'lecturesCompleted':
          return sortDirection === 'asc'
            ? a.topicFinished - b.topicFinished
            : b.topicFinished - a.topicFinished
        case 'lessonsCompleted':
          return sortDirection === 'asc'
            ? a.lessonsProgressesMap - b.lessonsProgressesMap
            : b.lessonsProgressesMap - a.lessonsProgressesMap
        case 'quizzesPassed':
          return sortDirection === 'asc'
            ? a.quizzesPassedPercentages - b.quizzesPassedPercentages
            : b.quizzesPassedPercentages - a.quizzesPassedPercentages
        case 'email':
          return sortDirection === 'asc'
            ? (a.email ?? '').localeCompare(b.email ?? '', 'en', {
              sensitivity: 'base'
            })
            : (b.email ?? '').localeCompare(a.email ?? '', 'en', {
              sensitivity: 'base'
            })
        default:
          return 0
      }
    })
  }

  const colors = ['bg-[#FFDF6E]', 'bg-[#78E389]', 'bg-[#72ABFF]']

  const handleAllRoomsSearch = event => {
    setSearchTerm(event.target.value.toLowerCase())
  }

  const renderSortedUsers = (maxLessonCount, maxTopicTotalCount) => {
    const sortedUsers = sortUsers(
      selectedRoom?.id ? selectedRoom.users : students
    )

    const filteredUsers = sortedUsers.filter(
      user =>
        user.firstname.toLowerCase().includes(searchTerm) ||
        user.lastname.toLowerCase().includes(searchTerm) ||
        user.externalId.toLowerCase().includes(searchTerm) ||
        user.email.toLowerCase().includes(searchTerm)
    )

    const getCheatingIndicator = probability => {
      if (probability >= 60) {
        return { colorClass: 'text-[#F12D2D]', imgSrc: {} }
      }
    }

    return filteredUsers.map((user, idx) => {
      let percentage =
        parseFloat(user.quizzesPassedPercentages?.toFixed(2)) || 0.0
      percentage = percentage.toFixed(2)

      const matchingUser = result.find(u => u.userId === user.id)

      let questionsNum = 0
      let resultRes = 0

      matchingUser.quizzesProgress.forEach(m => {
        questionsNum += m.questionsCount
        resultRes += m.progress.result
      })

      let quizPercentage = 0

      if (questionsNum && resultRes) {
        quizPercentage = (resultRes / questionsNum) * 100
      } else {
        quizPercentage = 0
      }

      quizPercentage = quizPercentage.toFixed(1)

      let [integerPart] = percentage.split('.')

      if (integerPart > 100) {
        integerPart = Math.floor(integerPart / 2)
      }

      let probabilityIntegerPart,
        probabilityFractionalPart,
        probabilityEmpty = true

      if (usersProb?.find(uc => uc.userId === user.id)?.probabilityOfCheating) {
        probabilityEmpty = false
        const probability = parseFloat(
          usersProb?.find(uc => uc.userId === user.id).probabilityOfCheating
        ).toFixed(2)
        const [intPart, fractPart] = probability.split('.')
        probabilityIntegerPart = intPart
        probabilityFractionalPart = fractPart
      }

      return (
        <tr className={'py-2'} key={idx}>
          <td className="text-[#5F6B88]">{idx + 1}</td>
          <td>
            <div className="flex flex-row cursor-pointer items-center">
              <Link
                className="flex flex-row cursor-pointer items-center"
                to={{
                  pathname: `/admin/courses/${courseId}/users/${user.id}`,
                  search: `?moduleId=${moduleId}&curator=${curatorId}`
                }}
              >
                <div
                  className={`${colors[idx % colors.length]
                    } rounded-full flex flex-col justify-center items-center w-[38px]  min-w-[38px] h-[38px]`}
                >
                  <div>
                    {user.firstname && user.firstname[0]}
                    {user.lastname && user.lastname[0]}
                  </div>
                </div>
                <div>
                  <div className="ml-2.5">
                    <span className={'text-[16px] text-[black] opacity-90'}>
                      {user.firstname} {user.lastname}
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          </td>
          <td>
            <span className="text-[#5F6B88] text-[16px]">
              {user.externalId}
            </span>
          </td>
          <td className="flex justify-center relative top-[10px] text-[15px] text-black">
            <span
              className={
                usersProb?.find(uc => uc.userId === user.id)
                  ? getCheatingIndicator(
                    usersProb?.find(uc => uc.userId === user.id)
                      .probabilityOfCheating
                  )
                  : ''
              }
            >
              {probabilityEmpty ? (
                <div>
                  <span className={'text-unix-gray text-xl leading-6'}>0</span>
                  <span
                    className={'text-unix-gray text-[15px] leading-[19.5px]'}
                  >
                    .00
                  </span>
                  <span className="text-[#AFB7CA] text-[15px] leading-[19.5px]">
                    %
                  </span>
                </div>
              ) : (
                <>
                  <span className="text-unix-gray text-xl leading-6">
                    {probabilityIntegerPart}.
                  </span>
                  <span className="text-unix-gray text-[15px] leading-[19.5px]">
                    {probabilityFractionalPart}
                  </span>
                  <span className="text-[#AFB7CA] text-[15px] leading-[19.5px]">
                    %
                  </span>
                </>
              )}
            </span>
          </td>
          <td className="">
            <div className="flex justify-center">
              <p className="flex items-end">
                <span
                  style={{
                    color:
                      user.topicFinished == maxLessonCount
                        ? '#3BBC30'
                        : '#5F6B88',
                    fontSize:
                      user.topicFinished == maxLessonCount ? '20px' : '20px'
                  }}
                >
                  {user.topicFinished}
                </span>
                <p className="text-[#5F6B88]">
                  <p className="text-[#AFB7CA] ml-[2px] relative bottom-[3px]">
                    <span className="mx-[2px]">/</span>
                    <span className="relative top-[1px]">{maxLessonCount}</span>
                  </p>
                </p>
              </p>
            </div>
          </td>
          <td className="">
            <p className="flex justify-center items-end">
              <span
                style={{
                  color:
                    user.passedLessonCount === user.maxTopicTotalCount
                      ? '#3BBC30'
                      : '#5F6B88',
                  fontSize:
                    user.passedLessonCount === user.maxTopicTotalCount
                      ? '20px'
                      : '20px'
                }}
              >
                {user.passedLessonCount}
              </span>
              <p className="text-[#AFB7CA] ml-[2px] relative bottom-[3px]">
                <span className="mx-[2px]">/</span>
                <span className="relative top-[1px]">{maxTopicTotalCount}</span>
              </p>
            </p>
          </td>
          <td>
            <p className="flex relative top-[1px]">
              <span style={{ fontSize: '20px', color: '#5F6B88' }}>
                {quizPercentage}
              </span>
              <div className="flex relative top-[4px] text-[#5F6B88]">
                {/*<span>{decimalPart}</span>*/}
                <p className="text-[#AFB7CA]">%</p>
              </div>
            </p>
          </td>
          <td className="text-[#5F6B88]">{user.email}</td>
        </tr>
      )
    })
  }

  const renderSortedStudents = () => {
    const sortedUsers = sortUsers(users)

    const filteredUsers = sortedUsers.filter(user =>
      `${user.firstname} ${user.lastname}`.toLowerCase().includes(searchTerm)
    )

    return filteredUsers.map((user, idx) => {
      let percentage =
        parseFloat(user.quizzesPassedPercentages?.toFixed(2)) || 0.0
      percentage = percentage.toFixed(2)

      const matchingUser = result.find(u => u.userId === user.id)

      let questionsNum = 0
      let resultRes = 0

      matchingUser.quizzesProgress.forEach(m => {
        questionsNum += m.questionsCount
        resultRes += m.progress.result
      })

      let quizPercentage = 0

      if (questionsNum && resultRes) {
        quizPercentage = (resultRes / questionsNum) * 100
      } else {
        quizPercentage = 0
      }

      quizPercentage = quizPercentage.toFixed(1)

      let [integerPart] = percentage.split('.')

      if (integerPart > 100) {
        integerPart = Math.floor(integerPart / 2)
      }

      const getCheatingIndicator = probability => {
        if (probability >= 60) {
          return { colorClass: 'text-[#F12D2D]', imgSrc: {} }
        }
      }

      let probabilityIntegerPart,
        probabilityFractionalPart,
        probabilityEmpty = true

      if (usersProb?.find(uc => uc.userId === user.id)?.probabilityOfCheating) {
        probabilityEmpty = false
        const probability = parseFloat(
          usersProb?.find(uc => uc.userId === user.id).probabilityOfCheating
        ).toFixed(2)
        const [intPart, fractPart] = probability.split('.')
        probabilityIntegerPart = intPart
        probabilityFractionalPart = fractPart
      }

      return (
        <tr className={'py-2'} key={idx}>
          <td className="text-[#5F6B88]">{idx + 1}</td>
          <td>
            <div className="flex flex-row cursor-pointer items-center">
              <Link
                className="flex flex-row cursor-pointer items-center"
                to={{
                  pathname: `/admin/courses/${courseId}/users/${user.id}`,
                  search: `?moduleId=${moduleId}&curator=${curatorId}`
                }}
              >
                <div
                  className={`${colors[idx % colors.length]
                    } rounded-full flex flex-col justify-center items-center w-[38px]  min-w-[38px] h-[38px]`}
                >
                  <div>
                    {user.firstname && user.firstname[0]}
                    {user.lastname && user.lastname[0]}
                  </div>
                </div>
                <div>
                  <div className="ml-2.5">
                    <span className={'text-[16px] text-black opacity-90'}>
                      {user.firstname} {user.lastname}
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          </td>
          <td>
            <span className="text-[#5F6B88]">{user.externalId}</span>
          </td>
          <td className="flex justify-center relative top-[10px] text-[15px] text-black">
            <span
              className={
                usersProb?.find(uc => uc.userId === user.id)
                  ? getCheatingIndicator(
                    usersProb?.find(uc => uc.userId === user.id)
                      .probabilityOfCheating
                  )
                  : ''
              }
            >
              {probabilityEmpty ? (
                <div>
                  <span className={'text-unix-gray text-xl leading-6'}>0</span>
                  <span
                    className={'text-unix-gray text-[15px] leading-[19.5px]'}
                  >
                    .00
                  </span>
                  <span className="text-[#AFB7CA] text-[15px] leading-[19.5px]">
                    %
                  </span>
                </div>
              ) : (
                <>
                  <span className="text-unix-gray text-xl leading-6">
                    {probabilityIntegerPart}.
                  </span>
                  <span className="text-unix-gray text-[15px] leading-[19.5px]">
                    {probabilityFractionalPart}
                  </span>
                  <span className="text-[#AFB7CA] text-[15px] leading-[19.5px]">
                    %
                  </span>
                </>
              )}
            </span>
          </td>
          <td className="">
            <div className="flex justify-center">
              <p className="flex items-end">
                <span
                  style={{
                    color:
                      user.topicFinished == maxTopicTotalCount
                        ? '#3BBC30'
                        : '#5F6B88',
                    fontSize:
                      user.topicFinished == maxTopicTotalCount ? '20px' : '20px'
                  }}
                >
                  {user.topicFinished}
                </span>
                <p className="text-[#AFB7CA] ml-[2px] relative bottom-[3px]">
                  <span className="mx-[2px]">/</span>
                  <span className="relative top-[1px]">
                    {maxTopicTotalCount}
                  </span>
                </p>
              </p>
            </div>
          </td>
          <td className="">
            {'passedLessonCount' in user ? (
              <p className="flex justify-center items-end">
                <span
                  style={{
                    color:
                      user.passedLessonCount === user.maxLessonCount
                        ? '#3BBC30'
                        : '#5F6B88',
                    fontSize:
                      user.passedLessonCount === user.maxLessonCount
                        ? '20px'
                        : '20px'
                  }}
                >
                  {user.passedLessonCount}
                </span>
                <p className="text-[#AFB7CA] ml-[2px] relative bottom-[3px]">
                  <span className="mx-[2px]">/</span>
                  <span className="relative top-[1px]">{maxLessonCount}</span>
                </p>
              </p>
            ) : null}
          </td>
          <td>
            <p className="flex relative top-[1px]">
              <span style={{ fontSize: '20px', color: '#5F6B88' }}>
                {quizPercentage}
              </span>
              <div className="flex relative top-[4px] text-[#5F6B88]">
                {/*<span>{decimalPart}</span>*/}
                <p className="text-[#AFB7CA]">%</p>
              </div>
            </p>
          </td>
          {console.log(user.email, ' ', quizPercentage)}
          <td className="text-[#5F6B88]">{user.email}</td>
        </tr>
      )
    })
  }

  function formatTime(seconds) {
    const days = Math.floor(seconds / 86400)
    const hours = Math.floor((seconds % 86400) / 3600)
    const minutes = ((seconds % 3600) / 60).toFixed(1)

    if (days > 0) {
      return (
        <span>
          <span>
            {days}{' '}
            <span className="text-2xl relative left-[-5px] text-[#AFB7CA] font-light">
              дней
            </span>
          </span>
          {hours > 0 && (
            <span>
              {hours}{' '}
              <span className="text-2xl relative left-[-5px] text-[#AFB7CA] font-light">
                ч
              </span>
            </span>
          )}
        </span>
      )
    } else if (hours > 0) {
      return (
        <span>
          <span>
            {hours}{' '}
            <span className="text-2xl relative left-[-5px] text-[#AFB7CA] font-light">
              ч
            </span>
          </span>
          <span>
            {minutes}{' '}
            <span className="text-2xl relative left-[-5px] text-[#AFB7CA] font-light">
              мин
            </span>
          </span>
        </span>
      )
    } else {
      return (
        <span>
          {minutes}{' '}
          <span className="text-2xl relative left-[-5px] text-[#AFB7CA] font-light">
            мин
          </span>
        </span>
      )
    }
  }

  const sortedUsers = sortUsers(users)

  const filteredUsers = sortedUsers.filter(
    user =>
      user.firstname.toLowerCase().includes(searchTerm) ||
      user.lastname.toLowerCase().includes(searchTerm) ||
      user.externalId.toLowerCase().includes(searchTerm) ||
      user.email.toLowerCase().includes(searchTerm)
  )

  let maxTopicTotalCount = -Infinity
  let maxLessonCount = -Infinity

  filteredUsers.map(user => {
    let percentage =
      parseFloat(user.quizzesPassedPercentages?.toFixed(2)) || 0.0
    percentage = percentage.toFixed(2)

    let [integerPart] = percentage.split('.')

    if (integerPart > 100) {
      integerPart = Math.floor(integerPart / 2)
    }

    filteredUsers.forEach(user => {
      if (
        user?.totalLessonsCount !== undefined &&
        user.totalLessonsCount > maxLessonCount
      ) {
        maxLessonCount = user.totalLessonsCount
      }
      if (
        user?.topicTotalCount !== undefined &&
        user.topicTotalCount > maxTopicTotalCount
      ) {
        maxTopicTotalCount = user.topicTotalCount
      }
    })
  })

  return (
    <>
      {loading ? (
        <div className="h-screen">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="grid grid-cols-1 grid-rows-10 w-[full] h-full pt-3 pb-10">
          {rooms.length > 0 ? (
            <>
              <div className="row-span-1 grid grid-cols-12">
                <div className="col-span-12 p-5">
                  <div className="col-span-12 mb-5 flex flex-row">
                    <Link
                      className="flex flex-row cursor-pointer items-center"
                      to={{
                        pathname: `/admin/modules/statistics/${moduleId}`,
                        search: `?courseId=${courseId}`
                      }}
                    >
                      {' '}
                      <img src={backWhite} />
                    </Link>
                    <UnixSelect
                      selectedValue={module}
                      options={moduleOptions}
                      disabled
                      optionLabelKey={'title'}
                      optionValueKey={'id'}
                      selectedCondition={(o, s) => o.id === s?.id}
                      additionalClassName="pt-[22px] pb-[9px] w-full"
                      wrapperClassName="ml-4 min-w-48 mr-[6px]"
                      label="Модуль"
                      setValue={setModule}
                    />
                    {module && (
                      <div className="flex flex-row">
                        <img src={arrowRightGray} />
                        <UnixSelect
                          disabled
                          selectedValue={course}
                          options={courseOptions}
                          optionLabelKey={'title'}
                          optionValueKey={'id'}
                          selectedCondition={(o, s) => o.id === s?.id}
                          additionalClassName="pt-[22px] pb-[9px] w-full"
                          wrapperClassName="mx-[6px] min-w-48"
                          label="Курс"
                          setValue={setCourse}
                        />
                      </div>
                    )}
                    {!!course && (
                      <div className="flex flex-row">
                        <img src={arrowRightGray} />
                        <UnixSelect
                          selectedValue={selectedCurator}
                          options={curators}
                          customOptionLabelGet={u =>
                            `${u.user.lastname} ${u.user.firstname}`
                          }
                          optionValueKey={'userId'}
                          selectedCondition={(o, s) => o.userId === s?.userId}
                          additionalClassName="pt-[22px] pb-[9px] w-full"
                          wrapperClassName="mx-[6px] min-w-48"
                          label="Преподаватель"
                          setValue={handleCuratorChange}
                          emptyOption={false}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row-span-8 flex">
                <div className="m-5 flex flex-col h-full overflow-y-scroll bg-white p-[5px] rounded-[12px] min-w-[150px] w-[200px]">
                  <div
                    className={`font-sf-pro-display shrink-0 h-10 flex pl-3 items-center rounded-[5px] max-xl:text-[14px] text-[#000] mb-3 ${selectedRoom.id == null ? 'bg-[#007AFF] text-[#fff]' : ''
                      }`}
                    onClick={() => handleClearSelections()}
                  >
                    Все классы
                  </div>
                  {rooms?.map(room => (
                    <div
                      className={`shrink-0 cursor-pointer h-[51px] flex py-2 pl-3 items-center rounded-[5px] mb-3 ${selectedRoom.id === room.id ? 'bg-[#007AFF]' : ' '
                        } `}
                      key={room.id}
                      onClick={() => handleRoomSelection(room)}
                    >
                      <p
                        className={`font-normal text-[16px] ${selectedRoom.id === room.id
                          ? 'text-white'
                          : 'text-black'
                          }`}
                      >
                        {room.title}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="flex-grow">
                  {rooms.length > 0 && selectedRoom.id ? (
                    <div className="flex text-[14px] justify-between w-[85%] stat-adaptive ml-1">
                      <div className="bg-white rounded-[20px] w-[30%] mt-4 p-4 flex flex-col justify-between h-[150px]">
                        <div className="flex justify-between">
                          <h2 className="text-black">
                            Среднее время просмотра видео/урока
                          </h2>
                          <img src={avgLessonIcon} />
                        </div>
                        <div className="text-[24px] font-extralight items-center flex gap-0">
                          <span className="text-[#0068FF] text-[32px] font-medium">
                            {selectedRoom.id
                              ? formatTime(
                                roomStatistics.roomStatistics
                                  .averageTimeLesson
                              )
                              : formatTime(
                                roomsStatistics.roomStatistics
                                  .averageTimeLesson
                              )}
                          </span>
                        </div>
                      </div>
                      <div className="bg-white rounded-[20px] w-[30%] mt-4 p-4 flex flex-col justify-between h-[150px]">
                        <div className="flex justify-between">
                          <h2 className="text-black">
                            Среднее время прохождения лекции
                          </h2>
                          <img src={avgLectureIcon} />
                        </div>
                        <div className="text-[24px] font-extralight items-center flex gap-0">
                          <span className="text-[#0068FF] text-[32px] font-medium">
                            {selectedRoom.id
                              ? formatTime(
                                roomStatistics.roomStatistics.averageTimeTopic
                              )
                              : formatTime(
                                roomsStatistics.roomStatistics
                                  .averageTimeTopic
                              )}
                          </span>
                        </div>
                      </div>
                      <div className="bg-white rounded-[20px] w-[30%] mt-4 p-4 flex flex-col justify-between h-[150px]">
                        <div className="flex justify-between">
                          <h2 className="text-black">
                            Среднее время прохождения курса
                          </h2>
                          <img src={avgCourse} />
                        </div>
                        <div className="text-[24px] font-extralight items-center flex gap-0">
                          <span className="text-[#0068FF] text-[32px] font-medium">
                            {selectedRoom.id
                              ? formatTime(
                                roomStatistics.roomStatistics.totalWatchedTime
                              )
                              : formatTime(
                                roomsStatistics.roomStatistics
                                  .totalWatchedTime
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="ml-1 rounded-[8px] p-5 mt-4 bg-white w-[85%] stat-adaptive">
                    <input
                      type="text"
                      placeholder="Поиск"
                      className="bg-[#F0F3FA] text-black w-[100%] pl-2 h-[43px] stroke-none border-none rounded-[8px]"
                      onChange={handleAllRoomsSearch}
                    />
                  </div>
                  <div
                    className={`unix-card ml-1 mt-5 stat-adaptive w-[85%] stat-adaptive`}
                    style={{ overflowX: 'auto' }}
                  >
                    {!selectedRoom.id && (
                      <table className="orgs-table w-[100%] table-auto">
                        <thead>
                          <tr className="text-left">
                            <th className="text-[#5F6B88]">#</th>
                            <th onClick={() => handleSort('firstname')}>
                              <div className="flex items-center text-[#5F6B88]">
                                Студент
                                {sortColumn === 'firstname' ? (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={
                                        sortDirection === 'asc'
                                          ? desc
                                          : descSelected
                                      }
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? ascSelected
                                          : asc
                                      }
                                      alt="Sort Selected"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={desc}
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={asc}
                                      alt="Sort Selected"
                                    />
                                  </div>
                                )}
                              </div>
                            </th>
                            <th onClick={() => handleSort('id')}>
                              <div className="flex items-center text-[#5F6B88]">
                                ID
                                {sortColumn === 'id' ? (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={
                                        sortDirection === 'asc'
                                          ? desc
                                          : descSelected
                                      }
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? ascSelected
                                          : asc
                                      }
                                      alt="Sort Selected"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={desc}
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={asc}
                                      alt="Sort Selected"
                                    />
                                  </div>
                                )}
                              </div>
                            </th>
                            <th
                              className="max-w-[170px]"
                              onClick={() => handleSort('cheatingProbability')}
                            >
                              <div className="flex justify-start w-[172px]  items-center text-[#5F6B88] relative">
                                <p>
                                  Вероятность
                                  <br /> читинга
                                </p>
                                {sortColumn === 'cheatingProbability' ? (
                                  <div className="flex flex-col ml-[2px]">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className="min-w-[12px] min-h-[7px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? desc
                                          : descSelected
                                      }
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px] min-w-[12px] min-h-[7px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? ascSelected
                                          : asc
                                      }
                                      alt="Sort Selected"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={desc}
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={asc}
                                      alt="Sort Selected"
                                    />
                                  </div>
                                )}
                              </div>
                            </th>
                            <th
                              className="min-w-[140px]"
                              onClick={() => handleSort('lecturesCompleted')}
                            >
                              <div className="flex items-center text-[#5F6B88]">
                                <p>
                                  Пройдено <br /> лекций
                                </p>
                                {sortColumn === 'lecturesCompleted' ? (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={
                                        sortDirection === 'asc'
                                          ? desc
                                          : descSelected
                                      }
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? ascSelected
                                          : asc
                                      }
                                      alt="Sort Selected"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={desc}
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={asc}
                                      alt="Sort Selected"
                                    />
                                  </div>
                                )}
                              </div>
                            </th>
                            <th
                              className="min-w-[140px]"
                              onClick={() => handleSort('lessonsCompleted')}
                            >
                              <div className="flex items-center text-[#5F6B88]">
                                <p>
                                  Пройдено <br /> уроков
                                </p>
                                {sortColumn === 'lessonsCompleted' ? (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className="min-w-[12px] min-h-[7px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? desc
                                          : descSelected
                                      }
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? ascSelected
                                          : asc
                                      }
                                      alt="Sort Selected"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={desc}
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={asc}
                                      alt="Sort Selected"
                                    />
                                  </div>
                                )}
                              </div>
                            </th>
                            <th onClick={() => handleSort('quizzesPassed')}>
                              <div className="flex w-[100px] items-center text-[#5F6B88]">
                                Тесты
                                {sortColumn === 'quizzesPassed' ? (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={
                                        sortDirection === 'asc'
                                          ? desc
                                          : descSelected
                                      }
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? ascSelected
                                          : asc
                                      }
                                      alt="Sort Selected"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={desc}
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={asc}
                                      alt="Sort Selected"
                                    />
                                  </div>
                                )}
                              </div>
                            </th>
                            <th onClick={() => handleSort('email')}>
                              <div className="flex items-center text-[#5F6B88]">
                                Почта
                                {sortColumn === 'email' ? (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={
                                        sortDirection === 'asc'
                                          ? desc
                                          : descSelected
                                      }
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? ascSelected
                                          : asc
                                      }
                                      alt="Sort Selected"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={desc}
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={asc}
                                      alt="Sort Selected"
                                    />
                                  </div>
                                )}
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {' '}
                          {renderSortedStudents(
                            maxTopicTotalCount,
                            maxLessonCount
                          )}
                        </tbody>
                      </table>
                    )}
                    {selectedRoom.id && (
                      <table className="orgs-table w-full table-auto">
                        <thead>
                          <tr className="text-left">
                            <th>#</th>
                            <th onClick={() => handleSort('firstname')}>
                              <div className="flex text-[#5F6B88]">
                                Студент {console.log('selected ', sortColumn)}
                                {sortColumn === 'firstname' ? (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={
                                        sortDirection === 'asc'
                                          ? desc
                                          : descSelected
                                      }
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? ascSelected
                                          : asc
                                      }
                                      alt="Sort Selected"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={desc}
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={asc}
                                      alt="Sort Selected"
                                    />
                                  </div>
                                )}
                              </div>
                            </th>
                            <th onClick={() => handleSort('id')}>
                              <div className="flex text-[#5F6B88]">
                                ID{' '}
                                {sortColumn === 'id' ? (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={
                                        sortDirection === 'asc'
                                          ? desc
                                          : descSelected
                                      }
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? ascSelected
                                          : asc
                                      }
                                      alt="Sort Selected"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={desc}
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={asc}
                                      alt="Sort Selected"
                                    />
                                  </div>
                                )}
                              </div>
                            </th>
                            <th
                              className="max-w-[170px]"
                              onClick={() => handleSort('cheatingProbability')}
                            >
                              <div className="flex justify-start w-[172px]  items-center text-[#5F6B88] relative">
                                Вероятность <br />
                                читинга
                                {sortColumn === 'cheatingProbability' ? (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={
                                        sortDirection === 'asc'
                                          ? desc
                                          : descSelected
                                      }
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? ascSelected
                                          : asc
                                      }
                                      alt="Sort Selected"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={desc}
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={asc}
                                      alt="Sort Selected"
                                    />
                                  </div>
                                )}
                              </div>
                            </th>
                            <th
                              className={'min-w-[140px]'}
                              onClick={() => handleSort('lecturesCompleted')}
                            >
                              <div className="flex items-center text-[#5F6B88]">
                                Пройдено <br />
                                лекций
                                {sortColumn === 'lecturesCompleted' ? (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={
                                        sortDirection === 'asc'
                                          ? desc
                                          : descSelected
                                      }
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? ascSelected
                                          : asc
                                      }
                                      alt="Sort Selected"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={desc}
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={asc}
                                      alt="Sort Selected"
                                    />
                                  </div>
                                )}
                              </div>
                            </th>

                            <th
                              className={'min-w-[140px]'}
                              onClick={() => handleSort('lessonsCompleted')}
                            >
                              <div className="flex items-center text-[#5F6B88]">
                                Пройдено <br />
                                уроков
                                {sortColumn === 'lessonsCompleted' ? (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={
                                        sortDirection === 'asc'
                                          ? desc
                                          : descSelected
                                      }
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? ascSelected
                                          : asc
                                      }
                                      alt="Sort Selected"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={desc}
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={asc}
                                      alt="Sort Selected"
                                    />
                                  </div>
                                )}
                              </div>
                            </th>
                            <th onClick={() => handleSort('quizzesPassed')}>
                              <div className="flex w-[100px] text-[#5F6B88]">
                                Тесты
                                {sortColumn === 'quizzesPassed' ? (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={
                                        sortDirection === 'asc'
                                          ? desc
                                          : descSelected
                                      }
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? ascSelected
                                          : asc
                                      }
                                      alt="Sort Selected"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={desc}
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={asc}
                                      alt="Sort Selected"
                                    />
                                  </div>
                                )}
                              </div>
                            </th>
                            <th onClick={() => handleSort('email')}>
                              <div className="flex text-[#5F6B88]">
                                Почта
                                {sortColumn === 'email' ? (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={
                                        sortDirection === 'asc'
                                          ? desc
                                          : descSelected
                                      }
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={
                                        sortDirection === 'asc'
                                          ? ascSelected
                                          : asc
                                      }
                                      alt="Sort Selected"
                                    />
                                  </div>
                                ) : (
                                  <div className="flex flex-col ml-2">
                                    <img
                                      width="12px"
                                      height="7px"
                                      className=""
                                      src={desc}
                                      alt="Sort"
                                    />
                                    <img
                                      onClick={toggleSortDirection}
                                      width="12px"
                                      height="7px"
                                      className="mt-[4px]"
                                      src={asc}
                                      alt="Sort Selected"
                                    />
                                  </div>
                                )}
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderSortedUsers(
                            maxTopicTotalCount,
                            maxLessonCount
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center justify-center col-start-1 row-start-1 row-end-6">
              <p className="text-[42px] text-center text-black">
                На данный момент у этого учителя нет класса
              </p>
              <div className="">
                <button
                  onClick={() =>
                    navigate(`/admin/modules/statistics/${moduleId}`)
                  }
                >
                  <p className="text-black py-4 px-8 mt-8 rounded-[16px] bg-white ">
                    Назад
                  </p>
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

const LoadingSpinner = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <CircularProgress />
    </div>
  )
}

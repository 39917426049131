import * as React from "react";
import { useState } from "react";

function ContactInfo({ label, value }) {
  return (
    <p>
      <span className="text-2xl text-white">{label}:</span>
      <br />
      <span className="text-white">{value}</span>
    </p>
  );
}

const FormDesk = () => {
  const countries = [
    { code: "KZ", name: "Казахстан", dialCode: "+7" },
    { code: "RU", name: "Россия", dialCode: "+7" },
    { code: "US", name: "США", dialCode: "+1" },
    { code: "GB", name: "Великобритания", dialCode: "+44" },
  ];

  const [phoneCode, setPhoneCode] = useState(countries[0].dialCode);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneCode: countries[0].dialCode,
    phoneNumber: "",
  });

  const handleCountryChange = (event) => {
    const selectedCountry = countries.find(
      (country) => country.dialCode === event.target.value
    );
    setPhoneCode(selectedCountry.dialCode);
    setFormData({ ...formData, phoneCode: selectedCountry.dialCode });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneNumberChange = (event) => {
    let value = event.target.value.replace(/\D/g, "");
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    const formattedNumber = value.replace(
      /(\d{3})(\d{3})(\d{2})(\d{2})/,
      "$1 $2 $3 $4"
    );
    setFormData({ ...formData, phoneNumber: formattedNumber });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzxHEd_Kus3qXUsAyPkwFuO4kmzeNY8Byogk7JhtHGKsBi-2mhQCRfOF_U67cy4voao/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(formData).toString(),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      if (result.result === "success") {
        console.log("Form submitted successfully");
      } else {
        console.error("Error submitting form:", result.error);
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };

  return (
    <section className="max-w-[1435px] mx-auto mt-10 relative">
      <div>
        <div className="flex justify-center max-md:flex-col max-md:gap-0">
          <div className="flex flex-col">
            <div className="flex flex-col w-[100%] max-md:w-full bg-[#292D32] rounded-br-[0px] pb-[30px] pl-[30px] pt-[30px] pr-[50px] rounded-[24px]">
              <header className="flex flex-col px-5 text-2xl font-medium text-white leading-[75px] max-md:mt-10">
                <h1 className="lg:text-4xl xl:text-6xl capitalize max-md:text-4xl max-md:leading-[61px]">
                  Свяжитесь <br />
                  с нами: <br />
                </h1>
                <h2 className="mt-10 lg:text-[24px] xl:text-[32px] leading-[74.88px] max-md:mt-4">
                  Контактная информация:
                </h2>
                <div className="mt-4 max-md:mt-10">
                  <ContactInfo label="Адрес" value="[адрес]" />
                  <ContactInfo label="Телефон" value="[номер телефона]" />
                  <ContactInfo
                    label="Электронная почта"
                    value="[электронная почта]"
                  />
                </div>
              </header>
            </div>
            <footer className="mt-10 ml-10 text-blue-600 capitalize text-[22px] leading-[341%] max-md:mt-10">
              All right reserved by Uni-x.kz
            </footer>
          </div>
          <section className="flex flex-col w-[55%] pr-[50px] max-md:w-full relative pt-10 top-16 left-0 bg-[#292D32] lg:h-[660px] xl:h-[700px] rounded-r-[24px]">
            <form
              onSubmit={handleSubmit}
              className="flex flex-col grow pl-20 w-full rounded-3xl pb-20 bg-[#FBFBFB] pr-10 max-md:px-5 pt-10 max-md:max-w-full"
            >
              <label
                htmlFor="fullName"
                className="text-3xl font-light leading-10 text-black max-md:max-w-full"
              >
                Полное имя:
              </label>
              <input
                value={formData.fullName}
                onChange={handleChange}
                required
                id="fullName"
                name="fullName"
                type="text"
                className="mt-12 bg-zinc-50 text-base font-light leading-10 text-neutral-500 max-md:mt-10 max-md:max-w-full"
                placeholder="Ваша Фамилия Имя Отчество"
                aria-label="Full Name"
              />
              <label
                htmlFor="email"
                className="mt-12 text-3xl font-light leading-10 text-black max-md:mt-10 max-md:max-w-full"
              >
                Email:
              </label>
              <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="mt-12 bg-zinc-50 text-base font-medium leading-10 text-neutral-500 max-md:mt-10 max-md:max-w-full"
                placeholder="Ваш email"
                aria-label="Email"
              />
              <div className="flex gap-5 mt-12 ml-3.5 max-w-full text-3xl font-light leading-10 text-black whitespace-nowrap w-[393px] max-md:mt-10 max-md:ml-2.5">
                <label htmlFor="country" className="flex-auto font-light">
                  Страна:
                </label>
                <label htmlFor="phone" className="flex-auto font-light">
                  Телефон:
                </label>
              </div>

              <div className="flex gap-5 items-center mt-10 text-2xl font-medium leading-10 text-zinc-800 max-md:flex-wrap max-md:max-w-full">
                <select
                  onChange={handleCountryChange}
                  value={phoneCode}
                  id="country"
                  className="flex-auto bg-zinc-50 p-2 text-base font-medium leading-10 text-neutral-500 max-md:mt-2 max-md:max-w-full"
                >
                  {countries.map((country) => (
                    <option key={country.code} value={country.dialCode}>
                      {country.name}
                    </option>
                  ))}
                </select>
                <input
                  id="phoneCode"
                  name="phoneCode"
                  type="text"
                  value={phoneCode}
                  readOnly
                  className="w-20 p-2 text-base font-medium leading-10 bg-zinc-50 text-neutral-500 max-md:mt-2 max-md:max-w-full"
                  placeholder="+7"
                  aria-label="Phone Code"
                />
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  value={formData.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  required
                  className="flex-auto p-2 text-base bg-zinc-50 font-medium leading-10 text-neutral-500 max-md:mt-2 max-md:max-w-full"
                  placeholder="--- --- -- --"
                  aria-label="Phone Number"
                />
              </div>
              <button
                type="submit"
                className="flex ml-30 gap-1 self-center px-3.5 py-2.5 mt-16 text-lg font-medium text-center text-white border-2 border-blue-600 border-solid bg-zinc-300 bg-opacity-10 leading-[75.06px] rounded-[44px] max-md:mt-10"
              >
                <span className="justify-center px-10 bg-blue-600 rounded-[44px] max-md:px-5">
                  Отправить заявку
                </span>
              </button>
            </form>
          </section>
        </div>
      </div>
    </section>
  );
};

export default FormDesk;

import { organizationsApi } from "../api"
import { showSnackbar } from "./snackbar"

const getOrganizations = async () => {
    const data = {}
    try {
        const response = await organizationsApi.all()
        if (response.data.organizations?.length) {
            data.success = true
            data.list = response.data.organizations
        }
    } catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getOrganization = async (orgId) => {
    const data = {}
    try {
        const response = await organizationsApi.getOrganization(orgId)
        if (response.data.organization) {
            data.success = true
            data.organization = response.data.organization
            data.admins = response.data.admins
        }
    } catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const addModulesToOrganization = async (orgId, body) => {
    const data = {}
    try {
        const response = await organizationsApi.addModules(orgId, body)
        if (response.data?.length) {
            showSnackbar()
            data.success = true
        }
    } catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const createOrganization = async (body) => {
    const data = {}
    try {
        const response = await organizationsApi.create(body)
        if (response.data.id) {
            showSnackbar()
            data.success = true
            data.id = response.data.id
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const updateOrganization = async (orgId, body) => {
    const data = {}
    try {
        const response = await organizationsApi.update(orgId, body)
        if (response.data.id) {
            showSnackbar()
            data.success = true
            data.id = response.data.id
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getOrgStats = async (orgId) => {
    const data = {}
    try {
        const response = await organizationsApi.getOrgStats(orgId)
        if (response.data) {
            data.success = true
            data.organization = response.data.organization
            data.statistics = response.data.statistics
            data.user = response.data.user
        }
    } catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

export {
    getOrganizations,
    getOrgStats,
    getOrganization,
    createOrganization,
    updateOrganization,
    addModulesToOrganization,
}

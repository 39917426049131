import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FormControl, MenuItem, Modal, Select, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { simpleGetter } from '../../../actions/common'
import {
  createOnlineLesson,
  getRoomLesson,
  updateRoomLesson
} from '../../../actions/course'
import {
  addUser,
  createRoom,
  createRoomFile,
  deleteRoom,
  downloadRooms,
  getRooms,
  updateRoom
} from '../../../actions/room'
import { showSnackbar } from '../../../actions/snackbar'
import {
  deleteStudent,
  roomSearch,
  userTeacherSearch
} from '../../../actions/user'
import { userApi } from '../../../api'
import { useTeacherCourses } from '../../../hooks/courses'
import { inputClass } from '../../../use-cases/constants'
import add from './add.svg'
import addIcon from './addIcon.svg'
import addWhite from './addWhite.png'
import arrowDown from './arrowDownIcon.svg'
import arrowUp from './arrowUpIcon.svg'
import bucket from './bucket.svg'
import closeIcon from './closeIcon.svg'
import deleteIcon from './deleteIcon.svg'
import editIcon from './editIcon.svg'
import exportIcon from './export.svg'
import clip from './insert.svg'
import leftIcon from './leftArrowIcon.svg'
import plus from './plus.svg'
import plusIcon from './plusIcon.svg'
import plusWhite from './plusWhite.svg'
import search from './search.svg'
import searchBlue from './searchBlue.svg'
import studentNum from './studentNum.svg'
import studentNumWhite from './studentNumWhite.svg'
import tick from './tick.svg'
import warningIcon from './warningIcon.svg'

export const TeacherCourse = () => {
  const { courseId } = useParams()
  const navigate = useNavigate()
  const [rooms, setRooms] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [roomName, setRoomName] = useState('')
  const [students, setStudents] = useState([])
  const [name, setName] = useState('')
  const [results, setResults] = useState([])
  const [resultsSearch, setResultsSearch] = useState([])
  const [selectedUser, setSelectedUser] = useState(undefined)
  const [selectedRoom, setSelectedRoom] = useState({})
  const [selectOption] = useState('firstname')
  const [sortDirection, setSortDirection] = useState('asc')
  const [sortColumn, setSortColumn] = useState(null)
  const [moding, setModing] = useState('')
  const modules = useTeacherCourses()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [roomIdToDelete, setRoomIdToDelete] = useState(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [roomLesson, setRoomLesson] = useState({})
  const [editLessonModalOpen, setEditLessonModalOpen] = useState(false)
  const [mode, setMode] = useState('search')
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false)
  const [studentToDelete, setStudentToDelete] = useState(null)
  const [userCheat, setUserCheat] = useState([])

  const fetchRoomLesson = async id => {
    const response = await getRoomLesson(id)
    if (response.success) {
      setRoomLesson(response.roomLesson)
    } else {
      setRoomLesson({})
    }
  }

  const openDeleteConfirmationModal = student => {
    setStudentToDelete(student)
    setIsDeleteConfirmationOpen(true)
  }

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationOpen(false)
    setStudentToDelete(null)
  }

  const confirmDeleteStudent = async () => {
    await deleteStudentHandler(courseId, studentToDelete.id)

    const updatedStudents = selectedRoom.users.filter(
      user => user.id !== studentToDelete.id
    )
    setSelectedRoom(prevRoom => ({
      ...prevRoom,
      users: updatedStudents
    }))

    closeDeleteConfirmationModal()
  }

  useEffect(() => {
    fetchRoomLesson(selectedRoom.id)
  }, [selectedRoom.id])

  useEffect(() => {
    fetchRoomLesson(selectedRoom.id)
  }, [selectedRoom.id])

  const handleLessonUpdate = updatedLesson => {
    setRoomLesson(updatedLesson)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const openDeleteModal = roomId => {
    setRoomIdToDelete(roomId)
    setIsDeleteModalOpen(true)
  }

  const confirmDeleteRoom = async () => {
    await deleteRoom(roomIdToDelete)
    fetchRooms()
    setIsDeleteModalOpen(false)
    setRoomIdToDelete(null)
    setSelectedRoom({})
  }

  const fetchRooms = async () => {
    const response = await getRooms(courseId)
    if (response.success) {
      setRooms(response.rooms.sort((a, b) => a.id - b.id))
      setStudents(response.users)
      setUserCheat(response.usersWithProbabilities)
      return response.rooms
    }
  }

  const searchByName = async query => {
    const response = await userTeacherSearch(courseId, query, selectOption)
    if (response.success) {
      const filteredResults = response.users.filter(
        user =>
          user?.firstname?.toLowerCase().includes(query.toLowerCase()) ||
          user?.lastname?.toLowerCase().includes(query.toLowerCase()) ||
          user?.email?.toLowerCase().includes(query.toLowerCase())
      )
      setResults(filteredResults)
    }
  }

  const roomSearchHandler = async query => {
    const response = await roomSearch(selectedRoom.id, query)
    if (response.success) {
      setResultsSearch(response.users.users)
    }
  }

  const handleInputChange = event => {
    const inputValue = event.target.value
    setName(inputValue)
    if (inputValue.length >= 3) {
      searchByName(inputValue)
    } else {
      setResults([])
    }
  }

  const handleAllRoomsSearch = async event => {
    if (event.target.value?.length) {
      const response = await simpleGetter(userApi.allRoomSearch, {
        courseId,
        query: event.target.value
      })
      setResultsSearch(response)
      setModing('searching')
    } else {
      setResultsSearch([])
      setModing('')
    }
  }

  const handleInputChange2 = event => {
    const inputValue = event.target.value
    setName(inputValue)
    if (inputValue.length) {
      roomSearchHandler(inputValue)
      setModing('searching')
    } else {
      setResults([])
      setModing('')
    }
  }

  const studClassAdd = user => {
    setSelectedUser(user)
    setShowModal(true)
  }

  const renderSearchResults = () => {
    return results.map((user, idx) => (
      <tr className={'py-2 items-center'} key={idx}>
        <td>{idx + 1}</td>
        <td>
          <div className="flex flex-row cursor-pointer items-center">
            <div
              className={`${colors[idx % colors.length]
                } rounded-full flex flex-col justify-center items-center w-[38px]  min-w-[38px] h-[38px]`}
            >
              <div>
                {user.firstname && user.firstname[0]}
                {user.lastname && user.lastname[0]}
              </div>
            </div>
            <div>
              <div className="ml-2.5">
                <span className={'text-[16px] text-black opacity-90'}>
                  {user.firstname} {user.lastname}
                </span>
              </div>
            </div>
          </div>
        </td>
        <td>
          <span>{user.externalId}</span>
        </td>
        <td>{user.email}</td>
        <td>
          <div className="flex min-w-[171px] relative max-[1310px]:left-[-15px] max-xl:w-[165px] items-center w-fit xl:pl-2 bg-[#3BBC30] text-white rounded-[8px] h-[43px] pr-3 pl-3 max-[1380px]:p-0 max-[1310px]:pr-1 max-[1310px]:pl-1 max-[1275px]:left-[-30px]">
            <img src={plusWhite} />
            <p
              className="font-medium max-xl:text-[14px] max-xl:font-normal"
              onClick={() => {
                studClassAdd(user)
              }}
            >
              Добавить в класс
            </p>
          </div>
        </td>
      </tr>
    ))
  }

  const addEmptyRoom = async () => {
    if (roomName.length > 25) {
      showSnackbar('Название должно содержать не более 25 символов.', 'error')
      return
    }

    const response = await createRoom(courseId)
    if (response.success) {
      const newRooms = await fetchRooms()
      setSelectedRoom(newRooms.find(r => r.id === response.id))
    }
  }

  const updateRoomLessonState = updatedLesson => {
    setRoomLesson(updatedLesson)
  }

  const onFileChange = async e => {
    if (e.target.files && e.target.files.length) {
      const form = new FormData()
      form.append('file', e.target.files[0])
      const response = await createRoomFile(courseId, form)
      if (response.success) {
        const newRooms = await fetchRooms()
        setSelectedRoom(newRooms[newRooms.length - 1])
      }
    }
  }

  const addUserRoom = async (user, room) => {
    const body = {
      userId: user.id,
      roomId: room.id
    }
    const response = await addUser(courseId, body)
    if (response.success) {
      const newRooms = await fetchRooms()
      setSelectedRoom(newRooms.find(r => r.id === room.id))
      showSnackbar('Студент успешно добавлен.', 'success')
    } else {
      let currentClass = null
      for (let r of rooms) {
        if (r.users.find(u => u.id === user.id)) {
          currentClass = r
          break
        }
      }
      if (currentClass) {
        showSnackbar(
          `Студент ${user.firstname} ${user.lastname} уже находится в классе "${currentClass.title}".`,
          'error'
        )
      } else {
        showSnackbar('Ошибка при добавлении студента.', 'error')
      }
    }
  }

  useEffect(() => {
    fetchRooms()
  }, [])

  useEffect(() => {
    if (name.length >= 3) {
      searchByName(name, selectOption)
    }
  }, [name, selectOption])

  useEffect(() => {
    if (name.length) {
      roomSearchHandler(name, selectOption)
    }
  }, [name, selectOption])

  useEffect(() => {
    setName('')
    setModing('')
    setResults([])
  }, [selectedRoom])

  useEffect(() => {
    const lastSelectedRoom = localStorage.getItem('lastSelectedRoom')
    if (lastSelectedRoom) {
      try {
        const parsed = JSON.parse(lastSelectedRoom)
        console.log(parsed)
        if (parsed.courseId === parseInt(courseId)) {
          setSelectedRoom(parsed)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }, [])

  const handleSelectRoom = room => {
    setSelectedRoom(room)
    localStorage.setItem('lastSelectedRoom', JSON.stringify(room))
  }

  const handleSelectRoom2 = () => {
    setSelectedRoom({})
    localStorage.removeItem('lastSelectedRoom')
  }
  // useEffect(() => {
  //     if(selectedUser?.id && selectedRoom?.id) {
  //         addUserRoom(selectedUser, selectedRoom)
  //     }
  // }, [selectedUser])

  const addStudent = selectedUser => {
    if (selectedUser?.id && selectedRoom?.id) {
      addUserRoom(selectedUser, selectedRoom)
        .then(() => {
          const updatedResults = results.filter(u => u.id !== selectedUser.id)
          setResults(updatedResults)
          setShowModal(false)
          setSelectedUser(null)
          setName('')
        })
        .catch(error => {
          showSnackbar('Ошибка при добавлении студента.', 'error')
          console.error(error)
        })
    }
  }

  const download = async id => {
    await downloadRooms(id)
  }

  const enableEditMode = async room => {
    if (!editMode) {
      setEditMode(true)
      setRoomName(room.title)
    } else {
      if (roomName.length > 25) {
        showSnackbar('Название должно содержать не более 25 символов.', 'error')
        return
      }
      const body = {
        title: roomName
      }
      const response = await updateRoom(courseId, room.id, body)
      if (response.success) {
        setEditMode(false)
        const newRooms = await fetchRooms()
        setSelectedRoom(newRooms.find(r => r.id === room.id))
      }
    }
  }

  const goToUser = id => () => {
    navigate(`/admin/teacher/courses/${courseId}/users/${id}`)
  }

  const toggleSortDirection = () => {
    setSortDirection(prevDirection =>
      prevDirection === 'asc' ? 'desc' : 'asc'
    )
  }

  const handleSort = (columnName, direction) => {
    console.log('dir ', direction)

    if (sortColumn === columnName) {
      toggleSortDirection()
    } else {
      setSortColumn(columnName)
      setSortDirection(direction)
    }
  }

  const renderSortIndicator = columnName => {
    const isSortedColumn = sortColumn === columnName

    return (
      <div className="flex flex-col">
        <img
          onClick={() => handleSort(columnName, 'asc')}
          src={arrowUp}
          alt="Sort Asc"
          className="mb-[5px] min-w-[12px] min-h-[7px]"
          width={12}
          height={7}
          style={{
            opacity: isSortedColumn && sortDirection === 'asc' ? 1 : 0.5
          }}
        />
        <img
          onClick={() => handleSort(columnName, 'desc')}
          src={arrowDown}
          alt="Sort Desc"
          className="min-w-[12px] min-h-[7px]"
          width={12}
          height={7}
          style={{
            opacity: isSortedColumn && sortDirection === 'desc' ? 1 : 0.5
          }}
        />
      </div>
    )
  }

  const sortUsers = users => {
    return users.sort((a, b) => {
      switch (sortColumn) {
        case 'firstname':
          return sortDirection === 'asc'
            ? (a.firstname ?? '').localeCompare(b.firstname ?? '', 'kz', {
              sensitivity: 'base'
            })
            : (b.firstname ?? '').localeCompare(a.firstname ?? '', 'kz', {
              sensitivity: 'base'
            })
        case 'lastname':
          return sortDirection === 'asc'
            ? (a.lastname ?? '').localeCompare(b.lastname ?? '', 'kz', {
              sensitivity: 'base'
            })
            : (b.lastname ?? '').localeCompare(a.lastname ?? '', 'kz', {
              sensitivity: 'base'
            })
        case 'id':
          return sortDirection === 'asc' ? a.id - b.id : b.id - a.id
        case 'cheatingProbability': {
          const aCheat = userCheat.find(uc => uc.userId === a.id)
          const bCheat = userCheat.find(uc => uc.userId === b.id)
          const aProbability = aCheat
            ? parseFloat(aCheat.probabilityOfCheating)
            : Infinity
          const bProbability = bCheat
            ? parseFloat(bCheat.probabilityOfCheating)
            : Infinity

          if (sortDirection === 'asc') {
            return aProbability - bProbability
          } else {
            return bProbability - aProbability
          }
        }
        case 'lecturesCompleted':
          return sortDirection === 'asc'
            ? a.topicFinished - b.topicFinished
            : b.topicFinished - a.topicFinished
        case 'lessonsCompleted':
          return sortDirection === 'asc'
            ? a.passedLessonCount - b.passedLessonCount
            : b.passedLessonCount - a.passedLessonCount
        case 'quizzesPassed':
          return sortDirection === 'asc'
            ? a.quizzesPassedPercentages - b.quizzesPassedPercentages
            : b.quizzesPassedPercentages - a.quizzesPassedPercentages
        case 'email':
          return sortDirection === 'asc'
            ? (a.email ?? '').localeCompare(b.email ?? '', 'en', {
              sensitivity: 'base'
            })
            : (b.email ?? '').localeCompare(a.email ?? '', 'en', {
              sensitivity: 'base'
            })
        default:
          return 0
      }
    })
  }

  const colors = ['bg-[#FFDF6E]', 'bg-[#78E389]', 'bg-[#72ABFF]']

  const AddStudentModal = ({ selectedUser, addStudent, closeModal }) => {
    return (
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#1A1A1A] bg-opacity-50 z-50">
        <div className="bg-white p-4 rounded-[12px] shadow-md ">
          <div className="flex justify-end mb-2 ml-[0px]">
            <img onClick={closeModal} className="text-end" src={closeIcon} />
          </div>
          <div className="flex items-center gap-3">
            <div>
              <img src={warningIcon} alt="" />
            </div>
            <div className="w-[350px]">
              {selectedUser?.firstname && (
                <p className="text-[#5F6B88] text-left text-[20px]">
                  Вы уверены что хотите добавить <br /> студента{' '}
                  <span className="text-black">
                    {selectedUser.firstname} {selectedUser.lastname}
                  </span>{' '}
                  в класс <br />{' '}
                  <span className="text-black">{selectedRoom.title}</span>?
                </p>
              )}
            </div>
          </div>
          <div className="flex justify-center mt-6 gap-3">
            <button
              className="flex w-full items-center px-4 py-3 text-white rounded bg-[#5F6B88] hover:bg-gray-600 justify-center gap-2 font-bold"
              onClick={closeModal}
            >
              <img src={leftIcon} />
              Вернуться
            </button>
            <button
              className="flex w-full items-center px-4 py-3 mr-2 bg-[#3BBC30] hover:bg-green-500 text-white rounded justify-center gap-2 font-bold"
              onClick={() => addStudent(selectedUser)}
            >
              <img src={addIcon} />
              Добавить
            </button>
          </div>
        </div>
      </div>
    )
  }

  const deleteStudentHandler = async (courseId, userId) => {
    const body = {
      roomId: selectedRoom.id,
      userId: userId
    }
    const response = await deleteStudent(courseId, body)
    if (response.success) {
      const updatedStudents = selectedRoom.users.filter(
        user => user.id !== userId
      )
      setSelectedRoom(prevRoom => ({
        ...prevRoom,
        users: updatedStudents
      }))
    }
  }

  const getCheatingIndicator = probability => {
    if (probability > 0) {
      return { colorClass: 'text-[#F12D2D]', imgSrc: { warningIcon } }
    }
  }

  const renderSortedUsers = () => {
    const sortedUsers = sortUsers(
      selectedRoom?.id ? selectedRoom.users : students
    )

    return sortedUsers.map((user, idx) => {
      const percentage =
        typeof user.quizzesPassedPercentages == 'number'
          ? (user.quizzesPassedPercentages * 100).toFixed(2)
          : '0.00'
      const [integerPart, decimalPart] = percentage.split('.')

      let probabilityIntegerPart,
        probabilityFractionalPart,
        probabilityEmpty = true

      if (userCheat?.find(uc => uc.userId === user.id)?.probabilityOfCheating) {
        probabilityEmpty = false
        const probability = parseFloat(
          userCheat?.find(uc => uc.userId === user.id).probabilityOfCheating * 100
        ).toFixed(1)
        const [intPart, fractPart] = probability.split('.')
        probabilityIntegerPart = intPart
        probabilityFractionalPart = fractPart
      }

      return (
        <tr className={'py-2'} key={idx}>
          <td>{idx + 1}</td>
          <td>
            <div
              className="flex flex-row cursor-pointer items-center"
              onClick={goToUser(user.id)}
            >
              <div
                className={`${colors[idx % colors.length]
                  } rounded-full flex flex-col justify-center items-center w-[38px]  min-w-[38px] h-[38px]`}
              >
                <div>
                  {user.firstname && user.firstname[0]}
                  {user.lastname && user.lastname[0]}
                </div>
              </div>
              <div>
                <div className="ml-2.5">
                  <span className={'text-[16px] text-black opacity-90'}>
                    {user.firstname} {user.lastname}
                  </span>
                </div>
              </div>
            </div>
          </td>
          <td>
            <span>{user.externalId}</span>
          </td>
          <td>
            <p
              className={`${userCheat?.find(uc => uc.userId === user.id)
                ? (getCheatingIndicator(
                  userCheat?.find(uc => uc.userId === user.id)
                    .probabilityOfCheating
                )?.colorClass || 'text-unix-gray')
                : 'text-unix-gray'
                } flex flex-row justify-end items-baseline`}
            >
              {probabilityEmpty ? (
                '-'
              ) : (
                <>
                  <span className="text-xl leading-6">
                    {probabilityIntegerPart}.
                  </span>
                  <span className="text-[15px] leading-[19.5px]">
                    {probabilityFractionalPart}
                  </span>
                  <span className="text-[#AFB7CA] text-[15px] leading-[19.5px]">
                    %
                  </span>
                </>
              )}
            </p>
          </td>
          <td className="">
            <div className="flex justify-center">
              <p>
                <span
                  style={{
                    color:
                      user.topicFinished === user.topicTotalCount
                        ? '#3BBC30'
                        : '#5F6B88',
                    fontSize:
                      user.topicFinished === user.topicTotalCount
                        ? '20px'
                        : '20px'
                  }}
                >
                  {user.topicFinished}
                </span>
                {' / ' + user.topicTotalCount}
              </p>
            </div>
          </td>
          <td className="">
            <p className="flex justify-center items-end">
              <span
                style={{
                  color:
                    user.passedLessonCount === user.lessonsTotalCount
                      ? '#3BBC30'
                      : '#5F6B88',
                  fontSize:
                    user.passedLessonCount === user.lessonsTotalCount
                      ? '20px'
                      : '20px'
                }}
              >
                {user.passedLessonCount}
              </span>
              <span className="relative top-[-1px] left-[3px]">
                {' / ' + user.lessonsTotalCount}{' '}
              </span>
            </p>
          </td>
          <td>
            <p>
              <span className="text-unix-gray text-xl leading-6">
                {integerPart}.
              </span>
              <span className="text-unix-gray text-[15px] leading-[19.5px]">
                {decimalPart}
              </span>
              <span className="text-[#AFB7CA] text-[15px] leading-[19.5px]">
                %
              </span>
            </p>
          </td>
          <td>{user.email}</td>
          {selectedRoom.id && (
            <td onClick={() => openDeleteConfirmationModal(user)}>
              <img className="min-w-[20px]" src={deleteIcon} />
            </td>
          )}
        </tr>
      )
    })
  }

  const renderSearchingUsers = () => {
    return resultsSearch.map((user, idx) => (
      <tr className={'py-2'} key={idx}>
        <td>{idx + 1}</td>
        <td>
          <div
            className="flex flex-row cursor-pointer items-center"
            onClick={goToUser(user.id)}
          >
            <div
              className={`${colors[idx % colors.length]
                } rounded-full flex flex-col justify-center items-center w-[38px]  min-w-[38px] h-[38px]`}
            >
              <div>
                {user.firstname && user.firstname[0]}
                {user.lastname && user.lastname[0]}
              </div>
            </div>
            <div>
              <div className="ml-2.5">
                <span className={'text-[16px] text-black opacity-90'}>
                  {user.firstname} {user.lastname}
                </span>
              </div>
            </div>
          </div>
        </td>
        <td>
          <span>{user.externalId}</span>
        </td>
      </tr>
    ))
  }

  const dayMapping = {
    Monday: 'Пн',
    Tuesday: 'Вт',
    Wednesday: 'Ср',
    Thursday: 'Чт',
    Friday: 'Пт',
    Saturday: 'Сб',
    Sunday: 'Вс'
  }

  const startDateTime = roomLesson
    ? roomLesson?.startDate?.substring(11, 16)
    : ''
  const endDateTime = roomLesson ? roomLesson?.endDate?.substring(11, 16) : ''

  const handleFocus = () => {
    if (name.trim() === '') {
      setModing('')
    } else {
      setModing('searching')
    }
  }

  const handleBlur = () => {
    if (name.trim() === '') {
      setModing('')
    }
  }

  const sortedUsers = sortUsers(
    selectedRoom?.id ? selectedRoom.users : students
  )
  const userCount = sortedUsers.length

  return (
    <div>
      <div className="p-5 ">
        <span className="mb-5  text-black   items-center">
          <span className="flex w-fit items-center bg-white rounded-[12px] p-2">
            <p className="mr-2">Курс</p>
            {modules.map(module => (
              <span key={module.id} className="flex items-center space-x-4">
                {module.courses.map(course => (
                  <a
                    key={course.id}
                    className={`cursor-pointer block px-4 py-2  text-[16px] ml-1 rounded-[8px] ${courseId == course.id ? 'bg-[#0068FF] text-white' : ''
                      }`}
                    href={`/admin/teacher/courses/${course.id}`}
                  >
                    {course.title}
                  </a>
                ))}
              </span>
            ))}
          </span>
        </span>
      </div>
      <div className="grid grid-cols-1 grid-rows-10 w-full h-full pt-3 pb-10 font-sf-pro-display">
        <div className="row-span-8 flex">
          <div className="ml-5 mr-3 mt-0 flex flex-col h-full overflow-y-scroll bg-white p-[5px] rounded-[12px] min-w-[200px] w-[200px]">
            <div
              className={`shrink-0 h-10 flex pl-3 items-center rounded-[5px] max-xl:text-[14px] text-[#000] mb-3 ${selectedRoom.id == null ? 'bg-[#007AFF] text-[#fff]' : ''
                }`}
              onClick={() => handleSelectRoom2()}
            >
              Все классы
            </div>
            {rooms.map(room => (
              <div
                className={`shrink-0 cursor-pointer py-2 flex pl-3 items-center rounded-[5px] mb-3 ${selectedRoom.id === room.id ? 'bg-[#007AFF]' : ' '
                  } `}
                key={room.id}
                onClick={() => handleSelectRoom(room)}
              >
                <p
                  className={`font-normal flex flex-row w-full items-center max-xl:font-normal max-xl:text-[14px] ${selectedRoom.id === room.id ? 'text-white' : 'text-black'
                    }`}
                >
                  <span className="flex-[6] break-words">{room.title}</span>
                  <div
                    className={`flex flex-row justify-end pr-[0.5] flex-[3] gap-[3px]`}
                  >
                    <p
                      className={`${selectedRoom.id === room.id
                        ? 'text-[#fff] opacity-50'
                        : 'text-[#AFB7CA]'
                        }`}
                    >
                      {room.users.length}
                    </p>
                    {selectedRoom.id === room.id ? (
                      <img
                        className="opacity-50"
                        src={studentNumWhite}
                        alt="student white"
                      />
                    ) : (
                      <img src={studentNum} alt="student" />
                    )}
                  </div>
                </p>
              </div>
            ))}
            <div
              className={`shrink-0 h-13 p-2.5 w-[90%] mx-auto rounded-[5px] border  border-[#3BBC30]`}
            >
              <div
                className="flex text-center justify-center items-center cursor-pointer"
                onClick={addEmptyRoom}
              >
                <button>
                  <img src={plus} alt="Add" />
                </button>
                <p className="text-[#3BBC30] max-xl:text-[14px] font-semibold">
                  Создать класс
                </p>
              </div>
            </div>
            <div
              className={`shrink-0 h-13 p-2.5 rounded-[5px] w-[90%] mx-auto border border-[#007AFF] mt-2.5`}
            >
              <div className="flex text-center justify-center gap-2 items-center h-full">
                <label
                  className={'file-mask flex items-center text-[#007AFF] '}
                >
                  <input
                    hidden
                    value={''}
                    type="file"
                    onChange={onFileChange}
                  />
                  <img src={clip} alt="Upload" />
                  <p className="font-semibold text-[15px] max-xl:text-[14px]">
                    Загрузить класс
                  </p>
                </label>
              </div>
            </div>
          </div>
          {/* <input type="text" placeholder="Поиск" className="bg-[#F0F3FA] max-xl:h-[43px] w-[full] stroke-none border-none rounded-[8px]"/> */}
          <div className="w-[100%]">
            {Object.keys(selectedRoom).length === 0 ? (
              <div className="ml-1 rounded-[8px] p-5 bg-white w-[70%] stat-adaptive">
                <input
                  type="text"
                  placeholder="Поиск"
                  className="bg-[#F0F3FA] text-black w-[100%] pl-2 h-[43px] stroke-none border-none rounded-[8px]"
                  onChange={handleAllRoomsSearch}
                />
              </div>
            ) : (
              <div>
                <div
                  className={`bg-white p-5 pl-2 rounded-[12px] ml-1  ${mode == 'add' ? 'w-[95%] stat-adaptive2' : 'stat-adaptive'
                    }`}
                >
                  {/* {selectedRoom?.title ? <span className="mr-1 text-black">Класс # {selectedRoom.id}{" "}</span> : ''} */}
                  <div className="flex flex-row justify-between text-2xl font-light text-[#2B2F38]">
                    {selectedRoom?.id ? (
                      <>
                        <div className="flex items-center gap-0">
                          {editMode ? (
                            <input
                              className={inputClass}
                              style={{ width: '150px' }}
                              value={roomName}
                              onChange={e => setRoomName(e.target.value)}
                            />
                          ) : (
                            <span className="max-xl:text-[14px] font-bold">
                              {selectedRoom.title}
                            </span>
                          )}
                          <button
                            className={`ml-3`}
                            onClick={() => enableEditMode(selectedRoom)}
                          >
                            {editMode ? (
                              <span className="text-xs leading-5 border border-[#3BBC30] text-[14px] text-[#3BBC30] px-1 font-medium flex gap-2 rounded-[8px] items-center h-[40px]">
                                Сохранить
                              </span>
                            ) : (
                              <img src={editIcon} />
                            )}
                          </button>
                        </div>
                        <div className="flex">
                          <div className="flex items-center">
                            {roomLesson.id && startDateTime && endDateTime ? (
                              <div>
                                <div className="flex items-center">
                                  <div>
                                    <p className="text-[13px] h-[20px] relative left-[35px]">
                                      Онлайн урок
                                    </p>
                                    <div className="text-[15px] flex font-medium">
                                      <p className="text-[#1A1A1A] max-[810px]:ml-2">
                                        {dayMapping[roomLesson.day]}.
                                      </p>
                                      <p className="text-[#1A1A1A] ">
                                        {startDateTime} - {endDateTime}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="">
                                    <button
                                      className="ml-2 border rounded-[8px] relative top-[4px] mr-5"
                                      onClick={() =>
                                        setEditLessonModalOpen(true)
                                      }
                                    >
                                      <img src={editIcon} />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <button
                                onClick={() => setModalOpen(true)}
                                className="border border-[#3BBC30] ml-1 text-[14px] text-[#3BBC30] px-4 font-medium flex gap-1 mr-2 rounded-[8px] items-center h-[40px] max-[1300px]:px-1 max-[1200px]:px-1"
                              >
                                {editMode ? (
                                  ''
                                ) : (
                                  <img
                                    className="max-[1110px]:hidden"
                                    src={plusIcon}
                                    alt="Plus"
                                  />
                                )}
                                Создать онлайн урок
                              </button>
                            )}
                            <LessonModal
                              roomId={selectedRoom.id}
                              isOpen={modalOpen}
                              onClose={() => setModalOpen(false)}
                              updateRoomLessonState={updateRoomLessonState}
                            />
                            <button
                              className="flex items-center gap-2 border border-[#0068FF] h-[40px] rounded-[8px] text-[14px] text-[#0068FF] px-4 max-[1200px]:px-1"
                              onClick={() => download(selectedRoom.id)}
                            >
                              {editMode ? (
                                ''
                              ) : (
                                <img
                                  className="max-[1110px]:hidden"
                                  src={exportIcon}
                                  alt="Plus"
                                />
                              )}
                              {/* {editMode ? '' : <img className="min-[1201px]:hidden" src={exportIcon} alt="Plus"/>} */}
                              Выгрузить
                            </button>
                            <button
                              className="flex items-center gap-2 border border-[#F12D2D] h-[40px] text-[#F12D2D] text-[14px] rounded-[8px] px-4 ml-2 max-[1200px]:px-1"
                              onClick={() => openDeleteModal(selectedRoom.id)}
                            >
                              {editMode ? (
                                ''
                              ) : (
                                <img
                                  className="max-[1110px]:hidden"
                                  src={deleteIcon}
                                  alt="Plus"
                                />
                              )}
                              Удалить
                            </button>
                          </div>
                        </div>
                        {isDeleteModalOpen && (
                          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
                            <div className="bg-white p-4 rounded-lg shadow-lg">
                              <div className="flex justify-end mb-4 cursor-pointer">
                                <img
                                  onClick={() => setIsDeleteModalOpen(false)}
                                  src={closeIcon}
                                />
                              </div>
                              <div className="flex items-center gap-4">
                                <img src={warningIcon} />
                                <h4 className="text-[#5F6B88]">
                                  Вы уверены что хотите удалить класс <br />{' '}
                                  <span className="text-[#1A1A1A]">
                                    {selectedRoom.title}
                                  </span>
                                  ?
                                </h4>
                              </div>
                              <div className="flex justify-end mt-12">
                                <button
                                  onClick={() => setIsDeleteModalOpen(false)}
                                  className="bg-[#5F6B88] text-white h-[48px] rounded-[8px] w-full flex items-center justify-center gap-2"
                                >
                                  <img src={leftIcon} />
                                  Вернуться
                                </button>
                                <button
                                  onClick={confirmDeleteRoom}
                                  className="bg-[#F12D2D] text-white h-[48px] ml-3 rounded-[8px] w-full flex items-center justify-center gap-2"
                                >
                                  <img src={bucket} />
                                  Удалить
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
                {showModal && (
                  <AddStudentModal
                    selectedUser={selectedUser}
                    addStudent={addStudent}
                    closeModal={closeModal}
                    selectedRoom={selectedRoom}
                  />
                )}
                <DeleteConfirmationModal
                  isOpen={isDeleteConfirmationOpen}
                  onClose={closeDeleteConfirmationModal}
                  onConfirm={confirmDeleteStudent}
                  student={studentToDelete}
                  selectedRoom={selectedRoom}
                />
                <div
                  className={`flex bg-white ml-1 p-3 mt-3 gap-2 stat-adaptive items-center ${mode == 'add' ? 'w-[95%] stat-adaptive2' : 'stat-adaptive'
                    } `}
                >
                  {mode == 'add' ? (
                    <TextField
                      sx={{
                        borderRadius: '8px',
                        height: '43px',
                        paddingLeft: '15px',
                        paddingTop: '5px'
                      }}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true
                      }}
                      className="w-80 ml-4 flex-1 bg-[#F0F3FA] rounded-[8px]  text-[#AFB7CA] border-none outline-none"
                      value={name}
                      onChange={handleInputChange}
                      placeholder="Поиск"
                    />
                  ) : (
                    <TextField
                      sx={{
                        borderRadius: '8px',
                        height: '43px',
                        paddingLeft: '15px',
                        paddingTop: '5px'
                      }}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true
                      }}
                      className="w-80 ml-4 flex-1 bg-[#F0F3FA] rounded-[8px]  text-[#AFB7CA] border-none outline-none"
                      value={name}
                      onChange={handleInputChange2}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      placeholder="Поиск"
                    />
                  )}
                  <button className="bg-[#0068FF] text-[#0068FF] h-[43px] bg-opacity-[7%] p-1 border border-opacity-50 rounded-[8px] border-[#0068FF] flex items-center gap-2 pr-3">
                    <button
                      className={`h-[35px] bg-[#0068FF] max-xl:text-[14px] items-center gap-2 flex  rounded-[6px] px-3 py-2 ${mode == 'search' ? 'bg-opacity-100' : 'bg-opacity-[0%]'
                        }`}
                    >
                      {mode == 'search' ? (
                        <img src={search} />
                      ) : (
                        <img src={searchBlue} />
                      )}
                      <p
                        className={`${mode == 'search' ? 'text-white' : 'text-[#0068FF]'
                          } max-xl:block xl:hidden pr-2`}
                        onClick={() => {
                          setMode('search')
                        }}
                      >
                        Поиск
                      </p>
                      <p
                        className={`${mode == 'search' ? 'text-white' : 'text-[#0068FF]'
                          } max-xl:hidden`}
                        onClick={() => {
                          setMode('search')
                        }}
                      >
                        Поиск в классе
                      </p>
                    </button>
                    <div
                      className={`${mode == 'add'
                        ? 'bg-[#0068ff] bg-opacity-100 text-white'
                        : ''
                        } rounded-[6px] px-3 py-1.5 flex items-center w-fit xl:pl-2`}
                    >
                      {mode == 'add' ? (
                        <img src={addWhite} />
                      ) : (
                        <img src={add} />
                      )}
                      <p
                        className="font-medium max-xl:text-[14px] max-xl:font-normal xl:pl-2"
                        onClick={() => {
                          setMode('add')
                        }}
                      >
                        Добавить студента
                      </p>
                    </div>
                  </button>
                </div>
              </div>
            )}
            <div
              className={`unix-card ml-1 mt-5 stat-adaptive ${mode == 'add' ? 'w-[95%] stat-adaptive2' : 'stat-adaptive'
                }`}
              style={{ overflowX: 'auto' }}
            >
              {mode == 'add' ? (
                <table className="orgs-table text-[#5F6B88] w-[100%]">
                  <thead>
                    <tr className="text-left font-medium text-[14px]">
                      <th>#</th>
                      <th
                        className="w-[300px]"
                        onClick={() => handleSort('firstname')}
                      >
                        <div className="flex font-medium items-center gap-2">
                          Студент {renderSortIndicator('firstname')}
                        </div>
                      </th>
                      <th onClick={() => handleSort('id')}>
                        <div className="flex font-medium items-center gap-2 pr-10">
                          ID {renderSortIndicator('id')}
                        </div>
                      </th>
                      <th onClick={() => handleSort('email')}>
                        <div className="flex font-medium items-center gap-2 max-w-[150px]">
                          Почта
                          {renderSortIndicator('email')}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ overflowY: 'auto' }}>
                    {renderSearchResults()}
                  </tbody>
                </table>
              ) : (
                <table className="orgs-table text-[#5F6B88] table-auto 2xl:w-[100%]">
                  <thead>
                    <tr className="text-left text-[14px]">
                      <th className="font-medium">#</th>
                      <th onClick={() => handleSort('firstname')}>
                        <div className="flex font-medium items-center gap-2">
                          Студент{' '}
                          {moding !== 'searching' && (
                            <span className="text-[#AFB7CA]">
                              ({userCount})
                            </span>
                          )}
                          {renderSortIndicator('firstname')}
                        </div>
                      </th>
                      <th onClick={() => handleSort('id')}>
                        <div className="flex font-medium items-center gap-2">
                          ID {renderSortIndicator('id')}
                        </div>
                      </th>
                      <th onClick={() => handleSort('cheatingProbability')}>
                        <div className="flex justify-end font-medium text-right items-center gap-2 w-[100%]">
                          <span>
                            Вероятность <br /> читинга{' '}
                          </span>
                          <div>
                            {renderSortIndicator('cheatingProbability')}
                          </div>
                        </div>
                      </th>
                      <th onClick={() => handleSort('lecturesCompleted')}>
                        <div className="flex font-medium text-right items-center gap-2">
                          Пройдено <br /> лекций
                          {renderSortIndicator('lecturesCompleted')}
                        </div>
                      </th>

                      <th onClick={() => handleSort('lessonsCompleted')}>
                        <div className="flex font-medium text-right items-center gap-2">
                          Пройдено <br /> уроков
                          {renderSortIndicator('lessonsCompleted')}
                        </div>
                      </th>
                      <th onClick={() => handleSort('quizzesPassed')}>
                        <div className="flex font-medium items-center gap-2">
                          Тесты
                          {renderSortIndicator('quizzesPassed')}
                        </div>
                      </th>
                      <th onClick={() => handleSort('email')}>
                        <div className="flex font-medium items-center gap-2">
                          Почта
                          {renderSortIndicator('email')}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ overflowY: 'auto' }}>
                    {moding == 'searching'
                      ? renderSearchingUsers()
                      : renderSortedUsers()}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      {editLessonModalOpen && (
        <EditLessonModal
          isOpen={editLessonModalOpen}
          onClose={() => setEditLessonModalOpen(false)}
          roomLesson={roomLesson}
          fetchRoomLesson={fetchRoomLesson}
          onLessonUpdate={handleLessonUpdate}
          updateRoomLessonState={updateRoomLessonState}
        />
      )}
    </div>
  )
}

const DaysOfWeekSelect = ({ value, onChange }) => {
  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={{
        backgroundColor: '#F0F3FA',
        marginBottom: '1rem',
        borderRadius: '8px',
        position: 'relative',
        top: '1px',
        color: '#1A1A1A'
      }}
    >
      <Select
        value={value}
        onChange={onChange}
        fullWidth
        disableUnderline
        IconComponent={ExpandMoreIcon}
        sx={{
          '& .MuiSelect-icon': { right: '10px' },
          '& .MuiListItem-root.Mui-selected, & .MuiListItem-root.Mui-selected:hover & .MuiListItem-root.Mui-selected:focus':
          {
            backgroundColor: 'transparent'
          },
          paddingLeft: '16px',
          paddingTop: '11.5px',
          paddingBottom: '11.5px'
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: '#F0F3FA',
              paddingLeft: '16px',
              paddingTop: '11.5px',
              paddingBottom: '11.5px'
            }
          }
        }}
      >
        <MenuItem value="Monday">Понедельник</MenuItem>
        <MenuItem value="Tuesday">Вторник</MenuItem>
        <MenuItem value="Wednesday">Среда</MenuItem>
        <MenuItem value="Thursday">Четверг</MenuItem>
        <MenuItem value="Friday">Пятница</MenuItem>
        <MenuItem value="Saturday">Суббота</MenuItem>
        <MenuItem value="Sunday">Воскресенье</MenuItem>
      </Select>
    </FormControl>
  )
}

// const createOnlineLesson = async (lessonDetails) => {
//     try {
//         const response = await fetch('https://uni-x-api-dev.kz/online-lessons', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify(lessonDetails),
//         });
//         const data = await response.json();
//         if (response.ok) {
//             console.log('Online lesson created successfully:', data);
//         } else {
//             console.error('Error creating online lesson:', data);
//         }
//     } catch (error) {
//         console.error('Error creating online lesson:', error);
//     }
// };

const LessonModal = ({ isOpen, onClose, roomId, updateRoomLessonState }) => {
  const { courseId } = useParams()
  const [googleMeetLink, setGoogleMeetLink] = useState('')
  const [startTime, setStartTime] = useState('')
  const [dayOfWeek, setDayOfWeek] = useState('')
  const [endTime, setEndTime] = useState('')

  const handleClose = () => {
    setGoogleMeetLink('')
    setDayOfWeek('')
    setStartTime('')
    setEndTime('')
    onClose()
  }

  const handleDayOfWeekChange = e => {
    setDayOfWeek(e.target.value)
  }

  const handleSave = async () => {
    if (!googleMeetLink.startsWith('https://')) {
      showSnackbar('Ссылка должна начинаться с https://', 'error')
      return
    }
    const lessonDetails = {
      roomId: roomId,
      courseId: parseInt(courseId),
      link: googleMeetLink,
      day: dayOfWeek,
      startDate:
        new Date().toISOString().split('T')[0] + `T${startTime}:00.000Z`,
      endDate: new Date().toISOString().split('T')[0] + `T${endTime}:00.000Z`
    }

    const response = await createOnlineLesson(lessonDetails)
    console.log(response)

    if (response.success) {
      updateRoomLessonState(response.data)
      showSnackbar('Онлайн урок успешно добавлен.', 'success')
      handleClose()
    } else {
      console.error('Failed to create online lesson')
    }
  }
  const generateTimeSlots = () => {
    const timeSlots = []
    let hour = 8
    let minute = 0

    while (hour < 20 || (hour === 20 && minute === 0)) {
      const time = `${hour.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')}`
      timeSlots.push(time)
      minute += 30
      if (minute >= 60) {
        minute = 0
        hour += 1
      }
    }

    return timeSlots
  }

  const timeSlots = generateTimeSlots()

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
        <div className="modal-content p-4 bg-white rounded-lg">
          <div className="flex justify-end" onClick={handleClose}>
            <img src={closeIcon} alt="Close" />
          </div>
          <h2 className="text-xl mb-4">Онлайн урок</h2>
          <p className="mb-[8px]">Ссылка на Google Meet</p>
          <TextField
            variant="standard"
            InputProps={{
              disableUnderline: true
            }}
            sx={{
              paddingLeft: '16px',
              paddingTop: '12px',
              paddingBottom: '12px',
              borderRadius: '8px',
              width: '450px'
            }}
            className="bg-[#F0F3FA] text-[#AFB7CA] pl-4 rounded-[8px]"
            placeholder="Введите ссылку"
            fullWidth
            value={googleMeetLink}
            onChange={e => setGoogleMeetLink(e.target.value)}
          />
          <div className="p-0 flex">
            <div className="w-[190px]">
              <p className="mt-5">День недели</p>
              <DaysOfWeekSelect
                value={dayOfWeek}
                onChange={handleDayOfWeekChange}
              />
            </div>
            <div className="w-[120px] outline-none relative left-[10px]">
              <p className="mt-5 mr-5 ">Начало</p>
              <select
                className="custom-select bg-[#F0F3FA] text-black mb-3 rounded-[8px] w-full p-2 pl-[16px] h-14 outline-none border-none"
                value={startTime}
                onChange={e => setStartTime(e.target.value)}
              >
                <option value="" disabled>
                  Время
                </option>
                {timeSlots.map((time, index) => (
                  <option key={index} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-[120px] relative left-[20px]">
              <p className="mt-5">Окончание</p>
              <select
                className="custom-select bg-[#F0F3FA] text-black rounded-[8px] mb-3  w-full p-2 pl-[16px] h-14  outline-none border-none"
                value={endTime}
                onChange={e => setEndTime(e.target.value)}
              >
                <option value="" disabled>
                  Время
                </option>
                {timeSlots.map((time, index) => (
                  <option key={index} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-6 flex justify-end">
            <button
              onClick={handleClose}
              className="bg-[#5F6B88] text-white h-[40px] rounded-[8px] w-full flex items-center justify-center gap-2"
            >
              <img src={leftIcon} alt="Back" />
              Вернуться
            </button>
            <button
              onClick={handleSave}
              className={`bg-[#3BBC30] text-white h-[40px] ml-3 rounded-[8px] w-full flex items-center justify-center gap-2 
                                ${dayOfWeek === '' ||
                  startTime === '' ||
                  endTime === ''
                  ? 'bg-opacity-30'
                  : ''
                }`}
            >
              <img src={tick} alt="Save" />
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const EditLessonModal = ({
  isOpen,
  onClose,
  roomLesson,
  updateRoomLessonState
}) => {
  const [googleMeetLink, setGoogleMeetLink] = useState(roomLesson?.link || '')
  const [startTime, setStartTime] = useState(
    roomLesson?.startDate?.substring(11, 16) || ''
  )
  const [dayOfWeek, setDayOfWeek] = useState(roomLesson?.day || '')
  const [endTime, setEndTime] = useState(
    roomLesson?.endDate?.substring(11, 16) || ''
  )

  const handleDayOfWeekChange = e => {
    setDayOfWeek(e.target.value)
  }

  const handleSave = async () => {
    const lessonDetails = {
      link: googleMeetLink,
      day: dayOfWeek,
      startDate:
        roomLesson.startDate.substring(0, 10) + `T${startTime}:00.000Z`,
      endDate: roomLesson.startDate.substring(0, 10) + `T${endTime}:00.000Z`
    }

    const response = await updateRoomLesson(roomLesson.id, lessonDetails)
    console.log(response)

    if (response.success) {
      updateRoomLessonState(response.data)
      onClose(true)
    } else {
      console.error('Failed to update online lesson')
    }
  }

  const generateTimeSlots = () => {
    const timeSlots = []
    let hour = 8
    let minute = 0

    while (hour < 20 || (hour === 20 && minute === 0)) {
      const time = `${hour.toString().padStart(2, '0')}:${minute
        .toString()
        .padStart(2, '0')}`
      timeSlots.push(time)
      minute += 30
      if (minute >= 60) {
        minute = 0
        hour += 1
      }
    }

    return timeSlots
  }

  const timeSlots = generateTimeSlots()

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
        <div className="modal-content p-4 bg-white rounded-lg">
          <div className="flex justify-end" onClick={onClose}>
            <img src={closeIcon} alt="Close" />
          </div>
          <h2 className="text-xl mb-4">Редактировать онлайн урок</h2>
          <p className="mb-[8px]">Ссылка на Google Meet</p>
          <TextField
            variant="standard"
            InputProps={{
              disableUnderline: true
            }}
            sx={{
              paddingLeft: '16px',
              paddingTop: '12px',
              paddingBottom: '12px',
              borderRadius: '8px',
              width: '450px'
            }}
            className="bg-[#F0F3FA] text-[#AFB7CA] pl-4 rounded-[8px]"
            placeholder="Введите ссылку"
            fullWidth
            value={googleMeetLink}
            onChange={e => setGoogleMeetLink(e.target.value)}
          />
          <div className="p-0 flex">
            <div className="w-[190px]">
              <p className="mt-5">День недели</p>
              <DaysOfWeekSelect
                value={dayOfWeek}
                onChange={handleDayOfWeekChange}
              />
            </div>
            <div className="w-[120px] outline-none relative left-[10px]">
              <p className="mt-5 mr-5 ">Начало</p>
              <select
                className="bg-[#F0F3FA] text-black mb-3 rounded-[8px] w-full p-2 pl-[16px] h-14 outline-none border-none"
                value={startTime}
                onChange={e => setStartTime(e.target.value)}
              >
                <option value="" disabled>
                  Время
                </option>
                {timeSlots.map((time, index) => (
                  <option key={index} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-[120px] relative left-[20px]">
              <p className="mt-5">Окончание</p>
              <select
                className="bg-[#F0F3FA] text-black rounded-[8px] mb-3  w-full p-2 pl-[16px] h-14  outline-none border-none"
                value={endTime}
                onChange={e => setEndTime(e.target.value)}
              >
                <option value="" disabled>
                  Время
                </option>
                {timeSlots.map((time, index) => (
                  <option key={index} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mt-6 flex justify-end">
            <button
              onClick={onClose}
              className="bg-[#5F6B88] text-white h-[40px] rounded-[8px] w-full flex items-center justify-center gap-2"
            >
              <img src={leftIcon} alt="Back" />
              Вернуться
            </button>
            <button
              onClick={handleSave}
              className={`bg-[#3BBC30] text-white h-[40px] ml-3 rounded-[8px] w-full flex items-center justify-center gap-2 
                                ${dayOfWeek === '' ||
                  startTime === '' ||
                  endTime === ''
                  ? 'bg-opacity-30'
                  : ''
                }`}
            >
              <img src={tick} alt="Save" />
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  student,
  selectedRoom
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="w-[50%] absolute top-[35%] left-[30%] modal-content bg-white rounded-lg overflow-hidden p-4">
        <div className="flex justify-end mb-2 ml-[0px]">
          <img onClick={onClose} className="text-end" src={closeIcon} />
        </div>
        <div className="flex items-center">
          <div>
            <img src={warningIcon} alt="" />
          </div>
          <div className="w-[90%] text-[20px] text-[#5F6B88]">
            <p className="ml-4 mb-">
              Вы уверены что хотите удалить студента{' '}
              <span className="text-[#1A1A1A]">
                {student?.firstname} {student?.lastname}
              </span>{' '}
              из класса{' '}
              <span className="text-[#1a1a1a]">{selectedRoom?.title}</span>
            </p>
          </div>
        </div>
        <div className="flex justify-end space-x-2 mt-8">
          <button
            onClick={onClose}
            className="bg-[#5F6B88] text-white h-[48px] rounded-[8px] w-full flex items-center justify-center gap-2"
          >
            <img src={leftIcon} />
            Вернуться
          </button>
          <button
            onClick={onConfirm}
            className="bg-[#F12D2D] text-white h-[48px] ml-3 rounded-[8px] w-full flex items-center justify-center gap-2"
          >
            <img src={bucket} />
            Удалить
          </button>
        </div>
      </div>
    </Modal>
  )
}

import { useEffect } from "react";

const useOutsideClick = (ref, onClickOutside = () => {}, exceptions = []) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && !exceptions.filter(c => [...(event.target?.classList || [])].includes(c)).length) {
          setTimeout(() => {
            onClickOutside()
          }, 100)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}


export {
    useOutsideClick
}
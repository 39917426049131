import { modulesApi } from "../api"
import { showSnackbar } from "./snackbar"

const createModule = async (body) => {
    const data = {}
    try {
        const response = await modulesApi.create(body)
        if (response.data.id) {
            showSnackbar()
            data.success = true
            data.id = response.data.id
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const updateModule = async (id, body) => {
    const data = {}
    try {
        const response = await modulesApi.update(id, body)
        if (response.data.id) {
            showSnackbar()
            data.success = true
            data.id = response.data.id
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const moduleDelete = async (id) => {
    const data = {}
    try {
        const response = await modulesApi.deleteModule(id)
        if (response.data.id) {
            showSnackbar()
            data.success = true
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getModule = async (id) => {
    const data = {}
    try {
        const response = await modulesApi.getModule(id)
        if (response.data.id) {
            data.success = true
            data.module = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getModuleAdmin = async (id) => {
    const data = {}
    try {
        const response = await modulesApi.getModuleAdmin(id)
        if (response.data) {
            data.success = true
            data.body = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const moduleStats = async (id) => {
    const data = {}
    try {
        const response = await modulesApi.moduleStats(id)
        if (response.data) {
            data.success = true
            data.module = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getModuleCourses = async (id) => {
    const data = {}
    try {
        const response = await modulesApi.moduleCourses(id)
        if (response.data.courses) {
            data.success = true
            data.courses = response.data.courses
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}


const getModules = async () => {
    const data = {}
    try {
        const response = await modulesApi.all()
        if (response.data.modules) {
            data.success = true
            data.modules = response.data.modules
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const search = async (q) => {
    const data = {}
    try {
        const response = await modulesApi.search(q)
        if (response.data instanceof Array) {
            data.success = true
            data.results = response.data
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const getModuleStats = async () => {
    let data = {}
    try {
        const response = await modulesApi.stats()
        if ('userTotalCount' in response.data) {
            data.success = true
            data = {
                ...data,
                ...response.data
            }
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const addCoursesToModulePost = async (moduleId, body) => {
    const data = {}
    try {
        const response = await modulesApi.addCourses(moduleId, body)
        if (response.data?.length) {
            data.success = true
        }
    } catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const searchModuleAdmin = async (moduleId, q) => {
    const data = {}
    try {
        const response = await modulesApi.searchModuleAdmin(moduleId, q)
        if (response.data?.length) {
            data.users = response.data
            data.success = true
        }
    } catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const searchModerators = async (moduleId, q) => {
    const data = {}
    try {
        const response = await modulesApi.searchModerators(moduleId, q)
        if (response.data?.length) {
            data.users = response.data
            data.success = true
        }
    } catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

const deleteModer = async (moduleId, userId) => {
    const data = {}
    try {
        const response = await modulesApi.deleteModer(moduleId, userId)
        if (response.data.id) {
            showSnackbar()
            data.success = true
        }
    }
    catch (e) {
        console.error(e)
        data.success = false
    }
    return data
}

export {
    createModule,
    updateModule,
    getModule,
    getModuleCourses,
    getModules,
    addCoursesToModulePost,
    moduleDelete,
    getModuleStats,
    search,
    moduleStats,
    searchModuleAdmin,
    getModuleAdmin,
    searchModerators,
    deleteModer
}
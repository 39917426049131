import { useEffect, useState } from "react"
import { simpleGetter } from "../actions/common"
import { getModule, getModuleCourses, getModuleStats, getModules, search } from "../actions/module"
import { modulesApi } from "../api"

const useModule = (id) => {

    const [data, setData] = useState({})

    const fetchModule = async () => {
        const response = await getModule(id)
        if (response.success) {
            setData(response.module)
        }
    }

    useEffect(() => {
        if (id) {
            fetchModule()
        }
    }, [id])
    return data
}

const useMemoModule = (id) => {
    const [data, setData] = useState({})

    const fetchModule = async () => {
        const response = await getModule(id)
        if (response.success) {
            setData({
                ...data,
                [id]: response.module
            })
        }
    }

    useEffect(() => {
        if (Boolean(id) && !(id in data)) {
            fetchModule()
        }
    }, [id])

    return data[id] || {}
}

const useModuleCourses = (id) => {
    const [data, setData] = useState([])

    const fetchModule = async () => {
        const response = await getModuleCourses(id)
        if (response.success) {
            setData(response.courses)
        }
    }

    useEffect(() => {
        fetchModule()
    }, [])
    return data
}

const useStats = () => {
    const [data, setData] = useState([])

    const fetchModules = async () => {
        const response = await getModuleStats()
        if (response.success) {
            setData(response)
        }
    }

    useEffect(() => {
        fetchModules()
    }, [])
    return data
}

const useModuleProgressMultiple = (ids) => {
    const [data, setData] = useState([])
    const [reduced, setReduced] = useState([])

    const fetchStats = async () => {
        const promisesArr = ids.map(id => ({ id, promise: simpleGetter(modulesApi.userProgress, id) }))
        const promises = promisesArr.map(p => p.promise)
        const responses = await Promise.allSettled(promises)
        const finalArr = responses.map((res, i) => ({ result: res.value, status: res.status, id: promisesArr[i].id }))
        setData(finalArr)
        setReduced(finalArr.map(r => r.result).reduce((acc, curr) => [...acc, ...curr], []))
    }

    useEffect(() => {
        if (ids.length) {
            fetchStats()
        }
    }, [ids.length])
    return { data, reduced }
}

const useModuleProgress = (id) => {
    const [data, setData] = useState([])

    const fetchStats = async () => {
        const response = await simpleGetter(modulesApi.userProgress, id)
        if (response.length) {
            setData(response)
        }
    }

    useEffect(() => {
        fetchStats()
    }, [])
    return data
}

const useModules = () => {
    const [data, setData] = useState([])

    const fetchModules = async () => {
        const response = await getModules()
        if (response.success) {
            setData(response.modules.sort((a, b) => a.id - b.id))
        }
    }

    useEffect(() => {
        fetchModules()
    }, [])
    return data
}

const useSearch = (query = "") => {
    const [data, setData] = useState([])

    const fetch = async () => {
        const response = await search(query)
        if (response.success) {
            setData(response.results)
        }
    }

    useEffect(() => {
        if (query.length >= 3) {
            fetch()
        } else {
            setData([])
        }
    }, [query])
    return data
}

export {
    useMemoModule, useModule, useModuleCourses,
    useModuleProgress,
    useModuleProgressMultiple,
    useModules, useSearch, useStats
}

import lessonCircleFinished from '../../../../assets/lesson-circle-finished.svg'
import lessonCircleNotStarted from '../../../../assets/lesson-circle-not-started.svg'
import lessonCircleStarted from '../../../../assets/lesson-circle-started.svg'
import { useGlobalMiscState } from '../../../../stores/misc'
import { getCourseLocalizedValue } from '../../../../utils'

export const SingleLesson = ({ lesson, currentLessonId }) => {
  const miscState = useGlobalMiscState()
  const platformLang = miscState.get('lang')
  const getLessonCircle = () => {
    if (!lesson.lessonsProgressesMap?.length) {
      return lessonCircleNotStarted
    } else {
      if (lesson.lessonsProgressesMap[0].isWatched) {
        if (
          lesson.quizes?.length === 0 ||
          (lesson.quizes?.length !== 0 &&
            lesson.lessonsProgressesMap[0].isPassed)
        ) {
          return lessonCircleFinished
        } else {
          return lessonCircleStarted
        }
      } else {
        return lessonCircleNotStarted
      }
    }
  }

  const isCurrentLesson = lesson.id === currentLessonId
  const iconCircle = getLessonCircle()
  return (
    <a
      onClick={() => localStorage.setItem('lastWatchedLesson', lesson.id)}
      className="flex flex-row items-center cursor-pointer"
      href={`/platform/lessons/${lesson.id}`}
    >
      <div className="flex flex-col items-center px-1.5">
        <div className="w-[1px] h-[7.5px] bg-[rgba(175,183,202,0.5)]" />
        <img className="w-4 h-4" src={iconCircle} />
        <div className="w-[1px] h-[7.5px] bg-[rgba(175,183,202,0.5)]" />
      </div>
      <div
        className={`${isCurrentLesson ? 'bg-[rgba(0,104,255,0.08)]' : ''} rounded-lg px-2 py-[4px] flex-1`}
      >
        <span className="text-sm text-unix-text-black dark:text-white line-clamp-1">
          {getCourseLocalizedValue('title', lesson, platformLang)}
        </span>
      </div>
    </a>
  )
}
